<template>
    <div>
        <div v-if="!existCanonical">
            <h5 class="mt-25 pt-0 d-flex" style="font-size: 14px !important; margin-left: 2px;">Current canonical URL:
                <div class="d-flex mt-0" v-if="objectError.filter(ele => ele.idc === 11 && ele.errors[0].iE === true).length">
                    <span class="mr-50 ml-50">
                        <feather-icon
                            icon="XIcon"
                            size="17"
                            style="color: rgb(252, 125, 125) !important; position: relative !important; top: -3px !important;"
                        />
                    </span>
                    <span style="color: gray !important; font-size: 14px !important; font-weight: 300 !important; padding-top: 0px; position: relative; top: -0.3px;">missing</span>
                </div>
                <span style="color: gray !important; font-size: 14px !important; font-weight: 400 !important; padding-top: 0px;" class="ml-50" v-else>
                    {{ url }}
                </span>
            </h5>
        </div>
        <div v-if="existCanonical">
            <h5 style="font-size: 14px !important; margin-left: 2px;">Current canonical URL:
                <span class="" style="color: gray; font-weight: 400; margin-left: 5px; font-size: 14px;"> {{ url }}</span>
            </h5>
            <b-button
                variant="primary"
                class="float-right d-block"
                :style="'width: 140px; height: 40px; margin-top: 0px !important;'"
                @click.prevent="existCanonical = false"
            >
                Edit
            </b-button>
        </div>
        <b-tabs class="copilot-tabs" style="margin-bottom: 4rem;" v-else>
            <b-tab @click.prevent="validateAndEmit(urlCanonical,'suggest')" :active="activeTab === 'sugg' ? true : false">
                <template #title>
                    <span>Suggestion</span>
                </template>

                <div>
                    <my-text-input
                        v-model="urlCanonical"
                        :is-valid-url.sync="isUrlValid"
                        :placeholder="'Canonical URL'"
                        :disabled-input="true"
                        :style="savingCanonical ? 'cursor: no-drop !important;' : ''"
                    />
                    <b-button
                        variant="primary"
                        class="float-right d-block mt-75 btn-action-canonical"
                        :style="savingCanonical ? 'width: 140px; height: 40px; cursor: progress !important;' : 'width: 140px; height: 40px;'"
                        :disabled="!isUrlValid ? true : !urlCanonical ? true : savingCanonical ? true : false"
                        @click.prevent="saveCanonicals(grammarTxt)"
                    >
                    <b-img-lazy
                            fluid
                            :src="refreshIconSuggestWhite"
                            alt="refresh"
                            width="18"
                            height="20"
                            :class="savingCanonical ? 'refreshIconSuggest' : 'refreshIconSuggest'"
                            loading="lazy"
                            style="position: relative !important; top: -3px !important; margin-right: 5px;"
                            v-if="savingCanonical"  
                        />
                        <feather-icon
                            v-else
                            :icon="savedCanonical.includes(urlCanonical) ? 'CheckIcon' : 'UploadIcon'"
                            size="18"
                            class="inline-block"
                            style="position: relative !important; top: -2px !important;"
                        />
                        <span class="pl-50" :style="savingCanonical ? '' : 'position: relative !important; top: -4px !important;'">{{savingCanonical ? 'Saving' : savedCanonical.includes(urlCanonical) ? 'Saved' : 'Save'}}</span>
                    </b-button>
                    <b-button
                        variant="flat-light"
                        style="color: #c1c1c1; display: block; text-align: left; width: 40px; margin-top: 7px !important;"
                        class="btn-icon pl-0 mb-0 pb-25 px-1 mt-75 float-right mr-2"
                        @click.prevent="existCanonical = true"
                    >
                        <feather-icon
                            icon="ArrowLeftIcon"
                            size="25"
                        />
                    </b-button>
                </div>
            </b-tab>
            <b-tab @click.prevent="validateAndEmit(manualUrlCanonical,'manual')" :active="activeTab === 'manual' ? true : false">
                <template #title>
                    <div style="border-left: 3px solid gray; height: 25px; position: absolute; left: -2.5px;"></div>
                    <span class="ml-25">Manual</span>
                </template>

                <div>
                    <my-text-input
                        v-model="manualUrlCanonical"
                        :is-valid-url.sync="isUrlValid"
                        :placeholder="'Canonical URL'"
                        :disabled-input.sync="savingCanonical"
                        :style="savingCanonical ? 'cursor: no-drop !important;' : ''"
                    />
                    <b-button
                        variant="primary"
                        class="float-right d-block mt-75 btn-action-canonical"
                        :style="savingCanonical ? 'width: 140px; height: 40px; cursor: progress !important;' : 'width: 140px; height: 40px;'"
                        :disabled="!isUrlValid ? true : !manualUrlCanonical ? true : savingCanonical ? true : false"
                        @click.prevent="saveCanonicals(grammarTxt)"
                    >
                        <b-img-lazy
                            fluid
                            :src="refreshIconSuggestWhite"
                            alt="refresh"
                            width="18"
                            height="20"
                            :class="savingCanonical ? 'refreshIconSuggest' : 'refreshIconSuggest'"
                            loading="lazy"
                            style="position: relative !important; top: -3px !important; margin-right: 5px;"
                            v-if="savingCanonical"  
                        />
                        <feather-icon
                            v-else
                            :icon="savedCanonical.includes(manualUrlCanonical) ? 'CheckIcon' : 'UploadIcon'"
                            size="18"
                            class="inline-block"
                            style="position: relative !important; top: -2px !important;"
                        />
                        <span class="pl-50" :style="savingCanonical ? '' : 'position: relative !important; top: -4px !important;'">{{savingCanonical ? 'Saving' : savedCanonical.includes(urlCanonical) ? 'Saved' : 'Save'}}</span>
                    </b-button>
                    <b-button
                        variant="flat-light"
                        style="color: #c1c1c1; display: block; text-align: left; width: 40px; margin-top: 7px !important;"
                        class="btn-icon pl-0 mb-0 pb-25 px-1 mt-75 float-right mr-2"
                        @click.prevent="existCanonical = true"
                    >
                        <feather-icon
                            icon="ArrowLeftIcon"
                            size="25"
                        />
                    </b-button>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import { BImgLazy, BTabs, BTab, BOverlay, BButton, } from 'bootstrap-vue'
import MyTextInput from '@/views/apps/IASuggestions/MyTextInput.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { db, firebase, auth, dbErrors, firebaseError } from '@/firebase'

export default {
    components: {
        BOverlay,
        BImgLazy,
        BTabs,
        BTab,
        BButton,

        MyTextInput,
    },
    props: {
        openSidebar: {
            type: Boolean,
            default: false
        },
        urlSite: {
            type: String,
            default: ''
        },
        url: {
            type: String,
            default: ''
        },
        siteId: {
            type: String,
            default: ''
        },
        objectError: {
            type: Array,
            default: []
        },
        permission: {
            type: String,
            default: ''
        },
        pros: {
            type: Array,
            default: []
        },
        siteSelected: {
            type: Array,
            default: []
        },
        errorPageLog: {
            type: Array,
            default: []
        },
    },
    data() {
        return {
            variant: 'light',
            opacity: 0.85,
            blur: '2px',
            variant: 'transparent',
            activeTab: '',
            loadingInfoGrammar: false,
            grammarTxt: '',
            responseCrawler: [],
            responsesTXT: [],
            processSave: false,
            savedCanonical: [],

            urlCanonical: '',
            manualUrlCanonical: '',
            savingCanonical: false,
            isUrlValid: false,
            arrayError: [],
            existCanonical: false,

            refreshIconSuggest: require('@/assets/images/icons/i-refesh.svg'),
            refreshIconSuggestWhite: require('@/assets/images/icons/i-refesh-white.svg'),
        };
    },
    watch: {
        url(val) {
            // console.log(val);
            this.urlCanonical = val
            // this.getObject()

            if(val) {
                this.existCanonical = true
            }
        },
        openSidebar(val) {
            this.existCanonical = false
            this.savingCanonical = false
            this.urlCanonical = ''
            this.manualUrlCanonical = ''
            this.arrayError = []

            // console.log(val);
            // if(val) {
            //     this.getObject()
            // }
        }
    },
    methods: {
        saveCanonicals() {
            if(this.objectError.length) {
                var arrayAtom = this.objectError.filter(ele => ele.idc === 11)
                this.arrayError = arrayAtom[0].errors[0]
                
                if(this.arrayError.id) {
                    // console.log(this.arrayError);
                    this.saveChange()

                    // this.savingCanonical = true

                    // setTimeout(() => {
                    //     this.savingCanonical = false
                    //     this.savedCanonical.push(this.urlCanonical)
                    // }, 5000)
                } else {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: `Something wrong, Please try again!`,
                            icon: 'BellIcon',
                            variant: 'warning',
                        },
                    })
                }
            }
        },
        saveChange() {
            // console.log('first')
            var fixText = this.activeTab === 'manual' ? this.manualUrlCanonical : this.urlCanonical
            // console.log(fixText);
            var oldText = ''
            var fixTextAlt = ''
            var imgRow = ''
            var counterListError = 0

            if(this.activeTab === 'manual') {
                this.urlCanonical = this.manualUrlCanonical
            }

            this.savingCanonical = true
            var user = auth.currentUser
            var urlWindow = window.location.href
            var urlWindowTransform = new URL(urlWindow)
            if(urlWindowTransform.pathname === '/share') {
                localStorage.setItem('takeScreenShare', 'true')
                this.savingCanonical = false
            } else if(user) {
                // console.log(this.permission, this.pros.includes(this.urlSite), user.uid)
                if(this.permission === 'me') {
                    if(this.pros.includes(this.urlSite)) {
                        // save changes
                        db.collection('Users').doc(user.uid).get()
                        .then(userDoc => {
                            var authUser = {
                                nombre: userDoc.data().nombre ? userDoc.data().nombre : '',
                                email: userDoc.data().email,
                                uid: userDoc.data().uid,
                                foto: userDoc.data().foto
                            }
                            
                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).get()
                            .then(siteProject => {
                                db.collection('Sites').doc(this.siteId).get()
                                .then(siteDoc => {
                                    var listCounterPage = siteDoc.data().errorsPages && siteDoc.data().errorsPages.length ? siteDoc.data().errorsPages : []
                                    var filterCounterPage = listCounterPage.filter(eleErr => eleErr.url === this.urlSite)
                                    var docsObjErrors = siteDoc.data().errorsTypePage && siteDoc.data().errorsTypePage.length >= 1 ? siteDoc.data().errorsTypePage : []
                                    var filterObjErrorsFilter = docsObjErrors.filter(errObj => errObj.url === this.urlSite)
                                    var filterObjNoSiteErrorsFilter = docsObjErrors.filter(errObj => errObj.url !== this.urlSite)
                                    if(this.arrayError.iE === true) {
                                        filterObjErrorsFilter.forEach(elePage => {
                                        if(elePage.errors && elePage.errors.length) {
                                            elePage.errors.forEach(eleCounter => {
                                            if(this.arrayError.idc === 1) {
                                                if(eleCounter.type === 'title') {
                                                eleCounter.counter -= 1
                                                }
                                            } else if(this.arrayError.idc === 2) {
                                                if(eleCounter.type === 'desc') {
                                                eleCounter.counter -= 1
                                                }
                                            } else if(this.arrayError.idc === 4) {
                                                if(eleCounter.type === 'h1') {
                                                counterListError >= 2 ? eleCounter.counter -= 1 : null
                                                }
                                            } else if(this.arrayError.idc === 5) {
                                                if(eleCounter.type === 'h2') {
                                                eleCounter.counter -= 1
                                                }
                                            } else if(this.arrayError.idc === 8) {
                                                if(eleCounter.type === 'imgs') {
                                                // ele.beforeIgnore === true ? eleCounter.counter -= 1 : null
                                                eleCounter.counter >= 1 ? eleCounter.counter -= 1 : null
                                                }
                                            } else if(this.arrayError.idc === 6) {
                                                if(eleCounter.type === 'lWord') {
                                                eleCounter.counter -= 1
                                                }
                                            } else if(this.arrayError.idc === 13) {
                                                if(eleCounter.type === 'strings') {
                                                eleCounter.counter -= 1
                                                }
                                            } else if(this.arrayError.idc === 3) {
                                                if(eleCounter.type === 'mAlt') {
                                                eleCounter.counter -= 1
                                                }
                                            } else if(this.arrayError.idc === 9) {
                                                if(eleCounter.type === 'dLink') {
                                                eleCounter.counter -= 1
                                                }
                                            } else if(this.arrayError.idc === 14) {
                                                if(eleCounter.type === 'imgs') {
                                                // ele.beforeIgnore === true ? eleCounter.counter -= 1 : null
                                                eleCounter.counter >= 1 ? eleCounter.counter -= 1 : null
                                                }
                                            } else if(this.arrayError.idc === 11) {
                                                if(eleCounter.type === 'urlCan') {
                                                eleCounter.counter -= 1
                                                }
                                            } else if(this.arrayError.idc === 10) {
                                                // if(eleCounter.type === 'urlCan') {
                                                //   eleCounter.counter -= 1
                                                // }
                                            } else if(this.arrayError.idc === 15) {
                                                if(eleCounter.type === 'strings') {
                                                eleCounter.counter -= 1
                                                }
                                            }
                                            })
                                        }
                                        })

                                        var counterFixErr = 0
                                        filterObjErrorsFilter.forEach(eleSiteTypeError => {
                                        if(counterFixErr === 0) {
                                            counterFixErr += 1
                                            filterObjNoSiteErrorsFilter.push(eleSiteTypeError)
                                        }
                                        })

                                        db.collection('Sites').doc(this.siteId).update({
                                        errorsTypePage: filterObjNoSiteErrorsFilter
                                        })
                                    }

                                    if(this.arrayError.idc === 1) {
                                        var arrayPages = siteDoc.data().pages && siteDoc.data().pages.length >= 1 ? siteDoc.data().pages : []
                                        var filterPageTitle = arrayPages.filter(ele => ele.url === this.urlSite && ele.status !== null)
                                        var filterNoPageTitle = arrayPages.filter(ele => ele.url !== this.urlSite)
                                        if(filterPageTitle.length >= 1) {
                                        var counterPageT = 0
                                        filterPageTitle.forEach(ele => {
                                            if(counterPageT === 0) {
                                            counterPageT += 1
                                            ele.title = fixText
                                            filterNoPageTitle.push(ele)
                                            }
                                        })

                                        db.collection('Sites').doc(this.siteId).update({
                                            pages: filterNoPageTitle
                                        })
                                        console.log('updated title')
                                        }
                                    }

                                    dbErrors.collection('fixes').where('idpage', '==', this.urlSite).limit(1).get()
                                    .then(docPageFixCollection => {
                                    dbErrors.collection('fixes_cache').where('SiteID', '==', this.siteId).limit(1).get()
                                    .then(docPageFixCacheCollection => {
                                        // console.log(this.arrayError.idc, this.urlCanonical, fixText, docPageFixCollection.size, docPageFixCacheCollection.size)

                                        var idDocPageFixCacheColl = ''
                                        var changesFixesCache = []
                                        docPageFixCacheCollection.forEach(docPageFixCacheColl => {
                                        idDocPageFixCacheColl = docPageFixCacheColl.id
                                        changesFixesCache = docPageFixCacheColl.data().changes
                                        })

                                        // exist already fix document
                                        if(docPageFixCollection.size >= 1) {
                                        var idDocPageFixColl = ''
                                        var counterErrorFixes = 0
                                        var arrayFixesDomain = []
                                        var originalTextFix = ''
                                        docPageFixCollection.forEach(docPageFixColl => {
                                            idDocPageFixColl = docPageFixColl.id
                                            if(docPageFixColl.data().changes && docPageFixColl.data().changes.length >= 1) {
                                            docPageFixColl.data().changes.forEach(ele => {
                                                arrayFixesDomain.push(ele)
                                                if(ele.idfix === 1 && ele.idError === this.arrayError.idc && ele.url === this.urlSite && ele.id === this.arrayError.id) {
                                                this.arrayError.idc === 3 ? originalTextFix = ele.urlImg : originalTextFix = ele.old
                                                }
                                            })
                                            }
                                        })
                                        // !originalTextFix ? originalTextFix = oldText : null
                                        counterErrorFixes = (arrayFixesDomain.filter(ele => ele.idError === this.arrayError.idc && ele.url === this.urlSite && ele.id === this.arrayError.id).length) + 1
                                        if(arrayFixesDomain.filter(ele => ele.idError === this.arrayError.idc && ele.url === this.urlSite && ele.id === this.arrayError.id).length === 0) {
                                            this.arrayError.idc === 3 ? originalTextFix = imgRow : originalTextFix = oldText
                                        }
                                        !originalTextFix && this.arrayError.idc === 3 ? originalTextFix = imgRow : !originalTextFix ? originalTextFix = oldText : null

                                        dbErrors.collection('Errors').where('idpage', '==', this.urlSite).limit(1).get()
                                        .then(docPageError => {
                                            var idDocError = ''
                                            var changesDocError = []
                                            var listErrors = []
                                            var changesPage = []
                                            docPageError.forEach(docError => {
                                            idDocError = docError.id
                                            changesDocError = docError.data().changes ? docError.data().changes : []
                                            listErrors = docError.data().errors && docError.data().errors.length ? docError.data().errors : []
                                            changesPage = docError.data().changes ? docError.data().changes : []
                                            })

                                            var pageSizes = siteDoc.data().pageSizes && siteDoc.data().pageSizes.length ? siteDoc.data().pageSizes : []
                                            var filterSizePageExclude = pageSizes.filter(ele => ele.url !== this.urlSite)
                                            if(!changesPage.length) {
                                            // update asignated pro and sizes
                                            db.collection('Sites').doc(this.siteId).update({
                                                asignatedProPages: true,
                                                pageSizes: filterSizePageExclude
                                            })

                                            // update error status and cont/est
                                            var filterErr = []
                                            if(this.arrayError.idc === 8) {
                                                filterErr = listErrors.filter(eleErr => eleErr.id === this.arrayError.id || (eleErr.cont === oldText && eleErr.idc === 3 && eleErr.urlS === this.urlSite))

                                                if(filterErr.length) {
                                                filterErr.forEach(ele => {
                                                    // console.log(ele)
                                                    dbErrors.collection('Errors').doc(idDocError).update({
                                                    errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                    })
                                                    .then(() => {
                                                    var originalIE = ele.iE
                                                    if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                                        ele.iE = false
                                                    } else {
                                                        counterListError >= 2 ? null : ele.iE = false
                                                    }

                                                    if(ele.idc === 3) {
                                                        ele.est = fixTextAlt
                                                        ele.cont = fixText
                                                        ele.sta = 200
                                                        ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                                        if(originalIE) {
                                                        if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                            db.collection('Sites').doc(this.siteId).update({
                                                            'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                            })

                                                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                            })
                                                        }
                                                        }
                                                    } else {
                                                        ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = fixText : ele.cont = fixText
                                                        ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                                        ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                                    }
                                                    dbErrors.collection('Errors').doc(idDocError).update({
                                                        errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                    })
                                                    .catch(e => console.log('Error add err: ', e.message))
                                                    })
                                                    .catch(e => console.log('Error delete err: ', e.message))
                                                })
                                                }
                                            } else {
                                                filterErr = listErrors.filter(eleErr => eleErr.id === this.arrayError.id)

                                                if(filterErr.length) {
                                                filterErr.forEach(ele => {
                                                    // console.log(ele)
                                                    dbErrors.collection('Errors').doc(idDocError).update({
                                                    errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                    })
                                                    .then(() => {
                                                    if(this.arrayError.idc === 8 || this.arrayError.idc === 14 || this.arrayError.idc === 3) {
                                                        ele.iE = false
                                                    } else {
                                                        counterListError >= 2 ? null : ele.iE = false
                                                    }
                                                    this.arrayError.idc === 13 || this.arrayError.idc === 15 || this.arrayError.idc === 3 ? ele.est = fixText : ele.cont = fixText
                                                    this.arrayError.idc === 3 ? ele.sta = 200 : this.arrayError.idc === 8 || this.arrayError.idc === 14 ? ele.est = 200 : null
                                                    ele.history && ele.history.length >= 1 ? ele.history.push({id: this.arrayError.id, idc: this.arrayError.idc}) : ele.history = [{id: this.arrayError.id, idc: this.arrayError.idc}]
                                                    dbErrors.collection('Errors').doc(idDocError).update({
                                                        errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                    })
                                                    .catch(e => console.log('Error add err: ', e.message))
                                                    })
                                                    .catch(e => console.log('Error delete err: ', e.message))
                                                })
                                                }
                                            }

                                            // update error counter category
                                            if(this.arrayError.idc === 1) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 2) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 4) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                                                if(counterListError <= 1) {
                                                    db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                                                    })

                                                    db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                    })
                                                }
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 5) {
                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                                                //   })
                                                // }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 8) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 6) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 13) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 3) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 9) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 14) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 11) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 10) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 15) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                                                //   })
                                                // }
                                            }
                                            
                                            // update error counters by page
                                            if(filterCounterPage.length) {
                                                filterErr.forEach(ele => {
                                                if(ele.counter) {
                                                    db.collection('Sites').doc(this.siteId).update({
                                                    errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                                                    })
                                                    .then(() => {
                                                    ele.counter = ele.counter - 1
                                                    db.collection('Sites').doc(this.siteId).update({
                                                        errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                                    })
                                                    })
                                                }
                                                })
                                            }

                                            if(changesPage.length) {
                                                // update asignated sizes
                                                db.collection('Sites').doc(this.siteId).update({
                                                pageSizes: filterSizePageExclude
                                                })

                                                // update Errors page
                                                if(idDocError) {
                                                if(changesDocError.length) {
                                                    dbErrors.collection('Errors').doc(idDocError).update({
                                                    changes: firebase.firestore.FieldValue.arrayUnion({
                                                        id: this.arrayError.id,
                                                        idError: this.arrayError.idc,
                                                        typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                        old: originalTextFix ? originalTextFix : '',
                                                        fix: fixText,
                                                        url: this.urlSite ? this.urlSite : '',
                                                        date: Date.now(),
                                                        user: authUser,
                                                        idfix: counterErrorFixes
                                                    }),
                                                    has_fix: true
                                                    })
                                                } else {
                                                    dbErrors.collection('Errors').doc(idDocError).update({
                                                    changes: [{
                                                        id: this.arrayError.id,
                                                        idError: this.arrayError.idc,
                                                        typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                        old: originalTextFix ? originalTextFix : '',
                                                        fix: fixText,
                                                        url: this.urlSite ? this.urlSite : '',
                                                        date: Date.now(),
                                                        user: authUser,
                                                        idfix: counterErrorFixes
                                                    }],
                                                    has_fix: true
                                                    })
                                                }
                                                }
                                            } else {
                                                // update asignated sizes
                                                db.collection('Sites').doc(this.siteId).update({
                                                pageSizes: filterSizePageExclude
                                                })

                                                // update Errors page
                                                if(idDocError) {
                                                if(changesDocError.length) {
                                                    dbErrors.collection('Errors').doc(idDocError).update({
                                                    changes: firebase.firestore.FieldValue.arrayUnion({
                                                        id: this.arrayError.id,
                                                        idError: this.arrayError.idc,
                                                        typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                        old: originalTextFix ? originalTextFix : '',
                                                        fix: fixText,
                                                        url: this.urlSite ? this.urlSite : '',
                                                        date: Date.now(),
                                                        user: authUser,
                                                        idfix: counterErrorFixes
                                                    }),
                                                    has_fix: true
                                                    })
                                                } else {
                                                    dbErrors.collection('Errors').doc(idDocError).update({
                                                    changes: [{
                                                        id: this.arrayError.id,
                                                        idError: this.arrayError.idc,
                                                        typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                        old: originalTextFix ? originalTextFix : '',
                                                        fix: fixText,
                                                        url: this.urlSite ? this.urlSite : '',
                                                        date: Date.now(),
                                                        user: authUser,
                                                        idfix: counterErrorFixes
                                                    }],
                                                    has_fix: true
                                                    })
                                                }
                                                }
                                            }

                                            setTimeout(() => {
                                                localStorage.setItem('updateErrChange', true)
                                                // this.$emit('update:changeerror', true)
                                                var arrayChan = []
                                                // this.arraychanges.forEach(ele => {
                                                // arrayChan.push(ele)
                                                // })
                                                arrayChan.push(this.arrayError.id)
                                                // this.$emit('update:arraychanges', arrayChan)
                                                this.$toast({
                                                component: ToastificationContent,
                                                props: {
                                                    title: `Change applied`,
                                                    icon: 'BellIcon',
                                                    variant: 'success',
                                                },
                                                })
                                                // this.$refs.sidebar_fix_error.hide()
                                                this.sendJSONFix(idDocPageFixCacheColl)
                                                this.savedCanonical.push(fixText)
                                                this.savingCanonical = false
                                                fixText = ''
                                                fixTextAlt = ''
                                                oldText = ''
                                            }, 1700)
                                            } else {
                                            // update asignated pro
                                            db.collection('Sites').doc(this.siteId).update({
                                                asignatedProPages: true,
                                                pageSizes: filterSizePageExclude
                                            })

                                            // update error status and cont/est
                                            var filterErr = []
                                            if(this.arrayError.idc === 8) {
                                                filterErr = listErrors.filter(eleErr => eleErr.id === this.arrayError.id || (eleErr.cont === oldText && eleErr.idc === 3 && eleErr.urlS === this.urlSite))

                                                if(filterErr.length) {
                                                filterErr.forEach(ele => {
                                                    // console.log(ele)
                                                    dbErrors.collection('Errors').doc(idDocError).update({
                                                    errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                    })
                                                    .then(() => {
                                                    var originalIE = ele.iE
                                                    if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                                        ele.iE = false
                                                    } else {
                                                        counterListError >= 2 ? null : ele.iE = false
                                                    }

                                                    if(ele.idc === 3) {
                                                        ele.est = fixTextAlt
                                                        ele.cont = fixText
                                                        ele.sta = 200
                                                        ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                                        if(originalIE) {
                                                        if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                            db.collection('Sites').doc(this.siteId).update({
                                                            'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                            })

                                                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                            })
                                                        }
                                                        }
                                                    } else {
                                                        ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = fixText : ele.cont = fixText
                                                        ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                                        ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                                    }
                                                    dbErrors.collection('Errors').doc(idDocError).update({
                                                        errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                    })
                                                    .catch(e => console.log('Error add err: ', e.message))
                                                    })
                                                    .catch(e => console.log('Error delete err: ', e.message))
                                                })
                                                }
                                            } else {
                                                filterErr = listErrors.filter(eleErr => eleErr.id === this.arrayError.id)

                                                if(filterErr.length) {
                                                filterErr.forEach(ele => {
                                                    // console.log(ele)
                                                    dbErrors.collection('Errors').doc(idDocError).update({
                                                    errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                    })
                                                    .then(() => {
                                                    if(this.arrayError.idc === 8 || this.arrayError.idc === 14 || this.arrayError.idc === 3) {
                                                        ele.iE = false
                                                    } else {
                                                        counterListError >= 2 ? null : ele.iE = false
                                                    }
                                                    this.arrayError.idc === 13 || this.arrayError.idc === 15 || this.arrayError.idc === 3 ? ele.est = fixText : ele.cont = fixText
                                                    this.arrayError.idc === 3 ? ele.sta = 200 : this.arrayError.idc === 8 || this.arrayError.idc === 14 ? ele.est = 200 : null
                                                    ele.history && ele.history.length >= 1 ? ele.history.push({id: this.arrayError.id, idc: this.arrayError.idc}) : ele.history = [{id: this.arrayError.id, idc: this.arrayError.idc}]
                                                    dbErrors.collection('Errors').doc(idDocError).update({
                                                        errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                    })
                                                    .catch(e => console.log('Error add err: ', e.message))
                                                    })
                                                    .catch(e => console.log('Error delete err: ', e.message))
                                                })
                                                }
                                            }

                                            // update error counter category
                                            if(this.arrayError.idc === 1) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 2) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 4) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                                                if(counterListError <= 1) {
                                                    db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                                                    })

                                                    db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                    })
                                                }
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 5) {
                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                                                //   })
                                                // }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 8) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 6) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 13) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 3) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 9) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 14) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 11) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 10) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                                                //   })
                                                // }
                                            } else if(this.arrayError.idc === 15) {
                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                                                db.collection('Sites').doc(this.siteId).update({
                                                    'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                                                })

                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                    totalErrors: siteProject.data().totalErrors - 1
                                                })
                                                }

                                                // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                                                //   db.collection('Sites').doc(this.siteId).update({
                                                //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                                                //   })
                                                // }
                                            }
                                            
                                            // update error counters by page
                                            if(filterCounterPage.length) {
                                                filterErr.forEach(ele => {
                                                if(ele.counter) {
                                                    db.collection('Sites').doc(this.siteId).update({
                                                    errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                                                    })
                                                    .then(() => {
                                                    ele.counter = ele.counter - 1
                                                    db.collection('Sites').doc(this.siteId).update({
                                                        errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                                    })
                                                    })
                                                }
                                                })
                                            }

                                            if(changesPage.length) {
                                                // update asignated sizes
                                                db.collection('Sites').doc(this.siteId).update({
                                                pageSizes: filterSizePageExclude
                                                })

                                                // update Errors page
                                                if(idDocError) {
                                                if(changesDocError.length) {
                                                    dbErrors.collection('Errors').doc(idDocError).update({
                                                    changes: firebase.firestore.FieldValue.arrayUnion({
                                                        id: this.arrayError.id,
                                                        idError: this.arrayError.idc,
                                                        typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                        old: originalTextFix ? originalTextFix : '',
                                                        fix: fixText,
                                                        url: this.urlSite ? this.urlSite : '',
                                                        date: Date.now(),
                                                        user: authUser,
                                                        idfix: counterErrorFixes
                                                    }),
                                                    has_fix: true
                                                    })
                                                } else {
                                                    dbErrors.collection('Errors').doc(idDocError).update({
                                                    changes: [{
                                                        id: this.arrayError.id,
                                                        idError: this.arrayError.idc,
                                                        typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                        old: originalTextFix ? originalTextFix : '',
                                                        fix: fixText,
                                                        url: this.urlSite ? this.urlSite : '',
                                                        date: Date.now(),
                                                        user: authUser,
                                                        idfix: counterErrorFixes
                                                    }],
                                                    has_fix: true
                                                    })
                                                }
                                                }
                                            } else {
                                                // update asignated sizes
                                                db.collection('Sites').doc(this.siteId).update({
                                                pageSizes: filterSizePageExclude
                                                })

                                                // update Errors page
                                                if(idDocError) {
                                                if(changesDocError.length) {
                                                    dbErrors.collection('Errors').doc(idDocError).update({
                                                    changes: firebase.firestore.FieldValue.arrayUnion({
                                                        id: this.arrayError.id,
                                                        idError: this.arrayError.idc,
                                                        typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                        old: originalTextFix ? originalTextFix : '',
                                                        fix: fixText,
                                                        url: this.urlSite ? this.urlSite : '',
                                                        date: Date.now(),
                                                        user: authUser,
                                                        idfix: counterErrorFixes
                                                    }),
                                                    has_fix: true
                                                    })
                                                } else {
                                                    dbErrors.collection('Errors').doc(idDocError).update({
                                                    changes: [{
                                                        id: this.arrayError.id,
                                                        idError: this.arrayError.idc,
                                                        typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                        old: originalTextFix ? originalTextFix : '',
                                                        fix: fixText,
                                                        url: this.urlSite ? this.urlSite : '',
                                                        date: Date.now(),
                                                        user: authUser,
                                                        idfix: counterErrorFixes
                                                    }],
                                                    has_fix: true
                                                    })
                                                }
                                                }
                                            }

                                            setTimeout(() => {
                                                localStorage.setItem('updateErrChange', true)
                                                // this.$emit('update:changeerror', true)
                                                var arrayChan = []
                                                // this.arraychanges.forEach(ele => {
                                                // arrayChan.push(ele)
                                                // })
                                                arrayChan.push(this.arrayError.id)
                                                // this.$emit('update:arraychanges', arrayChan)
                                                this.$toast({
                                                component: ToastificationContent,
                                                props: {
                                                    title: `Change applied`,
                                                    icon: 'BellIcon',
                                                    variant: 'success',
                                                },
                                                })
                                                // this.$refs.sidebar_fix_error.hide()
                                                this.sendJSONFix(idDocPageFixCacheColl)
                                                this.savedCanonical.push(fixText)
                                                this.savingCanonical = false
                                                fixText = ''
                                                fixTextAlt = ''
                                                oldText = ''
                                            }, 1700)
                                            }

                                            if(this.arrayError.idc === 8 || this.arrayError.idc === 14 || this.arrayError.idc === 3) {
                                            // console.log('1')
                                            if(this.arrayError.idc === 3) {
                                                dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                                changes: firebase.firestore.FieldValue.arrayUnion({
                                                    id: this.arrayError.id,
                                                    idError: this.arrayError.idc,
                                                    typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                    old: this.arrayError.idc === 3 ? oldText : originalTextFix ? originalTextFix : '',
                                                    fix: fixText,
                                                    alt: fixTextAlt,
                                                    url: this.urlSite ? this.urlSite : '',
                                                    urlImg: originalTextFix ? originalTextFix : '',
                                                    date: Date.now(),
                                                    user: authUser.uid,
                                                    idfix: counterErrorFixes
                                                }),
                                                has_fix: true,
                                                updated: Date.now()
                                                })

                                                var filterchangesFixesCache8 = changesFixesCache.filter(ele => ele.urlImg === originalTextFix && ele.idError === 8 && ele.url === this.urlSite)
                                                if(filterchangesFixesCache8.length === 0) {
                                                } else {
                                                var filterNochangesFixesCache = []
                                                var idChangeCache = ''
                                                changesFixesCache.forEach(ele => {
                                                    if(ele.urlImg === originalTextFix && ele.idError === 8 && ele.url === this.urlSite) {
                                                    idChangeCache = ele.id
                                                    } else {
                                                    filterNochangesFixesCache.push(ele)
                                                    }
                                                })
                                                filterNochangesFixesCache.push({
                                                    id: idChangeCache,
                                                    idError: 8,
                                                    typeError: 'Images',
                                                    old: this.arrayError.idc === 3 ? oldText : originalTextFix ? originalTextFix : '',
                                                    fix: fixText,
                                                    alt: fixTextAlt,
                                                    url: this.urlSite ? this.urlSite : '',
                                                    urlImg: originalTextFix ? originalTextFix : '',
                                                    date: Date.now(),
                                                    // user: authUser,
                                                    idfix: counterErrorFixes
                                                })

                                                dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                                    changes: firebase.firestore.FieldValue.arrayUnion({
                                                    id: filterNochangesFixesCache[0].id,
                                                    idError: 8,
                                                    typeError: 'Images',
                                                    old: this.arrayError.idc === 3 ? oldText : originalTextFix ? originalTextFix : '',
                                                    fix: fixText,
                                                    alt: fixTextAlt,
                                                    url: this.urlSite ? this.urlSite : '',
                                                    urlImg: originalTextFix ? originalTextFix : '',
                                                    date: Date.now(),
                                                    user: authUser.uid,
                                                    idfix: counterErrorFixes
                                                    }),
                                                    has_fix: true,
                                                    updated: Date.now()
                                                })

                                                dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                    changes: filterNochangesFixesCache,
                                                    has_fix: true,
                                                    updated: Date.now()
                                                })
                                                }

                                                var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === this.arrayError.id && ele.idError === this.arrayError.idc && ele.url === this.urlSite)
                                                if(filterchangesFixesCache.length === 0) {
                                                dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                    changes: firebase.firestore.FieldValue.arrayUnion({
                                                    id: this.arrayError.id,
                                                    idError: this.arrayError.idc,
                                                    typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                    old: this.arrayError.idc === 3 ? oldText : originalTextFix ? originalTextFix : '',
                                                    fix: fixText,
                                                    alt: fixTextAlt,
                                                    url: this.urlSite ? this.urlSite : '',
                                                    urlImg: originalTextFix ? originalTextFix : '',
                                                    date: Date.now(),
                                                    // user: authUser,
                                                    idfix: counterErrorFixes
                                                    }),
                                                    has_fix: true,
                                                    updated: Date.now()
                                                })
                                                } else {
                                                // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== this.arrayError.id && ele.idError !== this.arrayError.idc && ele.url !== this.urlSite)
                                                var filterNochangesFixesCache = []
                                                changesFixesCache.forEach(ele => {
                                                    // console.log(ele)
                                                    if(ele.id === this.arrayError.id && ele.idError === this.arrayError.idc && ele.url === this.urlSite) {
                                                    // filterNochangesFixesCache.push(ele)
                                                    // console.log(ele.id, this.arrayError.id, ele.idError, this.arrayError.idc, ele.url, this.urlSite)
                                                    } else {
                                                    // console.log(ele.id, this.arrayError.id, ele.idError, this.arrayError.idc, ele.url, this.urlSite)
                                                    filterNochangesFixesCache.push(ele)
                                                    }
                                                })
                                                // console.log(filterNochangesFixesCache, changesFixesCache)
                                                filterNochangesFixesCache.push({
                                                    id: this.arrayError.id,
                                                    idError: this.arrayError.idc,
                                                    typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                    old: this.arrayError.idc === 3 ? oldText : originalTextFix ? originalTextFix : '',
                                                    fix: fixText,
                                                    alt: fixTextAlt,
                                                    url: this.urlSite ? this.urlSite : '',
                                                    urlImg: originalTextFix ? originalTextFix : '',
                                                    date: Date.now(),
                                                    // user: authUser,
                                                    idfix: counterErrorFixes
                                                })

                                                dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                    changes: filterNochangesFixesCache,
                                                    has_fix: true,
                                                    updated: Date.now()
                                                })
                                                }
                                            } else {
                                                dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                                changes: firebase.firestore.FieldValue.arrayUnion({
                                                    id: this.arrayError.id,
                                                    idError: this.arrayError.idc,
                                                    typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                    old: this.arrayError.idc === 3 ? oldText : originalTextFix ? originalTextFix : '',
                                                    fix: fixText,
                                                    alt: fixTextAlt,
                                                    url: this.urlSite ? this.urlSite : '',
                                                    urlImg: originalTextFix ? originalTextFix : '',
                                                    date: Date.now(),
                                                    user: authUser.uid,
                                                    idfix: counterErrorFixes
                                                }),
                                                has_fix: true,
                                                updated: Date.now()
                                                })

                                                var filterchangesFixesCache3 = changesFixesCache.filter(ele => ele.urlImg === originalTextFix && ele.idError === 3 && ele.url === this.urlSite)
                                                if(filterchangesFixesCache3.length === 0) {
                                                } else {
                                                var filterNochangesFixesCache = []
                                                var idChangeCache = ''
                                                changesFixesCache.forEach(ele => {
                                                    if(ele.urlImg === originalTextFix && ele.idError === 3 && ele.url === this.urlSite) {
                                                    idChangeCache = ele.id
                                                    } else {
                                                    filterNochangesFixesCache.push(ele)
                                                    }
                                                })
                                                filterNochangesFixesCache.push({
                                                    id: idChangeCache,
                                                    idError: 3,
                                                    typeError: 'Missing alts',
                                                    old: this.arrayError.idc === 3 ? oldText : originalTextFix ? originalTextFix : '',
                                                    fix: fixText,
                                                    alt: fixTextAlt,
                                                    url: this.urlSite ? this.urlSite : '',
                                                    urlImg: originalTextFix ? originalTextFix : '',
                                                    date: Date.now(),
                                                    // user: authUser,
                                                    idfix: counterErrorFixes
                                                })

                                                dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                                    changes: firebase.firestore.FieldValue.arrayUnion({
                                                    id: filterNochangesFixesCache[0].id,
                                                    idError: 3,
                                                    typeError: 'Missing alts',
                                                    old: this.arrayError.idc === 3 ? oldText : originalTextFix ? originalTextFix : '',
                                                    fix: fixText,
                                                    alt: fixTextAlt,
                                                    url: this.urlSite ? this.urlSite : '',
                                                    urlImg: originalTextFix ? originalTextFix : '',
                                                    date: Date.now(),
                                                    user: authUser.uid,
                                                    idfix: counterErrorFixes
                                                    }),
                                                    has_fix: true,
                                                    updated: Date.now()
                                                })

                                                dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                    changes: filterNochangesFixesCache,
                                                    has_fix: true,
                                                    updated: Date.now()
                                                })
                                                }

                                                var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === this.arrayError.id && ele.idError === this.arrayError.idc && ele.url === this.urlSite)
                                                if(filterchangesFixesCache.length === 0) {
                                                dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                    changes: firebase.firestore.FieldValue.arrayUnion({
                                                    id: this.arrayError.id,
                                                    idError: this.arrayError.idc,
                                                    typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                    old: this.arrayError.idc === 3 ? oldText : originalTextFix ? originalTextFix : '',
                                                    fix: fixText,
                                                    alt: fixTextAlt,
                                                    url: this.urlSite ? this.urlSite : '',
                                                    urlImg: originalTextFix ? originalTextFix : '',
                                                    date: Date.now(),
                                                    // user: authUser,
                                                    idfix: counterErrorFixes
                                                    }),
                                                    has_fix: true,
                                                    updated: Date.now()
                                                })
                                                } else {
                                                // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== this.arrayError.id && ele.idError !== this.arrayError.idc && ele.url !== this.urlSite)
                                                var filterNochangesFixesCache = []
                                                changesFixesCache.forEach(ele => {
                                                    // console.log(ele)
                                                    if(ele.id === this.arrayError.id && ele.idError === this.arrayError.idc && ele.url === this.urlSite) {
                                                    // filterNochangesFixesCache.push(ele)
                                                    // console.log(ele.id, this.arrayError.id, ele.idError, this.arrayError.idc, ele.url, this.urlSite)
                                                    } else {
                                                    // console.log(ele.id, this.arrayError.id, ele.idError, this.arrayError.idc, ele.url, this.urlSite)
                                                    filterNochangesFixesCache.push(ele)
                                                    }
                                                })
                                                // console.log(filterNochangesFixesCache, changesFixesCache)
                                                filterNochangesFixesCache.push({
                                                    id: this.arrayError.id,
                                                    idError: this.arrayError.idc,
                                                    typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                    old: this.arrayError.idc === 3 ? oldText : originalTextFix ? originalTextFix : '',
                                                    fix: fixText,
                                                    alt: fixTextAlt,
                                                    url: this.urlSite ? this.urlSite : '',
                                                    urlImg: originalTextFix ? originalTextFix : '',
                                                    date: Date.now(),
                                                    // user: authUser,
                                                    idfix: counterErrorFixes
                                                })

                                                dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                    changes: filterNochangesFixesCache,
                                                    has_fix: true,
                                                    updated: Date.now()
                                                })
                                                }
                                            }
                                            } else {
                                            dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                                changes: firebase.firestore.FieldValue.arrayUnion({
                                                id: this.arrayError.id,
                                                idError: this.arrayError.idc,
                                                typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                old: originalTextFix ? originalTextFix : '',
                                                fix: fixText,
                                                url: this.urlSite ? this.urlSite : '',
                                                date: Date.now(),
                                                user: authUser.uid,
                                                idfix: counterErrorFixes
                                                }),
                                                has_fix: true,
                                                updated: Date.now()
                                            })

                                            var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === this.arrayError.id && ele.idError === this.arrayError.idc && ele.url === this.urlSite)
                                            // console.log(filterchangesFixesCache)
                                            if(filterchangesFixesCache.length === 0) {
                                                dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                changes: firebase.firestore.FieldValue.arrayUnion({
                                                    id: this.arrayError.id,
                                                    idError: this.arrayError.idc,
                                                    typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                    old: originalTextFix ? originalTextFix : '',
                                                    fix: fixText,
                                                    url: this.urlSite ? this.urlSite : '',
                                                    date: Date.now(),
                                                    // user: authUser,
                                                    idfix: counterErrorFixes
                                                }),
                                                has_fix: true,
                                                updated: Date.now()
                                                })
                                            } else {
                                                // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== this.arrayError.id && ele.idError !== this.arrayError.idc && ele.url !== this.urlSite)
                                                var filterNochangesFixesCache = []
                                                changesFixesCache.forEach(ele => {
                                                // console.log(ele)
                                                if(ele.id === this.arrayError.id && ele.idError === this.arrayError.idc && ele.url === this.urlSite) {
                                                    // filterNochangesFixesCache.push(ele)
                                                    // console.log(ele.id, this.arrayError.id, ele.idError, this.arrayError.idc, ele.url, this.urlSite)
                                                } else {
                                                    // console.log(ele.id, this.arrayError.id, ele.idError, this.arrayError.idc, ele.url, this.urlSite)
                                                    filterNochangesFixesCache.push(ele)
                                                }
                                                })
                                                // console.log(filterNochangesFixesCache, changesFixesCache)
                                                filterNochangesFixesCache.push({
                                                id: this.arrayError.id,
                                                idError: this.arrayError.idc,
                                                typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                old: originalTextFix ? originalTextFix : '',
                                                fix: fixText,
                                                url: this.urlSite ? this.urlSite : '',
                                                date: Date.now(),
                                                // user: authUser,
                                                idfix: counterErrorFixes
                                                })

                                                dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                changes: filterNochangesFixesCache,
                                                has_fix: true,
                                                updated: Date.now()
                                                })
                                            }
                                            }
                                        })
                                        } else {
                                        if(!idDocPageFixCacheColl) {
                                            // doesnt exist fix document
                                            dbErrors.collection('fixes_cache').add({
                                            SiteID: this.siteId,
                                            // idpage: this.urlSite,
                                            owner: authUser.uid,
                                            ownerEmail: authUser.email,
                                            changes: [],
                                            updated: Date.now()
                                            }).then((docRefFixCacheColl) => {
                                            idDocPageFixCacheColl = docRefFixCacheColl.id
                                            
                                            dbErrors.collection('fixes').add({
                                                SiteID: this.siteId,
                                                idpage: this.urlSite,
                                                changes: [],
                                                updated: Date.now()
                                            }).then((docRefFixColl) => {
                                                var idDocPageFixColl = docRefFixColl.id
                                                // var idDocPageFixCacheColl = docRefFixCacheColl.id

                                                dbErrors.collection('Errors').where('idpage', '==', this.urlSite).limit(1).get()
                                                .then(docPageError => {
                                                var idDocError = ''
                                                var changesDocError = []
                                                var listErrors = []
                                                var changesPage = []
                                                docPageError.forEach(docError => {
                                                    idDocError = docError.id
                                                    changesDocError = docError.data().changes ? docError.data().changes : []
                                                    listErrors = docError.data().errors && docError.data().errors.length ? docError.data().errors : []
                                                    changesPage = docError.data().changes ? docError.data().changes : []
                                                })

                                                var pageSizes = siteDoc.data().pageSizes && siteDoc.data().pageSizes.length ? siteDoc.data().pageSizes : []
                                                var filterSizePageExclude = pageSizes.filter(ele => ele.url !== this.urlSite)
                                                if(!changesPage.length) {
                                                    // update asignated pro and sizes
                                                    db.collection('Sites').doc(this.siteId).update({
                                                    asignatedProPages: true,
                                                    pageSizes: filterSizePageExclude
                                                    })

                                                    // update error status and cont/est
                                                    var filterErr = []
                                                    if(this.arrayError.idc === 8) {
                                                    filterErr = listErrors.filter(eleErr => eleErr.id === this.arrayError.id || (eleErr.cont === oldText && eleErr.idc === 3 && eleErr.urlS === this.urlSite))

                                                    if(filterErr.length) {
                                                        filterErr.forEach(ele => {
                                                        // console.log(ele)
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            var originalIE = ele.iE
                                                            if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                                            ele.iE = false
                                                            } else {
                                                            counterListError >= 2 ? null : ele.iE = false
                                                            }

                                                            if(ele.idc === 3) {
                                                            ele.est = fixTextAlt
                                                            ele.cont = fixText
                                                            ele.sta = 200
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                                            if(originalIE) {
                                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                                db.collection('Sites').doc(this.siteId).update({
                                                                    'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                                })

                                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                                    totalErrors: siteProject.data().totalErrors - 1
                                                                })
                                                                }
                                                            }
                                                            } else {
                                                            ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = fixText : ele.cont = fixText
                                                            ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                                            }
                                                            dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                            .catch(e => console.log('Error add err: ', e.message))
                                                        })
                                                        .catch(e => console.log('Error delete err: ', e.message))
                                                        })
                                                    }
                                                    } else {
                                                    filterErr = listErrors.filter(eleErr => eleErr.id === this.arrayError.id)

                                                    if(filterErr.length) {
                                                        filterErr.forEach(ele => {
                                                        // console.log(ele)
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            if(this.arrayError.idc === 8 || this.arrayError.idc === 14 || this.arrayError.idc === 3) {
                                                            ele.iE = false
                                                            } else {
                                                            counterListError >= 2 ? null : ele.iE = false
                                                            }
                                                            this.arrayError.idc === 13 || this.arrayError.idc === 15 || this.arrayError.idc === 3 ? ele.est = fixText : ele.cont = fixText
                                                            this.arrayError.idc === 3 ? ele.sta = 200 : this.arrayError.idc === 8 || this.arrayError.idc === 14 ? ele.est = 200 : null
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: this.arrayError.id, idc: this.arrayError.idc}) : ele.history = [{id: this.arrayError.id, idc: this.arrayError.idc}]
                                                            dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                            .catch(e => console.log('Error add err: ', e.message))
                                                        })
                                                        .catch(e => console.log('Error delete err: ', e.message))
                                                        })
                                                    }
                                                    }

                                                    // update error counter category
                                                    if(this.arrayError.idc === 1) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 2) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 4) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                                                        if(counterListError <= 1) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                            'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                        }
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 5) {
                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                                                    //   })
                                                    // }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 8) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 6) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 13) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 3) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 9) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 14) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 11) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 10) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 15) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                                                    //   })
                                                    // }
                                                    }
                                                    
                                                    // update error counters by page
                                                    if(filterCounterPage.length) {
                                                    filterErr.forEach(ele => {
                                                        if(ele.counter) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                            errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            ele.counter = ele.counter - 1
                                                            db.collection('Sites').doc(this.siteId).update({
                                                            errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                        })
                                                        }
                                                    })
                                                    }

                                                    if(changesPage.length) {
                                                    // update asignated sizes
                                                    db.collection('Sites').doc(this.siteId).update({
                                                        pageSizes: filterSizePageExclude
                                                    })

                                                    // update Errors page
                                                    if(idDocError) {
                                                        if(changesDocError.length) {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: this.arrayError.id,
                                                            idError: this.arrayError.idc,
                                                            typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                            old: oldText,
                                                            fix: fixText,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }),
                                                            has_fix: true
                                                        })
                                                        } else {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: [{
                                                            id: this.arrayError.id,
                                                            idError: this.arrayError.idc,
                                                            typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                            old: oldText,
                                                            fix: fixText,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }],
                                                            has_fix: true
                                                        })
                                                        }
                                                    }
                                                    } else {
                                                    // update asignated sizes
                                                    db.collection('Sites').doc(this.siteId).update({
                                                        pageSizes: filterSizePageExclude
                                                    })

                                                    // update Errors page
                                                    if(idDocError) {
                                                        if(changesDocError.length) {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: this.arrayError.id,
                                                            idError: this.arrayError.idc,
                                                            typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                            old: oldText,
                                                            fix: fixText,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }),
                                                            has_fix: true
                                                        })
                                                        } else {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: [{
                                                            id: this.arrayError.id,
                                                            idError: this.arrayError.idc,
                                                            typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                            old: oldText,
                                                            fix: fixText,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }],
                                                            has_fix: true
                                                        })
                                                        }
                                                    }
                                                    }

                                                    setTimeout(() => {
                                                        localStorage.setItem('updateErrChange', true)
                                                        // this.$emit('update:changeerror', true)
                                                        var arrayChan = []
                                                        // this.arraychanges.forEach(ele => {
                                                            // arrayChan.push(ele)
                                                        // })
                                                        arrayChan.push(this.arrayError.id)
                                                        // this.$emit('update:arraychanges', arrayChan)
                                                        this.$toast({
                                                            component: ToastificationContent,
                                                            props: {
                                                            title: `Change applied`,
                                                            icon: 'BellIcon',
                                                            variant: 'success',
                                                            },
                                                        })
                                                        // this.$refs.sidebar_fix_error.hide()
                                                        this.sendJSONFix(idDocPageFixCacheColl)
                                                        this.savedCanonical.push(fixText)
                                                        this.savingCanonical = false
                                                        fixText = ''
                                                        fixTextAlt = ''
                                                        oldText = ''
                                                    }, 1700)
                                                } else {
                                                    // update asignated pro
                                                    db.collection('Sites').doc(this.siteId).update({
                                                    asignatedProPages: true,
                                                    pageSizes: filterSizePageExclude
                                                    })

                                                    // update error status and cont/est
                                                    var filterErr = []
                                                    if(this.arrayError.idc === 8) {
                                                    filterErr = listErrors.filter(eleErr => eleErr.id === this.arrayError.id || (eleErr.cont === oldText && eleErr.idc === 3 && eleErr.urlS === this.urlSite))

                                                    if(filterErr.length) {
                                                        filterErr.forEach(ele => {
                                                        // console.log(ele)
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            var originalIE = ele.iE
                                                            if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                                            ele.iE = false
                                                            } else {
                                                            counterListError >= 2 ? null : ele.iE = false
                                                            }

                                                            if(ele.idc === 3) {
                                                            ele.est = fixTextAlt
                                                            ele.cont = fixText
                                                            ele.sta = 200
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                                            if(originalIE) {
                                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                                db.collection('Sites').doc(this.siteId).update({
                                                                    'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                                })

                                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                                    totalErrors: siteProject.data().totalErrors - 1
                                                                })
                                                                }
                                                            }
                                                            } else {
                                                            ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = fixText : ele.cont = fixText
                                                            ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                                            }
                                                            dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                            .catch(e => console.log('Error add err: ', e.message))
                                                        })
                                                        .catch(e => console.log('Error delete err: ', e.message))
                                                        })
                                                    }
                                                    } else {
                                                    filterErr = listErrors.filter(eleErr => eleErr.id === this.arrayError.id)

                                                    if(filterErr.length) {
                                                        filterErr.forEach(ele => {
                                                        // console.log(ele)
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            if(this.arrayError.idc === 8 || this.arrayError.idc === 14 || this.arrayError.idc === 3) {
                                                            ele.iE = false
                                                            } else {
                                                            counterListError >= 2 ? null : ele.iE = false
                                                            }
                                                            this.arrayError.idc === 13 || this.arrayError.idc === 15 || this.arrayError.idc === 3 ? ele.est = fixText : ele.cont = fixText
                                                            this.arrayError.idc === 3 ? ele.sta = 200 : this.arrayError.idc === 8 || this.arrayError.idc === 14 ? ele.est = 200 : null
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: this.arrayError.id, idc: this.arrayError.idc}) : ele.history = [{id: this.arrayError.id, idc: this.arrayError.idc}]
                                                            dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                            .catch(e => console.log('Error add err: ', e.message))
                                                        })
                                                        .catch(e => console.log('Error delete err: ', e.message))
                                                        })
                                                    }
                                                    }

                                                    // update error counter category
                                                    if(this.arrayError.idc === 1) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 2) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 4) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                                                        if(counterListError <= 1) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                            'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                        }
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 5) {
                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                                                    //   })
                                                    // }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 8) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 6) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 13) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 3) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 9) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 14) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 11) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 10) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 15) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                                                    //   })
                                                    // }
                                                    }
                                                    
                                                    // update error counters by page
                                                    if(filterCounterPage.length) {
                                                    filterErr.forEach(ele => {
                                                        if(ele.counter) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                            errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            ele.counter = ele.counter - 1
                                                            db.collection('Sites').doc(this.siteId).update({
                                                            errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                        })
                                                        }
                                                    })
                                                    }

                                                    if(changesPage.length) {
                                                    // update asignated sizes
                                                    db.collection('Sites').doc(this.siteId).update({
                                                        pageSizes: filterSizePageExclude
                                                    })

                                                    // update Errors page
                                                    if(idDocError) {
                                                        if(changesDocError.length) {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: this.arrayError.id,
                                                            idError: this.arrayError.idc,
                                                            typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                            old: oldText,
                                                            fix: fixText,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }),
                                                            has_fix: true
                                                        })
                                                        } else {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: [{
                                                            id: this.arrayError.id,
                                                            idError: this.arrayError.idc,
                                                            typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                            old: oldText,
                                                            fix: fixText,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }],
                                                            has_fix: true
                                                        })
                                                        }
                                                    }
                                                    } else {
                                                    // update asignated sizes
                                                    db.collection('Sites').doc(this.siteId).update({
                                                        pageSizes: filterSizePageExclude
                                                    })

                                                    // update Errors page
                                                    if(idDocError) {
                                                        if(changesDocError.length) {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: this.arrayError.id,
                                                            idError: this.arrayError.idc,
                                                            typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                            old: oldText,
                                                            fix: fixText,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }),
                                                            has_fix: true
                                                        })
                                                        } else {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: [{
                                                            id: this.arrayError.id,
                                                            idError: this.arrayError.idc,
                                                            typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                            old: oldText,
                                                            fix: fixText,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }],
                                                            has_fix: true
                                                        })
                                                        }
                                                    }
                                                    }

                                                    setTimeout(() => {
                                                        localStorage.setItem('updateErrChange', true)
                                                        // this.$emit('update:changeerror', true)
                                                        var arrayChan = []
                                                        // this.arraychanges.forEach(ele => {
                                                            // arrayChan.push(ele)
                                                        // })
                                                        arrayChan.push(this.arrayError.id)
                                                        // this.$emit('update:arraychanges', arrayChan)
                                                        this.$toast({
                                                            component: ToastificationContent,
                                                            props: {
                                                            title: `Change applied`,
                                                            icon: 'BellIcon',
                                                            variant: 'success',
                                                            },
                                                        })
                                                        // this.$refs.sidebar_fix_error.hide()
                                                        this.sendJSONFix(idDocPageFixCacheColl)
                                                        this.savedCanonical.push(fixText)
                                                        this.savingCanonical = false
                                                        fixText = ''
                                                        fixTextAlt = ''
                                                        oldText = ''
                                                    }, 1700)
                                                }

                                                if(this.arrayError.idc === 8 || this.arrayError.idc === 14 || this.arrayError.idc === 3) {
                                                    // console.log('2')
                                                    dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                                    changes: [{
                                                        id: this.arrayError.id,
                                                        idError: this.arrayError.idc,
                                                        typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                        old: oldText,
                                                        fix: fixText,
                                                        alt: fixTextAlt,
                                                        url: this.urlSite ? this.urlSite : '',
                                                        urlImg: this.arrayError.idc === 3 ? imgRow : oldText,
                                                        date: Date.now(),
                                                        user: authUser.uid,
                                                        idfix: 1
                                                    }],
                                                    has_fix: true,
                                                    updated: Date.now()
                                                    })

                                                    dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                    changes: [{
                                                        id: this.arrayError.id,
                                                        idError: this.arrayError.idc,
                                                        typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                        old: oldText,
                                                        fix: fixText,
                                                        alt: fixTextAlt,
                                                        url: this.urlSite ? this.urlSite : '',
                                                        urlImg: this.arrayError.idc === 3 ? imgRow : oldText,
                                                        date: Date.now(),
                                                        // user: authUser,
                                                        idfix: 1
                                                    }],
                                                    has_fix: true,
                                                    updated: Date.now()
                                                    })
                                                } else {
                                                    dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                                    changes: [{
                                                        id: this.arrayError.id,
                                                        idError: this.arrayError.idc,
                                                        typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                        old: oldText,
                                                        fix: fixText,
                                                        alt: fixTextAlt,
                                                        url: this.urlSite ? this.urlSite : '',
                                                        urlImg: this.arrayError.idc === 3 ? imgRow : oldText,
                                                        date: Date.now(),
                                                        user: authUser.uid,
                                                        idfix: 1
                                                    }],
                                                    has_fix: true,
                                                    updated: Date.now()
                                                    })

                                                    dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                    changes: [{
                                                        id: this.arrayError.id,
                                                        idError: this.arrayError.idc,
                                                        typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                        old: oldText,
                                                        fix: fixText,
                                                        alt: fixTextAlt,
                                                        url: this.urlSite ? this.urlSite : '',
                                                        urlImg: this.arrayError.idc === 3 ? imgRow : oldText,
                                                        date: Date.now(),
                                                        // user: authUser,
                                                        idfix: 1
                                                    }],
                                                    has_fix: true,
                                                    updated: Date.now()
                                                    })
                                                }
                                                })
                                            })
                                            })
                                        } else {
                                            // doesnt exist fix document
                                            dbErrors.collection('fixes').add({
                                                SiteID: this.siteId,
                                                idpage: this.urlSite,
                                                changes: [],
                                                updated: Date.now()
                                            }).then((docRefFixColl) => {
                                                var idDocPageFixColl = docRefFixColl.id
                                                // var idDocPageFixCacheColl = docRefFixCacheColl.id

                                                dbErrors.collection('Errors').where('idpage', '==', this.urlSite).limit(1).get()
                                                .then(docPageError => {
                                                var idDocError = ''
                                                var changesDocError = []
                                                var listErrors = []
                                                var changesPage = []
                                                docPageError.forEach(docError => {
                                                    idDocError = docError.id
                                                    changesDocError = docError.data().changes ? docError.data().changes : []
                                                    listErrors = docError.data().errors && docError.data().errors.length ? docError.data().errors : []
                                                    changesPage = docError.data().changes ? docError.data().changes : []
                                                })

                                                var pageSizes = siteDoc.data().pageSizes && siteDoc.data().pageSizes.length ? siteDoc.data().pageSizes : []
                                                var filterSizePageExclude = pageSizes.filter(ele => ele.url !== this.urlSite)
                                                if(!changesPage.length) {
                                                    // update asignated pro and sizes
                                                    db.collection('Sites').doc(this.siteId).update({
                                                    asignatedProPages: true,
                                                    pageSizes: filterSizePageExclude
                                                    })

                                                    // update error status and cont/est
                                                    var filterErr = []
                                                    if(this.arrayError.idc === 8) {
                                                    filterErr = listErrors.filter(eleErr => eleErr.id === this.arrayError.id || (eleErr.cont === oldText && eleErr.idc === 3 && eleErr.urlS === this.urlSite))

                                                    if(filterErr.length) {
                                                        filterErr.forEach(ele => {
                                                        // console.log(ele)
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            var originalIE = ele.iE
                                                            if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                                            ele.iE = false
                                                            } else {
                                                            counterListError >= 2 ? null : ele.iE = false
                                                            }

                                                            if(ele.idc === 3) {
                                                            ele.est = fixTextAlt
                                                            ele.cont = fixText
                                                            ele.sta = 200
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                                            if(originalIE) {
                                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                                db.collection('Sites').doc(this.siteId).update({
                                                                    'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                                })

                                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                                    totalErrors: siteProject.data().totalErrors - 1
                                                                })
                                                                }
                                                            }
                                                            } else {
                                                            ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = fixText : ele.cont = fixText
                                                            ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                                            }
                                                            dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                            .catch(e => console.log('Error add err: ', e.message))
                                                        })
                                                        .catch(e => console.log('Error delete err: ', e.message))
                                                        })
                                                    }
                                                    } else {
                                                    filterErr = listErrors.filter(eleErr => eleErr.id === this.arrayError.id)

                                                    if(filterErr.length) {
                                                        filterErr.forEach(ele => {
                                                        // console.log(ele)
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            if(this.arrayError.idc === 8 || this.arrayError.idc === 14 || this.arrayError.idc === 3) {
                                                            ele.iE = false
                                                            } else {
                                                            counterListError >= 2 ? null : ele.iE = false
                                                            }
                                                            this.arrayError.idc === 13 || this.arrayError.idc === 15 || this.arrayError.idc === 3 ? ele.est = fixText : ele.cont = fixText
                                                            this.arrayError.idc === 3 ? ele.sta = 200 : this.arrayError.idc === 8 || this.arrayError.idc === 14 ? ele.est = 200 : null
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: this.arrayError.id, idc: this.arrayError.idc}) : ele.history = [{id: this.arrayError.id, idc: this.arrayError.idc}]
                                                            dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                            .catch(e => console.log('Error add err: ', e.message))
                                                        })
                                                        .catch(e => console.log('Error delete err: ', e.message))
                                                        })
                                                    }
                                                    }

                                                    // update error counter category
                                                    if(this.arrayError.idc === 1) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 2) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 4) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                                                        if(counterListError <= 1) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                            'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                        }
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 5) {
                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                                                    //   })
                                                    // }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 8) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 6) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 13) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 3) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 9) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 14) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 11) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 10) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 15) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                                                    //   })
                                                    // }
                                                    }
                                                    
                                                    // update error counters by page
                                                    if(filterCounterPage.length) {
                                                    filterErr.forEach(ele => {
                                                        if(ele.counter) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                            errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            ele.counter = ele.counter - 1
                                                            db.collection('Sites').doc(this.siteId).update({
                                                            errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                        })
                                                        }
                                                    })
                                                    }

                                                    if(changesPage.length) {
                                                    // update asignated sizes
                                                    db.collection('Sites').doc(this.siteId).update({
                                                        pageSizes: filterSizePageExclude
                                                    })

                                                    // update Errors page
                                                    if(idDocError) {
                                                        if(changesDocError.length) {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: this.arrayError.id,
                                                            idError: this.arrayError.idc,
                                                            typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                            old: oldText,
                                                            fix: fixText,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }),
                                                            has_fix: true
                                                        })
                                                        } else {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: [{
                                                            id: this.arrayError.id,
                                                            idError: this.arrayError.idc,
                                                            typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                            old: oldText,
                                                            fix: fixText,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }],
                                                            has_fix: true
                                                        })
                                                        }
                                                    }
                                                    } else {
                                                    // update asignated sizes
                                                    db.collection('Sites').doc(this.siteId).update({
                                                        pageSizes: filterSizePageExclude
                                                    })

                                                    // update Errors page
                                                    if(idDocError) {
                                                        if(changesDocError.length) {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: this.arrayError.id,
                                                            idError: this.arrayError.idc,
                                                            typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                            old: oldText,
                                                            fix: fixText,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }),
                                                            has_fix: true
                                                        })
                                                        } else {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: [{
                                                            id: this.arrayError.id,
                                                            idError: this.arrayError.idc,
                                                            typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                            old: oldText,
                                                            fix: fixText,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }],
                                                            has_fix: true
                                                        })
                                                        }
                                                    }
                                                    }

                                                    setTimeout(() => {
                                                        localStorage.setItem('updateErrChange', true)
                                                        // this.$emit('update:changeerror', true)
                                                        var arrayChan = []
                                                        // this.arraychanges.forEach(ele => {
                                                            // arrayChan.push(ele)
                                                        // })
                                                        arrayChan.push(this.arrayError.id)
                                                        // this.$emit('update:arraychanges', arrayChan)
                                                        this.$toast({
                                                            component: ToastificationContent,
                                                            props: {
                                                            title: `Change applied`,
                                                            icon: 'BellIcon',
                                                            variant: 'success',
                                                            },
                                                        })
                                                        // this.$refs.sidebar_fix_error.hide()
                                                        this.sendJSONFix(idDocPageFixCacheColl)
                                                        this.savedCanonical.push(fixText)
                                                        this.savingCanonical = false
                                                        fixText = ''
                                                        fixTextAlt = ''
                                                        oldText = ''
                                                    }, 1700)
                                                } else {
                                                    // update asignated pro
                                                    db.collection('Sites').doc(this.siteId).update({
                                                    asignatedProPages: true,
                                                    pageSizes: filterSizePageExclude
                                                    })

                                                    // update error status and cont/est
                                                    var filterErr = []
                                                    if(this.arrayError.idc === 8) {
                                                    filterErr = listErrors.filter(eleErr => eleErr.id === this.arrayError.id || (eleErr.cont === oldText && eleErr.idc === 3 && eleErr.urlS === this.urlSite))

                                                    if(filterErr.length) {
                                                        filterErr.forEach(ele => {
                                                        // console.log(ele)
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            var originalIE = ele.iE
                                                            if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                                            ele.iE = false
                                                            } else {
                                                            counterListError >= 2 ? null : ele.iE = false
                                                            }

                                                            if(ele.idc === 3) {
                                                            ele.est = fixTextAlt
                                                            ele.cont = fixText
                                                            ele.sta = 200
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                                            if(originalIE) {
                                                                if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                                db.collection('Sites').doc(this.siteId).update({
                                                                    'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                                })

                                                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                                    totalErrors: siteProject.data().totalErrors - 1
                                                                })
                                                                }
                                                            }
                                                            } else {
                                                            ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = fixText : ele.cont = fixText
                                                            ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                                            }
                                                            dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                            .catch(e => console.log('Error add err: ', e.message))
                                                        })
                                                        .catch(e => console.log('Error delete err: ', e.message))
                                                        })
                                                    }
                                                    } else {
                                                    filterErr = listErrors.filter(eleErr => eleErr.id === this.arrayError.id)

                                                    if(filterErr.length) {
                                                        filterErr.forEach(ele => {
                                                        // console.log(ele)
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            if(this.arrayError.idc === 8 || this.arrayError.idc === 14 || this.arrayError.idc === 3) {
                                                            ele.iE = false
                                                            } else {
                                                            counterListError >= 2 ? null : ele.iE = false
                                                            }
                                                            this.arrayError.idc === 13 || this.arrayError.idc === 15 || this.arrayError.idc === 3 ? ele.est = fixText : ele.cont = fixText
                                                            this.arrayError.idc === 3 ? ele.sta = 200 : this.arrayError.idc === 8 || this.arrayError.idc === 14 ? ele.est = 200 : null
                                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: this.arrayError.id, idc: this.arrayError.idc}) : ele.history = [{id: this.arrayError.id, idc: this.arrayError.idc}]
                                                            dbErrors.collection('Errors').doc(idDocError).update({
                                                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                            .catch(e => console.log('Error add err: ', e.message))
                                                        })
                                                        .catch(e => console.log('Error delete err: ', e.message))
                                                        })
                                                    }
                                                    }

                                                    // update error counter category
                                                    if(this.arrayError.idc === 1) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 2) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 4) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                                                        if(counterListError <= 1) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                            'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                            totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                        }
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 5) {
                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                                                    //   })
                                                    // }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 8) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 6) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 13) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 3) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 9) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 14) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 11) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 10) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                                                    //   })
                                                    // }
                                                    } else if(this.arrayError.idc === 15) {
                                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                        'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                                                        })

                                                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.siteId).update({
                                                        totalErrors: siteProject.data().totalErrors - 1
                                                        })
                                                    }

                                                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                                                    //   db.collection('Sites').doc(this.siteId).update({
                                                    //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                                                    //   })
                                                    // }
                                                    }
                                                    
                                                    // update error counters by page
                                                    if(filterCounterPage.length) {
                                                    filterErr.forEach(ele => {
                                                        if(ele.counter) {
                                                        db.collection('Sites').doc(this.siteId).update({
                                                            errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                                                        })
                                                        .then(() => {
                                                            ele.counter = ele.counter - 1
                                                            db.collection('Sites').doc(this.siteId).update({
                                                            errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                                            })
                                                        })
                                                        }
                                                    })
                                                    }

                                                    if(changesPage.length) {
                                                    // update asignated sizes
                                                    db.collection('Sites').doc(this.siteId).update({
                                                        pageSizes: filterSizePageExclude
                                                    })

                                                    // update Errors page
                                                    if(idDocError) {
                                                        if(changesDocError.length) {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: this.arrayError.id,
                                                            idError: this.arrayError.idc,
                                                            typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                            old: oldText,
                                                            fix: fixText,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }),
                                                            has_fix: true
                                                        })
                                                        } else {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: [{
                                                            id: this.arrayError.id,
                                                            idError: this.arrayError.idc,
                                                            typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                            old: oldText,
                                                            fix: fixText,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }],
                                                            has_fix: true
                                                        })
                                                        }
                                                    }
                                                    } else {
                                                    // update asignated sizes
                                                    db.collection('Sites').doc(this.siteId).update({
                                                        pageSizes: filterSizePageExclude
                                                    })

                                                    // update Errors page
                                                    if(idDocError) {
                                                        if(changesDocError.length) {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: this.arrayError.id,
                                                            idError: this.arrayError.idc,
                                                            typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                            old: oldText,
                                                            fix: fixText,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }),
                                                            has_fix: true
                                                        })
                                                        } else {
                                                        dbErrors.collection('Errors').doc(idDocError).update({
                                                            changes: [{
                                                            id: this.arrayError.id,
                                                            idError: this.arrayError.idc,
                                                            typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                            old: oldText,
                                                            fix: fixText,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            date: Date.now(),
                                                            user: authUser,
                                                            idfix: 1
                                                            }],
                                                            has_fix: true
                                                        })
                                                        }
                                                    }
                                                    }

                                                    setTimeout(() => {
                                                        localStorage.setItem('updateErrChange', true)
                                                        // this.$emit('update:changeerror', true)
                                                        var arrayChan = []
                                                        // this.arraychanges.forEach(ele => {
                                                            // arrayChan.push(ele)
                                                        // })
                                                        arrayChan.push(this.arrayError.id)
                                                        // this.$emit('update:arraychanges', arrayChan)
                                                        this.$toast({
                                                            component: ToastificationContent,
                                                            props: {
                                                            title: `Change applied`,
                                                            icon: 'BellIcon',
                                                            variant: 'success',
                                                            },
                                                        })
                                                        // this.$refs.sidebar_fix_error.hide()
                                                        this.sendJSONFix(idDocPageFixCacheColl)
                                                        this.savedCanonical.push(fixText)
                                                        this.savingCanonical = false
                                                        fixText = ''
                                                        fixTextAlt = ''
                                                        oldText = ''
                                                    }, 1700)
                                                }

                                                if(this.arrayError.idc === 8 || this.arrayError.idc === 14 || this.arrayError.idc === 3) {
                                                    // console.log('3')
                                                    dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                                    changes: [{
                                                        id: this.arrayError.id,
                                                        idError: this.arrayError.idc,
                                                        typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                        old: oldText,
                                                        fix: fixText,
                                                        alt: fixTextAlt,
                                                        url: this.urlSite ? this.urlSite : '',
                                                        urlImg: this.arrayError.idc === 3 ? imgRow : oldText,
                                                        date: Date.now(),
                                                        user: authUser.uid,
                                                        idfix: 1
                                                    }],
                                                    has_fix: true,
                                                    updated: Date.now()
                                                    })

                                                    if(changesFixesCache.length >= 1) {
                                                    if(this.arrayError.idc === 3) {
                                                        var filterchangesFixesCache8 = changesFixesCache.filter(ele => ele.urlImg === oldText && ele.idError === 8 && ele.url === this.urlSite)
                                                        if(filterchangesFixesCache8.length === 0) {
                                                        } else {
                                                        var filterNochangesFixesCache = []
                                                        var idChangeCache = ''
                                                        changesFixesCache.forEach(ele => {
                                                            if(ele.urlImg === oldText && ele.idError === 8 && ele.url === this.urlSite) {
                                                            idChangeCache = ele.id
                                                            } else {
                                                            filterNochangesFixesCache.push(ele)
                                                            }
                                                        })
                                                        filterNochangesFixesCache.push({
                                                            id: idChangeCache,
                                                            idError: 8,
                                                            typeError: 'Images',
                                                            old: oldText,
                                                            fix: fixText,
                                                            alt: fixTextAlt,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            urlImg: this.arrayError.idc === 3 ? imgRow : oldText,
                                                            date: Date.now(),
                                                            // user: authUser,
                                                            idfix: counterErrorFixes
                                                        })

                                                        dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: filterNochangesFixesCache[0].id,
                                                            idError: 8,
                                                            typeError: 'Images',
                                                            old: oldText,
                                                            fix: fixText,
                                                            alt: fixTextAlt,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            urlImg: this.arrayError.idc === 3 ? imgRow : oldText,
                                                            date: Date.now(),
                                                            user: authUser.uid,
                                                            idfix: counterErrorFixes
                                                            }),
                                                            has_fix: true,
                                                            updated: Date.now()
                                                        })

                                                        dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                            changes: filterNochangesFixesCache,
                                                            has_fix: true,
                                                            updated: Date.now()
                                                        })
                                                        }

                                                        var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === this.arrayError.id && ele.idError === this.arrayError.idc && ele.url === this.urlSite)
                                                        if(filterchangesFixesCache.length === 0) {
                                                        dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: this.arrayError.id,
                                                            idError: this.arrayError.idc,
                                                            typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                            old: oldText,
                                                            fix: fixText,
                                                            alt: fixTextAlt,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            urlImg: this.arrayError.idc === 3 ? imgRow : oldText,
                                                            date: Date.now(),
                                                            // user: authUser,
                                                            idfix: 1
                                                            }),
                                                            has_fix: true,
                                                            updated: Date.now()
                                                        })
                                                        } else {
                                                        // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== this.arrayError.id && ele.idError !== this.arrayError.idc && ele.url !== this.urlSite)
                                                        var filterNochangesFixesCache = []
                                                        changesFixesCache.forEach(ele => {
                                                            // console.log(ele)
                                                            if(ele.id === this.arrayError.id && ele.idError === this.arrayError.idc && ele.url === this.urlSite) {
                                                            // filterNochangesFixesCache.push(ele)
                                                            // console.log(ele.id, this.arrayError.id, ele.idError, this.arrayError.idc, ele.url, this.urlSite)
                                                            } else {
                                                            // console.log(ele.id, this.arrayError.id, ele.idError, this.arrayError.idc, ele.url, this.urlSite)
                                                            filterNochangesFixesCache.push(ele)
                                                            }
                                                        })
                                                        // console.log(filterNochangesFixesCache, changesFixesCache)
                                                        filterNochangesFixesCache.push({
                                                            id: this.arrayError.id,
                                                            idError: this.arrayError.idc,
                                                            typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                            old: oldText,
                                                            fix: fixText,
                                                            alt: fixTextAlt,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            urlImg: this.arrayError.idc === 3 ? imgRow : oldText,
                                                            date: Date.now(),
                                                            // user: authUser,
                                                            idfix: 1
                                                        })

                                                        dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                            changes: filterNochangesFixesCache,
                                                            has_fix: true,
                                                            updated: Date.now()
                                                        })
                                                        }
                                                    } else {
                                                        var filterchangesFixesCache3 = changesFixesCache.filter(ele => ele.urlImg === oldText && ele.idError === 3 && ele.url === this.urlSite)
                                                        if(filterchangesFixesCache3.length === 0) {
                                                        } else {
                                                        var filterNochangesFixesCache = []
                                                        var idChangeCache = ''
                                                        changesFixesCache.forEach(ele => {
                                                            if(ele.urlImg === oldText && ele.idError === 3 && ele.url === this.urlSite) {
                                                            idChangeCache = ele.id
                                                            } else {
                                                            filterNochangesFixesCache.push(ele)
                                                            }
                                                        })
                                                        filterNochangesFixesCache.push({
                                                            id: idChangeCache,
                                                            idError: 3,
                                                            typeError: 'Missing alts',
                                                            old: oldText,
                                                            fix: fixText,
                                                            alt: fixTextAlt,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            urlImg: this.arrayError.idc === 3 ? imgRow : oldText,
                                                            date: Date.now(),
                                                            // user: authUser,
                                                            idfix: counterErrorFixes
                                                        })

                                                        dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: filterNochangesFixesCache[0].id,
                                                            idError: 3,
                                                            typeError: 'Missing alts',
                                                            old: oldText,
                                                            fix: fixText,
                                                            alt: fixTextAlt,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            urlImg: this.arrayError.idc === 3 ? imgRow : oldText,
                                                            date: Date.now(),
                                                            user: authUser.uid,
                                                            idfix: counterErrorFixes
                                                            }),
                                                            has_fix: true,
                                                            updated: Date.now()
                                                        })

                                                        dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                            changes: filterNochangesFixesCache,
                                                            has_fix: true,
                                                            updated: Date.now()
                                                        })
                                                        }

                                                        var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === this.arrayError.id && ele.idError === this.arrayError.idc && ele.url === this.urlSite)
                                                        if(filterchangesFixesCache.length === 0) {
                                                        dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                            changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: this.arrayError.id,
                                                            idError: this.arrayError.idc,
                                                            typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                            old: oldText,
                                                            fix: fixText,
                                                            alt: fixTextAlt,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            urlImg: this.arrayError.idc === 3 ? imgRow : oldText,
                                                            date: Date.now(),
                                                            // user: authUser,
                                                            idfix: 1
                                                            }),
                                                            has_fix: true,
                                                            updated: Date.now()
                                                        })
                                                        } else {
                                                        // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== this.arrayError.id && ele.idError !== this.arrayError.idc && ele.url !== this.urlSite)
                                                        var filterNochangesFixesCache = []
                                                        changesFixesCache.forEach(ele => {
                                                            // console.log(ele)
                                                            if(ele.id === this.arrayError.id && ele.idError === this.arrayError.idc && ele.url === this.urlSite) {
                                                            // filterNochangesFixesCache.push(ele)
                                                            // console.log(ele.id, this.arrayError.id, ele.idError, this.arrayError.idc, ele.url, this.urlSite)
                                                            } else {
                                                            // console.log(ele.id, this.arrayError.id, ele.idError, this.arrayError.idc, ele.url, this.urlSite)
                                                            filterNochangesFixesCache.push(ele)
                                                            }
                                                        })
                                                        // console.log(filterNochangesFixesCache, changesFixesCache)
                                                        filterNochangesFixesCache.push({
                                                            id: this.arrayError.id,
                                                            idError: this.arrayError.idc,
                                                            typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                            old: oldText,
                                                            fix: fixText,
                                                            alt: fixTextAlt,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            urlImg: this.arrayError.idc === 3 ? imgRow : oldText,
                                                            date: Date.now(),
                                                            // user: authUser,
                                                            idfix: 1
                                                        })

                                                        dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                            changes: filterNochangesFixesCache,
                                                            has_fix: true,
                                                            updated: Date.now()
                                                        })
                                                        }
                                                    }
                                                    } else {
                                                    dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                        changes: [{
                                                        id: this.arrayError.id,
                                                        idError: this.arrayError.idc,
                                                        typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                        old: oldText,
                                                        fix: fixText,
                                                        alt: fixTextAlt,
                                                        url: this.urlSite ? this.urlSite : '',
                                                        urlImg: this.arrayError.idc === 3 ? imgRow : oldText,
                                                        date: Date.now(),
                                                        // user: authUser,
                                                        idfix: 1
                                                        }],
                                                        has_fix: true,
                                                        updated: Date.now()
                                                    })
                                                    }
                                                } else {
                                                    dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                                    changes: [{
                                                        id: this.arrayError.id,
                                                        idError: this.arrayError.idc,
                                                        typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                        old: oldText,
                                                        fix: fixText,
                                                        url: this.urlSite ? this.urlSite : '',
                                                        date: Date.now(),
                                                        user: authUser.uid,
                                                        idfix: 1
                                                    }],
                                                    has_fix: true,
                                                    updated: Date.now()
                                                    })

                                                    if(changesFixesCache.length >= 1) {
                                                    var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === this.arrayError.id && ele.idError === this.arrayError.idc && ele.url === this.urlSite)
                                                    // console.log(filterchangesFixesCache)
                                                    if(filterchangesFixesCache.length === 0) {
                                                        dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                        changes: firebase.firestore.FieldValue.arrayUnion({
                                                            id: this.arrayError.id,
                                                            idError: this.arrayError.idc,
                                                            typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                            old: oldText,
                                                            fix: fixText,
                                                            url: this.urlSite ? this.urlSite : '',
                                                            date: Date.now(),
                                                            // user: authUser,
                                                            idfix: 1
                                                        }),
                                                        has_fix: true,
                                                        updated: Date.now()
                                                        })
                                                    } else {
                                                        // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== this.arrayError.id && ele.idError !== this.arrayError.idc && ele.url !== this.urlSite)
                                                        var filterNochangesFixesCache = []
                                                        changesFixesCache.forEach(ele => {
                                                        // console.log(ele)
                                                        if(ele.id === this.arrayError.id && ele.idError === this.arrayError.idc && ele.url === this.urlSite) {
                                                            // filterNochangesFixesCache.push(ele)
                                                            // console.log(ele.id, this.arrayError.id, ele.idError, this.arrayError.idc, ele.url, this.urlSite)
                                                        } else {
                                                            // console.log(ele.id, this.arrayError.id, ele.idError, this.arrayError.idc, ele.url, this.urlSite)
                                                            filterNochangesFixesCache.push(ele)
                                                        }
                                                        })
                                                        // console.log(filterNochangesFixesCache, changesFixesCache)
                                                        filterNochangesFixesCache.push({
                                                        id: this.arrayError.id,
                                                        idError: this.arrayError.idc,
                                                        typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                        old: oldText,
                                                        fix: fixText,
                                                        url: this.urlSite ? this.urlSite : '',
                                                        date: Date.now(),
                                                        // user: authUser,
                                                        idfix: 1
                                                        })

                                                        dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                        changes: filterNochangesFixesCache,
                                                        has_fix: true,
                                                        updated: Date.now()
                                                        })
                                                    }
                                                    } else {
                                                    dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                                        changes: [{
                                                        id: this.arrayError.id,
                                                        idError: this.arrayError.idc,
                                                        typeError: this.arrayError.idc === 1 ? 'Missing titles' : this.arrayError.idc === 2 ? 'Missing descriptions' : this.arrayError.idc === 11 ? 'URL canonalization' : this.arrayError.idc === 3 ? 'Missing alts' : this.arrayError.idc === 4 ? 'Headings H1' : this.arrayError.idc === 5 ? 'Headings H2' : this.arrayError.idc === 6 ? 'Low word count' : this.arrayError.idc === 8 ? 'Images' : this.arrayError.idc === 14 ? 'Images' : this.arrayError.idc === 9 ? 'Dead link checker' : this.arrayError.idc === 10 ? 'Canonicals' : this.arrayError.idc === 13 ? 'Strings' : this.arrayError.idc === 15 ? 'Suspicious' : this.arrayError.idc === 7 ? 'Externals' : '',
                                                        old: oldText,
                                                        fix: fixText,
                                                        url: this.urlSite ? this.urlSite : '',
                                                        date: Date.now(),
                                                        // user: authUser,
                                                        idfix: 1
                                                        }],
                                                        has_fix: true,
                                                        updated: Date.now()
                                                    })
                                                    }
                                                }
                                                })
                                            })
                                        }
                                        }
                                    })
                                    })
                                })
                            })
                        })
                    } else {
                        var objErr = this.errorPageLog.filter(ele => ele.idc === payload.idc)
                        localStorage.setItem('viewTSProMake', JSON.stringify(objErr))
                        localStorage.setItem('fixLogMakePro', JSON.stringify({payload: {fixText: fixText, oldText: oldText, idc: 11}, optionsChatGPTErrors: this.objectError, siteId: this.siteId, siteSelected: this.siteSelected, fixText: fixText, counterListError: this.counterListError}))
                        localStorage.setItem('fixCopilot', true)
                        this.processSave = false
                    }
                } else {
                    localStorage.setItem('configRunLicense', true)
                    this.savingCanonical = false
                }
            }
        },
        sendJSONFix(val) {
            if(this.arrayError.idc !== 7) {
                const user = auth.currentUser
                if(user) {
                    db.collection('Sites').doc(this.siteId).get()
                    .then(site => {
                        if(site.data().owner && site.data().owner.uid) {
                            // check script exitense
                            if(user.uid === site.data().owner.uid) {
                                var getFirstUser = user.uid.slice(0, 4);
                                var getFirstSite = site.id.slice(0, 4);
                                var keyID = getFirstUser + getFirstSite

                                var obj = {
                                    "url": site.data().homepage,
                                    "scriptSrc": `https://cdn.seocloud.ai/${keyID}`
                                }

                                var config = {
                                    method: 'POST',
                                    url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/check-script',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    data: JSON.stringify(obj),
                                }

                                this.axios(config)
                                .then(response => {
                                    console.log('header: ',response.data)
                                    if(!response.data.scriptPresent) {
                                        // this.isNotAlreadyOwner = false
                                        localStorage.setItem('configRunLicense', true)
                                    } else {
                                        // this.isNotAlreadyOwner = true
                                    }
                                })
                            } else {
                                // this.isNotAlreadyOwner = false
                                localStorage.setItem('configRunLicense', true)
                            }
                        } else {
                            // history owner lost
                            if(site.data().owners && site.data().owners.length) {
                                if(site.data().owners.includes(user.uid)) {
                                    // this.isNotAlreadyOwner = false
                                    localStorage.setItem('configRunLicense', true)
                                }
                            }
                        }
                    })

                    db.collection('Users').doc(user.uid).get()
                    .then(userDoc => {
                        setTimeout(() => {
                            dbErrors.collection('fixes_cache').doc(val).get()
                            .then(docFixCache => {
                                var urlTransform = new URL(this.urlSite)
                                var domainConverter = urlTransform.protocol + '//' + urlTransform.host

                                const getFirstUser = userDoc.id.slice(0, 4);
                                const getFirstSite = this.$route.params.id.slice(0, 4);
                                // const getFirstProject = this.$route.params.project.slice(0, 3);
                                var keyID = getFirstUser + getFirstSite

                                var obj = {}
                                obj.author = userDoc.data().email
                                obj.css = ""
                                obj.domain = domainConverter
                                obj.key = keyID
                                obj.mode = 1

                                var arraychanges = docFixCache.data().changes && docFixCache.data().changes.length ? docFixCache.data().changes : []
                                var pagesAlreadyProcessed = []
                                arraychanges.forEach(ele => {
                                    if(pagesAlreadyProcessed.filter(item => item === ele.url).length === 0) {
                                        var urlTransform = new URL(ele.url)
                                        // var domainConverter = urlTransform.protocol + '//' + urlTransform.host

                                        pagesAlreadyProcessed.push(ele.url)
                                        var pathURLConverter = urlTransform.pathname === '/' && !ele.url.endsWith('/') ? ele.url + '/' : ele.url
                                        var encodedURL = btoa(pathURLConverter)
                                        obj[encodedURL] = {
                                            "title": "",
                                            "path": urlTransform.pathname,
                                            "url": ele.url,
                                            "description": "",
                                            "canonical": "",
                                            "siteName": "",
                                            "alt": [],
                                            "src": [],
                                            "dlinks": [],
                                            "gp": [],
                                            "tags": {},
                                            "tgstxt": {},
                                            "findAndReplace": []
                                        }

                                        var filterFixesOnlyEachPage = arraychanges.filter(item => item.url === ele.url)
                                        filterFixesOnlyEachPage.forEach(item => {
                                            if(item.idError === 1) {
                                                // title
                                                obj[encodedURL].title = item.fix
                                                // obj[encodedURL].siteName = item.fix
                                            } else if(item.idError === 2) {
                                                // desc
                                                obj[encodedURL].description = item.fix
                                            } else if(item.idError === 4) {
                                                // h1
                                                if(obj[encodedURL].tgstxt.h1 && obj[encodedURL].tgstxt.h1.length) {
                                                    obj[encodedURL].tgstxt.h1.push({
                                                        txt: item.old,
                                                        ntx: item.fix
                                                    })
                                                } else {
                                                    obj[encodedURL].tgstxt.h1 = [{
                                                        txt: item.old,
                                                        ntx: item.fix
                                                    }]
                                                }
                                            } else if(item.idError === 5) {
                                                // h2
                                                if(obj[encodedURL].tgstxt.h2 && obj[encodedURL].tgstxt.h2.length) {
                                                    obj[encodedURL].tgstxt.h2.push({
                                                        txt: item.old,
                                                        ntx: item.fix
                                                    })
                                                } else {
                                                    obj[encodedURL].tgstxt.h2 = [{
                                                        txt: item.old,
                                                        ntx: item.fix
                                                    }]
                                                }
                                            } else if(item.idError === 8) {
                                                // imgs
                                                if(obj[encodedURL].src && obj[encodedURL].src.length) {
                                                    obj[encodedURL].src.push({
                                                        url: item.old,
                                                        src: item.fix
                                                    })
                                                } else {
                                                    obj[encodedURL].src = [{
                                                        url: item.old,
                                                        src: item.fix
                                                    }]
                                                }

                                                if(item.alt) {
                                                    if(obj[encodedURL].alt && obj[encodedURL].alt.length) {
                                                        obj[encodedURL].alt.push({
                                                            url: item.fix,
                                                            alt: item.alt
                                                        })
                                                    } else {
                                                        obj[encodedURL].alt = [{
                                                            url: item.fix,
                                                            alt: item.alt
                                                        }]
                                                    }
                                                }
                                            } else if(item.idError === 6) {
                                                // lWord
                                                // 08-03-2024 no available fix/edit (word count is value from crawl page)
                                            } else if(item.idError === 13) {
                                                // strings
                                                if(item.findAndReplace) {
                                                    if(obj[encodedURL].findAndReplace && obj[encodedURL].findAndReplace.length) {
                                                        obj[encodedURL].findAndReplace.push({
                                                            old: item.old,
                                                            new: item.fix
                                                        })
                                                    } else {
                                                        obj[encodedURL].findAndReplace = [{
                                                            old: item.old,
                                                            new: item.fix
                                                        }]
                                                    }
                                                }
                                            } else if(item.idError === 3) {
                                                // mAlt
                                                if(item.alt) {
                                                    if(obj[encodedURL].alt && obj[encodedURL].alt.length) {
                                                        obj[encodedURL].alt.push({
                                                            url: item.urlImg,
                                                            alt: item.fix
                                                        })
                                                    } else {
                                                        obj[encodedURL].alt = [{
                                                            url: item.urlImg,
                                                            alt: item.fix
                                                        }]
                                                    }
                                                }
                                            } else if(item.idError === 9) {
                                                // dLink
                                                if(item.dlinks) {
                                                    if(obj[encodedURL].dlinks && obj[encodedURL].dlinks.length) {
                                                        obj[encodedURL].dlinks.push({
                                                            url: item.old,
                                                            rmp: item.fix
                                                        })
                                                    } else {
                                                        obj[encodedURL].dlinks = [{
                                                            url: item.old,
                                                            rmp: item.fix
                                                        }]
                                                    }
                                                }
                                            } else if(item.idError === 14) {
                                                // missimgs
                                                if(obj[encodedURL].src && obj[encodedURL].src.length) {
                                                    obj[encodedURL].src.push({
                                                        url: item.old,
                                                        src: item.fix
                                                    })
                                                } else {
                                                    obj[encodedURL].src = [{
                                                        url: item.old,
                                                        src: item.fix
                                                    }]
                                                }

                                                if(item.alt) {
                                                    if(obj[encodedURL].alt && obj[encodedURL].alt.length) {
                                                        obj[encodedURL].alt.push({
                                                            url: item.fix,
                                                            alt: item.alt
                                                        })
                                                    } else {
                                                        obj[encodedURL].alt = [{
                                                            url: item.fix,
                                                            alt: item.alt
                                                        }]
                                                    }
                                                }
                                            } else if(item.idError === 11) {
                                                // urlCan
                                                obj[encodedURL].canonical = item.fix
                                            } else if(item.idError === 10) {
                                                // greenPadlockErrors
                                            } else if(item.idError === 15) {
                                                // Suspicious
                                                if(item.findAndReplace) {
                                                    if(obj[encodedURL].findAndReplace && obj[encodedURL].findAndReplace.length) {
                                                        obj[encodedURL].findAndReplace.push({
                                                            old: item.old,
                                                            new: item.fix
                                                        })
                                                    } else {
                                                        obj[encodedURL].findAndReplace = [{
                                                            old: item.old,
                                                            new: item.fix
                                                        }]
                                                    }
                                                }
                                            }
                                        })
                                    }
                                })

                                var config = {
                                    method: 'POST',
                                    url: 'https://apijs-wwkeahz7bq-uc.a.run.app',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    data: JSON.stringify(obj),
                                }

                                this.axios(config)
                                .then(response => {
                                    dbErrors.collection('fixes_cache').doc(val).update({
                                        api_file_request: obj
                                    })
                                    
                                    console.log('fixes file updated: '+response.data.status)
                                })
                                .catch(e => console.log(e.message))
                            })
                        }, 2000)
                    })
                }
            }
        },
        validateAndEmit(payload, type) {
            if(type === 'manual') {
                this.activeTab = 'manual'
            } else {
                this.activeTab = 'sugg'
            }

            // console.log(payload)
            // Expresión regular para validar la URL
            const urlPattern = /^(https?:\/\/)?([\w\-]+(\.[\w\-]+)+)([\w\-._~:/?#[\]@!$&'()*+,;=.]+)?$/;
            
            // Verificar si el valor ingresado es una URL válida
            var isValidUrlAtom = urlPattern.test(payload);
            // console.log(isValidUrlAtom);
            this.isUrlValid = isValidUrlAtom
            
            // Emitir el valor de la URL y el estado de la validación al componente padre
            // this.$emit('input', this.inputValue); // Emitir el valor de la URL
            // this.$emit('update:isValidUrl', isValidUrlAtom); // Emitir el estado de la validación
        }
    },
    created() {
        // console.log(this.url)
        this.urlCanonical = this.url ? this.url : ''
        this.validateAndEmit(this.urlCanonical)

        if(this.objectError.filter(ele => ele.idc === 11 && ele.errors[0].iE === true).length) {
            this.existCanonical = false
            this.activeTab = 'sugg'
        } else {
            this.existCanonical = true
            this.activeTab = 'manual'
        }
    }
}
</script>

<style>
.refreshIconSuggest {
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  width: 18px;
  height: 20px;
  /* margin:-60px 0 0 -60px; */
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
.btn-action-canonical:hover {
    padding: 0.85rem 1.5rem !important;
}
</style>