<template>
  <section
    class="section_TestResults"
  >
    <h2>Test Results</h2>
    <b-tabs class="tabs-performance">
      <b-tab
          v-if="diagnostics"
          active
      >
        <template #title>
          Diagnostics <span class="counter_diagnostics">{{ diagnostics.length }}</span>
        </template>
        <app-collapse accordion>
          <div class="d-flex p-2">
            <h4 class="w-100 ml-3">
              Failed test
            </h4>
            <h4 class="w-1/12 text-center mr-2">
            Grade
          </h4>
          </div>
        </app-collapse>

        <TWCollapse
            v-for="item in diagnostics"
            :key="item.auditRef.id"
            :item="item"
            v-model="collapseCurrentItem"
        />
      </b-tab>

      <b-tab
        v-if="opportunities"        
      >
        <template #title>
          Opportunities <span class="counter_opportunities">{{ opportunities.length }}</span>
        </template>
        <app-collapse accordion>
          <div class="d-flex justify-content-between p-2">
            <h4 class="w-11/12 ml-3">
              Failed test
            </h4>
            <h4 class="w-1/12 text-center mr-2">
              Grade
            </h4>
          </div>
        </app-collapse>

        <TWCollapse
            v-for="item in opportunities"
            :key="item.auditRef.id"
            :item="item"
            v-model="collapseCurrentItem"
        />
      </b-tab>

      <b-tab v-if="approves">
        <template #title>
          Approved <span class="counter_approved">{{ approves.length }}</span>
        </template>

        <app-collapse accordion>
          <div class="d-flex p-2">
            <h4 class="w-11/12 ml-3">
              Passed Test
            </h4>
            <h4 class="w-1/12 text-center mr-2">
              Score
            </h4>
          </div>
        </app-collapse>

        <TWCollapse
            v-for="item in approves"
            :key="item.auditRef.id"
            :item="item"
            v-model="collapseCurrentItem"
        />

      </b-tab>

    </b-tabs>
  </section>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import AppCollapse from './Collapse/AppCollapse.vue'
import AppCollapseItem from './Collapse/AppCollapseItem.vue'
import tableCollapse from './Collapse/TableInCollapse.vue'
import TWCollapse from "@/views/apps/sites/sites-edit/site-errors/Performance/views/Performance/Components/Collapse/TWCollapseItem.vue";
import V2AppCollapseItem from "@/views/apps/sites/sites-edit/site-errors/Performance/views/Performance/Components/Collapse/V2AppCollapseItem.vue";
export default {
  components: {
    V2AppCollapseItem,
    TWCollapse,
    BTabs,
    BTab,
    AppCollapse,
    AppCollapseItem,
    tableCollapse,
  },
  props: {
    opportunities: {
      type: Array,
      required: true,
      default: () => [],
    },
    approves: {
      type: Array,
      required: true,
      default: () => [],
    },
    diagnostics: {
      type: Array,
      required: true,
      default: () => [],
    }
  },
  data() {
    return {
      collapseCurrentItem: '',
    }
  }
}
</script>