<template>
    <div style="padding-bottom: 80px;">
        <you-lost-access-sidebar :show-open-you-lost-access-sidebar.sync="showOpenYouLostAccessSidebar" :url="idsite.domain" />
        <sidebar-ownership :idsite="idsite" :show-open.sync="showOpen" />
        <change-project :idsite="idsite" :show-open-change-project.sync="showOpenChangeProject" />
        <archive-site :idsite="idsite" :show-open-archive-site.sync="showOpenArchiveSite" />
        <accept-invitation :idsite="idsite" :show-open-accept-invitation.sync="showOpenAcceptInvitation" />
        
        <div v-if="existSites" class="mb-2">
            <b-overlay
                :show="loaderSites"
                rounded="xl"
                no-fade
            >
                <b-table-simple
                    hover
                    small
                    caption-top
                    responsive
                    v-for="(row, index) in rowsAtomic.length >= 1 ? rowsAtomic : rows"
                    :key="index"
                    striped
                >
                    <b-thead head-variant="light" v-if="row.children.length >= 1 && row.id && row.label && row.own === 'other' && row.invitation.statusInv === 'sended'">
                        <b-tr>
                            <b-td colspan="9" style="border-top: none !important;">
                                <span class="mr-50" style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px; display: flex; margin-top: 0px;" v-if="row.id && row.label">
                                    <div :style="`width: 12px; height: 12px; border-radius: 4px; margin-top: 6px;`" class="mr-50"></div>
                                    <b-link v-if="row.label !== 'My sites' && row.idsite" :style="row.noExistSites ? 'color: #A7AAB9 !important; padding-top: 16px;' : 'color: #A7AAB9 !important; padding-top: 16px;'" @click="logIdAcceptInvitation(row)">Project</b-link>
                                </span>
                                <span class="" :style="`font-weight: 700; font-size: 16px !important; color: #181E38 !important; display: flex; ${!row.id ? 'margin-top: 0px;' : 'margin-top: 0px;'}`">
                                    <div :style="`width: 12px; height: 12px; border-radius: 4px; background: ${row.color}; margin-top: 6px;`" class="mr-50"></div>
                                    <b-link v-if="row.label === 'My sites'" style="color: #181E38 !important; max-width: 33%;" @click="logIdAcceptInvitation(row)">{{ row.label.substring(0, 100) + (row.label.length > 100 ? '...' : '') }}</b-link>
                                    <b-link v-if="row.label !== 'My sites' && row.idsite" style="color: #181E38 !important; max-width: 33%;" @click="logIdAcceptInvitation(row)">{{ row.label.substring(0, 100) + (row.label.length > 100 ? '...' : '') }}</b-link>

                                    <div v-if="row.id && row.label && row.own === 'other' && row.invitation.statusInv === 'sended'" style="display: flex;" role="button">
                                        <b-button
                                            variant="outline-primary"
                                            class="p-50 ml-1"
                                            style="background: #CA34FF1A !important; border: 1px solid #7838ff !important; color: #7838ff !important; font-size: 13px !important;"
                                            @click.prevent="logIdAcceptInvitation(row)"
                                        >
                                            Accept Invitation
                                        </b-button>
                                    </div>
                                </span>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td v-for="(column, indexCol) in columns" :key="indexCol" :class="column.thClass">
                                <span>{{ column.label }}</span>
                            </b-td>
                        </b-tr>
                    </b-thead>
                    <b-thead head-variant="light" v-else-if="row.children.length >= 1">
                        <b-tr>
                            <b-td colspan="9" style="border-top: none !important;">
                                <span class="mr-50" style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px; display: flex; margin-top: 0px;" v-if="row.id && row.label">
                                    <div :style="`width: 12px; height: 12px; border-radius: 4px; margin-top: 6px;`" class="mr-50"></div>
                                    <b-link v-if="row.label !== 'My sites' && row.idsite" :style="row.noExistSites ? 'color: #A7AAB9 !important; padding-top: 16px;' : 'color: #A7AAB9 !important; padding-top: 16px;'" :to="{ name: 'project-sites', params: {id: row.idsite, name: row.label, own: row.own} }">Project</b-link>
                                </span>
                                <span class="" :style="`font-weight: 700; font-size: 16px !important; color: #181E38 !important; display: flex; ${!row.id ? 'margin-top: 0px;' : 'margin-top: 0px;'}`">
                                    <div :style="`width: 12px; height: 12px; border-radius: 4px; background: ${row.color}; margin-top: 6px;`" class="mr-50"></div>
                                    <b-link v-if="row.label === 'My sites'" style="color: #181E38 !important; max-width: 33%;" :to="{ name: 'sites-no-project', params: {id: row.idsite} }">{{ row.label.substring(0, 100) + (row.label.length > 100 ? '...' : '') }}</b-link>
                                    <b-link v-if="row.label !== 'My sites' && row.idsite" style="color: #181E38 !important; max-width: 33%;" :to="{ name: 'project-sites', params: {id: row.idsite, name: row.label, own: row.own} }">{{ row.label.substring(0, 100) + (row.label.length > 100 ? '...' : '') }}</b-link>
                                    
                                    <div v-if="row.id && row.label && row.own === 'other' && row.invitation.statusInv === 'accepted'" style="display: flex; position: relative; top: -5px;">
                                        <div class="cursor-pointer" v-if="row.children.length >= 1 && (row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit')" @click.prevent="logIdMembersProject(row)">
                                            <b-avatar-group size="20px" class="ml-25" style="padding-left: 8px;" @click.prevent="logIdMembersProject(row)" v-if="row.children.length >= 1 && (row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit')">
                                                <!-- {{row.members}} -->
                                                <b-avatar
                                                    v-b-tooltip.hover
                                                    :title="memb.name ? memb.name : memb.email"
                                                    :src="memb.imgU"
                                                    :class="index >= 4 ? 'd-none' : 'pull-up'"
                                                    variant="light-dark"
                                                    :style="row.members.length >= 2 ? 'width: 26px; height: 26px; background: #ddd !important;' : 'width: 34px; height: 28px; background: #ddd !important;'"
                                                    v-for="(memb, index) in row.members" :key="row.idsite + '' + index"
                                                    :text="avatarText(memb.name ? (memb.name.includes(' ') ? memb.name : memb.name + ' ' + memb.name.charAt(1)) : memb.email + ' ' + memb.email.charAt(1))"
                                                />
                                                <b-avatar
                                                    class="pull-up"
                                                    variant="light-dark"
                                                    v-if="row.members.length > 4"
                                                    style="background: #ddd !important; width: 26px; height: 26px;"
                                                >
                                                    <span class="b-avatar-img"></span>
                                                    <span
                                                        class="b-avatar-badge"
                                                        style="font-size: calc(13.16px);"
                                                    >+{{row.members.length - 4}}</span>
                                                </b-avatar>
                                            </b-avatar-group>
                                        </div>
                                        <div class="cursor-pointer" v-else-if="row.children.length >= 1 && (row.permission === 'view')" @click.prevent="logIdMembersProject(row)">
                                            <b-avatar-group size="20px" class="ml-25" style="padding-left: 8px;" v-if="row.children.length >= 1 && (row.permission === 'view')">
                                                <!-- {{row.members}} -->
                                                <b-avatar
                                                    v-b-tooltip.hover
                                                    :title="memb.name ? memb.name : memb.email"
                                                    :src="memb.imgU"
                                                    :class="index >= 4 ? 'd-none' : 'pull-up'"
                                                    variant="light-dark"
                                                    :style="row.members.length >= 2 ? 'width: 26px; height: 26px; background: #ddd !important;' : 'width: 34px; height: 28px; background: #ddd !important;'"
                                                    v-for="(memb, index) in row.members" :key="row.idsite + '' + index"
                                                    :text="avatarText(memb.name ? (memb.name.includes(' ') ? memb.name : memb.name + ' ' + memb.name.charAt(1)) : memb.email + ' ' + memb.email.charAt(1))"
                                                />
                                                <b-avatar
                                                    class="pull-up"
                                                    variant="light-dark"
                                                    v-if="row.members.length > 4"
                                                    style="background: #ddd !important; width: 26px; height: 26px;"
                                                >
                                                    <span class="b-avatar-img"></span>
                                                    <span
                                                        class="b-avatar-badge"
                                                        style="font-size: calc(13.16px);"
                                                    >+{{row.members.length - 4}}</span>
                                                </b-avatar>
                                            </b-avatar-group>
                                        </div>
                                    </div>

                                    <div v-if="row.id && row.label && row.own === 'me'" style="display: flex;" @click.prevent="logIdMembersProject(row)" role="button">
                                        <div v-if="row.totalInv === 0 && row.permission !== 'view'" style="position: relative; top: -5px; margin-left: 8px;">
                                            <b-button
                                                variant=""
                                                class="btn-icon rounded-circle"
                                                style="background: #ddddd9 !important; border: 1px solid #ddddd9 !important;"
                                                size="sm"
                                            >
                                                <feather-icon icon="PlusIcon" style="color: #5c5f5c;" />
                                            </b-button>
                                            <b-button
                                                variant="flat-secondary"
                                                class="p-50 ml-50"
                                                style="color: #7838ff !important; font-size: 13px !important;"
                                            >
                                                Share this project
                                            </b-button>
                                        </div>
                                        <div v-else class="d-flex" style="position: relative; top: -5px;">
                                            <b-avatar-group size="20px" class="ml-25" style="padding-left: 8px;" @click.prevent="logIdMembersProject(row)" v-if="row.children.length >= 1 && (row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit')">
                                                <!-- {{row.members}} -->
                                                <b-avatar
                                                    v-b-tooltip.hover
                                                    :title="memb.name ? memb.name : memb.email"
                                                    :src="memb.imgU"
                                                    :class="index >= 4 ? 'd-none' : 'pull-up'"
                                                    variant="light-dark"
                                                    :style="row.members.length >= 2 ? 'width: 26px; height: 26px; background: #ddd !important;' : 'width: 34px; height: 28px; background: #ddd !important;'"
                                                    v-for="(memb, index) in row.members" :key="row.idsite + '' + index"
                                                    :text="avatarText(memb.name ? (memb.name.includes(' ') ? memb.name : memb.name + ' ' + memb.name.charAt(1)) : memb.email + ' ' + memb.email.charAt(1))"
                                                />
                                                <b-avatar
                                                    class="pull-up"
                                                    variant="light-dark"
                                                    style="background: #ddd !important; width: 26px; height: 26px;"
                                                    v-if="row.members.length > 4"
                                                >
                                                    <span class="b-avatar-img"></span>
                                                    <span
                                                        class="b-avatar-badge"
                                                        style="font-size: calc(13.16px);"
                                                    >+{{row.members.length - 4}}</span>
                                                </b-avatar>
                                            </b-avatar-group>
                                            <!-- <span style="color: #A7AAB9; font-size: 15px; font-weight: 300; font-style: normal;" class="ml-50">Share this project</span> -->
                                        </div>
                                    </div>

                                    <div v-if="row.id && row.label && row.own === 'other' && row.invitation.statusInv === 'sended'" style="display: flex;" role="button">
                                        <b-button
                                            variant="outline-primary"
                                            class="p-50 ml-1"
                                            style="background: #CA34FF1A !important; border: 1px solid #7838ff !important; color: #7838ff !important; font-size: 13px !important;"
                                            @click.prevent="logIdAcceptInvitation(row)"
                                        >
                                            Accept Invitation
                                        </b-button>
                                    </div>
                                </span>
                                <div v-if="row.id && row.label && row.own === 'other' && row.invitation.statusInv === 'accepted'" style="display: flex;">
                                    <feather-icon icon="AlertOctagonIcon" style="color: #ff38d8; margin-top: 5px; margin-left: 15px;" />
                                    <span style="color: #ff38d8; font-size: 15px; font-weight: 300; font-style: normal;" class="ml-50" v-if="row.permission === 'view'">Shared with me</span>
                                    <span style="color: #ff38d8; font-size: 15px; font-weight: 300; font-style: normal;" class="ml-50 cursor-pointer" @click.prevent="logIdMembersProject(row)" v-else>Shared with me</span>
                                </div>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td v-for="(column, indexCol) in columns" :key="indexCol" :class="column.thClass">
                                <span>{{ column.label }}</span>
                            </b-td>
                        </b-tr>
                    </b-thead>
                    <b-thead head-variant="light" v-else-if="row.children.length === 0 && row.label !== 'My sites'">
                        <b-tr>
                            <b-td colspan="9" style="border-top: none !important;">
                                <span class="mr-50" style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px; display: flex; margin-top: 0px;" v-if="row.id && row.label">
                                    <div :style="`width: 12px; height: 12px; border-radius: 4px; margin-top: 6px;`" class="mr-50"></div>
                                    <b-link v-if="row.label !== 'My sites' && row.idsite" :style="row.noExistSites ? 'color: #A7AAB9 !important; padding-top: 16px;' : 'color: #A7AAB9 !important; padding-top: 16px;'" :to="{ name: 'project-sites', params: {id: row.idsite, name: row.label, own: row.own} }">Project</b-link>
                                </span>
                                <span class="" :style="`font-weight: 700; font-size: 16px !important; color: #181E38 !important; display: flex; ${!row.id ? 'margin-top: 0px;' : 'margin-top: 0px;'}`">
                                    <div :style="`width: 12px; height: 12px; border-radius: 4px; background: ${row.color}; margin-top: 6px;`" class="mr-50"></div>
                                    <b-link v-if="row.label === 'My sites'" style="color: #181E38 !important; max-width: 33%;" :to="{ name: 'sites-no-project', params: {id: row.idsite} }">{{ row.label.substring(0, 100) + (row.label.length > 100 ? '...' : '') }}</b-link>
                                    <b-link v-if="row.label !== 'My sites' && row.idsite" style="color: #181E38 !important; max-width: 33%;" :to="{ name: 'project-sites', params: {id: row.idsite, name: row.label, own: row.own} }">{{ row.label.substring(0, 100) + (row.label.length > 100 ? '...' : '') }}</b-link>
                                

                                    <div v-if="row.id && row.label && row.own === 'other' && row.invitation.statusInv === 'accepted'" style="display: flex; position: relative; top: -5px;">
                                        <div class="cursor-pointer" v-if="row.children.length >= 1 && (row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit')" @click.prevent="logIdMembersProject(row)">
                                            <b-avatar-group size="20px" class="ml-25" style="padding-left: 8px;" @click.prevent="logIdMembersProject(row)" v-if="row.children.length >= 1 && (row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit')">
                                                <b-avatar
                                                    v-b-tooltip.hover
                                                    :title="memb.name ? memb.name : memb.email"
                                                    :src="memb.imgU"
                                                    :class="index >= 4 ? 'd-none' : 'pull-up'"
                                                    variant="light-dark"
                                                    :style="row.members.length >= 2 ? 'width: 26px; height: 26px; background: #ddd !important;' : 'width: 34px; height: 28px; background: #ddd !important;'"
                                                    v-for="(memb, index) in row.members" :key="row.idsite + '' + index"
                                                    :text="avatarText(memb.name ? (memb.name.includes(' ') ? memb.name : memb.name + ' ' + memb.name.charAt(1)) : memb.email + ' ' + memb.email.charAt(1))"
                                                />
                                                <b-avatar
                                                    class="pull-up"
                                                    variant="light-dark"
                                                    v-if="row.members.length > 4"
                                                    style="background: #ddd !important; width: 26px; height: 26px;"
                                                >
                                                    <span class="b-avatar-img"></span>
                                                    <span
                                                        class="b-avatar-badge"
                                                        style="font-size: calc(13.16px);"
                                                    >+{{row.members.length - 4}}</span>
                                                </b-avatar>
                                            </b-avatar-group>
                                        </div>
                                        <div class="cursor-pointer" style="position: relative; top: -5px;" v-else-if="row.children.length >= 1 && (row.permission === 'view')" @click.prevent="logIdMembersProject(row)">
                                            <b-avatar-group size="20px" class="ml-25" style="padding-left: 8px;" v-if="row.children.length >= 1 && (row.permission === 'view')">
                                                <b-avatar
                                                    v-b-tooltip.hover
                                                    :title="memb.name ? memb.name : memb.email"
                                                    :src="memb.imgU"
                                                    :class="index >= 4 ? 'd-none' : 'pull-up'"
                                                    variant="light-dark"
                                                    :style="row.members.length >= 2 ? 'width: 26px; height: 26px; background: #ddd !important;' : 'width: 34px; height: 28px; background: #ddd !important;'"
                                                    v-for="(memb, index) in row.members" :key="row.idsite + '' + index"
                                                    :text="avatarText(memb.name ? (memb.name.includes(' ') ? memb.name : memb.name + ' ' + memb.name.charAt(1)) : memb.email + ' ' + memb.email.charAt(1))"
                                                />
                                                <b-avatar
                                                    class="pull-up"
                                                    variant="light-dark"
                                                    v-if="row.members.length > 4"
                                                    style="background: #ddd !important; width: 26px; height: 26px;"
                                                >
                                                    <span class="b-avatar-img"></span>
                                                    <span
                                                        class="b-avatar-badge"
                                                        style="font-size: calc(13.16px);"
                                                    >+{{row.members.length - 4}}</span>
                                                </b-avatar>
                                            </b-avatar-group>
                                        </div>
                                    </div>

                                    <div v-if="row.id && row.label && row.own === 'me'" style="display: flex;" @click.prevent="logIdMembersProject(row)" role="button">
                                        <div v-if="row.totalInv === 0 && row.permission !== 'view'" style="position: relative; top: -5px; margin-left: 8px;">
                                            <b-button
                                                variant=""
                                                class="btn-icon rounded-circle"
                                                style="background: #ddddd9 !important; border: 1px solid #ddddd9 !important;"
                                                size="sm"
                                            >
                                                <feather-icon icon="PlusIcon" style="color: #5c5f5c;" />
                                            </b-button>
                                            <b-button
                                                variant="flat-secondary"
                                                class="p-50 ml-50"
                                                style="color: #7838ff !important; font-size: 13px !important;"
                                            >
                                                Share this project
                                            </b-button>
                                        </div>
                                        <div v-else class="d-flex" style="position: relative; top: -5px;">
                                            <b-avatar-group size="20px" class="ml-25" style="padding-left: 8px;" @click.prevent="logIdMembersProject(row)" v-if="row.children.length >= 1 && (row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit')">
                                                <b-avatar
                                                    v-b-tooltip.hover
                                                    :title="memb.name ? memb.name : memb.email"
                                                    :src="memb.imgU"
                                                    :class="index >= 4 ? 'd-none' : 'pull-up'"
                                                    variant="light-dark"
                                                    :style="row.members.length >= 2 ? 'width: 26px; height: 26px; background: #ddd !important;' : 'width: 34px; height: 28px; background: #ddd !important;'"
                                                    v-for="(memb, index) in row.members" :key="row.idsite + '' + index"
                                                    :text="avatarText(memb.name ? (memb.name.includes(' ') ? memb.name : memb.name + ' ' + memb.name.charAt(1)) : memb.email + ' ' + memb.email.charAt(1))"
                                                />
                                                <b-avatar
                                                    class="pull-up"
                                                    variant="light-dark"
                                                    style="background: #ddd !important; width: 26px; height: 26px;"
                                                    v-if="row.members.length > 4"
                                                >
                                                    <span class="b-avatar-img"></span>
                                                    <span
                                                        class="b-avatar-badge"
                                                        style="font-size: calc(13.16px);"
                                                    >+{{row.members.length - 4}}</span>
                                                </b-avatar>
                                            </b-avatar-group>
                                        </div>
                                    </div>

                                    <div v-if="row.id && row.label && row.own === 'other' && row.invitation.statusInv === 'sended'" style="display: flex;" role="button">
                                        <b-button
                                            variant="outline-primary"
                                            class="p-50 ml-1"
                                            style="background: #CA34FF1A !important; border: 1px solid #7838ff !important; color: #7838ff !important; font-size: 13px !important;"
                                            @click.prevent="logIdAcceptInvitation(row)"
                                        >
                                            Accept Invitation
                                        </b-button>
                                    </div>
                                </span>
                                <div v-if="row.id && row.label && row.own === 'other' && row.invitation.statusInv === 'accepted'" style="display: flex;">
                                    <feather-icon icon="AlertOctagonIcon" style="color: #ff38d8; margin-top: 5px; margin-left: 15px;" />
                                    <span style="color: #ff38d8; font-size: 15px; font-weight: 300; font-style: normal;" class="ml-50" v-if="row.permission === 'view'">Shared with me</span>
                                    <span style="color: #ff38d8; font-size: 15px; font-weight: 300; font-style: normal;" class="ml-50 cursor-pointer" @click.prevent="logIdMembersProject(row)" v-else>Shared with me</span>
                                </div>
                            </b-td>
                        </b-tr>
                        <!-- <b-tr>
                            <b-td v-for="(column, indexCol) in columns" :key="indexCol" :class="column.thClass">
                                <span>{{ column.label }}</span>
                            </b-td>
                        </b-tr> -->
                    </b-thead>
                    <b-tbody v-if="row.children.length >= 1 && row.id && row.label && row.own === 'other' && row.invitation.statusInv === 'sended'">
                        <b-tr v-for="(siteRow, indexSite) in row.children" :key="indexSite">
                            <b-td v-if="siteRow.imgSite" class="tdTableSites" @click.prevent="logIdAcceptInvitation(row)">
                                <b-img-lazy :src="siteRow.imgSite" onerror="this.onerror=null;this.src='https://src.seocloud.ai/site-thumb.png';" width="120px" height="70px" class="my-50" loading="lazy" :alt="'img not found'" />
                            </b-td>
                            <b-td v-if="siteRow.name" class="tdTableSitesName" @click.prevent="logIdAcceptInvitation(row)">
                                <div :style="siteRow.crawl && siteRow.crawl.estatus === 'running' ? 'top: -12px; position: relative;' : ''">
                                    <span class="" style="font-size: 15px; color: #181E38;">{{ siteRow.name }}</span>
                                    <div style="width: 200px; position: absolute;" v-if="siteRow.crawl && siteRow.crawl.estatus === 'running'">
                                        <b-progress
                                            :value="siteRow.crawl.requests ? siteRow.crawl.requests : 0"
                                            :max="siteRow.crawl.availableURL ? siteRow.crawl.requests : 1"
                                            class="progress-bar-info mt-25"
                                            variant="info"
                                            style="height: 5px;"
                                        />
                                        <span class="" style="color: #A7AAB9; font-size: 14px;">Crawling {{siteRow.crawl.requests ? siteRow.crawl.requests : 0}} of {{siteRow.crawl.availableURL ? siteRow.crawl.availableURL : siteRow.crawl.requests ? siteRow.crawl.requests : 1}} pages</span>
                                    </div>
                                </div>
                            </b-td>
                            <b-td v-if="siteRow.domainFix" class="tdTableSitesDomain" @click.prevent="logIdAcceptInvitation(row)">
                                <span class="" :style="siteRow.crawl && siteRow.crawl.estatus === 'running' ? 'top: -12px; position: relative; font-size: 15px; color: #A7AAB9;' : 'font-size: 15px; color: #A7AAB9;'">{{ siteRow['domainFix'] }}</span>
                            </b-td>
                            <b-td v-if="siteRow.currentErrors" class="tdTableSitesViewList" @click.prevent="logIdAcceptInvitation(row)">
                                <b-spinner style="color: #e3e3e3;" label="Loading..." variant="" v-if="loadErrors" />
                                <span class="" style="font-size: 15px; color: #FC7D7D;" v-else>
                                    {{ siteRow['currentErrors'] }}
                                    <b-avatar variant="light-danger" size="12px" style="margin-bottom: 7px; background: rgba(234, 84, 85, 0.12) !important;">
                                        <feather-icon icon="ChevronDownIcon" />
                                    </b-avatar>
                                </span>
                            </b-td>
                            <!-- <b-td v-if="siteRow.fixedIssues" style="text-align: center;" class="tdTableSitesViewList" @click.prevent="logIdAcceptInvitation(row)">
                                <span class="" style="font-size: 15px; color: #53D991;">
                                    {{ siteRow['fixedIssues'] }}
                                    <b-avatar variant="light-success" size="12px" style="margin-bottom: 7px; background: rgba(40, 199, 111, 0.12) !important;">
                                        <feather-icon icon="ChevronUpIcon" />
                                    </b-avatar>
                                </span>
                            </b-td> -->
                            <!-- <b-td v-if="siteRow.key" class="tdTableSitesKeyViewListKey">
                                <span class="" style="font-size: 15px; color: #656B85;">••••••••{{ siteRow['key'].length === 21 ? siteRow['key'].slice(19) : siteRow['key'].slice(20) }}</span>
                                <b-button
                                    v-clipboard:copy="siteRow['key']"
                                    v-clipboard:success="onCopy"
                                    v-clipboard:error="onError"
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    variant="flat-dark"
                                    style="color: #A7AAB9;"
                                    class="btn-icon rounded-circle"
                                >
                                    <feather-icon icon="CopyIcon" />
                                </b-button>
                            </b-td> -->
                            <b-td v-if="siteRow.membership" class="tdTableSitesViewListMembership">
                                <span v-if="siteRow['membership'] === 'Free'" class="w-100 pb-50 d-block cursor-initial" style="margin-left: 3.1rem !important; font-size: 1rem; cursor: initial;">Free</span>
                                <b-dropdown
                                    :text="siteRow['membership']"
                                    :style="row.permission === 'view' ? 'cursor: initial !important;' : ''"
                                    variant="outline-secondary"
                                    :class="siteRow['membership'] === 'Free' ? 'style-chooser ml-3 mb-25' : 'style-chooser ml-2 mb-25'"
                                    v-if="siteRow['membership'] !== 'Free'"
                                    :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' ? false : true"
                                >
                                    <template #button-content>
                                        <b-img-lazy
                                            fluid
                                            :src="proIcon"
                                            alt="pro page img"
                                            width="17"
                                            height="16"
                                            style="margin-bottom: 7px;"
                                            class="mr-0"
                                            loading="lazy"
                                            v-if="siteRow['membership'] !== 'Free'"
                                        />
                                        {{siteRow['membership']}}
                                    </template>
                                    <b-dropdown-item style="width: 180px;" @click="logIdAcceptInvitation(row)">
                                        <span style="font-size: 15px; color: #656B85;">
                                            <feather-icon icon="ArrowUpIcon" class="mt-0 pt-0" size="12" />
                                            Upgrade
                                        </span><br>
                                        <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item style="width: 180px;" @click="logIdAcceptInvitation(row)">
                                        <span style="font-size: 15px; color: #656B85;">
                                            <feather-icon icon="ArrowDownIcon" class="mt-0 pt-0" size="12" />
                                            Downgrade
                                        </span><br>
                                        <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                                <b-button
                                    variant="outline-secondary"
                                    block
                                    class="ml-3 px-0 py-25"
                                    :style="row.permission === 'view' ? 'pointer-events: initial !important; cursor: initial !important; color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;' : 'color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;'"
                                    @click="logIdAcceptInvitation(row)"
                                    :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' ? false : true"
                                    pill
                                >
                                    Upgrade
                                </b-button>
                            </b-td>
                            <b-td v-if="siteRow.ownedBy" class="tdTableSitesOwned">
                                <div v-if="userData.uid !== siteRow['ownedBy'].uid || !siteRow['ownedBy'] || !siteRow['ownedBy'].uid">
                                    <div class="d-flex mb-50" v-if="(row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' || row.permission === 'view') && siteRow['ownedBy'].uid">
                                        <b-avatar
                                            size="27"
                                            :src="siteRow['ownedBy'].foto ? siteRow['ownedBy'].foto : ''"
                                            variant="light-dark"
                                            class="badge-minimal"
                                            alt="img user"
                                            :text="avatarText(!siteRow['ownedBy'].foto ? siteRow['ownedBy'].name : '')"
                                            style="background: #ddd !important;"
                                        >
                                            <feather-icon
                                                v-if="!siteRow['ownedBy'].foto && !siteRow['ownedBy'].name"
                                                icon="UserIcon"
                                                size="22"
                                            />
                                        </b-avatar>

                                        <div class="ml-25 mt-50">
                                            <p class="mb-0 " style="font-size: 13px; line-height: 14px; color: #656B85;">
                                                {{ siteRow['ownedBy'].name }}
                                            </p>
                                        </div>
                                    </div>
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        :style="row.permission === 'view' ? 'background: #DCDCF252 !important; border: 1px solid #DCDCF2 !important; border-radius: 10px; color: #656B85 !important; font-size: 16px; cursor: initial !important;' :'background: #DCDCF252 !important; border: 1px solid #DCDCF2 !important; border-radius: 10px; color: #656B85 !important; font-size: 16px;'"
                                        size="sm"
                                        :class="row.permission === 'view' ? 'cursor-initial' : 'cursor-pointer'"
                                        @click="logIdAcceptInvitation(row)"
                                        :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' ? false : true"
                                    >
                                    Claim
                                    </b-button>
                                    <span style="color: #FC7D7D; font-size: 13px;" class="d-block cursor-pointer" v-if="siteRow['ownedBy'].lost" @click.prevent="showOpenYouLostAccessSidebar = true, idsite = props.row">You lost access <feather-icon icon="HelpCircleIcon" style="color: #FC7D7D;" /></span>
                                </div>
                                <div v-else>
                                    <div class="d-flex">
                                        <b-avatar
                                            size="27"
                                            :src="siteRow['ownedBy'].foto ? siteRow['ownedBy'].foto : ''"
                                            variant="light-dark"
                                            class="badge-minimal"
                                            alt="img user"
                                            style="background: #ddd !important;"
                                            :text="avatarText(!siteRow['ownedBy'].foto ? siteRow['ownedBy'].name : '')"
                                        >
                                            <feather-icon
                                                v-if="!siteRow['ownedBy'].foto && !siteRow['ownedBy'].name"
                                                icon="UserIcon"
                                                size="22"
                                            />
                                        </b-avatar>

                                        <div :class="userData.uid === siteRow['ownedBy'].uid ? 'ml-25' : 'ml-25 mt-50'">
                                            <p class="mb-0 " style="font-size: 13px; line-height: 14px; color: #656B85; text-overflow: ellipsis; overflow: hidden; width: 8rem; display: inline-block;" v-if="userData.uid === siteRow['ownedBy'].uid">
                                                Me
                                            </p>
                                            <p class="mb-0 " style="font-size: 13px; line-height: 14px; color: #656B85; text-overflow: ellipsis; overflow: hidden; width: 8rem; display: inline-block;" v-else>
                                                {{ siteRow['ownedBy'].name }}
                                            </p>
                                        </div>
                                    </div>
                                    <span style="color: #FC7D7D; font-size: 13px;" class="cursor-pointer" v-if="siteRow['ownedBy'].lost" @click.prevent="showOpenYouLostAccessSidebar = true, idsite = props.row">You lost access <feather-icon icon="HelpCircleIcon" style="color: #FC7D7D;" /></span>
                                </div>
                            </b-td>
                            <b-td class="tdTableSitesAction">
                                <b-dropdown
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    dropleft
                                    no-caret
                                    variant="flat-dark"
                                    class="btn-icon dropdown-sites rounded-circle"
                                >
                                    <template #button-content class="pt-0">
                                        <feather-icon icon="MoreVerticalIcon" class="mt-0 pt-0" size="20" />
                                    </template>
                                    <b-dropdown-item link-class="d-flex align-items-center  item-dropdown-action-sites" @click="logIdAcceptInvitation(row)" :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' || row.permission === 'view' ? false : true">
                                        <feather-icon icon="Edit2Icon" class="mr-50" />
                                        Fix
                                    </b-dropdown-item>
                                    <b-dropdown-item link-class="d-flex align-items-center  item-dropdown-action-sites" @click="logIdAcceptInvitation(row)" :disabled="row.permission === 'owner' || row.permission === 'admin' ? false : true">
                                        <feather-icon icon="RepeatIcon" class="mr-50" />
                                        Change Project
                                    </b-dropdown-item>
                                    <b-dropdown-item link-class="d-flex align-items-center  item-dropdown-action-sites" @click="logIdAcceptInvitation(row)" :disabled="row.permission === 'owner' || row.permission === 'admin' ? false : true">
                                        <feather-icon icon="ArchiveIcon" class="mr-50" />
                                        Archive Site
                                    </b-dropdown-item>
                                    <b-dropdown-item link-class="d-flex align-items-center  item-dropdown-action-sites" @click="logIdAcceptInvitation(row)" :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' ? false : true">
                                        <feather-icon icon="SettingsIcon" class="mr-50" />
                                        Edit Site
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                    <b-tbody v-else-if="row.children.length >= 1">
                        <b-tr v-for="(siteRow, indexSite) in row.children" :key="indexSite">
                            <b-td v-if="siteRow.imgSite" class="tdTableSites" @click.prevent="onRowSelected(siteRow)">
                                <b-img-lazy :src="siteRow.imgSite" onerror="this.onerror=null;this.src='https://src.seocloud.ai/site-thumb.png';" width="120px" height="70px" class="my-50" loading="lazy" :alt="'img not found'" />
                            </b-td>
                            <b-td v-if="siteRow.name" class="tdTableSitesName" @click.prevent="onRowSelected(siteRow)">
                                <div :style="siteRow.crawl && siteRow.crawl.estatus === 'running' ? 'top: -12px; position: relative;' : ''">
                                    <span class="" style="font-size: 15px; color: #181E38;">{{ siteRow.name }}</span>
                                    <div style="width: 200px; position: absolute;" v-if="siteRow.crawl && siteRow.crawl.estatus === 'running'">
                                        <b-progress
                                            :value="siteRow.crawl.requests ? siteRow.crawl.requests : 0"
                                            :max="siteRow.crawl.availableURL ? siteRow.crawl.availableURL : 1"
                                            class="progress-bar-info mt-25"
                                            variant="info"
                                            style="height: 5px;"
                                        />
                                        <span class="" style="color: #A7AAB9; font-size: 14px;">Crawling {{siteRow.crawl.requests ? siteRow.crawl.requests : 0}} of {{siteRow.crawl.availableURL ? siteRow.crawl.availableURL : siteRow.crawl.requests ? siteRow.crawl.requests : 1}} pages</span>
                                    </div>
                                </div>
                            </b-td>
                            <b-td v-if="siteRow.domainFix" class="tdTableSitesDomain" @click.prevent="onRowSelected(siteRow)">
                                <span class="" :style="siteRow.crawl && siteRow.crawl.estatus === 'running' ? 'top: -12px; position: relative; font-size: 15px; color: #A7AAB9;' : 'font-size: 15px; color: #A7AAB9;'">{{ siteRow['domainFix'] }}</span>
                            </b-td>
                            <b-td v-if="siteRow.currentErrors" class="tdTableSitesViewList" @click.prevent="onRowSelected(siteRow)">
                                <b-spinner style="color: #e3e3e3;" label="Loading..." variant="" v-if="loadErrors" />
                                <span class="" style="font-size: 15px; color: #FC7D7D;" v-else>
                                    {{ siteRow['currentErrors'] }}
                                    <b-avatar variant="light-danger" size="12px" style="margin-bottom: 7px; background: rgba(234, 84, 85, 0.12) !important;">
                                        <feather-icon icon="ChevronDownIcon" />
                                    </b-avatar>
                                </span>
                            </b-td>
                            <!-- <b-td v-if="siteRow.fixedIssues" style="text-align: center;" class="tdTableSitesViewList" @click.prevent="onRowSelected(siteRow)">
                                <span class="" style="font-size: 15px; color: #53D991;">
                                    {{ siteRow['fixedIssues'] }}
                                    <b-avatar variant="light-success" size="12px" style="margin-bottom: 7px; background: rgba(40, 199, 111, 0.12) !important;">
                                        <feather-icon icon="ChevronUpIcon" />
                                    </b-avatar>
                                </span>
                            </b-td> -->
                            <!-- <b-td v-if="siteRow.key" class="tdTableSitesKeyViewListKey">
                                <span class="" style="font-size: 15px; color: #656B85;">••••••••{{ siteRow['key'].length === 21 ? siteRow['key'].slice(19) : siteRow['key'].slice(20) }}</span>
                                <b-button
                                    v-clipboard:copy="siteRow['key']"
                                    v-clipboard:success="onCopy"
                                    v-clipboard:error="onError"
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    variant="flat-dark"
                                    style="color: #A7AAB9;"
                                    class="btn-icon rounded-circle"
                                >
                                    <feather-icon icon="CopyIcon" />
                                </b-button>
                            </b-td> -->
                            <b-td v-if="siteRow.membership" class="tdTableSitesViewListMembership">
                                <span v-if="siteRow['membership'] === 'Free'" class="w-100 pb-50 d-block cursor-initial" style="margin-left: 3.1rem !important; font-size: 1rem; cursor: initial;">Free</span>
                                <b-dropdown
                                    :text="siteRow['membership']"
                                    :style="row.permission === 'view' ? 'cursor: initial !important;' : ''"
                                    variant="outline-secondary"
                                    :class="siteRow['membership'] === 'Free' ? 'style-chooser ml-3 mb-25' : 'style-chooser ml-2 mb-25'"
                                    v-if="siteRow['membership'] !== 'Free'"
                                    :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' ? false : true"
                                >
                                    <template #button-content>
                                        <b-img-lazy
                                            fluid
                                            :src="proIcon"
                                            alt="pro page img"
                                            width="17"
                                            height="16"
                                            style="margin-bottom: 7px;"
                                            class="mr-0"
                                            loading="lazy"
                                            v-if="siteRow['membership'] !== 'Free'"
                                        />
                                        {{siteRow['membership']}}
                                    </template>
                                    <b-dropdown-item style="width: 180px;" :to="{ name: 'memberships', params: {idsite: siteRow} }">
                                        <span style="font-size: 15px; color: #656B85;">
                                            <feather-icon icon="ArrowUpIcon" class="mt-0 pt-0" size="12" />
                                            Upgrade
                                        </span><br>
                                        <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item style="width: 180px;" :to="{ name: 'memberships', params: {idsite: siteRow} }">
                                        <span style="font-size: 15px; color: #656B85;">
                                            <feather-icon icon="ArrowDownIcon" class="mt-0 pt-0" size="12" />
                                            Downgrade
                                        </span><br>
                                        <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                                <b-button
                                    variant="outline-secondary"
                                    block
                                    class="ml-3 px-0 py-25"
                                    :style="row.permission === 'view' ? 'pointer-events: initial !important; cursor: initial !important; color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;' : 'color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;'"
                                    :to="{ name: 'memberships', params: {idsite: siteRow} }"
                                    :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' ? false : true"
                                    pill
                                >
                                    Upgrade
                                </b-button>
                            </b-td>
                            <b-td v-if="siteRow.ownedBy" class="tdTableSitesOwned">
                                <div v-if="userData.uid !== siteRow['ownedBy'].uid || !siteRow['ownedBy'] || !siteRow['ownedBy'].uid">
                                    <div class="d-flex mb-50" v-if="(row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' || row.permission === 'view') && siteRow['ownedBy'].uid">
                                        <b-avatar
                                            size="27"
                                            :src="siteRow['ownedBy'].foto ? siteRow['ownedBy'].foto : ''"
                                            variant="light-dark"
                                            class="badge-minimal"
                                            alt="img user"
                                            :text="avatarText(!siteRow['ownedBy'].foto ? siteRow['ownedBy'].name : '')"
                                            style="background: #ddd !important;"
                                            v-if="row.permission === 'ownerx'"
                                        >
                                            <feather-icon
                                                v-if="!siteRow['ownedBy'].foto && !siteRow['ownedBy'].name"
                                                icon="UserIcon"
                                                size="22"
                                            />
                                        </b-avatar>

                                        <div class="ml-25 mt-50">
                                            <p class="mb-0" style="font-size: 13px; line-height: 14px; color: #656B85; cursor: initial !important;" v-if="row.permission === 'ownerx'">
                                                {{ siteRow['ownedBy'].name }}
                                            </p>

                                            <b-dropdown
                                                :text="siteRow['membership']"
                                                variant="outline-secondary"
                                                class="style-chooser cursor-pointer"
                                                :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' || row.permission === 'view' ? false : true"
                                                v-else
                                            >
                                                <template #button-content>
                                                    <b-avatar
                                                        size="27"
                                                        :src="siteRow['ownedBy'].foto ? siteRow['ownedBy'].foto : ''"
                                                        variant="light-dark"
                                                        class="badge-minimal"
                                                        alt="img user"
                                                        :text="avatarText(!siteRow['ownedBy'].foto ? siteRow['ownedBy'].name : '')"
                                                        style="background: #ddd !important;"
                                                    >
                                                        <feather-icon
                                                            v-if="!siteRow['ownedBy'].foto && !siteRow['ownedBy'].name"
                                                            icon="UserIcon"
                                                            size="22"
                                                        />
                                                    </b-avatar>
                                                    <span class="ml-25 mt-50 mb-0" style="font-size: 13px; line-height: 14px; color: #656B85;">
                                                        {{ siteRow['ownedBy'].name }}
                                                    </span>
                                                </template>
                                                <b-dropdown-item 
                                                    :style="row.permission === 'view' ? 'cursor: no-drop; width: 180px; text-align: center;' : 'width: 180px; text-align: center;'"
                                                    :class="row.permission === 'view' ? 'cursor-initial' : 'cursor-pointer'"
                                                    @click="logIdSite(siteRow)"
                                                    :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' ? false : true"
                                                >
                                                    <span style="font-size: 15px; color: #656B85; text-align: center;">
                                                        Claim
                                                    </span>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                    <b-button
                                        v-else
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        :style="row.permission === 'view' ? 'background: #DCDCF252 !important; border: 1px solid #DCDCF2 !important; border-radius: 10px; color: #656B85 !important; font-size: 16px; cursor: initial !important;' :'background: #DCDCF252 !important; border: 1px solid #DCDCF2 !important; border-radius: 10px; color: #656B85 !important; font-size: 16px;'"
                                        size="sm"
                                        :class="row.permission === 'view' ? 'cursor-initial' : 'cursor-pointer'"
                                        @click="logIdSite(siteRow)"
                                        :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' ? false : true"
                                    >
                                    Claim
                                    </b-button>
                                    <span style="color: #FC7D7D; font-size: 13px;" class="d-block cursor-pointer" v-if="siteRow['ownedBy'].lost" @click.prevent="showOpenYouLostAccessSidebar = true, idsite = props.row">You lost access <feather-icon icon="HelpCircleIcon" style="color: #FC7D7D;" /></span>
                                </div>
                                <div v-else>
                                    <div class="d-flex">
                                        <b-avatar
                                            size="27"
                                            :src="siteRow['ownedBy'].foto ? siteRow['ownedBy'].foto : ''"
                                            variant="light-dark"
                                            class="badge-minimal"
                                            alt="img user"
                                            style="background: #ddd !important;"
                                            :text="avatarText(!siteRow['ownedBy'].foto ? siteRow['ownedBy'].name : '')"
                                        >
                                            <feather-icon
                                                v-if="!siteRow['ownedBy'].foto && !siteRow['ownedBy'].name"
                                                icon="UserIcon"
                                                size="22"
                                            />
                                        </b-avatar>

                                        <div :class="userData.uid === siteRow['ownedBy'].uid ? 'ml-25' : 'ml-25 mt-50'">
                                            <p class="mb-0 " style="font-size: 13px; line-height: 14px; color: #656B85; text-overflow: ellipsis; overflow: hidden; width: 8rem; display: inline-block;" v-if="userData.uid === siteRow['ownedBy'].uid">
                                                Me
                                            </p>
                                            <p class="mb-0 " style="font-size: 13px; line-height: 14px; color: #656B85; text-overflow: ellipsis; overflow: hidden; width: 8rem; display: inline-block;" v-else>
                                                {{ siteRow['ownedBy'].name }}
                                            </p>
                                        </div>
                                    </div>
                                    <span style="color: #FC7D7D; font-size: 13px;" class="cursor-pointer" v-if="siteRow['ownedBy'].lost" @click.prevent="showOpenYouLostAccessSidebar = true, idsite = props.row">You lost access <feather-icon icon="HelpCircleIcon" style="color: #FC7D7D;" /></span>
                                </div>
                            </b-td>
                            <b-td class="tdTableSitesAction">
                                <b-dropdown
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    dropleft
                                    no-caret
                                    variant="flat-dark"
                                    class="btn-icon dropdown-sites rounded-circle"
                                >
                                    <template #button-content class="pt-0">
                                        <feather-icon icon="MoreVerticalIcon" class="mt-0 pt-0" size="20" />
                                    </template>
                                    <b-dropdown-item link-class="d-flex align-items-center  item-dropdown-action-sites" @click="getError(siteRow)" :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' || row.permission === 'view' ? false : true">
                                        <feather-icon icon="Edit2Icon" class="mr-50" />
                                        Fix
                                    </b-dropdown-item>
                                    <b-dropdown-item link-class="d-flex align-items-center  item-dropdown-action-sites" @click="logIdChangeProject(siteRow)" :disabled="row.permission === 'owner' || row.permission === 'admin' ? false : true">
                                        <feather-icon icon="RepeatIcon" class="mr-50" />
                                        Change Project
                                    </b-dropdown-item>
                                    <b-dropdown-item link-class="d-flex align-items-center  item-dropdown-action-sites" @click="logIdArchive(siteRow)" :disabled="row.permission === 'owner' || row.permission === 'admin' ? false : true">
                                        <feather-icon icon="ArchiveIcon" class="mr-50" />
                                        Archive Site
                                    </b-dropdown-item>
                                    <b-dropdown-item link-class="d-flex align-items-center  item-dropdown-action-sites" :to="{ name: 'edit-site', params: { id: siteRow.id, project: siteRow.projectid ? siteRow.projectid : 'w_p' } }" :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' ? false : true">
                                        <feather-icon icon="SettingsIcon" class="mr-50" />
                                        Edit Site
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                    <b-tbody v-else-if="row.children.length === 0 && row.label !== 'My sites'">
                        <b-tr>
                            <b-td colspan="9">
                                <span>No sites</span>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                    <b-tfoot v-if="row.children.length >= 1 && ((row.count >= 10 && row.count - 10 >= 1) || (userData.totalSites > 20 && row.count - 2 >= 1))">
                        <b-tr>
                            <b-td
                                colspan="9"
                                class="text-right"
                            >
                                <!-- row.count > 4 -->
                                <b-button
                                    v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                                    variant="outline-info"
                                    v-if="row.count > 20 && row.title === 'My sites'"
                                    block
                                    class=" pt-0"
                                    style="background: #fff !important; border: 0px !important; color: #30CDFF !important; font-size: 18px !important; max-width: 250px; margin: auto;"
                                    :to="{ name: 'sites-no-project', params: {id: row.id} }"
                                >
                                    <feather-icon
                                        icon="ChevronDownIcon"
                                        size="30"
                                        class="mt-0 pt-0"
                                    /><br>
                                    <!-- {{ userData.totalSites >= 11 && userData.totalSites &lt;= 20 ? row.count - 4 : userData.totalSites > 20 ? row.count - 2 : row.count - 4 }} more sites -->
                                    {{ userData.totalSites >= 21 && userData.totalSites &lt;= 32 ? row.count - 4 : userData.totalSites > 32 ? row.count - 2 : row.count - 4 }} more sites
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        class="ml-25"
                                        style="background: #38cfff; border-radius: 10px; color: white;"
                                    />
                                </b-button>
                                <b-button
                                    v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                                    variant="outline-info"
                                    v-else-if="(row.count >= 21 && row.count - 10 >= 1) || (userData.totalSites > 20 && row.count - 4 >= 1)"
                                    block
                                    class=" pt-0"
                                    style="background: #fff !important; border: 0px !important; color: #30CDFF !important; font-size: 18px !important; max-width: 250px; margin: auto;"
                                    :to="{ name: 'project-sites', params: {id: row.id, name: row.title, own: row.own} }"
                                >
                                    <feather-icon
                                        icon="ChevronDownIcon"
                                        size="30"
                                        class="mt-0 pt-0"
                                    /><br>
                                    <!-- {{ userData.totalSites >= 11 && userData.totalSites &lt;= 20 ? row.count - 4 : userData.totalSites > 20 ? row.count - 2 : row.count - 4 }} more sites -->
                                    {{ userData.totalSites >= 21 && userData.totalSites &lt;= 32 ? row.count - 4 : userData.totalSites > 32 ? row.count - 2 : row.count - 4 }} more sites
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        class="ml-25"
                                        style="background: #38cfff; border-radius: 10px; color: white;"
                                    />
                                </b-button>
                            </b-td>
                        </b-tr>
                    </b-tfoot>
                </b-table-simple>
                <b-table-simple
                    hover
                    small
                    caption-top
                    responsive
                    v-for="(row, index) in rowsAcceptInvitation"
                    :key="row.id+index"
                    striped
                >
                    <b-thead head-variant="light" v-if="row.children.length >= 1 && row.id && row.label && row.own === 'other' && row.invitation.statusInv === 'sended'">
                        <b-tr>
                            <b-td colspan="9" style="border-top: none !important;">
                                <span class="mr-50" style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px; display: flex; margin-top: 0px;" v-if="row.id && row.label">
                                    <div :style="`width: 12px; height: 12px; border-radius: 4px; margin-top: 6px;`" class="mr-50"></div>
                                    <b-link v-if="row.label !== 'My sites' && row.idsite" :style="row.noExistSites ? 'color: #A7AAB9 !important; padding-top: 16px;' : 'color: #A7AAB9 !important; padding-top: 16px;'" @click="logIdAcceptInvitation(row)">Project</b-link>
                                </span>
                                <span class="" :style="`font-weight: 700; font-size: 16px !important; color: #181E38 !important; display: flex; ${!row.id ? 'margin-top: 0px;' : 'margin-top: 0px;'}`">
                                    <div :style="`width: 12px; height: 12px; border-radius: 4px; background: ${row.color}; margin-top: 6px;`" class="mr-50"></div>
                                    <b-link v-if="row.label === 'My sites'" style="color: #181E38 !important; max-width: 33%;" @click="logIdAcceptInvitation(row)">{{ row.label.substring(0, 100) + (row.label.length > 100 ? '...' : '') }}</b-link>
                                    <b-link v-if="row.label !== 'My sites' && row.idsite" style="color: #181E38 !important; max-width: 33%;" @click="logIdAcceptInvitation(row)">{{ row.label.substring(0, 100) + (row.label.length > 100 ? '...' : '') }}</b-link>

                                    <div v-if="row.id && row.label && row.own === 'other' && row.invitation.statusInv === 'sended'" style="display: flex;" role="button">
                                        <b-button
                                            variant="outline-primary"
                                            class="p-50 ml-1"
                                            style="background: #CA34FF1A !important; border: 1px solid #7838ff !important; color: #7838ff !important; font-size: 13px !important;"
                                            @click.prevent="logIdAcceptInvitation(row)"
                                        >
                                            Accept Invitation
                                        </b-button>
                                    </div>
                                </span>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td v-for="(column, indexCol) in columns" :key="indexCol" :class="column.thClass">
                                <span>{{ column.label }}</span>
                            </b-td>
                        </b-tr>
                    </b-thead>
                    <b-thead head-variant="light" v-else-if="row.children.length >= 1">
                        <b-tr>
                            <b-td colspan="9" style="border-top: none !important;">
                                <span class="mr-50" style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px; display: flex; margin-top: 0px;" v-if="row.id && row.label">
                                    <div :style="`width: 12px; height: 12px; border-radius: 4px; margin-top: 6px;`" class="mr-50"></div>
                                    <b-link v-if="row.label !== 'My sites' && row.idsite" :style="row.noExistSites ? 'color: #A7AAB9 !important; padding-top: 16px;' : 'color: #A7AAB9 !important; padding-top: 16px;'" :to="{ name: 'project-sites', params: {id: row.idsite, name: row.label, own: row.own} }">Project</b-link>
                                </span>
                                <span class="" :style="`font-weight: 700; font-size: 16px !important; color: #181E38 !important; display: flex; ${!row.id ? 'margin-top: 0px;' : 'margin-top: 0px;'}`">
                                    <div :style="`width: 12px; height: 12px; border-radius: 4px; background: ${row.color}; margin-top: 6px;`" class="mr-50"></div>
                                    <b-link v-if="row.label === 'My sites'" style="color: #181E38 !important; max-width: 33%;" :to="{ name: 'sites-no-project', params: {id: row.idsite} }">{{ row.label.substring(0, 100) + (row.label.length > 100 ? '...' : '') }}</b-link>
                                    <b-link v-if="row.label !== 'My sites' && row.idsite" style="color: #181E38 !important; max-width: 33%;" :to="{ name: 'project-sites', params: {id: row.idsite, name: row.label, own: row.own} }">{{ row.label.substring(0, 100) + (row.label.length > 100 ? '...' : '') }}</b-link>
                                    
                                    <div v-if="row.id && row.label && row.own === 'other' && row.invitation.statusInv === 'accepted'" style="display: flex; position: relative; top: -5px;">
                                        <div class="cursor-pointer" v-if="row.children.length >= 1 && (row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit')" @click.prevent="logIdMembersProject(row)">
                                            <b-avatar-group size="20px" class="ml-25" style="padding-left: 8px;" @click.prevent="logIdMembersProject(row)" v-if="row.children.length >= 1 && (row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit')">
                                                <!-- {{row.members}} -->
                                                <b-avatar
                                                    v-b-tooltip.hover
                                                    :title="memb.name ? memb.name : memb.email"
                                                    :src="memb.imgU"
                                                    :class="index >= 4 ? 'd-none' : 'pull-up'"
                                                    variant="light-dark"
                                                    :style="row.members.length >= 2 ? 'width: 26px; height: 26px; background: #ddd !important;' : 'width: 34px; height: 28px; background: #ddd !important;'"
                                                    v-for="(memb, index) in row.members" :key="row.idsite + '' + index"
                                                    :text="avatarText(memb.name ? (memb.name.includes(' ') ? memb.name : memb.name + ' ' + memb.name.charAt(1)) : memb.email + ' ' + memb.email.charAt(1))"
                                                />
                                                <b-avatar
                                                    class="pull-up"
                                                    variant="light-dark"
                                                    v-if="row.members.length > 4"
                                                    style="background: #ddd !important; width: 26px; height: 26px;"
                                                >
                                                    <span class="b-avatar-img"></span>
                                                    <span
                                                        class="b-avatar-badge"
                                                        style="font-size: calc(13.16px);"
                                                    >+{{row.members.length - 4}}</span>
                                                </b-avatar>
                                            </b-avatar-group>
                                        </div>
                                        <div class="cursor-pointer" v-else-if="row.children.length >= 1 && (row.permission === 'view')" @click.prevent="logIdMembersProject(row)">
                                            <b-avatar-group size="20px" class="ml-25" style="padding-left: 8px;" v-if="row.children.length >= 1 && (row.permission === 'view')">
                                                <!-- {{row.members}} -->
                                                <b-avatar
                                                    v-b-tooltip.hover
                                                    :title="memb.name ? memb.name : memb.email"
                                                    :src="memb.imgU"
                                                    :class="index >= 4 ? 'd-none' : 'pull-up'"
                                                    variant="light-dark"
                                                    :style="row.members.length >= 2 ? 'width: 26px; height: 26px; background: #ddd !important;' : 'width: 34px; height: 28px; background: #ddd !important;'"
                                                    v-for="(memb, index) in row.members" :key="row.idsite + '' + index"
                                                    :text="avatarText(memb.name ? (memb.name.includes(' ') ? memb.name : memb.name + ' ' + memb.name.charAt(1)) : memb.email + ' ' + memb.email.charAt(1))"
                                                />
                                                <b-avatar
                                                    class="pull-up"
                                                    variant="light-dark"
                                                    v-if="row.members.length > 4"
                                                    style="background: #ddd !important; width: 26px; height: 26px;"
                                                >
                                                    <span class="b-avatar-img"></span>
                                                    <span
                                                        class="b-avatar-badge"
                                                        style="font-size: calc(13.16px);"
                                                    >+{{row.members.length - 4}}</span>
                                                </b-avatar>
                                            </b-avatar-group>
                                        </div>
                                    </div>

                                    <div v-if="row.id && row.label && row.own === 'me'" style="display: flex;" @click.prevent="logIdMembersProject(row)" role="button">
                                        <div v-if="row.totalInv === 0 && row.permission !== 'view'" style="position: relative; top: -5px; margin-left: 8px;">
                                            <b-button
                                                variant=""
                                                class="btn-icon rounded-circle"
                                                style="background: #ddddd9 !important; border: 1px solid #ddddd9 !important;"
                                                size="sm"
                                            >
                                                <feather-icon icon="PlusIcon" style="color: #5c5f5c;" />
                                            </b-button>
                                            <b-button
                                                variant="flat-secondary"
                                                class="p-50 ml-50"
                                                style="color: #7838ff !important; font-size: 13px !important;"
                                            >
                                                Share this project
                                            </b-button>
                                        </div>
                                        <div v-else class="d-flex" style="position: relative; top: -5px;">
                                            <b-avatar-group size="20px" class="ml-25" style="padding-left: 8px;" @click.prevent="logIdMembersProject(row)" v-if="row.children.length >= 1 && (row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit')">
                                                <!-- {{row.members}} -->
                                                <b-avatar
                                                    v-b-tooltip.hover
                                                    :title="memb.name ? memb.name : memb.email"
                                                    :src="memb.imgU"
                                                    :class="index >= 4 ? 'd-none' : 'pull-up'"
                                                    variant="light-dark"
                                                    :style="row.members.length >= 2 ? 'width: 26px; height: 26px; background: #ddd !important;' : 'width: 34px; height: 28px; background: #ddd !important;'"
                                                    v-for="(memb, index) in row.members" :key="row.idsite + '' + index"
                                                    :text="avatarText(memb.name ? (memb.name.includes(' ') ? memb.name : memb.name + ' ' + memb.name.charAt(1)) : memb.email + ' ' + memb.email.charAt(1))"
                                                />
                                                <b-avatar
                                                    class="pull-up"
                                                    variant="light-dark"
                                                    style="background: #ddd !important; width: 26px; height: 26px;"
                                                    v-if="row.members.length > 4"
                                                >
                                                    <span class="b-avatar-img"></span>
                                                    <span
                                                        class="b-avatar-badge"
                                                        style="font-size: calc(13.16px);"
                                                    >+{{row.members.length - 4}}</span>
                                                </b-avatar>
                                            </b-avatar-group>
                                            <!-- <span style="color: #A7AAB9; font-size: 15px; font-weight: 300; font-style: normal;" class="ml-50">Share this project</span> -->
                                        </div>
                                    </div>

                                    <div v-if="row.id && row.label && row.own === 'other' && row.invitation.statusInv === 'sended'" style="display: flex;" role="button">
                                        <b-button
                                            variant="outline-primary"
                                            class="p-50 ml-1"
                                            style="background: #CA34FF1A !important; border: 1px solid #7838ff !important; color: #7838ff !important; font-size: 13px !important;"
                                            @click.prevent="logIdAcceptInvitation(row)"
                                        >
                                            Accept Invitation
                                        </b-button>
                                    </div>
                                </span>
                                <div v-if="row.id && row.label && row.own === 'other' && row.invitation.statusInv === 'accepted'" style="display: flex;">
                                    <feather-icon icon="AlertOctagonIcon" style="color: #ff38d8; margin-top: 5px; margin-left: 15px;" />
                                    <span style="color: #ff38d8; font-size: 15px; font-weight: 300; font-style: normal;" class="ml-50" v-if="row.permission === 'view'">Shared with me</span>
                                    <span style="color: #ff38d8; font-size: 15px; font-weight: 300; font-style: normal;" class="ml-50 cursor-pointer" @click.prevent="logIdMembersProject(row)" v-else>Shared with me</span>
                                </div>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td v-for="(column, indexCol) in columns" :key="indexCol" :class="column.thClass">
                                <span>{{ column.label }}</span>
                            </b-td>
                        </b-tr>
                    </b-thead>
                    <b-thead head-variant="light" v-else-if="row.children.length === 0 && row.label !== 'My sites'">
                        <b-tr>
                            <b-td colspan="9" style="border-top: none !important;">
                                <span class="mr-50" style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px; display: flex; margin-top: 0px;" v-if="row.id && row.label">
                                    <div :style="`width: 12px; height: 12px; border-radius: 4px; margin-top: 6px;`" class="mr-50"></div>
                                    <b-link v-if="row.label !== 'My sites' && row.idsite" :style="row.noExistSites ? 'color: #A7AAB9 !important; padding-top: 16px;' : 'color: #A7AAB9 !important; padding-top: 16px;'" :to="{ name: 'project-sites', params: {id: row.idsite, name: row.label, own: row.own} }">Project</b-link>
                                </span>
                                <span class="" :style="`font-weight: 700; font-size: 16px !important; color: #181E38 !important; display: flex; ${!row.id ? 'margin-top: 0px;' : 'margin-top: 0px;'}`">
                                    <div :style="`width: 12px; height: 12px; border-radius: 4px; background: ${row.color}; margin-top: 6px;`" class="mr-50"></div>
                                    <b-link v-if="row.label === 'My sites'" style="color: #181E38 !important; max-width: 33%;" :to="{ name: 'sites-no-project', params: {id: row.idsite} }">{{ row.label.substring(0, 100) + (row.label.length > 100 ? '...' : '') }}</b-link>
                                    <b-link v-if="row.label !== 'My sites' && row.idsite" style="color: #181E38 !important; max-width: 33%;" :to="{ name: 'project-sites', params: {id: row.idsite, name: row.label, own: row.own} }">{{ row.label.substring(0, 100) + (row.label.length > 100 ? '...' : '') }}</b-link>
                                

                                    <div v-if="row.id && row.label && row.own === 'other' && row.invitation.statusInv === 'accepted'" style="display: flex; position: relative; top: -5px;">
                                        <div class="cursor-pointer" v-if="row.children.length >= 1 && (row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit')" @click.prevent="logIdMembersProject(row)">
                                            <b-avatar-group size="20px" class="ml-25" style="padding-left: 8px;" @click.prevent="logIdMembersProject(row)" v-if="row.children.length >= 1 && (row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit')">
                                                <b-avatar
                                                    v-b-tooltip.hover
                                                    :title="memb.name ? memb.name : memb.email"
                                                    :src="memb.imgU"
                                                    :class="index >= 4 ? 'd-none' : 'pull-up'"
                                                    variant="light-dark"
                                                    :style="row.members.length >= 2 ? 'width: 26px; height: 26px; background: #ddd !important;' : 'width: 34px; height: 28px; background: #ddd !important;'"
                                                    v-for="(memb, index) in row.members" :key="row.idsite + '' + index"
                                                    :text="avatarText(memb.name ? (memb.name.includes(' ') ? memb.name : memb.name + ' ' + memb.name.charAt(1)) : memb.email + ' ' + memb.email.charAt(1))"
                                                />
                                                <b-avatar
                                                    class="pull-up"
                                                    variant="light-dark"
                                                    v-if="row.members.length > 4"
                                                    style="background: #ddd !important; width: 26px; height: 26px;"
                                                >
                                                    <span class="b-avatar-img"></span>
                                                    <span
                                                        class="b-avatar-badge"
                                                        style="font-size: calc(13.16px);"
                                                    >+{{row.members.length - 4}}</span>
                                                </b-avatar>
                                            </b-avatar-group>
                                        </div>
                                        <div class="cursor-pointer" style="position: relative; top: -5px;" v-else-if="row.children.length >= 1 && (row.permission === 'view')" @click.prevent="logIdMembersProject(row)">
                                            <b-avatar-group size="20px" class="ml-25" style="padding-left: 8px;" v-if="row.children.length >= 1 && (row.permission === 'view')">
                                                <b-avatar
                                                    v-b-tooltip.hover
                                                    :title="memb.name ? memb.name : memb.email"
                                                    :src="memb.imgU"
                                                    :class="index >= 4 ? 'd-none' : 'pull-up'"
                                                    variant="light-dark"
                                                    :style="row.members.length >= 2 ? 'width: 26px; height: 26px; background: #ddd !important;' : 'width: 34px; height: 28px; background: #ddd !important;'"
                                                    v-for="(memb, index) in row.members" :key="row.idsite + '' + index"
                                                    :text="avatarText(memb.name ? (memb.name.includes(' ') ? memb.name : memb.name + ' ' + memb.name.charAt(1)) : memb.email + ' ' + memb.email.charAt(1))"
                                                />
                                                <b-avatar
                                                    class="pull-up"
                                                    variant="light-dark"
                                                    v-if="row.members.length > 4"
                                                    style="background: #ddd !important; width: 26px; height: 26px;"
                                                >
                                                    <span class="b-avatar-img"></span>
                                                    <span
                                                        class="b-avatar-badge"
                                                        style="font-size: calc(13.16px);"
                                                    >+{{row.members.length - 4}}</span>
                                                </b-avatar>
                                            </b-avatar-group>
                                        </div>
                                    </div>

                                    <div v-if="row.id && row.label && row.own === 'me'" style="display: flex;" @click.prevent="logIdMembersProject(row)" role="button">
                                        <div v-if="row.totalInv === 0 && row.permission !== 'view'" style="position: relative; top: -5px; margin-left: 8px;">
                                            <b-button
                                                variant=""
                                                class="btn-icon rounded-circle"
                                                style="background: #ddddd9 !important; border: 1px solid #ddddd9 !important;"
                                                size="sm"
                                            >
                                                <feather-icon icon="PlusIcon" style="color: #5c5f5c;" />
                                            </b-button>
                                            <b-button
                                                variant="flat-secondary"
                                                class="p-50 ml-50"
                                                style="color: #7838ff !important; font-size: 13px !important;"
                                            >
                                                Share this project
                                            </b-button>
                                        </div>
                                        <div v-else class="d-flex" style="position: relative; top: -5px;">
                                            <b-avatar-group size="20px" class="ml-25" style="padding-left: 8px;" @click.prevent="logIdMembersProject(row)" v-if="row.children.length >= 1 && (row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit')">
                                                <b-avatar
                                                    v-b-tooltip.hover
                                                    :title="memb.name ? memb.name : memb.email"
                                                    :src="memb.imgU"
                                                    :class="index >= 4 ? 'd-none' : 'pull-up'"
                                                    variant="light-dark"
                                                    :style="row.members.length >= 2 ? 'width: 26px; height: 26px; background: #ddd !important;' : 'width: 34px; height: 28px; background: #ddd !important;'"
                                                    v-for="(memb, index) in row.members" :key="row.idsite + '' + index"
                                                    :text="avatarText(memb.name ? (memb.name.includes(' ') ? memb.name : memb.name + ' ' + memb.name.charAt(1)) : memb.email + ' ' + memb.email.charAt(1))"
                                                />
                                                <b-avatar
                                                    class="pull-up"
                                                    variant="light-dark"
                                                    style="background: #ddd !important; width: 26px; height: 26px;"
                                                    v-if="row.members.length > 4"
                                                >
                                                    <span class="b-avatar-img"></span>
                                                    <span
                                                        class="b-avatar-badge"
                                                        style="font-size: calc(13.16px);"
                                                    >+{{row.members.length - 4}}</span>
                                                </b-avatar>
                                            </b-avatar-group>
                                        </div>
                                    </div>

                                    <div v-if="row.id && row.label && row.own === 'other' && row.invitation.statusInv === 'sended'" style="display: flex;" role="button">
                                        <b-button
                                            variant="outline-primary"
                                            class="p-50 ml-1"
                                            style="background: #CA34FF1A !important; border: 1px solid #7838ff !important; color: #7838ff !important; font-size: 13px !important;"
                                            @click.prevent="logIdAcceptInvitation(row)"
                                        >
                                            Accept Invitation
                                        </b-button>
                                    </div>
                                </span>
                                <div v-if="row.id && row.label && row.own === 'other' && row.invitation.statusInv === 'accepted'" style="display: flex;">
                                    <feather-icon icon="AlertOctagonIcon" style="color: #ff38d8; margin-top: 5px; margin-left: 15px;" />
                                    <span style="color: #ff38d8; font-size: 15px; font-weight: 300; font-style: normal;" class="ml-50" v-if="row.permission === 'view'">Shared with me</span>
                                    <span style="color: #ff38d8; font-size: 15px; font-weight: 300; font-style: normal;" class="ml-50 cursor-pointer" @click.prevent="logIdMembersProject(row)" v-else>Shared with me</span>
                                </div>
                            </b-td>
                        </b-tr>
                        <!-- <b-tr>
                            <b-td v-for="(column, indexCol) in columns" :key="indexCol" :class="column.thClass">
                                <span>{{ column.label }}</span>
                            </b-td>
                        </b-tr> -->
                    </b-thead>
                    <b-tbody v-if="row.children.length >= 1 && row.id && row.label && row.own === 'other' && row.invitation.statusInv === 'sended'">
                        <b-tr v-for="(siteRow, indexSite) in row.children" :key="indexSite">
                            <b-td v-if="siteRow.imgSite" class="tdTableSites" @click.prevent="logIdAcceptInvitation(row)">
                                <b-img-lazy :src="siteRow.imgSite" onerror="this.onerror=null;this.src='https://src.seocloud.ai/site-thumb.png';" width="120px" height="70px" class="my-50" loading="lazy" :alt="'img not found'" />
                            </b-td>
                            <b-td v-if="siteRow.name" class="tdTableSitesName" @click.prevent="logIdAcceptInvitation(row)">
                                <div :style="''">
                                    <span class="" style="font-size: 15px; color: #181E38;">{{ siteRow.name }}</span>
                                </div>
                            </b-td>
                            <b-td v-if="siteRow.domainFix" class="tdTableSitesDomain" @click.prevent="logIdAcceptInvitation(row)">
                                <span class="" :style="'font-size: 15px; color: #A7AAB9;'">{{ siteRow['domainFix'] }}</span>
                            </b-td>
                            <b-td v-if="siteRow.currentErrors" class="tdTableSitesViewList" @click.prevent="logIdAcceptInvitation(row)">
                                <b-spinner style="color: #e3e3e3;" label="Loading..." variant="" v-if="loadErrors" />
                                <span class="" style="font-size: 15px; color: #FC7D7D;" v-else>
                                    {{ siteRow['currentErrors'] }}
                                    <b-avatar variant="light-danger" size="12px" style="margin-bottom: 7px; background: rgba(234, 84, 85, 0.12) !important;">
                                        <feather-icon icon="ChevronDownIcon" />
                                    </b-avatar>
                                </span>
                            </b-td>
                            <b-td v-if="siteRow.membership" class="tdTableSitesViewListMembership">
                                <span v-if="siteRow['membership'] === 'Free'" class="w-100 pb-50 d-block cursor-initial" style="margin-left: 3.1rem !important; font-size: 1rem; cursor: initial;">Free</span>
                                <b-dropdown
                                    :text="siteRow['membership']"
                                    :style="row.permission === 'view' ? 'cursor: initial !important;' : ''"
                                    variant="outline-secondary"
                                    :class="siteRow['membership'] === 'Free' ? 'style-chooser ml-3 mb-25' : 'style-chooser ml-2 mb-25'"
                                    v-if="siteRow['membership'] !== 'Free'"
                                    :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' ? false : true"
                                >
                                    <template #button-content>
                                        <b-img-lazy
                                            fluid
                                            :src="proIcon"
                                            alt="pro page img"
                                            width="17"
                                            height="16"
                                            style="margin-bottom: 7px;"
                                            class="mr-0"
                                            loading="lazy"
                                            v-if="siteRow['membership'] !== 'Free'"
                                        />
                                        {{siteRow['membership']}}
                                    </template>
                                    <b-dropdown-item style="width: 180px;" @click="logIdAcceptInvitation(row)">
                                        <span style="font-size: 15px; color: #656B85;">
                                            <feather-icon icon="ArrowUpIcon" class="mt-0 pt-0" size="12" />
                                            Upgrade
                                        </span><br>
                                        <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item style="width: 180px;" @click="logIdAcceptInvitation(row)">
                                        <span style="font-size: 15px; color: #656B85;">
                                            <feather-icon icon="ArrowDownIcon" class="mt-0 pt-0" size="12" />
                                            Downgrade
                                        </span><br>
                                        <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                                <b-button
                                    variant="outline-secondary"
                                    block
                                    class="ml-3 px-0 py-25"
                                    :style="row.permission === 'view' ? 'pointer-events: initial !important; cursor: initial !important; color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;' : 'color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;'"
                                    @click="logIdAcceptInvitation(row)"
                                    :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' ? false : true"
                                    pill
                                >
                                    Upgrade
                                </b-button>
                            </b-td>
                            <b-td v-if="siteRow.ownedBy" class="tdTableSitesOwned">
                                <div v-if="userData.uid !== siteRow['ownedBy'].uid || !siteRow['ownedBy'] || !siteRow['ownedBy'].uid">
                                    <div class="d-flex mb-50" v-if="(row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' || row.permission === 'view') && siteRow['ownedBy'].uid">
                                        <b-avatar
                                            size="27"
                                            :src="siteRow['ownedBy'].foto ? siteRow['ownedBy'].foto : ''"
                                            variant="light-dark"
                                            class="badge-minimal"
                                            alt="img user"
                                            :text="avatarText(!siteRow['ownedBy'].foto ? siteRow['ownedBy'].name : '')"
                                            style="background: #ddd !important;"
                                        >
                                            <feather-icon
                                                v-if="!siteRow['ownedBy'].foto && !siteRow['ownedBy'].name"
                                                icon="UserIcon"
                                                size="22"
                                            />
                                        </b-avatar>

                                        <div class="ml-25 mt-50">
                                            <p class="mb-0 " style="font-size: 13px; line-height: 14px; color: #656B85;">
                                                {{ siteRow['ownedBy'].name }}
                                            </p>
                                        </div>
                                    </div>
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        :style="row.permission === 'view' ? 'background: #DCDCF252 !important; border: 1px solid #DCDCF2 !important; border-radius: 10px; color: #656B85 !important; font-size: 16px; cursor: initial !important;' :'background: #DCDCF252 !important; border: 1px solid #DCDCF2 !important; border-radius: 10px; color: #656B85 !important; font-size: 16px;'"
                                        size="sm"
                                        :class="row.permission === 'view' ? 'cursor-initial' : 'cursor-pointer'"
                                        @click="logIdAcceptInvitation(row)"
                                        :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' ? false : true"
                                    >
                                    Claim
                                    </b-button>
                                    <span style="color: #FC7D7D; font-size: 13px;" class="d-block cursor-pointer" v-if="siteRow['ownedBy'].lost" @click.prevent="showOpenYouLostAccessSidebar = true, idsite = props.row">You lost access <feather-icon icon="HelpCircleIcon" style="color: #FC7D7D;" /></span>
                                </div>
                                <div v-else>
                                    <div class="d-flex">
                                        <b-avatar
                                            size="27"
                                            :src="siteRow['ownedBy'].foto ? siteRow['ownedBy'].foto : ''"
                                            variant="light-dark"
                                            class="badge-minimal"
                                            alt="img user"
                                            style="background: #ddd !important;"
                                            :text="avatarText(!siteRow['ownedBy'].foto ? siteRow['ownedBy'].name : '')"
                                        >
                                            <feather-icon
                                                v-if="!siteRow['ownedBy'].foto && !siteRow['ownedBy'].name"
                                                icon="UserIcon"
                                                size="22"
                                            />
                                        </b-avatar>

                                        <div :class="userData.uid === siteRow['ownedBy'].uid ? 'ml-25' : 'ml-25 mt-50'">
                                            <p class="mb-0 " style="font-size: 13px; line-height: 14px; color: #656B85; text-overflow: ellipsis; overflow: hidden; width: 8rem; display: inline-block;" v-if="userData.uid === siteRow['ownedBy'].uid">
                                                Me
                                            </p>
                                            <p class="mb-0 " style="font-size: 13px; line-height: 14px; color: #656B85; text-overflow: ellipsis; overflow: hidden; width: 8rem; display: inline-block;" v-else>
                                                {{ siteRow['ownedBy'].name }}
                                            </p>
                                        </div>
                                    </div>
                                    <span style="color: #FC7D7D; font-size: 13px;" class="cursor-pointer" v-if="siteRow['ownedBy'].lost" @click.prevent="showOpenYouLostAccessSidebar = true, idsite = props.row">You lost access <feather-icon icon="HelpCircleIcon" style="color: #FC7D7D;" /></span>
                                </div>
                            </b-td>
                            <b-td class="tdTableSitesAction">
                                <b-dropdown
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    dropleft
                                    no-caret
                                    variant="flat-dark"
                                    class="btn-icon dropdown-sites rounded-circle"
                                >
                                    <template #button-content class="pt-0">
                                        <feather-icon icon="MoreVerticalIcon" class="mt-0 pt-0" size="20" />
                                    </template>
                                    <b-dropdown-item link-class="d-flex align-items-center  item-dropdown-action-sites" @click="logIdAcceptInvitation(row)" :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' || row.permission === 'view' ? false : true">
                                        <feather-icon icon="Edit2Icon" class="mr-50" />
                                        Fix
                                    </b-dropdown-item>
                                    <b-dropdown-item link-class="d-flex align-items-center  item-dropdown-action-sites" @click="logIdAcceptInvitation(row)" :disabled="row.permission === 'owner' || row.permission === 'admin' ? false : true">
                                        <feather-icon icon="RepeatIcon" class="mr-50" />
                                        Change Project
                                    </b-dropdown-item>
                                    <b-dropdown-item link-class="d-flex align-items-center  item-dropdown-action-sites" @click="logIdAcceptInvitation(row)" :disabled="row.permission === 'owner' || row.permission === 'admin' ? false : true">
                                        <feather-icon icon="ArchiveIcon" class="mr-50" />
                                        Archive Site
                                    </b-dropdown-item>
                                    <b-dropdown-item link-class="d-flex align-items-center  item-dropdown-action-sites" @click="logIdAcceptInvitation(row)" :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' ? false : true">
                                        <feather-icon icon="SettingsIcon" class="mr-50" />
                                        Edit Site
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                    <b-tbody v-else-if="row.children.length >= 1">
                        <b-tr v-for="(siteRow, indexSite) in row.children" :key="indexSite">
                            <b-td v-if="siteRow.imgSite" class="tdTableSites" @click.prevent="onRowSelected(siteRow)">
                                <b-img-lazy :src="siteRow.imgSite" onerror="this.onerror=null;this.src='https://src.seocloud.ai/site-thumb.png';" width="120px" height="70px" class="my-50" loading="lazy" :alt="'img not found'" />
                            </b-td>
                            <b-td v-if="siteRow.name" class="tdTableSitesName" @click.prevent="onRowSelected(siteRow)">
                                <div :style="''">
                                    <span class="" style="font-size: 15px; color: #181E38;">{{ siteRow.name }}</span>
                                </div>
                            </b-td>
                            <b-td v-if="siteRow.domainFix" class="tdTableSitesDomain" @click.prevent="onRowSelected(siteRow)">
                                <span class="" :style="'font-size: 15px; color: #A7AAB9;'">{{ siteRow['domainFix'] }}</span>
                            </b-td>
                            <b-td v-if="siteRow.currentErrors" class="tdTableSitesViewList" @click.prevent="onRowSelected(siteRow)">
                                <b-spinner style="color: #e3e3e3;" label="Loading..." variant="" v-if="loadErrors" />
                                <span class="" style="font-size: 15px; color: #FC7D7D;" v-else>
                                    {{ siteRow['currentErrors'] }}
                                    <b-avatar variant="light-danger" size="12px" style="margin-bottom: 7px; background: rgba(234, 84, 85, 0.12) !important;">
                                        <feather-icon icon="ChevronDownIcon" />
                                    </b-avatar>
                                </span>
                            </b-td>
                            <!-- <b-td v-if="siteRow.fixedIssues" style="text-align: center;" class="tdTableSitesViewList" @click.prevent="onRowSelected(siteRow)">
                                <span class="" style="font-size: 15px; color: #53D991;">
                                    {{ siteRow['fixedIssues'] }}
                                    <b-avatar variant="light-success" size="12px" style="margin-bottom: 7px; background: rgba(40, 199, 111, 0.12) !important;">
                                        <feather-icon icon="ChevronUpIcon" />
                                    </b-avatar>
                                </span>
                            </b-td> -->
                            <!-- <b-td v-if="siteRow.key" class="tdTableSitesKeyViewListKey">
                                <span class="" style="font-size: 15px; color: #656B85;">••••••••{{ siteRow['key'].length === 21 ? siteRow['key'].slice(19) : siteRow['key'].slice(20) }}</span>
                                <b-button
                                    v-clipboard:copy="siteRow['key']"
                                    v-clipboard:success="onCopy"
                                    v-clipboard:error="onError"
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    variant="flat-dark"
                                    style="color: #A7AAB9;"
                                    class="btn-icon rounded-circle"
                                >
                                    <feather-icon icon="CopyIcon" />
                                </b-button>
                            </b-td> -->
                            <b-td v-if="siteRow.membership" class="tdTableSitesViewListMembership">
                                <span v-if="siteRow['membership'] === 'Free'" class="w-100 pb-50 d-block cursor-initial" style="margin-left: 3.1rem !important; font-size: 1rem; cursor: initial;">Free</span>
                                <b-dropdown
                                    :text="siteRow['membership']"
                                    :style="row.permission === 'view' ? 'cursor: initial !important;' : ''"
                                    variant="outline-secondary"
                                    :class="siteRow['membership'] === 'Free' ? 'style-chooser ml-3 mb-25' : 'style-chooser ml-2 mb-25'"
                                    v-if="siteRow['membership'] !== 'Free'"
                                    :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' ? false : true"
                                >
                                    <template #button-content>
                                        <b-img-lazy
                                            fluid
                                            :src="proIcon"
                                            alt="pro page img"
                                            width="17"
                                            height="16"
                                            style="margin-bottom: 7px;"
                                            class="mr-0"
                                            loading="lazy"
                                            v-if="siteRow['membership'] !== 'Free'"
                                        />
                                        {{siteRow['membership']}}
                                    </template>
                                    <b-dropdown-item style="width: 180px;" :to="{ name: 'memberships', params: {idsite: siteRow} }">
                                        <span style="font-size: 15px; color: #656B85;">
                                            <feather-icon icon="ArrowUpIcon" class="mt-0 pt-0" size="12" />
                                            Upgrade
                                        </span><br>
                                        <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item style="width: 180px;" :to="{ name: 'memberships', params: {idsite: siteRow} }">
                                        <span style="font-size: 15px; color: #656B85;">
                                            <feather-icon icon="ArrowDownIcon" class="mt-0 pt-0" size="12" />
                                            Downgrade
                                        </span><br>
                                        <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                                <b-button
                                    variant="outline-secondary"
                                    block
                                    class="ml-3 px-0 py-25"
                                    :style="row.permission === 'view' ? 'pointer-events: initial !important; cursor: initial !important; color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;' : 'color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;'"
                                    :to="{ name: 'memberships', params: {idsite: siteRow} }"
                                    :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' ? false : true"
                                    pill
                                >
                                    Upgrade
                                </b-button>
                            </b-td>
                            <b-td v-if="siteRow.ownedBy" class="tdTableSitesOwned">
                                <div v-if="userData.uid !== siteRow['ownedBy'].uid || !siteRow['ownedBy'] || !siteRow['ownedBy'].uid">
                                    <div class="d-flex mb-50" v-if="(row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' || row.permission === 'view') && siteRow['ownedBy'].uid">
                                        <b-avatar
                                            size="27"
                                            :src="siteRow['ownedBy'].foto ? siteRow['ownedBy'].foto : ''"
                                            variant="light-dark"
                                            class="badge-minimal"
                                            alt="img user"
                                            :text="avatarText(!siteRow['ownedBy'].foto ? siteRow['ownedBy'].name : '')"
                                            style="background: #ddd !important;"
                                        >
                                            <feather-icon
                                                v-if="!siteRow['ownedBy'].foto && !siteRow['ownedBy'].name"
                                                icon="UserIcon"
                                                size="22"
                                            />
                                        </b-avatar>

                                        <div class="ml-25 mt-50">
                                            <p class="mb-0 " style="font-size: 13px; line-height: 14px; color: #656B85;">
                                                {{ siteRow['ownedBy'].name }}
                                            </p>
                                        </div>
                                    </div>
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        :style="row.permission === 'view' ? 'background: #DCDCF252 !important; border: 1px solid #DCDCF2 !important; border-radius: 10px; color: #656B85 !important; font-size: 16px; cursor: initial !important;' :'background: #DCDCF252 !important; border: 1px solid #DCDCF2 !important; border-radius: 10px; color: #656B85 !important; font-size: 16px;'"
                                        size="sm"
                                        :class="row.permission === 'view' ? 'cursor-initial' : 'cursor-pointer'"
                                        @click="logIdSite(siteRow)"
                                        :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' ? false : true"
                                    >
                                    Claim
                                    </b-button>
                                    <span style="color: #FC7D7D; font-size: 13px;" class="d-block cursor-pointer" v-if="siteRow['ownedBy'].lost" @click.prevent="showOpenYouLostAccessSidebar = true, idsite = props.row">You lost access <feather-icon icon="HelpCircleIcon" style="color: #FC7D7D;" /></span>
                                </div>
                                <div v-else>
                                    <div class="d-flex">
                                        <b-avatar
                                            size="27"
                                            :src="siteRow['ownedBy'].foto ? siteRow['ownedBy'].foto : ''"
                                            variant="light-dark"
                                            class="badge-minimal"
                                            alt="img user"
                                            style="background: #ddd !important;"
                                            :text="avatarText(!siteRow['ownedBy'].foto ? siteRow['ownedBy'].name : '')"
                                        >
                                            <feather-icon
                                                v-if="!siteRow['ownedBy'].foto && !siteRow['ownedBy'].name"
                                                icon="UserIcon"
                                                size="22"
                                            />
                                        </b-avatar>

                                        <div :class="userData.uid === siteRow['ownedBy'].uid ? 'ml-25' : 'ml-25 mt-50'">
                                            <p class="mb-0 " style="font-size: 13px; line-height: 14px; color: #656B85; text-overflow: ellipsis; overflow: hidden; width: 8rem; display: inline-block;" v-if="userData.uid === siteRow['ownedBy'].uid">
                                                Me
                                            </p>
                                            <p class="mb-0 " style="font-size: 13px; line-height: 14px; color: #656B85; text-overflow: ellipsis; overflow: hidden; width: 8rem; display: inline-block;" v-else>
                                                {{ siteRow['ownedBy'].name }}
                                            </p>
                                        </div>
                                    </div>
                                    <span style="color: #FC7D7D; font-size: 13px;" class="cursor-pointer" v-if="siteRow['ownedBy'].lost" @click.prevent="showOpenYouLostAccessSidebar = true, idsite = props.row">You lost access <feather-icon icon="HelpCircleIcon" style="color: #FC7D7D;" /></span>
                                </div>
                            </b-td>
                            <b-td class="tdTableSitesAction">
                                <b-dropdown
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    dropleft
                                    no-caret
                                    variant="flat-dark"
                                    class="btn-icon dropdown-sites rounded-circle"
                                >
                                    <template #button-content class="pt-0">
                                        <feather-icon icon="MoreVerticalIcon" class="mt-0 pt-0" size="20" />
                                    </template>
                                    <b-dropdown-item link-class="d-flex align-items-center  item-dropdown-action-sites" @click="getError(siteRow)" :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' || row.permission === 'view' ? false : true">
                                        <feather-icon icon="Edit2Icon" class="mr-50" />
                                        Fix
                                    </b-dropdown-item>
                                    <b-dropdown-item link-class="d-flex align-items-center  item-dropdown-action-sites" @click="logIdChangeProject(siteRow)" :disabled="row.permission === 'owner' || row.permission === 'admin' ? false : true">
                                        <feather-icon icon="RepeatIcon" class="mr-50" />
                                        Change Project
                                    </b-dropdown-item>
                                    <b-dropdown-item link-class="d-flex align-items-center  item-dropdown-action-sites" @click="logIdArchive(siteRow)" :disabled="row.permission === 'owner' || row.permission === 'admin' ? false : true">
                                        <feather-icon icon="ArchiveIcon" class="mr-50" />
                                        Archive Site
                                    </b-dropdown-item>
                                    <b-dropdown-item link-class="d-flex align-items-center  item-dropdown-action-sites" :to="{ name: 'edit-site', params: { id: siteRow.id, project: siteRow.projectid ? siteRow.projectid : 'w_p' } }" :disabled="row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' ? false : true">
                                        <feather-icon icon="SettingsIcon" class="mr-50" />
                                        Edit Site
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                    <b-tbody v-else-if="row.children.length === 0 && row.label !== 'My sites'">
                        <b-tr>
                            <b-td colspan="9">
                                <span>No sites</span>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                    <b-tfoot v-if="row.children.length >= 1 && ((row.count >= 10 && row.count - 10 >= 1) || (userData.totalSites > 20 && row.count - 2 >= 1))">
                        <b-tr>
                            <b-td
                                colspan="9"
                                class="text-right"
                            >
                                <!-- row.count > 4 -->
                                <b-button
                                    v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                                    variant="outline-info"
                                    v-if="row.count > 20 && row.title === 'My sites'"
                                    block
                                    class=" pt-0"
                                    style="background: #fff !important; border: 0px !important; color: #30CDFF !important; font-size: 18px !important; max-width: 250px; margin: auto;"
                                    :to="{ name: 'sites-no-project', params: {id: row.id} }"
                                >
                                    <feather-icon
                                        icon="ChevronDownIcon"
                                        size="30"
                                        class="mt-0 pt-0"
                                    /><br>
                                    <!-- {{ userData.totalSites >= 11 && userData.totalSites &lt;= 20 ? row.count - 4 : userData.totalSites > 20 ? row.count - 2 : row.count - 4 }} more sites -->
                                    {{ userData.totalSites >= 21 && userData.totalSites &lt;= 32 ? row.count - 4 : userData.totalSites > 32 ? row.count - 2 : row.count - 4 }} more sites
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        class="ml-25"
                                        style="background: #38cfff; border-radius: 10px; color: white;"
                                    />
                                </b-button>
                                <b-button
                                    v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                                    variant="outline-info"
                                    v-else-if="(row.count >= 21 && row.count - 10 >= 1) || (userData.totalSites > 20 && row.count - 2 >= 1)"
                                    block
                                    class=" pt-0"
                                    style="background: #fff !important; border: 0px !important; color: #30CDFF !important; font-size: 18px !important; max-width: 250px; margin: auto;"
                                    :to="{ name: 'project-sites', params: {id: row.id, name: row.title, own: row.own} }"
                                >
                                    <feather-icon
                                        icon="ChevronDownIcon"
                                        size="30"
                                        class="mt-0 pt-0"
                                    /><br>
                                    <!-- {{ userData.totalSites >= 11 && userData.totalSites &lt;= 20 ? row.count - 4 : userData.totalSites > 20 ? row.count - 2 : row.count - 4 }} more sites -->
                                    {{ userData.totalSites >= 21 && userData.totalSites &lt;= 32 ? row.count - 4 : userData.totalSites > 32 ? row.count - 2 : row.count - 4 }} more sites
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        class="ml-25"
                                        style="background: #38cfff; border-radius: 10px; color: white;"
                                    />
                                </b-button>
                            </b-td>
                        </b-tr>
                    </b-tfoot>
                </b-table-simple>
            </b-overlay>
        </div>
        <div v-else>
            <b-overlay
                :show="loaderSites"
                rounded="xl"
                no-fade
            >
                <b-table-simple
                    hover
                    small
                    caption-top
                    responsive
                    striped
                >
                    <b-thead head-variant="light">
                    <b-tr>
                        <b-td v-for="(column, indexCol) in columns" :key="indexCol" :class="column.thClass">
                        <span>{{ column.label }}</span>
                        </b-td>
                    </b-tr>
                    </b-thead>
                    <b-tbody>
                    <b-tr>
                        <b-td colspan="9">
                        <span>No sites</span>
                        </b-td>
                    </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-overlay>
        </div>
    
    </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdownItem, BDropdown, BImgLazy, BButton, BAvatarGroup, BLink, BProgress,
  VBTooltip, BTableSimple, BTfoot, BTr, BTd, BTbody, BThead, BTh, BOverlay, BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import TitlePage from '@/@core/layouts/components/app-navbar/components/TitlePage.vue'
// import TitlePage from './components/TitlePage.vue'
import { avatarText } from '@core/utils/filter'
// import YouLostAccessSidebar from './sidebar/YouLostAccessSidebar.vue'
// import SidebarOwnership from './sidebar/SidebarOwnership.vue'
// import ChangeProject from './sidebar/ChangeProject.vue'
// import ArchiveSite from './sidebar/ArchiveSite.vue'
// import MembersProject from './sidebar/MembersProject.vue'
// import AcceptInvitation from './sidebar/AcceptInvitation.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import 'vue-good-table/dist/vue-good-table.css'
import store from '@/store/index'
import axios from '@axios'
import vSelect from 'vue-select'
import { db, auth, firebase, storage } from '@/firebase'

export default {
    name: 'GroupSites',
    components: {
        BCardCode,
        VueGoodTable,
        BAvatar,
        BAvatarGroup,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdownItem,
        BDropdown,
        BImgLazy,
        BButton,
        BLink,
        BProgress,
        BSpinner,
        vSelect,
        // YouLostAccessSidebar,
        // SidebarOwnership,
        // ChangeProject,
        // ArchiveSite,
        // MembersProject,
        // AcceptInvitation,
        BTableSimple,
        BTfoot,
        BTr,
        BTd,
        BTbody,
        BThead,
        BTh,
        BOverlay,
        // TitlePage,
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
            userData: '',
            proIcon: require('@/assets/images/icons/i-pro-badge.svg'),
            titlePage: '',
            selectedPlan: 'Basic',
            optionsPlan: [
                'Free',
                'Basic',
                'Pro',
                'Custom license'
            ],
            // table
            columns: [
                {
                    label: '',
                    field: 'imgSite',
                    sortable: false,
                    thClass: 'thTableSites',
                    tdClass: 'tdTableSites'
                },
                {
                    label: 'Site Name',
                    field: 'name',
                    sortable: false,
                    thClass: 'thTableSitesName',
                    tdClass: 'tdTableSitesName'
                },
                {
                    label: 'URL',
                    field: 'domainFix',
                    sortable: false,
                    thClass: 'thTableSitesDomain',
                    tdClass: 'tdTableSitesDomain'
                },
                {
                    label: 'Current Errors',
                    field: 'currentErrors',
                    sortable: false,
                    thClass: 'thTableSitesViewList',
                    tdClass: 'tdTableSitesViewList'
                },
                // {
                //     label: 'Fixed Issues',
                //     field: 'fixedIssues',
                //     sortable: false,
                //     thClass: 'thTableSitesViewList',
                //     tdClass: 'tdTableSitesViewList'
                // },
                // {
                //     label: 'Key',
                //     field: 'key',
                //     sortable: false,
                //     thClass: 'thTableSitesViewListKey',
                //     tdClass: 'tdTableSitesKeyViewListKey'
                // },
                // {
                //     label: 'Alerts',
                //     field: 'alerts',
                //     thClass: 'thTableSites',
                //     tdClass: 'tdTableSites'
                // },
                {
                    label: 'Membership',
                    field: 'membership',
                    sortable: false,
                    thClass: 'thTableSitesViewListMembership',
                    tdClass: 'tdTableSitesViewListMembership'
                },
                {
                    label: 'Owned by',
                    field: 'ownedBy',
                    sortable: false,
                    thClass: 'thTableSitesOwned',
                    tdClass: 'tdTableSitesOwned'
                },
                {
                    label: '',
                    field: 'action',
                    sortable: false,
                    thClass: 'thTableSitesAction',
                    tdClass: 'tdTableSitesAction'
                },
            ],
            rows: [
                // {
                //     mode: 'span',
                //     html: false,
                //     label: 'Mammals Total', // this is the label that'll be used for the header
                //     diet: undefined,
                //     count: '', // total count will be displayed here
                //     children: [
                //         { name: 'Elephant', diet: 'herbivore', count: 5 },
                //         { name: 'Cat', diet: 'carnivore', count: 28 }
                //     ]
                // },
            ],
            rowsAtomic: [],
            dir: false,
            siteSelected: '',
            arrayTeamSort: [],
            sortedComplete: false,
            // searchSite: '',
            pageLength: 5,
            events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
            shared: false,
            isBusy: false,
            counterTotalSites: 0,
            extraWP: 0,
            chargeAllSites: false,
            existSites: false,
            completedArrayTeams: false,
            rowsAcceptInvitation: [],
            initDate: 0,
            initDate2: 0,
            loaderSites: true,
            loadErrors: true,

            // sidebar ownership
            idsite: '',
            showOpen: false,
            showOpenChangeProject: false,
            showOpenArchiveSite: false,
            showOpenMembersProject: false,
            showOpenAcceptInvitation: false,
            showOpenYouLostAccessSidebar: false,

            // add with teams logic
            // arrayTeam: []
        }
    },
    props: ['iduser','searchSite','arrayTeam'],
    // ,'arrayTeam'
    computed: {
        statusVariant() {
            const statusColor = {
                /* eslint-disable key-spacing */
                Current      : 'light-primary',
                Professional : 'light-success',
                Rejected     : 'light-danger',
                Resigned     : 'light-warning',
                Applied      : 'light-info',
                /* eslint-enable key-spacing */
            }

            return status => statusColor[status]
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    watch: {
        rows(val) {
            // console.log(val)
            // if(!this.existSites) {
            //     val.forEach(item => {
            //         if(item.children.length >= 1) {
            //             this.existSites = true
            //         }
            //     })
            // }
        },
        arrayTeamSort(val) {
            setTimeout(() => {
                // console.log(val.length,this.arrayTeam.length)
                // if(this.arrayTeam.length && val.length && val.length === this.arrayTeam.length && !this.sortedComplete) {
                if(val.length && this.completedArrayTeams && !this.sortedComplete) {
                    this.sortedComplete = true
                    this.sortProjects()
                }
            }, 2000)
        },
        // completedArrayTeams(val) {
        //     // console.log(val)
        //     if(val) {
        //         console.log(this.arrayTeamSort.length,this.arrayTeam.length)
        //         if(this.arrayTeamSort.length && !this.sortedComplete) {
        //             this.sortedComplete = true
        //             this.sortProjects()
        //         }
        //     }
        // }
    },
    methods: {
        sortProjects() {
            this.arrayTeamSort.sort(function (a, b) {
                if(a.title.toLowerCase() > b.title.toLowerCase()) {
                    return 1;
                }
                if(a.title.toLowerCase() < b.title.toLowerCase()) {
                    return -1;
                }
                return 0;
            });

            this.arrayTeamSort.forEach(item => {
                var isAddedAlready = this.rows.filter(ele => ele.id === item.id)
                    // console.log(isAddedAlready.length)
                if(isAddedAlready.length === 0 && item.own === 'me') {
                    this.rows.push(item)
                    // this.existSites = true
                    // console.log(this.rows)
                }
            });

            this.arrayTeamSort.forEach(item => {
                if(item.id && item.label && item.own === 'other' && item.invitation.statusInv === 'sended') {
                    // var isAddedAlready = this.rowsAcceptInvitation.filter(ele => ele.id === item.id)
                    var isAddedAlready = this.rows.filter(ele => ele.id === item.id)
                    // console.log(isAddedAlready.length)
                    if(isAddedAlready.length === 0) {
                        // this.rowsAcceptInvitation.push(item)
                        this.rows.push(item)
                        this.existSites = true
                        // console.log(this.rows)
                    }
                } else {
                    var isAddedAlready = this.rows.filter(ele => ele.id === item.id)
                    // console.log(isAddedAlready.length)
                    if(isAddedAlready.length === 0) {
                        this.rows.push(item)
                        this.existSites = true
                        // console.log(this.rows)
                    }
                }
            })
            // this.rows.sort(function (a, b) {
            //     if(a.title !== 'My sites' && b.title !== 'My sites') {
            //         if(a.title.toLowerCase() > b.title.toLowerCase()) {
            //             return 1;
            //         }
            //         if(a.title.toLowerCase() < b.title.toLowerCase()) {
            //             return -1;
            //         }
            //         return 0;
            //     }
            // });
            this.loaderSites = false
        },
        logIdSite(val) {
            // console.log(val)
            this.idsite = val
            this.showOpen = true
        },
        logIdChangeProject(val) {
            // console.log('first')
            this.idsite = val
            this.showOpenChangeProject = true
        },
        logIdArchive(val) {
            this.idsite = val
            this.showOpenArchiveSite = true
        },
        logIdMembersProject(val) {
            this.idsite = val
            this.showOpenMembersProject = true
        },
        logIdAcceptInvitation(val) {
            this.idsite = val
            this.showOpenAcceptInvitation = true
        },
        getError(val) {
            this.$router.push({ name: 'view-errors', params: {id: val.id, imgSite: val.imgSite, name: val.name, url: val.domain, project: val.projectid, own: val.own} })
        },
        onRowSelected(item) {
            this.$router.push({ name: 'view-errors', params: {id:  item.id, imgSite:  item.imgSite, name:  item.name, url:  item.domain, project:  item.projectid, own: item.own} })
        },
        charge(val) {
            // console.log(this.rows.length)
            // console.log(this.arrayTeam.length)
            if(this.rows.length === 0) {
                this.rows = []
                this.rowsAcceptInvitation = []
                this.arrayTeamSort = []
                this.counterTotalSites = 0
                this.extraWP = 0
                this.chargeAllSites = false
                this.sortedComplete = false
                // My sites
                db.collection('sites').orderBy("n", "asc").get()
                .then(sites => {
                    var arraySitesChildren = []
                    var counterSites = 1
                    sites.forEach(site => {
                        if(site.data().status === 'active') {
                            if(!site.data().project && (site.data().createdBy === this.userData.uid)) {
                                var URLactual = new URL(site.data().url);
                                var URLdomain = URLactual.host;
                                var pathname = URLactual.pathname;
                                var domainFixed = pathname === '/' ? URLdomain : URLdomain + pathname;

                                if(this.userData.totalSites >= 21 && this.userData.totalSites <= 32) {
                                    if(counterSites <= 4) {
                                        counterSites += 1
                                        this.counterTotalSites += 1
                                        arraySitesChildren.push({ imgSite: site.data().img ? site.data().img : 'https://src.seocloud.ai/site-thumb.png', name: site.data().name ? site.data().name : domainFixed, domainFix: domainFixed, domain: site.data().homepage, currentErrors: '0', fixedIssues: '79', key: site.id, alerts: '3', membership: (site.data().plan ? site.data().plan : 'Free')+' license', ownedBy: {uid: site.data().owned.uid, name: site.data().owned.nombre, email: site.data().owned.email, foto: site.data().owned.foto, lost: (site.data().lastOwner && site.data().lastOwner.includes(this.userData.uid)) && (site.data().owned.uid !== this.userData.uid) ? true : false}, label: 'My sites', color: '#F0F1F7', id: site.id, projectid: 'w_p', owner: site.data().createdBy === this.userData.uid && (site.data().owned.uid !== this.userData.uid) ? 'lost' : 'other', permission: 'owner', crawl: '', own: 'me' })
                                    }
                                } else if(this.userData.totalSites > 32) {
                                    if(counterSites <= 2) {
                                        counterSites += 1
                                        this.counterTotalSites += 1
                                        arraySitesChildren.push({ imgSite: site.data().img ? site.data().img : 'https://src.seocloud.ai/site-thumb.png', name: site.data().name ? site.data().name : domainFixed, domainFix: domainFixed, domain: site.data().homepage, currentErrors: '0', fixedIssues: '79', key: site.id, alerts: '3', membership: (site.data().plan ? site.data().plan : 'Free')+' license', ownedBy: {uid: site.data().owned.uid, name: site.data().owned.nombre, email: site.data().owned.email, foto: site.data().owned.foto, lost: (site.data().lastOwner && site.data().lastOwner.includes(this.userData.uid)) && (site.data().owned.uid !== this.userData.uid) ? true : false}, label: 'My sites', color: '#F0F1F7', id: site.id, projectid: 'w_p', owner: site.data().createdBy === this.userData.uid && (site.data().owned.uid !== this.userData.uid) ? 'lost' : 'other', permission: 'owner', crawl: '', own: 'me' })
                                    }
                                } else if(this.userData.totalSites <= 20) {
                                    counterSites += 1
                                    this.counterTotalSites += 1
                                    arraySitesChildren.push({ imgSite: site.data().img ? site.data().img : 'https://src.seocloud.ai/site-thumb.png', name: site.data().name ? site.data().name : domainFixed, domainFix: domainFixed, domain: site.data().homepage, currentErrors: '0', fixedIssues: '79', key: site.id, alerts: '3', membership: (site.data().plan ? site.data().plan : 'Free')+' license', ownedBy: {uid: site.data().owned.uid, name: site.data().owned.nombre, email: site.data().owned.email, foto: site.data().owned.foto, lost: (site.data().lastOwner && site.data().lastOwner.includes(this.userData.uid)) && (site.data().owned.uid !== this.userData.uid) ? true : false}, label: 'My sites', color: '#F0F1F7', id: site.id, projectid: 'w_p', owner: site.data().createdBy === this.userData.uid && (site.data().owned.uid !== this.userData.uid) ? 'lost' : 'other', permission: 'owner', crawl: '', own: 'me' })
                                }
                                this.extraWP += 1
                            }
                        }
                    })
                    this.rows.push({
                        mode: 'span',
                        html: false,
                        label: 'My sites',
                        color: '#F0F1F7',
                        idsite: val.uid,
                        id: 'w_p',
                        title: 'My sites',
                        permission: 'owner',
                        // count: 0,
                        members: '',
                        invitation: '',
                        count: this.extraWP,
                        own: 'me',
                        children: arraySitesChildren
                    })
                })

                if(this.userData.teams && this.userData.teams.length >= 1) {
                    // var lengthTeams = this.arrayTeam.length
                    var lengthTeams = this.userData.teams.length
                    var countTeam = lengthTeams === 1 ? 0 : 1
                    var countTeamActives = 0
                    var nameProject = ''
                    var idProject = ''
                    var counterSitesProject = ''

                    // add with teams logic
                    this.userData.teams.forEach(element => {
                        // countTeam += 1
                        db.collection('projects').doc(element).get()
                        .then(projects => {
                            db.collection('projects').doc(element).collection('members').where('uid', '!=', 'resume').get()
                            .then(invitationsProject => {
                                db.collection('projects').doc(element).collection('members').where('uid', '==', this.userData.uid).get()
                                .then(invitationOwn => {
                                    db.collection('sites').where('project', '==', element).orderBy("n", "asc").get()
                                    .then(sites => {
                                        // array of members
                                        var arrayInvitations = []
                                        var arrayInvitationsAccepted = []
                                        if(invitationsProject.size >= 1) {
                                            invitationsProject.forEach(inv => {
                                                if(inv.data().status === 'active') {
                                                    arrayInvitationsAccepted.push(inv.data())
                                                }
                                                // console.log(inv.data())
                                                arrayInvitations.push(inv.data())
                                            })
                                        }

                                        var activeInvitation = true
                                        if(invitationOwn.size >= 1) {
                                            invitationOwn.forEach(ele => {
                                                ele.data().status === 'active' || ele.data().status === 'invitated' ? activeInvitation = true : activeInvitation = false
                                            })
                                        }
                                        if(!activeInvitation) {
                                            countTeam += 1
                                        }
                                        // console.log(projects.data().name,activeInvitation,sites.size,projects.data().owned.uid,this.userData.uid)

                                        var memberPerm = 'view'
                                        if(activeInvitation) {
                                            if(sites.size >= 1) {
                                                var arraySitesChildren = []
                                                var counterSites = 1
                                                sites.forEach(site => {
                                                    var isMember = arrayInvitations.filter(ele => ele.uid === site.data().createdBy || (site.data().owned && ele.uid === site.data().owned.uid))
                                                    var membersPerm = arrayInvitations.filter(ele => ele.uid === this.userData.uid)
                                                    if(membersPerm.length >= 1) {
                                                        memberPerm = membersPerm[0].perm
                                                    }

                                                    var URLactual = new URL(site.data().url);
                                                    var URLdomain = URLactual.host;
                                                    var pathname = URLactual.pathname;
                                                    var domainFixed = pathname === '/' ? URLdomain : URLdomain + pathname;
                                                    if(site.data().project && site.data().status === 'active') {
                                                        this.counterTotalSites += 1
                                                        if(this.userData.totalSites >= 21 && this.userData.totalSites <= 32) {
                                                            if(counterSites <= 4) {
                                                                counterSites += 1
                                                                arraySitesChildren.push({ imgSite: site.data().img ? site.data().img : 'https://src.seocloud.ai/site-thumb.png', name: site.data().name ? site.data().name : domainFixed, domainFix: domainFixed, domain: site.data().homepage, currentErrors: '0', fixedIssues: '79', key: site.id, alerts: '3', membership: (site.data().plan ? site.data().plan : 'Free')+' license', ownedBy: {uid: site.data().owned.uid, name: site.data().owned.nombre, email: site.data().owned.email, foto: site.data().owned.foto, lost: (site.data().lastOwner && site.data().lastOwner.includes(this.userData.uid)) && (site.data().owned.uid !== this.userData.uid) ? true : false}, label: projects.data().name, color: projects.data().color, id: site.id, projectid: projects.id, owner: (site.data().createdBy === this.userData.uid || (site.data().lastOwner && site.data().lastOwner.includes(this.userData.uid))) || isMember.length >= 1 ? 'member' : site.data().createdBy === this.userData.uid && (site.data().owned.uid !== this.userData.uid) ? 'lost' : site.data().createdBy === this.userData.uid && (site.data().owned.uid === this.userData.uid) ? 'me' : 'other', permission: site.data().owned.uid === this.userData.uid ? 'owner' : memberPerm, crawl: '', own: projects.data().owned.uid === this.userData.uid ? 'me' : 'other' })
                                                            }
                                                        } else if(this.userData.totalSites > 32) {
                                                            if(counterSites <= 2) {
                                                                counterSites += 1
                                                                arraySitesChildren.push({ imgSite: site.data().img ? site.data().img : 'https://src.seocloud.ai/site-thumb.png', name: site.data().name ? site.data().name : domainFixed, domainFix: domainFixed, domain: site.data().homepage, currentErrors: '0', fixedIssues: '79', key: site.id, alerts: '3', membership: (site.data().plan ? site.data().plan : 'Free')+' license', ownedBy: {uid: site.data().owned.uid, name: site.data().owned.nombre, email: site.data().owned.email, foto: site.data().owned.foto, lost: (site.data().lastOwner && site.data().lastOwner.includes(this.userData.uid)) && (site.data().owned.uid !== this.userData.uid) ? true : false}, label: projects.data().name, color: projects.data().color, id: site.id, projectid: projects.id, owner: (site.data().createdBy === this.userData.uid || (site.data().lastOwner && site.data().lastOwner.includes(this.userData.uid))) || isMember.length >= 1 ? 'member' : site.data().createdBy === this.userData.uid && (site.data().owned.uid !== this.userData.uid) ? 'lost' : site.data().createdBy === this.userData.uid && (site.data().owned.uid === this.userData.uid) ? 'me' : 'other', permission: site.data().owned.uid === this.userData.uid ? 'owner' : memberPerm, crawl: '', own: projects.data().owned.uid === this.userData.uid ? 'me' : 'other' })
                                                            }
                                                        } else if(this.userData.totalSites <= 20) {
                                                            counterSites += 1
                                                            arraySitesChildren.push({ imgSite: site.data().img ? site.data().img : 'https://src.seocloud.ai/site-thumb.png', name: site.data().name ? site.data().name : domainFixed, domainFix: domainFixed, domain: site.data().homepage, currentErrors: '0', fixedIssues: '79', key: site.id, alerts: '3', membership: (site.data().plan ? site.data().plan : 'Free')+' license', ownedBy: {uid: site.data().owned.uid, name: site.data().owned.nombre, email: site.data().owned.email, foto: site.data().owned.foto, lost: (site.data().lastOwner && site.data().lastOwner.includes(this.userData.uid)) && (site.data().owned.uid !== this.userData.uid) ? true : false}, label: projects.data().name, color: projects.data().color, id: site.id, projectid: projects.id, owner: (site.data().createdBy === this.userData.uid || (site.data().lastOwner && site.data().lastOwner.includes(this.userData.uid))) || isMember.length >= 1 ? 'member' : site.data().createdBy === this.userData.uid && (site.data().owned.uid !== this.userData.uid) ? 'lost' : site.data().createdBy === this.userData.uid && (site.data().owned.uid === this.userData.uid) ? 'me' : 'other', permission: site.data().owned.uid === this.userData.uid ? 'owner' : memberPerm, crawl: '', own: projects.data().owned.uid === this.userData.uid ? 'me' : 'other' })
                                                        }
                                                    }
                                                })

                                                var objInv = ''
                                                var statusInvitation = ''
                                                var counterInvitation = 0
                                                if(invitationOwn.size >= 1) {
                                                    invitationOwn.forEach(inv => {
                                                        objInv = {}
                                                        objInv = inv.data()
                                                        objInv.id = inv.id
                                                        statusInvitation = inv.data().statusInv
                                                    })
                                                }

                                                counterSitesProject = 0
                                                sites.forEach(lastSite => {
                                                    if(lastSite.data().project && lastSite.data().status === 'active') {
                                                        counterSitesProject+=1
                                                    }
                                                })
                                            
                                                countTeamActives += 1
                                                countTeam += 1
                                                if(countTeamActives === 1) {
                                                    if(invitationOwn.size >= 1 || projects.data().owned.uid === this.userData.uid) {
                                                        if(projects.data().owned.uid === this.userData.uid || statusInvitation !== 'declined') {
                                                            this.arrayTeamSort.push({
                                                                mode: 'span',
                                                                html: false,
                                                                label: projects.data().name,
                                                                color: projects.data().color,
                                                                idsite: projects.id,
                                                                // id: val.uid,
                                                                // title: 'My sites',
                                                                id: projects.id,
                                                                // title: nameProject,
                                                                title: projects.data().name,
                                                                // count: this.extraWP,
                                                                count: counterSitesProject,
                                                                // totalInv: invitationsProject.size,
                                                                permission: projects.data().owned.uid === this.userData.uid ? 'owner' : memberPerm,
                                                                totalInv: arrayInvitationsAccepted.length,
                                                                members: arrayInvitationsAccepted,
                                                                own: projects.data().owned.uid === this.userData.uid ? 'me' : 'other',
                                                                invitation: projects.data().owned.uid === this.userData.uid ? 'own' : objInv,
                                                                children: arraySitesChildren
                                                            })
                                                        }
                                                    }
                                                } else {
                                                    if(invitationOwn.size >= 1 || projects.data().owned.uid === this.userData.uid) {
                                                        if(projects.data().owned.uid === this.userData.uid || statusInvitation !== 'declined') {
                                                            this.arrayTeamSort.push({
                                                                mode: 'span',
                                                                html: false,
                                                                label: projects.data().name,
                                                                color: projects.data().color,
                                                                idsite: projects.id,
                                                                // id: idProject,
                                                                id: projects.id,
                                                                // title: nameProject,
                                                                title: projects.data().name,
                                                                // count: counterSitesProject,
                                                                count: counterSitesProject,
                                                                // totalInv: invitationsProject.size,
                                                                permission: projects.data().owned.uid === this.userData.uid ? 'owner' : memberPerm,
                                                                totalInv: arrayInvitationsAccepted.length,
                                                                members: arrayInvitationsAccepted,
                                                                own: projects.data().owned.uid === this.userData.uid ? 'me' : 'other',
                                                                invitation: projects.data().owned.uid === this.userData.uid ? 'own' : objInv,
                                                                children: arraySitesChildren
                                                            })
                                                        }
                                                    }
                                                }

                                                nameProject = projects.data().name
                                                idProject = projects.id
                                            } else {
                                                var statusInvitation = ''
                                                var objInv = ''
                                                if(invitationOwn.size >= 1) {
                                                    invitationOwn.forEach(inv => {
                                                        statusInvitation = inv.data().statusInv
                                                        objInv = {}
                                                        objInv = inv.data()
                                                        objInv.id = inv.id
                                                    })
                                                }

                                                nameProject = projects.data().name
                                                idProject = projects.id
                                                counterSitesProject = sites.size
                                                if(invitationOwn.size >= 1 || projects.data().owned.uid === this.userData.uid) {
                                                    if(projects.data().owned.uid === this.userData.uid || statusInvitation !== 'declined') {
                                                        var filterRow = []
                                                        filterRow = this.arrayTeamSort.filter(itemR => itemR.id === idProject)

                                                        this.arrayTeamSort.push({
                                                            mode: 'span',
                                                            html: false,
                                                            label: projects.data().name,
                                                            color: projects.data().color,
                                                            idsite: projects.id,
                                                            id: idProject,
                                                            title: nameProject,
                                                            count: filterRow.length >= 1 ? 0 : counterSitesProject,
                                                            // count: sites.size,
                                                            // totalInv: invitationsProject.size,
                                                            permission: projects.data().owned.uid === this.userData.uid ? 'owner' : memberPerm,
                                                            totalInv: arrayInvitationsAccepted.length,
                                                            members: arrayInvitationsAccepted,
                                                            own: projects.data().owned.uid === this.userData.uid ? 'me' : 'other',
                                                            invitation: projects.data().owned.uid === this.userData.uid ? 'own' : objInv,
                                                            noExistSites: true,
                                                            children: []
                                                        })
                                                    }
                                                }
                                            }
                                        }
                                    })
                                })
                            })
                        })
                        .catch(error => {
                            console.error(error);
                        })
                    })
                    setTimeout(() => {
                        this.completedArrayTeams = true
                    }, 1500)
                }
            }
        },
        onCopy() {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Key copied',
                    icon: 'BellIcon',
                    variant: 'success'
                },
            })
        },
        onError() {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Failed to copy key!',
                    icon: 'BellIcon',
                    variant: 'danger'
                },
            })
        },
        getSharedStatus() {
            // setTimeout(() => {
            //     this.shared = localStorage.getItem('filtShared')
            //     const collection = document.getElementsByClassName("vgt-text-disabled")
            //     if(collection[0]) {
            //         collection[0].innerHTML = 'No sites'
            //     }

            //     this.isBusy = true
            //     if(this.shared === 'true') {
            //         this.rowsAtomic = this.rows.filter(user => user.own === 'other')
            //         if(this.rowsAtomic.length === 0) {
            //             this.rowsAtomic.forEach(item => {
            //                 this.rowsAtomic.push({
            //                     mode: 'span',
            //                     html: false,
            //                     label: '',
            //                     color: '',
            //                     id: item.idsite,
            //                     title: item.label,
            //                     count: 12,
            //                     own: 'other',
            //                     children: [
            //                         {}
            //                     ]
            //                 })
            //             })
            //         }
            //         this.isBusy = false
            //     } else {
            //         this.rowsAtomic = []
            //         this.isBusy = false
            //     }
            // }, 500);

            // var seconds = !this.loaderSites ? (Date.now() - this.initDate2) / 1000 : 0;
            // var calSecs2 = 20
            // if(seconds > calSecs2) {
            //     this.initDate2 = Date.now()
            //     var obj = {
            //         "typeRequest": "sitios"
            //     }
            // }

            // var seconds = (Date.now() - this.initDate) / 1000;
            // var calSecs = 10
            // if(seconds > calSecs) {
            //     this.initDate = Date.now()
            //     this.rows.forEach(item => {
            //         if(item.children.length) {
            //             item.children.forEach(site => {
            //                 var URLactual = new URL(site.domain);
            //                 var URLdomain = URLactual.host;
            //                 if(URLdomain) {
            //                 }
            //             })
            //         }
            //     })
            // }
        },
        myFunction(stringSite,arraySites) {
            // var obj = {
            //     url: stringSite.url
            // };

            // var config = {
            //     method: 'POST',
            //     url: 'https://luguzxy83e.execute-api.us-east-2.amazonaws.com/prod/imgseocloud',
            //     headers: {
            //         'Content-Type': 'application/json'
            //         // 'Access-Control-Allow-Origin': '*'
            //     },
            //     data : JSON.stringify(obj),
            // };

            // this.axios(config)
            // .then(async (response) => {
            //     var refImg = await storage.ref().child('sitesImgs').child('siteImg'+stringSite.id)
            //     var upl = await refImg.putString(response.data, 'data_url')
            //     var urlDescarga = await refImg.getDownloadURL()
            //     // console.log(urlDescarga)
            //     // user has the site shared
            //     db.collection('sites').doc(stringSite.id).update({
            //         img: urlDescarga
            //     })
            //     if(arraySites.length >= 1) {
            //         stringSite = arraySites[0]
            //         arraySites = arraySites.splice(1);
            //         this.myFunction(stringSite,arraySites)
            //     }
            // })
        
        }
    },
    setup() {
        return {
            avatarText,
        }
    },
    mounted() {
        this.existSites = false
        // this.events.forEach((event) => {
        //     this.shared = window.addEventListener(event, this.getSharedStatus)
        // })
    },
    created() {
        this.loadErrors = true
        this.sortedComplete = false
        this.completedArrayTeams = false
        this.initDate = 0
        this.initDate2 = 0

        // const user = auth.currentUser
        // if(user) {
        //     db.collection('Users').doc(user.uid)
        //     .onSnapshot((userDoc) => {
        //         this.userData = userDoc.data()
        //         this.rows = []
        //         this.rowsAcceptInvitation = []
        //         this.charge(this.iduser)
        //     }, error => console.log(error))
        // }
    },
    destroyed() {
        // this.events.forEach((event) => {
        //     window.removeEventListener(event, this.getSharedStatus)
        // })
    },
}
</script>

<style>
.vgt-text-disabled {
    border: transparent !important;
    text-align: left !important;
}
table.vgt-table {
    border-bottom: transparent !important;
    border-right: transparent !important;
    border-left: transparent !important;
}
.vgt-table.bordered td {
    /* border-bottom: transparent !important; */
    border-right: transparent !important;
    border-left: transparent !important;
}
/* .thTableSites {
    background: transparent !important;
    border: 1px solid transparent !important;
    color: #A7AAB9 !important;
    font-size: 15px !important;
    font-weight: 300;
    padding-right: 0.75em !important;
}
.tdTableSites {
    background: transparent !important;
    border: 1px solid transparent !important;
    border-bottom: 1px solid #DCDCF2 !important;
    cursor: pointer;
    vertical-align: middle !important;
    width: 165px !important;
    max-width: 165px !important;
}
.thTableSitesName {
    background: transparent !important;
    border: 1px solid transparent !important;
    color: #A7AAB9 !important;
    font-size: 15px !important;
    font-weight: 300;
    padding-right: 0.75em !important;
    max-width: 255px !important;
    width: 255px !important;
}
.tdTableSitesName {
    background: transparent !important;
    border: 1px solid transparent !important;
    border-bottom: 1px solid #DCDCF2 !important;
    cursor: pointer;
    vertical-align: middle !important;
    max-width: 255px !important;
    width: 255px !important;
}
.thTableSitesViewListMembership {
    background: transparent !important;
    border: 1px solid transparent !important;
    color: #A7AAB9 !important;
    font-size: 15px !important;
    font-weight: 300;
    text-align: center !important;
    padding-right: 30px !important;
    max-width: 200px !important;
    width: 200px !important;
}
.tdTableSitesViewListMembership {
    background: transparent !important;
    border: 1px solid transparent !important;
    border-bottom: 1px solid #DCDCF2 !important;
    cursor: pointer;
    vertical-align: middle !important;
    padding-right: 30px !important;
    max-width: 200px !important;
    width: 200px !important;
}
.thTableSitesViewListKey {
    background: transparent !important;
    border: 1px solid transparent !important;
    color: #A7AAB9 !important;
    font-size: 15px !important;
    font-weight: 300;
    max-width: 132px !important;
    width: 132px !important;
    text-align: center !important;
    padding-right: 0.75em !important;
}
.tdTableSitesKeyViewListKey {
    background: transparent !important;
    border: 1px solid transparent !important;
    border-bottom: 1px solid #DCDCF2 !important;
    cursor: pointer;
    vertical-align: middle !important;
    max-width: 132px !important;
    width: 132px !important;
    text-align: center !important;
}
.thTableSitesViewList {
    background: transparent !important;
    border: 1px solid transparent !important;
    color: #A7AAB9 !important;
    font-size: 15px !important;
    font-weight: 300;
    max-width: 125px !important;
    width: 125px !important;
    text-align: center !important;
    padding-right: 0.75em !important;
}
.tdTableSitesViewList {
    background: transparent !important;
    border: 1px solid transparent !important;
    border-bottom: 1px solid #DCDCF2 !important;
    cursor: pointer;
    vertical-align: middle !important;
    max-width: 125px !important;
    width: 125px !important;
    text-align: center !important;
}
.thTableSitesDomain {
    background: transparent !important;
    border: 1px solid transparent !important;
    color: #A7AAB9 !important;
    font-size: 15px !important;
    font-weight: 300;
    padding-right: 0.75em !important;
}
.tdTableSitesDomain {
    background: transparent !important;
    border: 1px solid transparent !important;
    border-bottom: 1px solid #DCDCF2 !important;
    cursor: pointer;
    word-wrap: break-word;
    vertical-align: middle !important;
}
.tdTableSitesKeyViewList {
    background: transparent !important;
    border: 1px solid transparent !important;
    border-bottom: 1px solid #DCDCF2 !important;
    vertical-align: middle !important;
    max-width: 132px !important;
    width: 132px !important;
    text-align: center !important;
}
.thTableSitesOwned {
    background: transparent !important;
    border: 1px solid transparent !important;
    color: #A7AAB9 !important;
    font-size: 15px !important;
    font-weight: 300;
    padding-right: 0.75em !important;
    width: 92px !important;
    max-width: 92px !important;
}
.tdTableSitesOwned {
    background: transparent !important;
    border: 1px solid transparent !important;
    border-bottom: 1px solid #DCDCF2 !important;
    vertical-align: middle !important;
    width: 92px !important;
    max-width: 92px !important;
}
.thTableSitesAction {
    background: transparent !important;
    border: 1px solid transparent !important;
    cursor: pointer;
    vertical-align: middle !important;
    width: 50px !important;
    max-width: 50px !important;
}
.tdTableSitesAction {
    background: transparent !important;
    border: 1px solid transparent !important;
    border-bottom: 1px solid #DCDCF2 !important;
    vertical-align: middle !important;
    width: 50px !important;
    max-width: 50px !important;
} */
.vgt-table.bordered th {
    background: #fff !important;
    border: 1px solid transparent !important;
    margin-top: 20px !important;
    /* font-size: 16px !important; */
    /* color: #181E38 !important; */
}
.item-dropdown-action-sites:hover {
  background: none !important;
  background-color: none !important;
  color: #000 !important;
}
.dropdown-sites button {
    padding: 0px !important;
}
.dropdown-sites button:hover {
    background: transparent !important;
}
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #fff;
  border: none;
  padding: 0px !important;
  /* color: #394066; */
  /* text-transform: lowercase; */
  /* font-variant: small-caps; */
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
  padding: 0px !important;
}

.style-chooser .dropdown-toggle {
    background: transparent !important;
    border: 0px !important;
    padding: 0px !important;
    color: #656B85 !important;
}
.style-chooser .dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black' stroke='' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}
.style-chooser .dropdown-item {
    padding: 0px 5px;
}
/* .style-chooser .dropdown-menu {
    border-radius: 10px;
    border: 1px solid #bbbbb9 !important;
    width: 180px !important;
} */
/* .vgt-table.bordered th {
  border: 1px solid #dcdfe6;
} */
/* .vgt-table thead th {
  border-bottom: 1px solid #dcdfe6;
  background: linear-gradient(#f4f5f8,#f1f3f6);
} */
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>