<template>
  <div>
    <b-sidebar
      id="sidebar-right-grammar"
      ref="sidebar_grammar"
      :visible="showOpenSidebarGrammar"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-sidebar-grammar', val)"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #F0F1F7; height: 91px;"
        >
          <b-card
            no-body
            class="overflow-hidden w-100"
            style="background-color: #F0F1F7;"
          >
            <header-sidebar :idsite-header.sync="idsite" />
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <div class="ml-3 my-2">
          <p
            v-if="idsite.label"
            class="mb-0"
            style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px;"
          >
            Project
          </p>
          <h4
            v-if="idsite.label"
            class=""
            style="font-weight: 700;"
          >
            {{ idsite.label }}
          </h4>
        </div>
        <hr v-if="idsite.label">
        <h3
          class="mb-0 pb-0 pt-0 ml-0"
          style="font-weight: 700; color: #181E38 !important; padding: 20px;"
        >
          Fix Grammar
        </h3>
        <div
          class="b-sidebar-body mt-0"
          style="padding: 20px;"
        >
          <b-row v-if="idsite.idc !== 7">
            <b-col md="12">
                <b-card-text
                  class="mb-25 pt-0"
                  style="font-weight: 700; color: #181E38; font-size: 15px;"
                >
                  Page:
                </b-card-text>
                <p style="color: rgb(45, 45, 45); margin-bottom: 0px;">{{ titleGrammar }}</p>
                <p style="color: #8e8a8a; margin-top: 5px;">
                  <a :href="idsite.domain" target="_blank" style="text-decoration: none !important; color: #8e8a8a !important;">
                    <feather-icon
                      icon="ExternalLinkIcon"
                      style="position: relative; top: -2.5px;"
                      class="mr-25"
                    />
                    {{ urlGrammar }}
                  </a>
                </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
              <b-img-lazy
                :src="errSitesImg"
                width="100px"
                height="35px"
                class="mt-2"
                loading="lazy"
              />
            </b-col>
            <b-col md="10">
              <p
                class="mb-0"
                style="color: #656B85;"
              >
                <feather-icon
                  icon="ToolIcon"
                  style="fill: #656b85;"
                  class="mr-50"
                />How to fix Grammar
              </p>
              <span class="hurmbeRegular">Donec vitae ex ut lectus pretium porta. In interdum ligula ac elementum aliquet. Suggestions: Lorem, ipsum, dolor, sit, amet.</span>
            </b-col>
          </b-row>
        </div>

        <div style="padding-bottom: 80px;">
          <div style="border-left: 4px solid #7838FF; height: 100%;" class="pt-1">
            <b-tabs class="ml-75">
              <b-tab @click="changeTabFix">
                <template #title>
                  <b-img-lazy
                    :src="faviconSEO"
                    width="20px"
                    height="16px"
                    class=""
                    style="height: 16px; margin-right: 10px;"
                    loading="lazy"
                  />
                  <span>ChatGPT</span>
                </template>

                <b-overlay
                  :show="busyChatGPT"
                  rounded="xl"
                  :variant="'transparent'"
                  :opacity="0.85"
                  :blur="'2px'"
                  class="mt-2 ml-2"
                >
                  <h4 style="text-align: center;" v-if="busyChatGPT">Loading suggestions...</h4>
                  <b-form-group class="mt-2">
                    <template
                      v-slot:label
                    >
                      <feather-icon
                        icon="AlignCenterIcon"
                        :style="'top: -3px; position: relative;'"
                      />
                      <span
                        :style="''"
                        class="pt-0 px-0"
                      >
                        Site Grammar
                      </span>
                    </template>
                    <b-form-textarea
                      id="textarea-plaintext"
                      plaintext
                      :value="responseCrawler"
                    />
                  </b-form-group>

                  <b-form-group class="mt-2">
                    <template
                      v-slot:label
                    >
                      <feather-icon
                        icon="CpuIcon"
                        :style="'color: #15a17f; top: -3px; position: relative;'"
                      />
                      <span
                        :style="'color: #15a17f;'"
                        class="pt-0 px-0"
                      >
                        Grammar Suggestions
                      </span>
                    </template>
                    <b-form-textarea
                      id="textarea-plaintext"
                      rows="6"
                      :value="optionsChatGPT"
                    />
                  </b-form-group>
                  <!-- <b-form-group v-for="(item, index) in optionsChatGPT" :key="index" class="mb-50" v-else>
                    <template
                      v-slot:label
                    >
                      <feather-icon
                        icon="CpuIcon"
                        :style="'color: #15a17f; top: -3px; position: relative;'"
                      />
                      <span
                        :style="'color: #15a17f;'"
                        class="pt-0 px-0"
                      >
                        {{ 'ChatGPT suggestion'+ (index + 1) }}
                      </span>
                    </template>
                    <b-form-radio
                      v-model="fixText"
                      :value="item"
                      class="custom-control-success"
                    >
                      {{ item }}
                    </b-form-radio>
                  </b-form-group> -->
                </b-overlay>
              </b-tab>
            </b-tabs>
          </div>

        </div>
        <help-footer :url="idsite.domain" />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BImgLazy, BTabs, BTab,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow, BOverlay, BFormRadio,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, url, validimg
} from '@validations'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import HelpFooter from '@/views/components/footer/HelpFooter'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { db, firebase, auth, dbErrors, firebaseError } from '@/firebase'
import { filter } from 'postcss-rtl/lib/affected-props'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BImgLazy,
    BTabs,
    BTab,
    BOverlay,
    BFormRadio,
    HelpFooter,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenSidebarGrammar',
    event: 'update:show-open-sidebar-grammar',
  },
  props: ['idsite', 'showOpenSidebarGrammar', 'urlGrammar', 'titleGrammar'],
  data() {
    return {
      required,
      url,
      validimg,

      selectedMove: '',

      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      allProject: [
        {
          label: 'My sites',
          color: '#F0F1F7',
        },
        {
          label: 'Facade interactive sites',
          color: '#F39C19',
        },
        {
          label: "Jane's sites",
          color: '#1CA085',
        },
      ],
      selectedProPage: '',
      optionsProPage: [],
      selectedError: '',
      optionsErrors: [
        'Meta title',
        'Title',
        'Descriptions',
        'Alts tag',
        'Headings H1',
        'Low word count',
        // 'External links',
      ],
      oldText: '',
      fixText: '',
      fixTextAlt: '',
      processSave: false,
      pageTitleSelect: '',
      counterListError: 0,
      busyChatGPT: false,
      optionsChatGPT: '',
      responseCrawler: '',

      //   sources
      errSitesImg: require('@/assets/images/pages/robot-thumb.svg'),
      faviconSEO: require('@/assets/images/icons/favicon-seocloud.png'),
    }
  },
  watch: {
    // rowError(val) {
    //   // console.log(val.idc)
    //   this.oldText = val.idc === 7 ? val.url : val.idc === 13 || val.idc === 15 ? val.location : val.idc === 3 ? val.alt : val.content
    //   this.selectedError = 'Title'
    //   if(val.idc === 1) {
    //     this.selectedError = 'Titles'
    //   } else if(val.idc === 2) {
    //     this.selectedError = 'Descriptions'
    //   }
    //   // else if(val.idc === 3) {
    //   //   this.selectedError = 'Headings H1'
    //   // } else if(val.idc === 4) {
    //   //   this.selectedError = 'Headings H2'
    //   // }
    //   // else if(val.idc === 5) {
    //   //   this.selectedError = 'Images'
    //   // }
    //   else if(val.idc === 6) {
    //     this.selectedError = 'Low word count'
    //   }
    //   else if(val.idc === 8) {
    //     this.selectedError = 'Images'
    //   }
    //   else if(val.idc === 9) {
    //     this.selectedError = 'Dead links'
    //   }
    //   else if(val.idc === 3) {
    //     this.selectedError = 'Missing alts'
    //   }
    //   else if(val.idc === 13) {
    //     this.selectedError = 'Strings'
    //   }
    //   // else if(val.idc === 12) {
    //   //   this.selectedError = 'Missing images'
    //   // }
    //   else if(val.idc === 11) {
    //     this.selectedError = 'Canonicals'
    //   }
    //   else if(val.idc === 14) {
    //     this.selectedError = 'Missing images'
    //   }
    // },
    showOpenSidebarGrammar(val) {
      if(val) {
        document.body.style.overflow = 'hidden'
        this.fixText = ''
        this.fixTextAlt = ''
        this.busyChatGPT = false
        this.optionsChatGPT = ''
        this.responseCrawler = ''

        this.loadProPagesObject()
      } else {
        localStorage.removeItem('fixErrorHistory')
        document.body.style.overflow = 'auto'
        this.busyChatGPT = false
        this.optionsChatGPT = ''
        this.responseCrawler = ''
      }
    },
  },
  validations: {
  },
  methods: {
    changeTabFix() {
      this.fixText = ''
    },
    onEnterPressed() {
      // 
    },
    watchHistoryError() {
      localStorage.setItem('fixErrorHistory', true)
    },
    structureChatGPT(payload) {
    //   console.log(JSON.parse(payload))
      this.busyChatGPT = false
      // this.optionsChatGPT = JSON.parse(payload)
      this.optionsChatGPT = payload
    },
    loadProPagesObject() {
    //   console.log('call pro pages', this.idsite)
    //   this.pageTitleSelect = { title: '', url: this.urlGrammar }

    //   this.optionsProPage = []
    //   var filterProPagesView = this.contenterr.filter(ele => ele.pro === true)
    //   filterProPagesView.forEach(ele => {
    //     var alreadyAdd = this.optionsProPage.filter(item => item.url === ele.domain)
    //     if(alreadyAdd.length === 0) {
    //       this.optionsProPage.push({title: ele.name, url: ele.domain})
    //     }
    //   })

      if(this.urlGrammar) {
        var typeBuild = 'gram'
        var cadenaPrompt = []
        var arrayVariables = []
        var arrayFieldsAnswer = []
        var itemsBuilds = []
        var itemsBuildsCache = []
        this.busyChatGPT = true
        dbErrors.collection('IA_form_builder').doc('prompt').get()
        .then(docPrompt => {
          var promtObj = docPrompt.data().prompt.filter(ele => ele.typeError === typeBuild)

          dbErrors.collection('IA_form_builder').doc('content').get()
          .then(docBuilder => {
            if(docBuilder.data().form) {
              itemsBuilds = docBuilder.data().form.filter(ele => ele.typeError === typeBuild)
              itemsBuildsCache = docBuilder.data().form.filter(ele => ele.typeError === typeBuild)
            }

            if(promtObj.length >= 1) {
              const cadena = promtObj[0].logic
              cadenaPrompt = cadena

              // Utilizando expresiones regulares con el modificador global para encontrar todas las palabras entre paréntesis
              const resultados = cadena.match(/\((.*?)\)/g)

              if(resultados) {
                const palabrasEntreParentesis = resultados.map(resultado => resultado.slice(1, -1))
                arrayVariables = palabrasEntreParentesis
                // console.log(palabrasEntreParentesis)
                if(palabrasEntreParentesis.includes('web_content')) {
                  itemsBuilds.push({labelField: 'Web content', textField: '', typeField: 'textarea', variableField: 'web_content'})
                }
              } else {
                console.log("No se encontró variables en el prompt.")
              }
            } else {
              cadenaPrompt = []
              arrayVariables = []
            }

            //  actualizar only-text site
            db.collection('Sites').doc(this.idsite.id).get()
            .then(site => {
              // this.optionsProPage.forEach(ele => {
                db.collection('Requests').where('RequestURL', '==', this.urlGrammar).where('idcrawl', '==', site.data().mostRecentCrawl).where('tipo', '==', 'only-text').limit(1).get()
                .then(docsRequests => {
                  // console.log(docsRequests.size)
                  if(docsRequests.size === 0) {
                    console.log('no exist request')
                    var makeid = (length) => {
                      var result = ''
                      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
                      var charactersLength = characters.length
                      for(var i = 0; i < length; i++) {
                        result += characters.charAt(Math.floor(Math.random() * charactersLength))
                      }
                      return result
                    }
                    
                    var dateQueue = new Date()
                    var string_date_queue = dateQueue.getDate()+'/'+(dateQueue.getMonth()+1)+'/'+dateQueue.getFullYear()+' '+dateQueue.getHours()+':'+dateQueue.getMinutes()+':'+dateQueue.getSeconds()
                    var idNewRequest = makeid(20)
                    var URLactual = new URL(this.urlGrammar)
                    var payloadReq = {
                      id: site.data().mostRecentCrawl,
                      type: 'only-text',
                      forceNotFound: 'no',
                      url: this.urlGrammar,
                      pageSize: 9999999999,
                      takeScreenshots: 'no',
                      ignoreIndexNoFollow: 'yes',
                      useFastCrawler: 'no',
                      triggeredBy: 'automated',
                      source: 'overview',
                      stringSearch: []
                    }

                    db.collection('Requests').add({
                      request_json: payloadReq,
                      IDRequest: idNewRequest,
                      RequestURL: this.urlGrammar,
                      CrawlJSON: null,
                      SiteURL: URLactual.hostname,
                      data: null,
                      // tipo: 'only-text',
                      type: 'nova',
                      takeScreenshots: 'no',
                      apiWI: false,
                      idcrawl: site.data().mostRecentCrawl,
                      hitcache: false,
                      cachedID: null,
                      tries: 1,
                      useFastCrawler: 'no',
                      created_at: string_date_queue,
                      request_date: Date.now(),
                      response_date: 0,
                    })
                    .then(docRefAddRequests => {
                      var config = {
                        'url': 'https://app-facm3chzha-uc.a.run.app/crawl-url',
                        'method': 'POST',
                        'maxBodyLength': Infinity,
                        'headers': {
                          'Content-Type': 'application/json',
                        },
                        'data': JSON.stringify(payloadReq)
                      };
                  
                      this.axios(config)
                      .then((response) => {
                        console.log('response only-urls: ', payloadReq.url)

                        var body = response.data
                        var dataBodyResponse = 'found'

                        db.collection('Requests').doc(docRefAddRequests.id).update({
                          CrawlJSON: body,
                          data: dataBodyResponse,
                          response_date: Date.now()
                        })
                        .catch(e => console.log('Error update requests: ', e.message))

                        // docsRequests.forEach(doc => {
                          // console.log(doc.data().CrawlJSON.data.allText)

                        //   var responseObjArray = []
                        //   var buildR = itemsBuilds.filter(ele => ele.variableField === 'page_title')
                        //   buildR.forEach(ele => {
                        //     ele.textField = this.idsite.name
                        //   })

                        //   var buildR = itemsBuilds.filter(ele => ele.variableField === 'web_content')
                        //   buildR.forEach(ele => {
                        //     ele.textField = body.data.allText
                        //   })

                        //   var arrayFieldsAnswer = itemsBuilds
                        //   arrayVariables.forEach(ele => {
                        //     arrayFieldsAnswer.forEach((item, index) => {
                        //       if(item.variableField === ele) {
                        //         var responseField = ''
                        //         if(item.typeField === 'checkbox') {
                        //           var variableCounter = 0
                        //           item.textField.forEach(ele => {
                        //             variableCounter += 1
                        //             responseField += (variableCounter === 1 ? '(' : '') + ele + (variableCounter === item.textField.length ? '' : ', ' ) + (variableCounter === item.textField.length ? ')' : '')
                        //           })
                        //         } else {
                        //           responseField = item.textField
                        //         }

                        //         responseObjArray.push({response: responseField})
                        //       }
                        //     })
                        //   })

                        //   if(cadenaPrompt.length >= 1) {
                        //     // this.showDelayGPT = true
                        //     const partesNoParentesis = cadenaPrompt.split(/\([^()]*\)/)
                        //     var counterFor = 0
                        //     var sendToApi = ''
                        //     partesNoParentesis.forEach(element => {
                        //       if(responseObjArray[counterFor]) {
                        //         sendToApi += element + responseObjArray[counterFor].response
                        //         counterFor += 1
                        //       } else {
                        //         sendToApi += element
                        //       }
                        //     })
                            // console.log(sendToApi);
                            var responseObjArray = []
                            var web_content = ''
                            var buildR = itemsBuilds.filter(ele => ele.variableField === 'web_content')
                            buildR.forEach(ele => {
                              ele.textField = body.data.allText.substring(0, 170)
                            })
                            var arrayFieldsAnswer = itemsBuilds
                            arrayVariables.forEach(ele => {
                              arrayFieldsAnswer.forEach((item, index) => {
                                if(item.variableField === ele) {
                                  if(item.variableField === 'web_content') {
                                    web_content = body.data.allText.substring(0, 170)
                                  }

                                  // console.log(index)
                                  var responseField = ''
                                  if(item.variableField === 'web_content') {
                                    responseField = web_content
                                  } else if(item.typeField === 'checkbox') {
                                    var variableCounter = 0
                                    item.textField.forEach(ele => {
                                      variableCounter += 1
                                      // console.log(variableCounter,item.textField.length)
                                      responseField += (variableCounter === 1 ? '(' : '') + ele + (variableCounter === item.textField.length ? '' : ', ' ) + (variableCounter === item.textField.length ? ')' : '')
                                    })
                                  } else {
                                    responseField = item.textField
                                  }

                                  responseObjArray.push({response: responseField})
                                }
                              })
                            })
                            
                            var sendToApi = ''
                            if(cadenaPrompt.length >= 1) {
                              // showDelayGPT = true
                              const partesNoParentesis = cadenaPrompt.split(/\([^()]*\)/)
                              var counterFor = 0
                              partesNoParentesis.forEach(element => {
                                if(responseObjArray[counterFor]) {
                                  sendToApi += element + responseObjArray[counterFor].response
                                  counterFor += 1
                                } else {
                                  sendToApi += element
                                }
                              })
                              // responseFromApi = ''
                            }
                            // console.log(sendToApi);

                            // var sendToApi = 'Array de máximo longitud 6 sin errores con reemplazos para el siguiente contenido: '+body.data.allText
                            // Sugerencias de grammar en párrafos con reemplazos para el siguiente contenido:
                            sendToApi ? null : sendToApi = 'What are the grammar issues in the text content on this website: ' + body.data.allText.substring(0, 170)
                            this.responseCrawler = body.data.allText
                            // console.log(sendToApi);
                            
                            var config = {
                              method: 'POST',
                              // url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/chat',
                              url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/chat-bedrock-nova-lite',
                              headers: {
                                'Content-Type': 'application/json',
                              },
                              data: JSON.stringify({messages: sendToApi}),
                            }

                            this.axios(config)
                            .then(response => {
                            //   console.log(response.data.response)
                              var responseFromApi = response.data.response
                              // this.showDelayGPT = false
                              this.structureChatGPT(responseFromApi)
                            })
                            .catch(e => {
                              console.log(e)
                              // this.showDelayGPT = false
                              this.busyChatGPT = false
                            })
                        //   } else {
                        //     console.log('No prompt created');
                        //     // this.sendToApi = 'No prompt created'
                        //     // this.responseFromApi = ''
                        //     this.busyChatGPT = false
                        //   }
                        // })
                      })
                    })
                    .catch(e => {
                      console.log('Error update requests: ', e.message)
                      this.busyChatGPT = false
                    })
                  } else {
                    console.log('exist request')
                    docsRequests.forEach(doc => {
                      // console.log(doc.data().CrawlJSON.data.allText)

                    //   var responseObjArray = []
                    //   var buildR = itemsBuilds.filter(ele => ele.variableField === 'page_title')
                    //   buildR.forEach(ele => {
                    //     ele.textField = this.idsite.name
                    //   })

                    //   var buildR = itemsBuilds.filter(ele => ele.variableField === 'web_content')
                    //   buildR.forEach(ele => {
                    //     ele.textField = doc.data().CrawlJSON.data.allText
                    //   })

                    //   var arrayFieldsAnswer = itemsBuilds
                    //   arrayVariables.forEach(ele => {
                    //     arrayFieldsAnswer.forEach((item, index) => {
                    //       if(item.variableField === ele) {
                    //         var responseField = ''
                    //         if(item.typeField === 'checkbox') {
                    //           var variableCounter = 0
                    //           item.textField.forEach(ele => {
                    //             variableCounter += 1
                    //             responseField += (variableCounter === 1 ? '(' : '') + ele + (variableCounter === item.textField.length ? '' : ', ' ) + (variableCounter === item.textField.length ? ')' : '')
                    //           })
                    //         } else {
                    //           responseField = item.textField
                    //         }

                    //         responseObjArray.push({response: responseField})
                    //       }
                    //     })
                    //   })

                    //   if(cadenaPrompt.length >= 1) {
                    //     // this.showDelayGPT = true
                    //     const partesNoParentesis = cadenaPrompt.split(/\([^()]*\)/)
                    //     var counterFor = 0
                    //     var sendToApi = ''
                    //     partesNoParentesis.forEach(element => {
                    //       if(responseObjArray[counterFor]) {
                    //         sendToApi += element + responseObjArray[counterFor].response
                    //         counterFor += 1
                    //       } else {
                    //         sendToApi += element
                    //       }
                    //     })
                        // console.log(sendToApi);

                        var responseObjArray = []
                        var web_content = ''
                        var buildR = itemsBuilds.filter(ele => ele.variableField === 'web_content')
                        buildR.forEach(ele => {
                          ele.textField = doc.data().CrawlJSON.data.allText.substring(0, 170)
                        })
                        var arrayFieldsAnswer = itemsBuilds
                        arrayVariables.forEach(ele => {
                          arrayFieldsAnswer.forEach((item, index) => {
                            if(item.variableField === ele) {
                              if(item.variableField === 'web_content') {
                                web_content = doc.data().CrawlJSON.data.allText.substring(0, 170)
                              }

                              // console.log(index)
                              var responseField = ''
                              if(item.variableField === 'web_content') {
                                responseField = web_content
                              } else if(item.typeField === 'checkbox') {
                                var variableCounter = 0
                                item.textField.forEach(ele => {
                                  variableCounter += 1
                                  // console.log(variableCounter,item.textField.length)
                                  responseField += (variableCounter === 1 ? '(' : '') + ele + (variableCounter === item.textField.length ? '' : ', ' ) + (variableCounter === item.textField.length ? ')' : '')
                                })
                              } else {
                                responseField = item.textField
                              }

                              responseObjArray.push({response: responseField})
                            }
                          })
                        })
                        
                        var sendToApi = ''
                        if(cadenaPrompt.length >= 1) {
                          // showDelayGPT = true
                          const partesNoParentesis = cadenaPrompt.split(/\([^()]*\)/)
                          var counterFor = 0
                          partesNoParentesis.forEach(element => {
                            if(responseObjArray[counterFor]) {
                              sendToApi += element + responseObjArray[counterFor].response
                              counterFor += 1
                            } else {
                              sendToApi += element
                            }
                          })
                          // responseFromApi = ''
                        }
                        // console.log(sendToApi);

                        // var sendToApi = 'Array de máximo longitud 6 sin errores con reemplazos para el siguiente contenido: '+doc.data().CrawlJSON.data.allText
                        // Sugerencias de grammar en párrafos con reemplazos para el siguiente contenido:
                        sendToApi ? null : sendToApi = 'What are the grammar issues in the text content on this website: ' + doc.data().CrawlJSON.data.allText.substring(0, 170)
                        this.responseCrawler = doc.data().CrawlJSON.data.allText
                        // console.log(sendToApi);

                        var config = {
                          method: 'POST',
                          // url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/chat',
                          url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/chat-bedrock-nova-lite',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          data: JSON.stringify({messages: sendToApi}),
                        }

                        this.axios(config)
                        .then(response => {
                        //   console.log(response.data.response)
                          var responseFromApi = response.data.response
                          // this.showDelayGPT = false
                          this.structureChatGPT(responseFromApi)
                        })
                        .catch(e => {
                          console.log(e)
                          // this.showDelayGPT = false
                          this.busyChatGPT = false
                        })
                    //   } else {
                    //     console.log('No prompt created');
                    //     // this.sendToApi = 'No prompt created'
                    //     // this.responseFromApi = ''
                    //     this.busyChatGPT = false
                    //   }
                    })
                  }
                })
              // })
            })
          })
        })
      }
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-grammar {
  width: 90%;
  max-width: 1200px !important;
}
// label {
//   margin-bottom: 0px !important;
//   height: 25px;
// }
// .col-form-label {
//   margin-bottom: 0px !important;
//   height: 25px;
//   font-size: 0.857rem !important;
// }
// .form-control {
//   height: 35px;
// }
// .v-select {
//   height: 35px;
//   margin-top: 0px;
// }
// .vs__dropdown-toggle {
//   height: 35px;
// }
// .vs__selected {
//   margin-top: 0px !important;
// }
</style>