<template>
  <div>
    <strings-search
      :idsite="siteObj.length >= 1 ? siteObj[0] : ''"
      :strings.sync="optionsStrings"
      :show-open-strings-search.sync="showOpenStringsSearch"
      :type="'suspicious'"
    />
    <delete-strings-search
      :idsite="siteObj.length >= 1 ? siteObj[0] : ''"
      :strings.sync="optionsStrings"
      :string-to-delete.sync="stringToDelete"
      :show-open-delete-strings-search.sync="showOpenDeleteStringsSearch"
      :type="'suspicious'"
    />
    <fix-error
      :idsite="siteObj.length >= 1 ? siteObj[0] : ''"
      :row-error="rowError"
      :show-open-fix-error.sync="showOpenFixError"
      :permission="siteObj.length >= 1 && siteObj[0].permission"
      :changeerror.sync="changeerror"
    />
    <register-sidebar :show-open-register-sidebar.sync="showOpenRegisterSidebar" />

    <div class="d-flex mt-50">
      <b-button
        :variant="BtnScreenshots ? 'primary' : 'light'"
        :class="BtnScreenshots ? 'px-50 py-50 mr-50 mt-50' : 'px-50 py-50 mr-50 mt-50 button-tab-unselect'"
        pill
        :style="BtnScreenshots ? 'border: 0px !important; height: 35px !important; min-height: 35px !important;' : 'border: 0px !important; height: 35px !important; min-height: 35px !important;'"
        @click="BtnScreenshots = true, BtnRedirections = false, BtnSuspiciousText = false, BtnImageOptimization = false, BtnRobots = false"
      >
        Screenshots
        <span class="bullet bullet-primary svg-font-small-3 cursor-pointer" :style="BtnScreenshots ? 'background-color: #fff !important; width: .75rem !important; height: .75rem !important; position: relative; top: 1px;' : 'background-color: #7367f0 !important; width: .75rem !important; height: .75rem !important; position: relative; top: 1px;'" v-if="arrayWScreenshotsDays.length"></span>
      </b-button>
      <b-button
        :variant="BtnRobots ? 'primary' : 'light'"
        :class="BtnRobots ? 'px-50 py-50 mr-50 mt-50' : 'px-50 py-50 mr-50 mt-50 button-tab-unselect'"
        pill
        :style="BtnRobots ? 'border: 0px !important; height: 35px !important; min-height: 35px !important;' : 'border: 0px !important; height: 35px !important; min-height: 35px !important;'"
        @click="BtnScreenshots = false, BtnRedirections = false, BtnSuspiciousText = false, BtnImageOptimization = false, BtnRobots = true"
      >
        robots.txt
        <span class="bullet bullet-primary svg-font-small-3 cursor-pointer" :style="BtnRobots ? 'background-color: #fff !important; width: .75rem !important; height: .75rem !important; position: relative; top: 1px;' : 'background-color: #7367f0 !important; width: .75rem !important; height: .75rem !important; position: relative; top: 1px;'"></span>
      </b-button>
      <b-button
        :variant="BtnRedirections ? 'primary' : 'light'"
        :class="BtnRedirections ? 'px-50 py-50 mr-50 mt-50' : 'px-50 py-50 mr-50 mt-50 button-tab-unselect'"
        pill
        :style="BtnRedirections ? 'border: 0px !important; height: 35px !important; min-height: 35px !important;' : 'border: 0px !important; height: 35px !important; min-height: 35px !important;'"
        @click="BtnScreenshots = false, BtnRedirections = true, BtnSuspiciousText = false, BtnImageOptimization = false, BtnRobots = false"
      >
        Redirections
      </b-button>
    </div>
    <div class="mt-3">
      <div v-if="BtnRobots">
        <b-row class="mt-2 px-50" style="border: 1px solid #F0F1F3; border-radius: 10px;">
          <b-col md="4">
            <b-card
              style=""
              class=""
              no-body
            >
              <b-card-body class="d-flex justify-content-between px-0 pt-1">
                <div class="truncate">
                  <h2
                    class="mb-75 font-weight-bolder"
                    style="font-size: 24px !important; font-weight: 400 !important;"
                  >
                    What is a robots.txt?
                    <!-- <feather-icon
                      size="21"
                      icon="HelpCircleIcon"
                      style="color: #A7AAB9;"
                      class="mb-1"
                    /> -->
                  </h2>
                  <span>The robots.txt file is a text file used by websites to communicate with web crawlers and bots. It provides instructions about which pages or sections of the site should not be processed or scanned. This helps manage web traffic and can prevent servers from being overwhelmed by too many requests.</span>
                  <b-img-lazy
                    :src="robotErrorImg"
                    width=""
                    height="164px"
                    class="my-1 w-100"
                    loading="lazy"
                  />
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="8">
            <!-- <b-card
              style=""
              no-body
            >
              <b-card-body class="d-flex justify-content-between align-items-center pt-1"> -->
                <b-row :style="existRobots ? '' : 'width: 100% !important;'" class="d-flex justify-content-between pt-1">
                  <b-col md="6" class="mb-2 pb-2">
                    <div class="truncate">
                      <h2
                        class="mb-75 font-weight-bolder"
                        style="font-size: 24px !important; font-weight: 400 !important;"
                      >
                        Your robots.txt
                        <!-- <feather-icon
                          size="21"
                          icon="HelpCircleIcon"
                          style="color: #A7AAB9;"
                          class="mb-1"
                        /> -->
                      </h2>
                      <span>This is your current robots.txt</span>
                      <div
                        style="background: #F0F1F3; border-radius: 5px; font-size: 18px;"
                        class="p-1 mt-1"
                      >
                        <b-overlay
                          :show="showDelayRobots"
                          :variant="'transparent'"
                          :opacity="0.85"
                          :blur="'2px'"
                          style="width: 100%;"
                        >
                          <b-form-textarea
                            class="content-robots"
                            plaintext
                            :value="contentRobots"
                            rows="6"
                            max-rows="240"
                            v-if="existRobots"
                          />
                          <svg width="170" height="50" xmlns="http://www.w3.org/2000/svg" class="mt-2 mb-4" v-else>
                            <text x="50%" y="70%" dominant-baseline="middle" text-anchor="middle" font-family="Arial, sans-serif" font-size="20" fill="#181e38">
                              robots.txt not found
                            </text>
                          </svg>
                        </b-overlay>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="6" v-if="existRobots">
                    <div class="truncate whatDoesYourRobot">
                      <h2
                        class="mb-75 font-weight-bolder"
                        style="font-size: 24px !important; font-weight: 400 !important;"
                      >
                        What <b style="font-weight: 800;">ChatGPT</b> says about your robots.txt file?
                        <!-- <feather-icon
                          size="21"
                          icon="HelpCircleIcon"
                          style="color: #A7AAB9;"
                          class="mb-1"
                        /> -->
                      </h2>
                      <div class="mb-0">
                        <b-overlay
                          :show="sendToChatGPT"
                          :variant="'transparent'"
                          :opacity="0.85"
                          :blur="'2px'"
                          style="width: 100%;"
                        >
                          <b-form-textarea
                            class="suggest-robots pt-0"
                            plaintext
                            :value="suggestRobots"
                            rows="6"
                            max-rows="240"
                          />
                        </b-overlay>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              <!-- </b-card-body>
            </b-card> -->
          </b-col>
        </b-row>
      </div>
      <div v-else-if="BtnSuspiciousText">
        <b-row
          style="border: 1px solid rgb(240, 241, 243); border-radius: 5px; height: 122px;"
          class="mt-0 mb-2 mx-0"
        >
          <b-col
            md="12"
            class="pr-0"
          >
            <h1
              class="mt-1 mb-0"
              style="font-weight: 700;"
            >
              Suspicious Text
            </h1>
          </b-col>
          <b-col
            md="12"
            class="mt-25"
          >
            <h2
              class="float-left mb-0 w-100"
              style="font-weight: 700; color: #7838ff !important; text-align: left; font-size: 28px !important;"
            >
              {{ totalErrorsSuspicious ? totalErrorsSuspicious : 0 }}
            </h2>
            <span
              class=" float-left"
              style="color: #656B85;"
            >In all pages</span>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12" :style="loaderDataErrorsApi ? 'min-height: 1500px;' : ''">
            <b-row class="pb-2">
              <b-overlay
                :show="loaderDataErrorsApi"
                rounded="xl"
                no-fade
                style="width: 100%;"
              >
                <div class="d-flex mb-1">
                  <b-button
                    :variant="'primary'"
                    :class="'px-1 py-0 mr-1'"
                    pill
                    :style="'color: #2a2e30 !important; border: 0px !important; background: #e3e3e3 !important;'"
                    v-for="(selected, index) in optionsStrings"
                    :key="index"
                  >
                    <div style="display: block; margin-top: 5px;">
                      <span>
                        {{ selected }}
                      </span>
                      <b-dropdown
                        id="dropdown-grouped"
                        :variant="'outline-secondary'"
                        dropdown
                        class="btn-dropdown-strings btn-icon p-0 m-0 dropdown-icon-wrapper"
                        style="background: transparent; border: 0px !important; position: relative; top: -1px;"
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle"
                            style="color: #777d94 !important;"
                          />
                        </template>
                        <b-dropdown-item-button @click.prevent="eliminarString(selected)">
                          Remove
                        </b-dropdown-item-button>
                      </b-dropdown>
                    </div>
                  </b-button>
                  <b-button
                    :variant="'primary'"
                    :class="'px-1 py-50 mr-1'"
                    pill
                    :style="'color: #2a2e30 !important; border: 0px !important; background: #e3e3e3 !important;'"
                    v-b-tooltip.hover.top="'Add Suspicious'"
                    @click.prevent="showOpenStringsSearch = true"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      style="color: #777d94 !important;"
                    />
                  </b-button>
                </div>

                <b-table-simple
                  small
                  responsive
                  class="vgt-table tableErrors"
                  :style="loaderDataErrorsApi ? 'min-height: 1500px;' : ''"
                  v-if="rowsTools.length >= 1"
                >
                  <b-thead>
                    <b-tr>
                      <b-th class="thProTableErrors">PRO</b-th>
                      <b-th class="thPageTableErrors">Page</b-th>
                      <b-th class="thStatusTableErrors">Status</b-th>
                      <b-th>Suspicious Text</b-th>
                      <b-th>Location</b-th>
                      <b-th>Source</b-th>
                      <!-- <b-th class="thHistoryTableErrors">History</b-th> -->
                      <b-th class="thBtnTableErrors"></b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody v-if="rowsTools.length === 0">
                    <b-tr>
                      <b-td>
                        No pages
                      </b-td>
                    </b-tr>
                  </b-tbody>
                  <b-tbody v-for="(data, index) in rowsTools" :key="index" :style="index % 2 ? 'background: #fff !important;' : 'background: rgb(240, 241, 243) !important;'" v-else>
                    <b-tr v-for="(dataList, indexList) in data.list" :key="indexList" >
                      <b-th :rowspan="data.list.length" v-if="indexList === 0" class="tdProTableErrors" style="vertical-align: top !important;">
                        <b-button
                          style="background: transparent !important; border: 0px; position: absolute !important; left: -11px !important; margin-top: 5px; display: inline !important;"
                          target="_blank"
                          :href="data.page"
                          class="p-0 ml-75 eyeBtns linkURL pl-25"
                        >
                          <feather-icon
                            icon="ExternalLinkIcon"
                            class="cursor-pointer eyeBtns"
                            style="color: #656b85 !important;"
                            size="20"
                          />
                        </b-button>
                        <b-button
                          style="background: transparent !important; border: 0px; display: inline !important;"
                          @click.prevent.stop="showOpenSidebarProPages = true"
                          class="p-0 ml-0"
                          v-if="$route.name !== 'shared-site'"
                        >
                          <b-img-lazy
                            v-if="data.pro"
                            fluid
                            :src="proIcon"
                            alt="pro page img"
                            width="17"
                            height="16"
                            class=""
                            style="margin-left: 49px !important;"
                            loading="lazy"
                            @click.prevent.stop="showOpenSidebarProPages = true"
                          />
                          <b-img-lazy
                            v-else
                            fluid
                            :src="proIconNone"
                            alt="pro page img"
                            width="17"
                            height="16"
                            class=""
                            style="margin-left: 49px !important;"
                            loading="lazy"
                            @click.prevent.stop="showOpenSidebarProPages = true"
                          />
                        </b-button>
                        <b-button
                          style="background: transparent !important; border: 0px; display: inline !important;"
                          class="p-0 ml-0"
                          v-else-if="$route.name === 'shared-site'"
                        >
                          <b-img-lazy
                            v-if="data.pro"
                            fluid
                            :src="proIcon"
                            alt="pro page img"
                            width="17"
                            height="16"
                            class=""
                            style="margin-left: 49px !important;"
                            loading="lazy"
                          />
                          <b-img-lazy
                            v-else
                            fluid
                            :src="proIconNone"
                            alt="pro page img"
                            width="17"
                            height="16"
                            class=""
                            style="margin-left: 49px !important;"
                            loading="lazy"
                          />
                        </b-button>
                      </b-th>
                      <b-th :rowspan="data.list.length" v-if="indexList === 0" class="tdPageTableErrors" style="vertical-align: top !important;">
                        <span class="ml-0" style="font-weight: 400 !important; font-size: 14px !important;">{{ data.title ? data.title.substring(0, 60) + (data.title.length > 60 ? '...' : '') : data.title.substring(0, 60) + (data.title.length > 60 ? '...' : data.title) }}</span><br>
                        <span class="ml-0" style="text-overflow: ellipsis !important; white-space: nowrap; width: 435px; font-weight: 300 !important; font-size: 14px !important; overflow: hidden; display: block; color: #A7AAB9;">{{ data.title === 'home' ? 'Homepage' : data.url }}</span>
                      </b-th>
                      <b-td class="tdStatusTableErrors">
                        <feather-icon
                          v-if="!dataList.exist"
                          icon="CheckIcon"
                          class="mr-50"
                          style="color: #A7AAB9;"
                          size="20"
                        />
                        <feather-icon
                          v-else
                          icon="XIcon"
                          class="mr-50"
                          style="color: #FC7D7D;"
                          size="20"
                        />
                      </b-td>
                      <b-td class="">
                        {{ dataList.content }}
                      </b-td>
                      <b-td class="">
                        {{ dataList.location }}
                      </b-td>
                      <b-td class="">
                        {{ dataList.source }}
                      </b-td>
                      <!-- <b-td class="tdHistoryTableErrors">
                        <feather-icon
                          v-if="dataList.pro"
                          icon="TrendingUpIcon"
                          class="mr-50 cursor-pointer"
                          style="color: #30CDFF;"
                          size="20"
                        />
                        <feather-icon
                          v-else
                          icon="TrendingUpIcon"
                          class="mr-50 cursor-pointer"
                          style="color: #A7AAB9"
                          size="20"
                        />
                      </b-td> -->
                      <b-td class="tdBtnTableErrors">
                        <b-button
                          v-if="dataList.btn === 'Fix this'"
                          variant="outline-secondary float-right"
                          class="p-50 mr-1"
                          style="font-weight: 700; min-width: 102px;"
                          pill
                          @click.prevent="$route.name !== 'shared-site' ? functionFixPage(dataList) : openRegister()"
                        >
                          {{ dataList.btn }}
                        </b-button>
                        <b-button
                          v-else
                          variant="outline-light float-right"
                          class="p-50 mr-1"
                          style="font-weight: 700; width: max-content; min-width: 102px; border: 1px solid #A7AAB9; color: #A7AAB9; cursor: no-drop !important;"
                          pill
                        >
                          {{ dataList.btn }}
                        </b-button>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-overlay>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="BtnRedirections">
        <b-row class="mt-2">
          <b-col md="12">
            <!-- <h4 style="text-align: center;">Coming soon...</h4> -->
             <redirections />
          </b-col>
        </b-row>
      </div>
      <div v-else-if="BtnScreenshots">
        <b-row class="mt-2">
          <b-col md="12">
            <screenshot :url-view-site.sync="urlSiteScreenshot" :url-view-site-converter.sync="urlConvertScreenshot" :days-w-screenshots.sync="arrayWScreenshotsDays" :idsite="siteObj.length >= 1 ? siteObj[0] : ''" />
            <!-- <shared-screenshots /> -->
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BAvatar, BImgLazy, BAlert, BOverlay, BFormGroup, BFormTextarea,
  BTabs, BTab, BImg, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BModal, BBadge, BProgress, BFormInput, BSpinner, BListGroup, BListGroupItem, BPagination, BFormSelect, BFormCheckbox,
  BTable, BTableSimple, BThead, BTbody, BTr, BTd, BLink, BTh, VBTooltip, BDropdownItemButton,
} from 'bootstrap-vue'
import Screenshot from '@/views/apps/screenshots/Screenshot.vue'
import SharedScreenshots from '@/views/apps/screenshots/SharedScreenshots.vue'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Calendar from 'v-calendar/lib/components/calendar.umd'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import StringsSearch from '../StringsSearch.vue'
import Redirections from './Redirections.vue'
import FixError from '../SidebarFixError.vue'
import RegisterSidebar from '@/views/pages/authentication/RegisterSidebar.vue'
import DeleteStringsSearch from '../DeleteStringsSearch.vue'
import screenshotsRepository from '@/views/apps/screenshots/services'
import { $themeColors } from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'

import moment from 'moment'
import { db, auth, firebase, storage, dbErrors } from '@/firebase'

export default {
  name: 'Tools',
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BAvatar,
    BImgLazy,
    BAlert,
    BOverlay,
    BFormGroup,
    BFormTextarea,
    BTabs,
    BTab,
    BImg,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BModal,
    BBadge,
    BProgress,
    BFormInput,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BTable,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BLink,
    BTh,
    BDropdownItemButton,
    vSelect,
    StringsSearch,
    DeleteStringsSearch,
    RegisterSidebar,
    FixError,
    Redirections,

    // calendar
    Calendar,
    DatePicker,

    // charts
    VueApexCharts,

    // table
    VueGoodTable,
    Screenshot,
    SharedScreenshots,
  },
  data() {
    return {
      momentVar: moment,
      BtnScreenshots: true,
      BtnRedirections: false,
      BtnSuspiciousText: false,
      BtnImageOptimization: false,
      BtnRobots: false,
      robotErrorImg: require('@/assets/images/pages/robot-thumb.svg'),
      wordpressIcon: require('@/assets/images/icons/i-wordpress.svg'),
      redirectionsIcon: require('@/assets/images/icons/redirections-rep.svg'),
      warningIcon: require('@/assets/images/icons/i-warning.svg'),
      // sources
      errSitesImg: require('@/assets/images/pages/robot-thumb.svg'),
      proIcon: require('@/assets/images/icons/i-pro-badge.svg'),
      proIconNone: require('@/assets/images/icons/i-pro-badge-none.svg'),
      refreshIcon: require('@/assets/images/icons/i-refesh.svg'),
      sslSuccessIcon: require('@/assets/images/icons/i-fixed-issues-success.svg'),
      // websiteIcon: require('@/assets/images/icons/i-website.svg'),
      loaderDataErrorsApi: false,
      showOpenDeleteStringsSearch: false,
      showOpenStringsSearch: false,
      showOpenRegisterSidebar: false,

      // pages site
      selectedPage: { title: 'Select page' },
      optionPages: [{ title: 'Select page' }],
      rowError: '',
      showOpenFixError: false,
      urlSiteScreenshot: '',
      urlConvertScreenshot: '',
      arrayWScreenshotsDays: [],

      // date range screenshots
      rangeScreenshots: {
        start: new Date(2020, 0, 1),
        end: new Date(2020, 0, 5)
      },
      attributes: [
        {
          dot: true,
          dates: [
            new Date(2018, 0, 1), // Jan 1st
            new Date(2018, 0, 10), // Jan 10th
            new Date(2018, 0, 22), // Jan 22nd
          ],
        },
      ],

      // graph
      revenue: {},
      revenueComparisonLine: {
        series: [
          {
            name: 'This Month',
            data: [45000, 47000, 44800, 47500, 45500, 48000, 46500, 46600],
          },
          {
            name: 'Last Month',
            data: [46000, 48000, 45500, 46600, 44500, 46500, 45000, 45000],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            offsetX: -10,
            sparkline: { enabled: true },
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 12],
            width: [4, 3],
          },
          legend: {
            show: false,
          },
          colors: ['#30CDFF', '#ebe9f1'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: [$themeColors.primary, '#ebe9f1'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: ['01', '05', '09', '13', '17', '21', '26', '31'],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(0)}` : val
              },
            },
          },
          grid: {
            borderColor: '#e7eef7',
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },

      // table
      columnsContent: [
        {
          label: 'Image',
          field: 'image',
          sortable: false,
        },
        {
          label: '',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Optimized',
          field: 'optimized',
          sortable: false,
        },
        {
          label: 'Savings',
          field: 'savings',
          sortable: false,
        },
        {
          label: 'Fix issue',
          field: 'history',
          sortable: false,
        },
        {
          label: '',
          field: 'btn',
          sortable: false,
        },
      ],
      rowsContent: [
        {
          image: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sources%2Fi-thumb1.png?alt=media&token=d80050de-8ba7-4858-9991-42e1cccf44e0', name: 'https://example.com/wp-content/uploads/2019/05/example.jpg', domain: 'Original size: 71KB', pro: true, exist: false, inlinks: 2, optimized: '-', savings: 0, history: '', btn: 'Optimize',
        },
        {
          image: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sources%2Fi-thumb2.png?alt=media&token=c516d96a-99be-4853-912e-b3298b14cf59', name: 'https://example.com/wp-content/uploads/2019/05/example.jpg', domain: 'Original size: 71KB', pro: true, exist: false, inlinks: 3, optimized: '-', savings: 0, history: '', btn: 'Optimize',
        },
        {
          image: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sources%2Fi-thumb3.png?alt=media&token=bb0e5089-9dda-4cc1-a48f-4e75958413be', name: 'https://example.com/wp-content/uploads/2019/05/example.jpg', domain: 'Original size: 71KB', pro: true, exist: true, inlinks: 1, optimized: '23', savings: 74, history: '', btn: 'Optimize',
        },
        {
          image: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sources%2Fi-thumb4.png?alt=media&token=dd25b006-4606-453b-afc6-7a2118740dbb', name: 'https://example.com/wp-content/uploads/2019/05/example.jpg', domain: 'Original size: 71KB', pro: false, exist: true, inlinks: 3, optimized: '27', savings: 74, history: '', btn: 'Optimize',
        },
        {
          image: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sources%2Fi-thumb3.png?alt=media&token=bb0e5089-9dda-4cc1-a48f-4e75958413be', name: 'https://example.com/wp-content/uploads/2019/05/example.jpg', domain: 'Original size: 71KB', pro: false, exist: true, inlinks: 5, optimized: '27', savings: 86, history: '', btn: 'Optimize',
        },
        {
          image: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sources%2Fi-thumb2.png?alt=media&token=c516d96a-99be-4853-912e-b3298b14cf59', name: 'https://example.com/wp-content/uploads/2019/05/example.jpg', domain: 'Original size: 71KB', pro: false, exist: true, inlinks: 8, optimized: '23', savings: 31, history: '', btn: 'Optimize',
        },
      ],

      // table Tools
      rowsTools: [],
      optionsStrings: [],
      stringToDelete: '',
      totalErrorsSuspicious: 0,

      // table Screenshots
      columnsScreenshots: [
        {
          label: 'Page',
          field: 'url',
          sortable: false,
        },
        {
          label: 'Description',
          field: 'content',
          sortable: false,
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'tdScreenshotsAction',
          thClass: 'thScreenshotsAction'
        },
        {
          label: 'Time',
          field: 'time',
          sortable: false,
        },
      ],
      rowsScreenshots: [],

      // table Redirections
      columnsRedirections: [
        {
          label: 'Source URL',
          field: 'url',
          sortable: false,
        },
        {
          label: 'Target URL',
          field: 'content',
          sortable: false,
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ],
      rowsRedirections: [],

      // consume api vars
      loaderDataErrorsApi: true,
      changeerror: false,

      // robots
      showDelayRobots: true,
      sendToChatGPT: true,
      contentRobots: '',
      suggestRobots: '',
      existRobots: true
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['siteObj','contentVal','itemsPagesOverview','active'],
  watch: {
    BtnScreenshots(val) {
      if(val) {
        this.BtnRedirections = false
        this.BtnSuspiciousText = false
        this.BtnImageOptimization = false
        this.BtnRobots = false
        this.$route.name === 'shared-site' ? history.pushState(null, "", `?site=${this.$route.query.site}&section=tools&content=screen`) : history.pushState(null, "", `?section=tools&content=screen`)
      }
    },
    BtnRedirections(val) {
      if(val) {
        this.BtnScreenshots = false
        this.BtnSuspiciousText = false
        this.BtnImageOptimization = false
        this.BtnRobots = false
        this.getRedirections()
        this.$route.name === 'shared-site' ? history.pushState(null, "", `?site=${this.$route.query.site}&section=tools&content=redirect`) : history.pushState(null, "", `?section=tools&content=redirect`)
      }
    },
    BtnSuspiciousText(val) {
      if(val) {
        this.BtnScreenshots = false
        this.BtnRedirections = false
        this.BtnImageOptimization = false
        this.BtnRobots = false
        this.getSuspicious()
        this.$route.name === 'shared-site' ? history.pushState(null, "", `?site=${this.$route.query.site}&section=tools&content=suspT`) : history.pushState(null, "", `?section=tools&content=suspT`)
      }
    },
    BtnImageOptimization(val) {
      if(val) {
        this.BtnScreenshots = false
        this.BtnRedirections = false
        this.BtnSuspiciousText = false
        this.BtnRobots = false
        this.$route.name === 'shared-site' ? history.pushState(null, "", `?site=${this.$route.query.site}&section=tools&content=imgOp`) : history.pushState(null, "", `?section=tools&content=imgOp`)
      }
    },
    BtnRobots(val) {
      if(val) {
        this.BtnScreenshots = false
        this.BtnRedirections = false
        this.BtnSuspiciousText = false
        this.BtnImageOptimization = false
        this.$route.name === 'shared-site' ? history.pushState(null, "", `?site=${this.$route.query.site}&section=tools&content=robot`) : history.pushState(null, "", `?section=tools&content=robot`)
        this.getRobot()
      }
    },
    contentVal(val) {
      // console.log(val)
      if(val === 'screen') {
        this.BtnScreenshots = true
      } else if(val === 'redirect') {
        this.BtnRedirections = true
      } else if(val === 'suspT') {
        this.BtnSuspiciousText = true
      } else if(val === 'imgOp') {
        this.BtnImageOptimization = true
      } else if(val === 'robot') {
        this.BtnRobots = true
      }
    },
    active(val) {
      if(val) {
        if(this.BtnScreenshots) {
          this.$route.name === 'shared-site' ? history.pushState(null, "", `?site=${this.$route.query.site}&section=tools&content=screen`) : history.pushState(null, "", `?section=tools&content=screen`)
        } else if(this.BtnRedirections) {
          this.$route.name === 'shared-site' ? history.pushState(null, "", `?site=${this.$route.query.site}&section=tools&content=redirect`) : history.pushState(null, "", `?section=tools&content=redirect`)
        } else if(this.BtnSuspiciousText) {
          this.$route.name === 'shared-site' ? history.pushState(null, "", `?site=${this.$route.query.site}&section=tools&content=suspT`) : history.pushState(null, "", `?section=tools&content=suspT`)
        } else if(this.BtnImageOptimization) {
          this.$route.name === 'shared-site' ? history.pushState(null, "", `?site=${this.$route.query.site}&section=tools&content=imgOp`) : history.pushState(null, "", `?section=tools&content=imgOp`)
        } else if(this.BtnRobots) {
          this.$route.name === 'shared-site' ? history.pushState(null, "", `?site=${this.$route.query.site}&section=tools&content=robot`) : history.pushState(null, "", `?section=tools&content=robot`)
        }

        setTimeout(() => {
          if(this.$route.query.section) {
            if(this.$route.query.content) {
              this.contentVal = this.$route.query.content
            }
          }
        }, 1800)
      }
    },
    changeerror(val) {
      if(val) {
        this.getSuspicious()
        this.changeerror = false
      }
    },
    siteObj(val) {
      // console.log(val)
      if(val && val.length) {
        var urlDomain = val[0].domainFix
        var urlConvertDomain = new URL(urlDomain)
        var urlModificada = urlConvertDomain.host.replace(/\./g, "_");
        // console.log(urlModificada)
        this.urlSiteScreenshot = urlConvertDomain.protocol + '//' + urlConvertDomain.host
        this.urlConvertScreenshot = urlModificada
        // this.checkScreenshots()

        if(this.BtnRobots) {
          this.getRobot()
        }
      }
    }
  },
  methods: {
    functionFixPage(val) {
      this.rowError = val
      this.showOpenFixError = true
    },
    getRedirections() {
      this.loaderDataErrorsApi = false
      this.rowsRedirections = []

      // if(this.siteObj.length >= 1) {
      //   var URLactual = new URL(this.siteObj[0].domain);
      //   var URLdomain = URLactual.host;

      //   var obj = {
      //     url: URLdomain,
      //     typeRequest: 'sitios'
      //   }

      //   var config = {
      //     "url": "https://bhajsiv3j9.execute-api.us-east-2.amazonaws.com/prod/jsonsitios",
      //     "method": "POST",
      //     "timeout": 0,
      //     "headers": {
      //         "Content-Type": "text/plain"
      //     },
      //     "data": JSON.stringify(obj),
      //   };

      //   this.axios(config)
      //   .then(responseSite => {
      //     if(responseSite.data && responseSite.data !== 'Dont exist') {
      //       var obj =  {
      //         "typeRequest": "bitacoraErrores",
      //         "tipo": 12,
      //         "url": "",
      //         "idsite": responseSite.data
      //       }
      //       var config = {
      //         "url": "https://bhajsiv3j9.execute-api.us-east-2.amazonaws.com/prod/jsonsitios",
      //         "method": "POST",
      //         "timeout": 0,
      //         "headers": {
      //             "Content-Type": "text/plain"
      //         },
      //         "data": JSON.stringify(obj),
      //       };

      //       this.axios(config)
      //       .then(response => {
      //         // console.log(response.data.length)
      //         if(response.data.length) {
      //           response.data.forEach(item => {
      //             var URLactualAtom = new URL(item.url)
      //             this.rowsRedirections.push({
      //               name: URLactualAtom.pathname === '/' ? 'home' : URLactualAtom.pathname.substring(0, 25) + (URLactualAtom.pathname.length > 25 ? '...' : ''),
      //               domain: item.url,
      //               exist: item.isError,
      //               inlinks: 0,
      //               content: item.content,
      //               tlenght: item.content ? item.content.length : 0,
      //               history: '',
      //               btn: 'Edit'
      //             })
      //             this.loaderDataErrorsApi = false
      //           })
      //         } else {
      //           this.loaderDataErrorsApi = false
      //         }
      //       })
      //       .catch(error => {
      //         this.loaderDataErrorsApi = false
      //         console.log(error)
      //       })
      //     }
      //   })
      //   .catch(error => {
      //     console.log(error)
      //     this.loaderDataErrorsApi = false
      //   })
      // } else {
      //   this.loaderDataErrorsApi = false
      // }
    },
    getScreenshots() {
      this.loaderDataErrorsApi = false
      this.rowsScreenshots = []

      // if(this.siteObj.length >= 1) {
      //   var URLactual = new URL(this.siteObj[0].domain);
      //   var URLdomain = URLactual.host;

      //   var obj = {
      //     typeRequest: 'screenshots'
      //   }

      //   var config = {
      //     "url": "https://bhajsiv3j9.execute-api.us-east-2.amazonaws.com/prod/jsonsitios",
      //     "method": "POST",
      //     "timeout": 0,
      //     "headers": {
      //         "Content-Type": "text/plain"
      //     },
      //     "data": JSON.stringify(obj),
      //   };

      //   this.axios(config)
      //   .then(responseScreenshots => {
      //     if(responseScreenshots.data && responseScreenshots.data !== 'Dont exist') {
      //       var filterScreen = responseScreenshots.data.filter(ele => ele.sitio === URLdomain)
      //       if(filterScreen.length >= 1) {
      //         // console.log(filterScreen[0].screenshots)
      //         var arrayScreenshots = []
      //         filterScreen[0].screenshots.forEach(screenElement => {
      //           for(screen in screenElement) {
      //             // console.log(screenElement[screen].desktop)
      //             arrayScreenshots.push({
      //               url: screen,
      //               img: screenElement[screen].desktop ? screenElement[screen].desktop : screenElement[screen].tablet,
      //               time: screenElement[screen].date,
      //               description: screenElement[screen].triggeredBy
      //             })
      //           }
      //         });
              
      //         if(arrayScreenshots.length >= 1) {
      //           arrayScreenshots.forEach(eleScreen => {
      //             var URLactualAtom = new URL(eleScreen.url);
      //             // var URLeleDomain = URLele.host;
      //             this.rowsScreenshots.push({
      //               url: eleScreen.url,
      //               name: URLactualAtom.pathname === '/' ? '/home' : URLactualAtom.pathname.substring(0, 25) + (URLactualAtom.pathname.length > 25 ? '...' : ''),
      //               content: eleScreen.description ? eleScreen.description : '-',
      //               img: eleScreen.img,
      //               action: '',
      //               time: moment(eleScreen.time).format('MM-DD-YYYY hh:mm'),
      //             })
      //           })
      //           this.loaderDataErrorsApi = false
      //         } else {
      //           this.loaderDataErrorsApi = false
      //         }
      //       } else {
      //         this.loaderDataErrorsApi = false
      //       }
      //     } else {
      //       this.loaderDataErrorsApi = false  
      //     }
      //   })
      //   .catch(error => {
      //     this.loaderDataErrorsApi = false
      //     console.log(error)
      //   })
      // }
    },
    getSuspicious() {
      if(this.siteObj.length >= 1) {
        this.rowsTools = []
        this.loaderDataErrorsApi = true
        this.totalErrorsSuspicious = 0
        db.collection('Sites').doc(this.siteObj[0].id).get()
        .then(docS => {
          this.optionsStrings = docS.data().suspiciousText ? docS.data().suspiciousText : []
        })

        db.collection('Sites').doc(this.siteObj[0].id).collection('pages').where('status', 'not-in', [301,404,500]).where('isSitemap', '==', false).get()
        .then(docsExternals => {
          docsExternals.forEach(ele => {
            var listErrors = ele.data().errors && ele.data().errors.length ? ele.data().errors : []
            var filterErrors = listErrors.filter(ele => ele.idc === 15)
            var arrayErr = []
            
            filterErrors.forEach((itemP,index) => {
              var URLactualAtom = new URL(itemP.urlS)
              var existTitle = ''
              var isProPage = false
              var filterTitlePage = this.itemsPagesOverview.filter(ele => ele.cUrl === itemP.urlS)
              filterTitlePage.forEach(eleTitle => {
                existTitle = eleTitle.title
                isProPage = eleTitle.pro
              })

              arrayErr.push({
                id: index,
                idc: 15,
                idError: itemP.urlS,
                idsite: this.siteObj[0].id,
                idpage: ele.id,
                name: URLactualAtom.pathname === '/' ? 'home' : existTitle ? existTitle.substring(0, 60) + (existTitle.length > 60 ? '...' : '') : URLactualAtom.pathname.substring(0, 60) + (URLactualAtom.pathname.length > 60 ? '...' : ''),
                nameFilter: URLactualAtom.pathname === '/' ? 'a' : existTitle ? existTitle.substring(0, 60) + (existTitle.length > 60 ? '...' : '') : URLactualAtom.pathname.substring(0, 60) + (URLactualAtom.pathname.length > 60 ? '...' : ''),
                fixUrl: URLactualAtom.pathname === '/' ? 'home' : URLactualAtom.pathname.substring(0, 60) + (URLactualAtom.pathname.length > 60 ? '...' : ''),
                domain: itemP.urlS,
                url: itemP.url,
                pro: isProPage,
                exist: itemP.iE,
                inlinks: 0,
                content: typeof itemP.cont === 'object' ? itemP.cont[0] : itemP.cont,
                tlenght: itemP.cont ? itemP.cont.length : 0,
                location: itemP.est ? itemP.est : '',
                source: itemP.oS.foundIn ? itemP.oS.foundIn : '',
                history: '',
                btn: itemP.iE ? 'Fix this' : 'Edit'
              })

              itemP.iE ? this.totalErrorsSuspicious += 1 : null
            })

            arrayErr.forEach(eleT => {
              var filterTitlePage = arrayErr.filter(ele => ele.domain === eleT.domain)
              var filterTitleList = this.rowsTools.filter(ele => ele.page === eleT.domain)
              if(filterTitleList.length === 0) {
                this.rowsTools.push({
                  pro: eleT.pro,
                  page: eleT.domain,
                  url: eleT.url,
                  nameFilter: eleT.nameFilter,
                  title: eleT.name,
                  list: filterTitlePage
                })
              }
            })
          })

          var condicionParaOrdenar = (pageA, pageB) => {
            return pageB.pro - pageA.pro;
          }
          this.rowsTools.sort(condicionParaOrdenar)
          var condicionParaOrdenar2 = (pageA, pageB) => {
            if(pageA.pro && pageB.pro) {
              if(pageA.nameFilter.toLowerCase() > pageB.nameFilter.toLowerCase()) {
                return 1;
              }
              if(pageA.nameFilter.toLowerCase() < pageB.nameFilter.toLowerCase()) {
                return -1;
              }
              return 0;
            }
          }
          this.rowsTools.sort(condicionParaOrdenar2)
          // this.functionSelectedFilteredPages()
          this.loaderDataErrorsApi = false
        })
      }
    },
    eliminarString(payload) {
      this.showOpenDeleteStringsSearch = true
      this.stringToDelete = payload
    },
    openRegister() {
      // console.log('open register')
      this.showOpenRegisterSidebar = true
    },
    getRobot() {
      this.showDelayRobots = true
      this.sendToChatGPT = true

      if(!this.contentRobots && !this.suggestRobots && (this.siteObj && this.siteObj.length)) {
        var urlDomain = this.siteObj[0].domainFix
        var urlConvertDomain = new URL(urlDomain)
        // var urlModificada = urlConvertDomain.host.replace(/\./g, "_");
        var urlSiteDomain = urlConvertDomain.protocol + '//' + urlConvertDomain.host
        var payloadReq = {
          type: 'only-text',
          url: urlSiteDomain + '/robots.txt'
        }

        var config = {
          'url': 'https://app-facm3chzha-uc.a.run.app/crawl-url',
          'method': 'POST',
          'maxBodyLength': Infinity,
          'headers': {
          'Content-Type': 'application/json',
          },
          'data': JSON.stringify(payloadReq)
        };
    
        this.axios(config)
        .then((response) => {
          console.log('response robots: ', payloadReq.url)
          var body = response.data
          // console.log(body.data.allText)
          this.contentRobots = body.data.allText
          this.showDelayRobots = false

          if(body.data.allText) {
            var itemsBuilds = []
            var itemsBuildsCache = []
            var cadenaPrompt = []
            var arrayVariables = []
            var sendToApi = ''
            dbErrors.collection('IA_form_builder').doc('tools').get()
            .then(doc => {
              if(doc.data().form) {
                itemsBuilds = doc.data().form.filter(ele => ele.typeError === 'robot')
                this.itemsBuildsCache = doc.data().form.filter(ele => ele.typeError === 'robot')
              }

              dbErrors.collection('IA_form_builder').doc('prompt').get()
              .then(docPrompt => {
                var promtObj = docPrompt.data().prompt.filter(ele => ele.typeError === 'robot')
                if(promtObj.length >= 1) {
                  const cadena = promtObj[0].logic
                  cadenaPrompt = cadena
                  // console.log(cadena);

                  // Utilizando expresiones regulares con el modificador global para encontrar todas las palabras entre paréntesis
                  const resultados = cadena.match(/\((.*?)\)/g)

                  if(resultados) {
                    const palabrasEntreParentesis = resultados.map(resultado => resultado.slice(1, -1))
                    arrayVariables = palabrasEntreParentesis
                    // console.log(palabrasEntreParentesis)
                    if(palabrasEntreParentesis.includes('web_content')) {
                      itemsBuilds.push({labelField: 'Web content', textField: '', typeField: 'textarea', variableField: 'web_content'})
                    }
                  } else {
                    console.log("No se encontró variables en el prompt.")
                  }
                } else {
                  cadenaPrompt = []
                  arrayVariables = []
                }

                this.sendToChatGPT = true
                // this.showDelay = true
                var arrayFieldsAnswer = itemsBuilds
                var web_content = ''
                var robot_content = ''

                var responseObjArray = []
                arrayVariables.forEach(ele => {
                  arrayFieldsAnswer.forEach((item, index) => {
                    if(item.variableField === ele) {
                      if(item.variableField === 'web_content') {
                        web_content = this.contentRobots
                      }

                      if(item.variableField === 'robot_content') {
                        robot_content = this.contentRobots
                      }

                      // console.log(index)
                      var responseField = ''
                      if(item.typeField === 'checkbox') {
                        var variableCounter = 0
                        item.textField.forEach(ele => {
                          variableCounter += 1
                          // console.log(variableCounter,item.textField.length)
                          responseField += (variableCounter === 1 ? '(' : '') + ele + (variableCounter === item.textField.length ? '' : ', ' ) + (variableCounter === item.textField.length ? ')' : '')
                        })
                      } else {
                        item.variableField === 'robot_content' ? responseField = `"`+robot_content+`"` : item.variableField === 'web_content' ? responseField = `"`+web_content+`"` : responseField = item.textField
                      }

                      responseObjArray.push({response: responseField})
                    }
                  })
                })
                
                if(cadenaPrompt.length >= 1) {
                  // this.sendToChatGPT = true
                  const partesNoParentesis = cadenaPrompt.split(/\([^()]*\)/)
                  var counterFor = 0
                  partesNoParentesis.forEach(element => {
                    if(responseObjArray[counterFor]) {
                      sendToApi += element + responseObjArray[counterFor].response
                      counterFor += 1
                    } else {
                      sendToApi += element
                    }
                  })
                  this.suggestRobots = ''

                  var config = {
                    method: 'POST',
                    // url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/chat',
                    url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/chat-bedrock-nova-lite',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    data: JSON.stringify({messages: sendToApi}),
                  }

                  this.axios(config)
                  .then(response => {
                    // console.log(response.data.response)
                    this.suggestRobots = response.data.response
                    this.sendToChatGPT = false
                  })
                  .catch(e => {
                    console.log(e)
                    this.sendToChatGPT = false
                  })
                } else {
                  sendToApi = 'No prompt created'
                  this.suggestRobots = ''
                  this.showDelayRobots = false
                  this.sendToChatGPT = false
                }
              })
            })
          } else {
            this.contentRobots = 'No robots.txt file'
            this.existRobots = false
            this.showDelayRobots = false
          }
          // this.showDelayRobots = false
          // this.sendToChatGPT = true
        })
        .catch(e => {
          // this.showDelayRobots = false
          // this.sendToChatGPT = true
          this.existRobots = false
          this.showDelayRobots = false
          console.log('Error response robots ', e)
        })
      } else {
        this.showDelayRobots = false
        this.sendToChatGPT = false
      }
    }
    // async checkScreenshots() {
    //   if(!this.urlConvertScreenshot) {
    //     console.error('The param site is required.')
    //     return
    //   }

    //   try {
    //     const response = await screenshotsRepository.actions.getScreenshots(this.urlConvertScreenshot)
    //     const resProPages = await screenshotsRepository.actions.getPages(response.pages[response.home])
    //     this.proPagesList = resProPages?.pages
    //     this.allData = response
    //     this.daysWithData = response.daysWithData
    //     this.currentDate = response.moreRecent
    //     this.urlSite = response.pages[response.home]
    //     this.pages = response.pages
    //     this.moreRecentDate = this.allData?.moreRecent
    //     console.log(this.daysWithData)
    //   } catch (error) {
    //     console.error('Error al obtener capturas de pantalla:', error)
    //   }
    // }
  },
  created() {
    // setTimeout(() => {
    //   this.getScreenshots()
    // }, 1500)
    this.existRobots = true
    this.changeerror = false
    this.showOpenRegisterSidebar = false
    setTimeout(() => {
      if(this.$route.query.section) {
        if(this.$route.query.content) {
          this.contentVal = this.$route.query.content
        }
      }
    }, 1800)

    // console.log(this.contentVal)
    if(this.contentVal === 'screen') {
      this.BtnScreenshots = true
    } else if(this.contentVal === 'redirect') {
      this.BtnRedirections = true
    } else if(this.contentVal === 'suspT') {
      this.BtnSuspiciousText = true
    } else if(this.contentVal === 'imgOp') {
      this.BtnImageOptimization = true
    } else if(this.contentVal === 'robot') {
      this.BtnRobots = true
    }
  }
}
</script>

<style>
.content-robots {
  overflow-y: hidden !important;
}
.suggest-robots {
  overflow-y: hidden !important;
}
.thScreenshotsAction {
  width: 110px !important;
  max-width: 110px !important;
  min-width: 110px !important;
/* padding: 15px 0px !important; */
}
.tdScreenshotsAction {
  width: 110px !important;
  max-width: 110px !important;
  min-width: 110px !important;
  padding: 15px 0px !important;
}
.collapse-icon [aria-expanded="false"]::after {
  transform: rotate(-0deg) !important;
}
.collapse-icon [aria-expanded="true"]::after {
  transform: rotate(-180deg) !important;
}
.filteredPages .vs__selected {
  background: #E3E6F2 !important;
  border: 1px solid #CFD3E5 !important;
  color: #656B85 !important;
  border-radius: 8px !important;
}
.filteredPages .vs__selected-options .vs__search {
  min-width: 40px !important;
}
.filteredPages li {
  padding: 5px !important;
}
/* .filteredPages .vs__selected .vs__deselect {
  display: none !important;
} */
.custom-control-gray label::before {
  border-color: #aeaeae !important;
  background-color: #aeaeae !important;
}
input[class="custom-control-input"] + label::before {
  border-color: #656b85 !important;
  /* border: 1px solid #656b85 !important; */
  /* background-color: #656b85 !important; */
  /* -webkit-box-shadow: 0px 0px 0px 1px rgba(255,0,0,1) !important; */
  /* -moz-box-shadow: 0px 0px 0px 1px rgba(255,0,0,1) !important; */
  /* box-shadow: 0px 0px 0px 1px rgba(255,0,0,1) !important; */
}
.tablePagesErrors th::before {
  display: none !important;
}
.tablePagesErrors th::after {
  display: none !important;
}
.tablePagesErrors .vgt-global-search__actions {
  margin: auto;
  width: 100%;
}
.tablePagesErrors .vgt-wrap__actions-footer {
  border: none;
}
thead .vgt-checkbox-col {
  width: 10px !important;
  max-width: 20px !important;
}
tbody .vgt-checkbox-col {
  width: 10px !important;
  max-width: 20px !important;
}
thead .vgt-checkbox-col input {
  display: none;
}
.thTableSites {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
}
.tdTableSites {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  /* margin: 20px !important; */
}
.vgt-table.bordered th {
  background: #fff !important;
  border: 1px solid transparent !important;
  margin-top: 20px !important;
  /* font-size: 17px !important; */
  /* color: #181E38 !important; */
}
.vgt-left-align {
    border: 1px solid transparent !important;
}
.vgt-row-header
.triangle {
    display: none !important;
}
.tableOverview {
  table-layout: auto;
  display: table;
  width: 100%;
}
/* .thTableSitesSelectShortOverviewErrors {
  background: #fff !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  width: 51px !important;
  max-width: 53px !important;
  font-weight: 300;
  padding: 0px !important;
  font-size: 17px !important;
  text-transform: capitalize !important;
} */
.tdTableSitesSelectShortOverviewErrors {
  background: transparent !important;
  border: 1px solid transparent !important;
  /* border-bottom: 1px solid #dcdfe6 !important; */
  /* padding: 0px !important; */
  width: 51px !important;
  max-width: 53px !important;
  padding: 0px !important;
  /* text-align: right; */
  font-size: 17px !important;
}
.thTableSitesShortOverviewErrors {
  background: #fff !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  width: 100px !important;
  max-width: 100px !important;
  min-width: 100px !important;
  vertical-align: middle !important;
  font-weight: 300;
  /* text-align: right !important; */
  padding: .75em .0em !important;
  font-size: 17px !important;
  text-transform: capitalize !important;
}
.tdTableSitesShortOverviewErrors {
  background: transparent !important;
  border: 1px solid transparent !important;
  /* border-bottom: 1px solid #dcdfe6 !important; */
  /* padding: 0px !important; */
  width: 100px !important;
  max-width: 100px !important;
  min-width: 100px !important;
  vertical-align: middle !important;
  padding: .10em .0em !important;
  text-align: center !important;
  font-size: 17px !important;
}
.thTableSitesShortOverviewErrorsPro {
  background: #fff !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  width: 100px !important;
  max-width: 100px !important;
  min-width: 100px !important;
  vertical-align: middle !important;
  font-weight: 300;
  /* text-align: right !important; */
  padding: .75em 0em !important;
  font-size: 17px !important;
  text-transform: capitalize !important;
}
.tdTableSitesShortOverviewErrorsPro {
  background: transparent !important;
  border: 1px solid transparent !important;
  /* border-bottom: 1px solid #dcdfe6 !important; */
  /* padding: 0px !important; */
  width: 100px !important;
  max-width: 100px !important;
  min-width: 100px !important;
  vertical-align: middle !important;
  padding: .10em 0em !important;
  text-align: center;
  /* text-align: right; */
  font-size: 17px !important;
}
/* .thTableSitesPageShortOverviewErrors {
  background: #fff !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  width: 100% !important;
  max-width: 100% !important;
  font-weight: 300;
  font-size: 17px !important;
  padding: .75em 0em !important;
  text-transform: capitalize !important;
}
.tdTableSitesPageShortOverviewErrors {
  background: transparent !important;
  border: 1px solid transparent !important;
  width: 100% !important;
  max-width: 100% !important;
  text-overflow: ellipsis !important;
  padding: .10em .90em !important;
  font-size: 17px !important;
  line-height: normal;
} */
.tdStatusTableErrors {
  text-align: center !important;
  vertical-align: middle !important;
}
.tdHistoryTableErrors {
  text-align: center !important;
  vertical-align: middle !important;
}
table.vgt-table.tableErrors {
  font-size: 17px !important;
}
table.vgt-table.tableContent {
  font-size: 17px !important;
}
.errors-card-stonk
.card-body {
  margin: auto !important;
  text-align: center !important;
}
.item-dropdown-action-sites:hover {
  background: none !important;
  background-color: none !important;
  color: #000 !important;
}
.dropdown-sites button {
  padding: 0px !important;
}
.dropdown-sites button:hover {
  background: transparent !important;
}
#modalHistory-lg___BV_modal_body_ {
  padding: 0px;
}
/* .linkURL {
  display: none !important;
} */
table.vgt-table tr.clickable .linkURL {
  display: inline !important;
  padding-top: 12px !important;
}
table.vgt-table tr:hover .linkURL svg {
  filter: invert(63%) sepia(20%) saturate(9%) hue-rotate(317deg) brightness(98%) contrast(100%);
}
table.b-table-selectable tr.b-table-row-selected:hover .linkURL svg {
  /* color: rgb(170, 170, 170) !important; */
  /* fill: #000 !important; */
  filter: invert(63%) sepia(20%) saturate(9%) hue-rotate(317deg) brightness(98%) contrast(100%);
}
table.b-table-selectable tr:hover .linkURL svg {
  filter: invert(63%) sepia(20%) saturate(9%) hue-rotate(317deg) brightness(98%) contrast(100%);
}
.externalLinkContent:hover {
  color: #7838FF !important;
}
.externalLinkContent:hover .linkURL svg {
  filter: invert(63%) sepia(20%) saturate(9%) hue-rotate(317deg) brightness(98%) contrast(100%);
}
.linkURL:hover svg {
  filter: invert(63%) sepia(20%) saturate(9%) hue-rotate(317deg) brightness(98%) contrast(100%);
}
.card-header:hover .headerExternalLink .ExternalLinkURLHeader svg {
/* .headerExternalLink:hover .ExternalLinkURLHeader svg { */
  filter: invert(63%) sepia(20%) saturate(9%) hue-rotate(317deg) brightness(98%) contrast(100%);
}
/* table.vgt-table tr.clickable:hover .vgt-checkbox-col input {
  margin-left: 20px;
} */
.vgt-checkbox-col input {
  margin-left: 8px;
}
.tabsViewErrors
.nav-tabs
.nav-link.active {
  color: #232C51 !important;
  font-family: 'Hurme Geometric Sans 3';
  font-weight: 700;
  padding-bottom: 4px;
  font-size: 19px;
  background: none !important;
}
.tabsViewErrors
.nav-tabs
.nav-link.active::after {
  background: transparent linear-gradient(90deg, #7838ff 0%, #30CDFF 100%) !important;
}
.tabsViewErrors
.nav-tabs
.nav-link {
  color: #232C51 !important;
  /* font-family: ; */
  padding-bottom: 0px;
  font-size: 19px;
}
.allSelectedRows1 .vgt-checkbox-col input:checked {
  /* background-color: red !important; */
  accent-color: #8c8c8c !important;
  color: #fff !important;
}
.allSelectedRows2 .vgt-checkbox-col input:checked {
  /* background-color: red !important; */
  accent-color: #be35ff !important;
  color: #fff !important;
}
.eyeBtn {
  color: rgb(198, 198, 198)
}
.eyeBtn:hover {
  color: #A7AAB9 !important;
}
.listNumberErrors th.vgt-left-align {
  display: none !important;
}
.btn-fix-all-errors:hover {
  border: 1px solid transparent !important;
}
.refreshIcon {
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  width: 18px;
  height: 20px;
  /* margin:-60px 0 0 -60px; */
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
.listExternalPages .card-body {
  padding: 0px !important;
}
.listExternalPages .card-header {
  padding: 1rem 2.8rem 1rem 0.5rem !important;
}
.vs__actions {
  cursor: pointer !important;
}
.button-tab-unselect {
  background-color: #e3e3e3 !important;
}
.button-tab-unselect:hover {
  background-color: #f6f6f6 !important;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
/* input[type=checkbox]:checked {
  background-color: red !important;
  color: red !important;
} */
/* input[type='checkbox']:checked {
    accent-color: #8c8c8c !important;
    color: #fff !important;
} */
/* body  .table.b-table>tfoot>tr>[aria-sort=none], body  .table.b-table>thead>tr>th[aria-sort=none] { */
  /* background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='70' height='70' view-box='0 0 70 70' preserveAspectRatio='none'%3e%3cpath fill='gray' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='gray' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e")!important;
  width: 20px !important;
  height: 20px !important; */
  /* font-weight: bold;
} */
/* body  .table.b-table>tfoot>tr>[aria-sort=ascending], body .table.b-table>thead>tr>th[aria-sort=ascending] { */
  /* background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='gray' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='gray' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e")!important;
  width: 20px !important;
  height: 20px !important; */
  /* font-weight: bold;
  opacity: 1;
content: "\2191";
} */
/* body  .table.b-table>tfoot>tr>[aria-sort=descending], body .table.b-table>thead>tr>th[aria-sort=descending] { */
  /* background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='gray' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='gray' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e")!important;
  width: 20px !important;
  height: 20px !important; */
  /* font-weight: bold;
  opacity: 1;
content: "\2193"; */
/* } */
/* table.table.b-table>thead>tr>th[aria-sort=none]::after,
table.table.b-table>tfoot>tr>th[aria-sort="none"]::after {
  opacity: 1 !important;
  content: "\2191" !important;
} */
/* table.table.b-table > thead > tr > th[aria-sort="ascending"]::after,
table.table.b-table > tfoot > tr > th[aria-sort="ascending"]::after { */
  /* opacity: 1 !important;
  content: "\2191" !important; */
  /* right: 6px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin-top: -7px;
  border-bottom: 5px solid #cfd3e0;
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  box-sizing: border-box;
} */
/* table.table.b-table > thead > tr > th[aria-sort][aria-sort="descending"]::before,
table.table.b-table > tfoot > tr > th[aria-sort][aria-sort="descending"]::before { */
  /* opacity: 1 !important;
  content: "\2193" !important; */
  /* right: 6px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin-bottom: -7px;
  border-top: 5px solid #cfd3e0;
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  box-sizing: border-box;
} */

body .tableOverview .table.b-table>thead>tr>th[aria-sort=none] {
  background-position: right .375rem center !important;
  padding-right: calc(.75rem + .65em) !important;
}
body .tableOverview .table.b-table>thead>tr>th[aria-sort=ascending] {
  background-position: right .375rem center !important;
  padding-right: calc(.75rem + .65em) !important;
}
body .tableOverview .table.b-table>thead>tr>th[aria-sort=descending] {
  background-position: right .375rem center !important;
  padding-right: calc(.75rem + .65em) !important;
}
body .tableOverview .table.b-table>thead>tr>th[aria-sort] {
  cursor: pointer !important;
  /* background-image: none !important; */
  background-repeat: no-repeat !important;
  background-size: .65em 1em !important;
}
body .tableOverview .table.b-table>thead>tr>th[aria-sort=none] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath opacity='.1' d='M51 1l25 23 24 22H1l25-22zm0 100l25-23 24-22H1l25 22z'/%3E%3C/svg%3E") !important;
}
body .tableOverview .table.b-table>thead>tr>th[aria-sort="ascending"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E") !important;
}
body .tableOverview .table.b-table>thead>tr>th[aria-sort="descending"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E") !important;
}
body .tableOverview .table.b-table>tfoot>tr>th[aria-sort=none] {
  background-image: none !important;
}
body .tableOverview .table.b-table>tfoot>tr>th[aria-sort="descending"] {
  background-image: none !important;
}
body .tableOverview .table.b-table>tfoot>tr>th[aria-sort="ascending"] {
  background-image: none !important;
}
.tdImgTableErrors {
  padding: auto 0px !important;
  text-align: center !important;
}
.thStatusTableErrors {
  text-align: center !important;
}
.thImgTableErrors {
  text-align: center !important;
}
.thHistoryTableErrors {
  text-align: center !important;
}
.tableContent thead th {
  background: #fff !important;
  font-weight: initial;
  font-size: 16px;
  color: #656b85;
  border: 0px !important;
  text-transform: initial !important;
}
.tableErrors thead th {
  background: #fff !important;
  font-weight: initial;
  font-size: 16px;
  color: #656b85;
  border: 0px !important;
  text-transform: initial !important;
}
.selectStrings .vs__dropdown-toggle {
  border-radius: 10px !important;
}
.selectStrings .vs__dropdown-toggle .vs__selected-options .vs__selected {
  border-radius: 15px !important;
  background: #E3E6F2 !important;
  border: 1px solid #E3E6F2 !important;
  color: #656B85 !important
}
.selectStrings .vs__dropdown-toggle .vs__selected-options .vs__selected .vs__deselect {
  display: none !important;
}
.btn-dropdown-strings .dropdown-toggle {
  padding: 0px !important;
  margin: 0px !important;
  border: 0px !important;
  padding-bottom: 5px !important;
}
.tableErrors td {
  vertical-align: middle !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.tableContent td {
  vertical-align: middle !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.thProTableErrors {
  width: 82px !important;
  max-width: 82px !important;
  min-width: 82px !important;
  padding: 0.72rem 0.45rem !important;
  text-align: right !important;
}
.tdProTableErrors {
  width: 82px !important;
  max-width: 82px !important;
  min-width: 82px !important;
  padding: 4px 0rem !important;
}
.thPageTableErrors {
  width: 442px !important;
  max-width: 442px !important;
  min-width: 442px !important;
  padding: 0.72rem 0.45rem !important;
}
.tdPageTableErrors {
  width: 442px !important;
  max-width: 442px !important;
  min-width: 442px !important;
  padding: 4px 0.45rem !important;
}
.thHistoryTableErrors {
  text-align: right !important;
  width: 80px !important;
  max-width: 80px !important;
}
.tdHistoryTableErrors {
  text-align: right !important;
  width: 80px !important;
  max-width: 80px !important;
}
.thBtnTableErrors {
  text-align: right !important;
  width: 132px !important;
  max-width: 132px !important;
}
.tdBtnTableErrors {
  text-align: right !important;
  width: 132px !important;
  max-width: 132px !important;
}
/* .table thead th {
  vertical-align: bottom !important;
  border-bottom: 2px solid #dee2e6 !important;
} */
/* .table td, .table th {
  padding: .75rem !important;
  padding-right: 0.75rem !important;
  vertical-align: top !important;
  border-top: 1px solid #dee2e6 !important;
} */
@media (max-width: 768px) {
  .whatDoesYourRobot {
    margin-top: 12px !important;
  }
}
</style>