<template>
  <section>
    <b-skeleton class="h2Skeleton" />
    <nav class="menu_testResults">
      <ul>
        <li><b-skeleton class="skeleton-nav" /></li>
        <li><b-skeleton class="skeleton-nav" /></li>
        <li><b-skeleton class="skeleton-nav" /></li>
      </ul>
    </nav>
    <template>
      <b-row>
        <b-col md="8">
          <b-skeleton-img height="550px" />
        </b-col>
        <b-col md="4"> <b-skeleton-img height="550px" /> </b-col
      ></b-row>
    </template>
  </section>
</template>

<script>
import { BCol, BRow, BSkeleton, BSkeletonImg } from "bootstrap-vue";
export default {
  name: "SkeletonPerformance",
  components: {
    BSkeleton,
    BSkeletonImg,
    BCol,
    BRow,
  },
};
</script>