<script>
import {CriticalRequestChainRenderer} from "../../../../../@core/utils/psi-render/crc-details-renderer";

export default {
  computed: {
    CriticalRequestChainRenderer() {
      return CriticalRequestChainRenderer
    }
  },
  components: {},
  props: {
    details: {
      type: Object,
      required: true,
    },
  }
}
</script>

<template>
  <div>
    {{ CriticalRequestChainRenderer.render(details, null) }}
  </div>
</template>

<style scoped lang="scss">

</style>
