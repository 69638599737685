const SUMMABLE_VALUETYPES = ['bytes', 'numeric', 'ms', 'timespanMs'];

function getColorChart(val) {
    if (val >= 0 && val <= 49) return '#FC7D7D'
    if (val >= 50 && val <= 89) return '#FFA54E'
    if (val >= 90) return '#53D991'
    return '#53D991'
}

function getColorLoadTime(val) {
    if (val >= 0 && val <= 27.45) return '#FC7D7D'
    if (val >= 27.46 && val <= 47.39) return '#FFA54E'
    if (val >= 47.40 && val <= 100) return '#53D991'
    return '#FC7D7D'
}

function getColorChartVariant(val) {
    if (val >= 0 && val <= 49) return 'red'
    if (val >= 50 && val <= 89) return 'warning'
    if (val >= 90) return 'green'
    return 'green'
}

function getColorLoadTimeVariant(val) {
    if (val >= 0 && val <= 27.45) return 'red'
    if (val >= 27.46 && val <= 47.39) return 'warning'
    if (val >= 47.40 && val <= 100) return 'green'
    return 'red'
}

function generateTimes() {
    const today = new Date()
    // Last month
    const previousMonth = new Date()
    previousMonth.setMonth(today.getMonth() - 1)
    // Last year
    const previousYear = new Date()
    previousYear.setFullYear(today.getFullYear() - 1)
    // Last week
    const lastWeek = today.getTime() - (7 * 24 * 60 * 60 * 1000)
    return {
        today: today.getTime(),
        previousYear: previousYear.getTime(),
        previousMonth: previousMonth.getTime(),
        lastWeek,
    }
}

function generateDateString(date, isTime) {
    const datePost = isTime ? new Date(date * 1000) : new Date(date)
    const options = {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    }
    return datePost.toLocaleString('en-US', options)
}

function generateDateStringNoDay(date, isTime) {
    const datePost = isTime ? new Date((date * 1000)) : new Date(date)
    const year = datePost.getFullYear()
    const month = datePost.toLocaleString('en-US', {month: 'long'})
    return `${datePost.toLocaleString('en-US', {day: 'numeric'})}th ${month}, ${year}`
}

const generateDateTime = date => new Date(date).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
})

function formatDataToResumeToolTipClick(dataPointIndex, history) {
    const data = {}
    data.performanceDesktop = history.performance.desktop[dataPointIndex]
    data.performanceMobile = history.performance.mobile[dataPointIndex]
    data.loadtimeDesktop = history.loadTime.desktop[dataPointIndex]
    data.loadtimeMobile = history.loadTime.mobile[dataPointIndex]
    data.performanceDesktop.color = getColorChartVariant(data.performanceDesktop.y)
    data.performanceMobile.color = getColorChartVariant(data.performanceMobile.y)
    data.loadtimeDesktop.color = getColorLoadTimeVariant(data.loadtimeDesktop.y)
    data.loadtimeMobile.color = getColorLoadTimeVariant(data.loadtimeMobile.y)
    data.date = generateDateStringNoDay(data.loadtimeMobile.x)
    data.time = generateDateTime(data.loadtimeMobile.x)
    data.dKey = data.performanceDesktop.key
    data.mKey = data.performanceMobile.key
    return data
}

function hoverIndexKeys(dataPointIndex, history) {
    const data = {}
    const performanceDesktop = history.performance.desktop[dataPointIndex]
    const performanceMobile = history.performance.mobile[dataPointIndex]
    data.dKey = performanceDesktop.key
    data.mKey = performanceMobile.key
    return data
}

function renderObject() {
    const average = {
        year: {
            performance: {
                mobile: [],
                desktop: [],
            },
            loadtime: {
                mobile: [],
                desktop: [],
            },
        },
        month: {
            performance: {
                mobile: [],
                desktop: [],
            },
            loadtime: {
                mobile: [],
                desktop: [],
            },
        },
        week: {
            performance: {
                mobile: [],
                desktop: [],
            },
            loadtime: {
                mobile: [],
                desktop: [],
            },
        },
    }
    const history = {
        desktop: [],
        mobile: [],
    }
    const chartData = {
        performance: {
            desktop: [],
            mobile: [],
        },
        loadtime: {
            desktop: [],
            mobile: [],
        },
    }
    const lastItems = {}
    return {
        average, history, chartData, lastItems,
    }
}

function formatPerformance(val) {
    const performanceData = renderObject()
    const {previousYear, previousMonth, lastWeek} = generateTimes()

    if (!val) {
        return performanceData
    }

    const filterByDate = (data, date) => Object.values(data).filter(x => x.x >= date)

    performanceData.chartData = val

    // Year Average
    performanceData.average.year.performance.mobile = filterByDate(
        val.performance.mobile,
        previousYear,
    )
    performanceData.average.year.performance.desktop = filterByDate(
        val.performance.desktop,
        previousYear,
    )
    performanceData.average.year.loadtime.mobile = filterByDate(
        val.loadTime.mobile,
        previousYear,
    )
    performanceData.average.year.loadtime.desktop = filterByDate(
        val.loadTime.desktop,
        previousYear,
    )

    // Month Average
    performanceData.average.month.performance.mobile = filterByDate(
        val.performance.mobile,
        previousMonth,
    )
    performanceData.average.month.performance.desktop = filterByDate(
        val.performance.desktop,
        previousMonth,
    )
    performanceData.average.month.loadtime.mobile = filterByDate(
        val.loadTime.mobile,
        previousMonth,
    )
    performanceData.average.month.loadtime.desktop = filterByDate(
        val.loadTime.desktop,
        previousMonth,
    )

    // Week Average
    performanceData.average.week.performance.mobile = filterByDate(
        val.performance.mobile,
        lastWeek,
    )
    performanceData.average.week.performance.desktop = filterByDate(
        val.performance.desktop,
        lastWeek,
    )
    performanceData.average.week.loadtime.mobile = filterByDate(
        val.loadTime.mobile,
        lastWeek,
    )
    performanceData.average.week.loadtime.desktop = filterByDate(
        val.loadTime.desktop,
        lastWeek,
    )

    // Registrar los ultimos datos
    performanceData.lastItems.desktop = val.lastDesktop
    performanceData.lastItems.mobile = val.lastMobile

    return performanceData
}

function calculateDifference(data) {
    const difference = {
        now: 0,
        before: 0,
        diff: 0,
    }
    try {
        if (data[1]?.y && data[0]?.y) {
            difference.now = data[1].y
            difference.before = data[0].y
            difference.diff = difference.now - difference.before
            return difference
        }else{
            return {
                now: 0,
                before: 0,
                diff: 0,
            }
        }
        
    } catch (error) {
        console.error(error)
        return null
    }
}


/**
 * Split a string on markdown links (e.g. [some link](https://...)) into
 * segments of plain text that weren't part of a link (marked as
 * `isLink === false`), and segments with text content and a URL that did make
 * up a link (marked as `isLink === true`).
 * @param {string} text
 * @return {Array<{isLink: true, text: string, linkHref: string}|{isLink: false, text: string}>}
 */
export const splitMarkdownLink = (text) => {
    /** @type {Array<{isLink: true, text: string, linkHref: string}|{isLink: false, text: string}>} */
    const segments = [];

    const parts = text.split(/\[([^\]]+?)\]\((https?:\/\/.*?)\)/g);
    while (parts.length) {
        // Shift off the same number of elements as the pre-split and capture groups.
        const [preambleText, linkText, linkHref] = parts.splice(0, 3);

        if (preambleText) { // Skip empty text as it's an artifact of splitting, not meaningful.
            segments.push({
                isLink: false,
                text: preambleText,
            });
        }

        // Append link if there are any.
        if (linkText && linkHref) {
            segments.push({
                isLink: true,
                text: linkText,
                linkHref,
            });
        }
    }

    return segments;
}

/**
 * Split a string by markdown code spans (enclosed in `backticks`), splitting
 * into segments that were enclosed in backticks (marked as `isCode === true`)
 * and those that outside the backticks (`isCode === false`).
 * @param {string} text
 * @return {Array<{isCode: true, text: string}|{isCode: false, text: string}>}
 */
export const splitMarkdownCodeSpans = (text) => {
    /** @type {Array<{isCode: true, text: string}|{isCode: false, text: string}>} */
    const segments = [];

    // Split on backticked code spans.
    const parts = text.split(/`(.*?)`/g);
    for (let i = 0; i < parts.length; i++) {
        const text = parts[i];

        // Empty strings are an artifact of splitting, not meaningful.
        if (!text) continue;

        // Alternates between plain text and code segments.
        const isCode = i % 2 !== 0;
        segments.push({
            isCode,
            text,
        });
    }

    return segments;
}

/**
 * @param {string} markdownText
 * @return {string}
 */
export const convertMarkdownCodeSnippets = (markdownText) => {
    const codesHtmlList = [];

    for (const segment of splitMarkdownCodeSpans(markdownText)) {
        if (segment.isCode) {
            codesHtmlList.push('<code>' + segment.text + '</code>');
        } else {
            //element.append(this._document.createTextNode(segment.text));
            codesHtmlList.push('<span>' + segment.text + '</span>');
        }
    }

    let html = '<span>' + codesHtmlList.join('') + '</span>';
    return replacePatternsToLink(html);
}

/**
 * @param {string} text
 * @param {{alwaysAppendUtmSource?: boolean}} opts
 * @return {string}
 */
const convertMarkdownLinkSnippets = (text, opts = {}) => {
    const elementsList = [];

    for (const segment of splitMarkdownLink(text)) {
        const processedSegment = segment.text.includes('`') ?
            convertMarkdownCodeSnippets(segment.text) :
            segment.text;

        if (!segment.isLink) {
            // Plain text segment.
            elementsList.push(processedSegment);
            continue;
        }

        // Otherwise, append any links found.
        const url = new URL(segment.linkHref);

        const DOCS_ORIGINS = ['https://developers.google.com', 'https://web.dev', 'https://developer.chrome.com'];
        if (DOCS_ORIGINS.includes(url.origin) || opts.alwaysAppendUtmSource) {
            url.searchParams.set('utm_source', 'lighthouse');
            url.searchParams.set('utm_medium', this._lighthouseChannel);
        }
        const aLink = `<a href="${url.href}" target="_blank" rel="noopener">${processedSegment}</a>`;
        elementsList.push(aLink);
    }

    return '<span>' + elementsList.join('') + '</span>';
}

const replacePatternsToLink = (text) => {
    // [Learn how to defer third-parties with a facade](https://developer.chrome.com/docs/lighthouse/performance/third-party-facades/).
    // this is formatted as [text](url) and needs to be converted to a link html element
    const pattern = /\[([^\]]+?)\]\((https?:\/\/.*?)\)/g;
    return text.replace(pattern, '<a class="text-blue-600" href="$2" target="_blank" rel="noopener">$1</a>');
}

/**
 * Returns a comparator created from the supplied list of keys
 * @param {Array<string>} sortedBy
 * @return {((a: LH.Audit.Details.TableItem, b: LH.Audit.Details.TableItem) => number)}
 */
export const getTableItemSortComparator = (sortedBy) => {
    return (a, b) => {
        for (const key of sortedBy) {
            const aVal = a[key];
            const bVal = b[key];
            if (typeof aVal !== typeof bVal || !['number', 'string'].includes(typeof aVal)) {
                console.warn(`Warning: Attempting to sort unsupported value type: ${key}.`);
            }
            if (typeof aVal === 'number' && typeof bVal === 'number' && aVal !== bVal) {
                return bVal - aVal;
            }
            if (typeof aVal === 'string' && typeof bVal === 'string' && aVal !== bVal) {
                return aVal.localeCompare(bVal);
            }
        }
        return 0;
    };
}


export const _getEntityGroupItems = (details) => {
    const {items, headings, sortedBy} = details;

    if (!items) return [];


    // Exclude entity-grouped audits and results without entity classification.
    // Eg. Third-party Summary comes entity-grouped.
    if (!items.length || details.isEntityGrouped || !items.some(item => item.entity)) {
        return [];
    }

    const skippedColumns = new Set(details.skipSumming || []);
    /** @type {string[]} */
    const summableColumns = [];
    for (const heading of headings) {
        if (!heading.key || skippedColumns.has(heading.key)) continue;
        if (SUMMABLE_VALUETYPES.includes(heading.valueType)) {
            summableColumns.push(heading.key);
        }
    }



    // Grab the first column's key to group by entity
    const firstColumnKey = headings[0].key;
    if (!firstColumnKey) return [];

    const byEntity = new Map();

    for (const item of items) {
        const entityName = typeof item.entity === 'string' ? item.entity : undefined;
        const groupedItem = byEntity.get(entityName) || {
            [firstColumnKey]: entityName || 'Unattributable',
            entity: entityName,
        };

        for (const key of summableColumns) {
            groupedItem[key] = Number(groupedItem[key] || 0) + Number(item[key] || 0);
        }
        byEntity.set(entityName, groupedItem);
    }


    const result = [...byEntity.values()];

    if (sortedBy) {
        result.sort(getTableItemSortComparator(sortedBy));
    }

    return result;
}

export default {
    getColorChart,
    formatPerformance,
    getColorLoadTime,
    generateTimes,
    generateDateString,
    generateDateStringNoDay,
    getColorChartVariant,
    getColorLoadTimeVariant,
    generateDateTime,
    formatDataToResumeToolTipClick,
    hoverIndexKeys,
    calculateDifference,
    convertMarkdownLinkSnippets,
    _getEntityGroupItems
}
