<template>
  <div class="diff_data" v-if="difference.diff !== 0">
    <p>
      <feather-icon
        v-if="typeGraph"
        :icon="difference.diff > 0 ? 'ChevronsUpIcon' : 'ChevronsDownIcon'"
        class="inline-block"
        :class="difference.diff > 0 ? 'text-success' : 'text-danger'"
        size="2x"
      />
      <feather-icon
        v-if="!typeGraph"
        :icon="difference.diff < 0 ? 'ChevronsDownIcon' : 'ChevronsUpIcon'"
        class="inline-block"
        :class="difference.diff < 0 ? 'text-success' : 'text-danger'"
        size="2x"
      />
      {{ difference.now }}{{ unit }}
      <sup v-if="difference.diff !== 0">{{ formattedDifference }}{{ formattedDiffValue }}</sup>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    difference: {
      type: null,
      required: true,
    },
    typeGraph: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    formattedDifference() {
      return this.difference.diff >= 1 ? "+" : "";
    },
    formattedDiffValue() {
      return this.difference.diff % 1 !== 0
        ? this.difference.diff.toFixed(2)
        : this.difference.diff;
    },
    unit() {
      return this.typeGraph ? "%" : "s";
    },
  },
};
</script>