<template>
  <div>
    <b-sidebar
      id="sidebar-right-accept-invitation"
      ref="sidebar_accept_invitation"
      :visible="showOpenAcceptInvitation"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-accept-invitation', val)"
    >
      <template #default="{ hide }">
        <b-overlay
          :show="loader"
          rounded="xl"
          no-fade
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
            style="background-color: #F0F1F7; height: 91px;"
          >
            <!-- <b-card no-body class="overflow-hidden w-100" style="background-color: #F0F1F7;"> -->
            <h3>Project Invitation</h3>
            <!-- </b-card> -->
            <feather-icon
              class="cursor-pointer"
              icon="XIcon"
              size="20"
              @click="hide"
            />
          </div>
          <div class="b-sidebar-body mt-2 mx-3">
            <!-- {{idsite}} -->
            <div v-for="(invitation, index) in arrayInvitations" :key="index">
              <b-row>
                <b-col md="8" class="pr-0">
                  <div class="mt-50">
                    <p
                      v-if="invitation.label"
                      class="mb-0"
                      style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px;"
                    >
                      Project
                    </p>
                    <h4
                      v-if="invitation.label"
                      class=""
                      style="font-weight: 700;"
                    >
                      {{ invitation.label }}
                    </h4>
                    <b v-if="invitation.ownedBy.uid" style="color: #676b85;">Owner</b>
                    <div class="d-flex mb-50 mt-50" v-if="invitation.ownedBy.uid">
                      <b-avatar
                        size="27"
                        :src="invitation.ownedBy.foto ? invitation.ownedBy.foto : ''"
                        variant="light-dark"
                        class="badge-minimal"
                        alt="img user"
                        :text="avatarText(!invitation.ownedBy.foto ? invitation.ownedBy.nombre : '')"
                        style="background: #ddd !important;"
                      >
                        <feather-icon
                          v-if="!invitation.ownedBy.foto && !invitation.ownedBy.nombre"
                          icon="UserIcon"
                          size="22"
                        />
                      </b-avatar>

                      <div class="ml-25 mt-50">
                        <p class="mb-0 " style="font-size: 13px; line-height: 14px; color: #656B85;">
                          {{ invitation.ownedBy.nombre }}
                        </p>
                        <span class="user-status" style="color: #A7AAB9;">{{ invitation.ownedBy.email }}</span>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col md="4" class="pl-0">
                  <div class="mt-50">
                    <b-button
                      style="background: #7838ff !important; border: 1px solid #7838ff !important; border-radius: 15px !important; width: 158px; height: 42px;"
                      class="mr-2 mb-1"
                      variant="primary"
                      @click.prevent="acceptInvitation(invitation)"
                    >
                      <span
                        class="align-middle"
                        style="font-weight: 700; color: #FFFFFF;"
                      >Accept</span>
                    </b-button>
                    <b-button
                      style="background: #DCDCF2 !important; border: 1px solid #A7AAB9 !important; border-radius: 15px !important; width: 158px; height: 42px; color: #656B85 !important;"
                      class=""
                      variant="primary"
                      @click.prevent="declineInvitation(invitation)"
                    >
                      <span
                        class="align-middle"
                        style="font-weight: 700; color: #656B85;"
                      >Decline</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <hr v-if="invitation.label">
            </div>
          </div>
          </b-overlay>
          <help-footer :url="arrayInvitations.length >= 1 ? arrayInvitations[0].domain : ''" />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BOverlay,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import ClaimOwnership from '@/views/apps/sites/sites-add/ClaimOwnership.vue'
import HelpFooter from '@/views/components/footer/HelpFooter'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { db, auth } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BOverlay,
    ClaimOwnership,
    HelpFooter,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenAcceptInvitation',
    event: 'update:show-open-accept-invitation',
  },
  props: ['idsite', 'showOpenAcceptInvitation'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,

      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      loader: true,
      selectedPermissions: [],
      permissionsOptions: ['clinician viewer', 'manage users', 'data specialist'],
      arrayInvitations: [],
    }
  },
  watch: {
    // idsite(val) {
      // console.log(val)
    //   if(val) {
    //     this.userData = {}
    //     const ref = db.collection('Users').doc(val)
    //     ref.onSnapshot(doc => {
    //       this.usuarios = {}
    //       this.usuarios = {
    //           id: doc.data().uid,
    //           fullName: doc.data().nombre,
    //           firstName: doc.data().firstName ? doc.data().firstName : '',
    //           lastName: doc.data().lastName ? doc.data().lastName : '',
    //           email: doc.data().email,
    //           role: doc.data().role,
    //           avatar: doc.data().foto,
    //           contact: doc.data().contact ? doc.data().contact : '',
    //           permissions: doc.data().permissions ?  doc.data().permissions : [],
    //       }
    //       this.emailOld = doc.data().email
    //       this.userData = this.usuarios
    //     })
    //   }
    // },
    showOpenAcceptInvitation(val) {
      // console.log(val)
      if(val) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }

      if(val) {
        const user = auth.currentUser
        if(user) {
          // console.log(user.uid)
          db.collection('Users').doc(user.uid).get()
          .then(userDoc => {
            if(userDoc.data().teams && userDoc.data().teams.length >= 1) {
              // this.totalUserMember = userDoc.data().teams.length
              // this.arrayTeamSort = []
              this.arrayInvitations = []
              userDoc.data().teams.forEach(element => {
                db.collection('projects').doc(element).get()
                .then(project => {
                  db.collection('projects').doc(element).collection('members').where('status', '==', 'invitated').where('uid', '==', user.uid).get()
                  .then(invitationOwn => {
                    var objInv = ''
                    if(invitationOwn.size >= 1) {
                      invitationOwn.forEach(inv => {
                        if(inv.data().statusInv === 'sended' && inv.data().status === 'invitated') {
                          // console.log(inv.data())
                          objInv = {}
                          objInv = inv.data()
                          objInv.id = inv.id
                        }
                      })
                    }

                    // this.arrayTeamSort.push({id: project.id, name: project.data().name ? project.data().name.toLowerCase() : ''})
                    db.collection('sites').where('project', '==', project.id).orderBy("n", "asc").get()
                    .then(sites => {
                      if(sites.size >= 1) {
                        sites.forEach(site => {
                          var URLactual = new URL(site.data().url);
                          var URLdomain = URLactual.host;
                          var pathname = URLactual.pathname;
                          var domainFixed = pathname === '/' ? URLdomain : URLdomain + pathname;
                          if(site.data().project) {
                            var own = project.data().owned.uid === user.uid ? 'me' : 'other'
                            var invitation = project.data().owned.uid === user.uid ? 'own' : objInv
                            var filterInvit = this.arrayInvitations.filter(ele => ele.idsite === project.id)
                            if(site.id && own === 'other' && invitation.statusInv === 'sended' && filterInvit.length === 0) {
                              this.arrayInvitations.push({ id: site.id, img: site.data().img ? site.data().img : 'https://src.seocloud.ai/site-thumb.png', name: site.data().name ? site.data().name : domainFixed, domainFixed: domainFixed, project: project.data().name, status: site.data().status, domain: site.data().url, projectid: project.id, color: project.data().color, own: own, invitation: invitation, label: project.data().name, idsite: project.id, imgSite: site.data().img ? site.data().img : 'https://src.seocloud.ai/site-thumb.png', ownedBy: project.data().owned ? project.data().owned : '' })
                            }
                          }
                        })
                        this.sortProjects()
                      } else {
                        var own = project.data().owned.uid === user.uid ? 'me' : 'other'
                        var invitation = project.data().owned.uid === user.uid ? 'own' : objInv
                        if(own === 'other' && invitation.statusInv === 'sended') {
                          this.arrayInvitations.push({ id: '', img: '', name: '', domainFixed: '', project: project.data().name, status: '', domain: '', projectid: project.id, color: project.data().color, own: own, invitation: invitation, label: project.data().name, idsite: project.id, imgSite: '', ownedBy: project.data().owned ? project.data().owned : '' })
                          this.sortProjects()
                        }
                      }
                    })
                  })
                })
              })
            }
          })
        }
      }
    }
  },
  validations: {
  },
  methods: {
    sortProjects() {
      this.arrayInvitations.sort(function (a, b) {
        if (a.label > b.label) {
            return 1;
        }
        if (a.label < b.label) {
            return -1;
        }
        return 0;
      });
      this.loader = false
    },
    // submitEditar(payload) {
    //   this.$refs.sidebar_accept_invitation.hide()
    // },
    acceptInvitation(val) {
      // console.log(this.idsite)
      db.collection('projects').doc(val.projectid).collection('members').doc(val.invitation.id)
      .update({
        status: 'active',
        statusInv: 'accepted',
      })
      .then(() => {
        const user = auth.currentUser
        if(user) {
          // console.log(user.uid)
          db.collection('Users').doc(user.uid).get()
          .then(userDoc => {
            if(userDoc.data().teams && userDoc.data().teams.length >= 1) {
              // this.totalUserMember = userDoc.data().teams.length
              // this.arrayTeamSort = []
              this.arrayInvitations = []
              var totalTeams = 0
              userDoc.data().teams.forEach(element => {
                db.collection('projects').doc(element).get()
                .then(project => {
                  db.collection('projects').doc(element).collection('members').where('uid', '==', user.uid).get()
                  .then(invitationOwn => {
                    var objInv = ''
                    if(invitationOwn.size >= 1) {
                      invitationOwn.forEach(inv => {
                        if(inv.data().statusInv === 'sended' && inv.data().status === 'invitated') {
                          // console.log(inv.data())
                          objInv = {}
                          objInv = inv.data()
                          objInv.id = inv.id
                        }
                      })
                    }

                    // this.arrayTeamSort.push({id: project.id, name: project.data().name ? project.data().name.toLowerCase() : ''})
                    db.collection('sites').where('project', '==', project.id).orderBy("n", "asc").get()
                    .then(sites => {
                      if(sites.size >= 1) {
                        sites.forEach(site => {
                          var URLactual = new URL(site.data().url);
                          var URLdomain = URLactual.host;
                          var pathname = URLactual.pathname;
                          var domainFixed = pathname === '/' ? URLdomain : URLdomain + pathname;
                          if(site.data().project) {
                            var own = project.data().owned.uid === user.uid ? 'me' : 'other'
                            var invitation = project.data().owned.uid === user.uid ? 'own' : objInv
                            var filterInvit = this.arrayInvitations.filter(ele => ele.idsite === project.id)
                            if(site.id && own === 'other' && invitation.statusInv === 'sended' && filterInvit.length === 0) {
                              this.arrayInvitations.push({ id: site.id, img: site.data().img ? site.data().img : 'https://src.seocloud.ai/site-thumb.png', name: site.data().name ? site.data().name : domainFixed, domainFixed: domainFixed, project: project.data().name, status: site.data().status, domain: site.data().url, projectid: project.id, color: project.data().color, own: own, invitation: invitation, label: project.data().name, idsite: project.id, imgSite: site.data().img ? site.data().img : 'https://src.seocloud.ai/site-thumb.png', ownedBy: project.data().owned ? project.data().owned : '' })
                            }
                          }
                        })
                        this.sortProjects()

                        db.collection('Users').doc(user.uid).update({
                          totalInvitation: this.arrayInvitations.length
                        })
                      } else {
                        var own = project.data().owned.uid === user.uid ? 'me' : 'other'
                        var invitation = project.data().owned.uid === user.uid ? 'own' : objInv
                        if(own === 'other' && invitation.statusInv === 'sended') {
                          this.arrayInvitations.push({ id: '', img: '', name: '', domainFixed: '', project: project.data().name, status: '', domain: '', projectid: project.id, color: project.data().color, own: own, invitation: invitation, label: project.data().name, idsite: project.id, imgSite: '', ownedBy: project.data().owned ? project.data().owned : '' })
                          this.sortProjects()
                        }
                      }
                      
                      totalTeams += 1
                      if(totalTeams === userDoc.data().teams.length) {
                        if(this.arrayInvitations.length === 0) {
                          this.$refs.sidebar_accept_invitation.hide()
                        }
                      }
                      // setTimeout(() => {
                      //   if(this.arrayInvitations.length === 0) {
                      //     this.$refs.sidebar_accept_invitation.hide()
                      //   }
                      // }, 2000)
                    })
                  })
                })
              })
            }
          })
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Invitation accepted',
            icon: 'BellIcon',
            variant: 'success',
          },
        })

        db.collection('projects').doc(val.projectid).update({
          updated: Date.now(),
        })

        db.collection('Users').doc(val.invitation.uid).update({
          updated: Date.now(),
        })
      })
      .catch(error => console.log(error))
    },
    declineInvitation(val) {
      db.collection('projects').doc(val.projectid).collection('members').doc(val.invitation.id)
      .update({
      // status: 'active',
        statusInv: 'declined',
      })
      .then(() => {
        const user = auth.currentUser
        if(user) {
          // console.log(user.uid)
          db.collection('Users').doc(user.uid).get()
          .then(userDoc => {
            if(userDoc.data().teams && userDoc.data().teams.length >= 1) {
              // this.totalUserMember = userDoc.data().teams.length
              // this.arrayTeamSort = []
              this.arrayInvitations = []
              var totalTeams = 0
              userDoc.data().teams.forEach(element => {
                db.collection('projects').doc(element).get()
                .then(project => {
                  db.collection('projects').doc(element).collection('members').where('uid', '==', user.uid).get()
                  .then(invitationOwn => {
                    var objInv = ''
                    if(invitationOwn.size >= 1) {
                      invitationOwn.forEach(inv => {
                        if(inv.data().statusInv === 'sended' && inv.data().status === 'invitated') {
                          // console.log(inv.data())
                          objInv = {}
                          objInv = inv.data()
                          objInv.id = inv.id
                        }
                      })
                    }

                    // this.arrayTeamSort.push({id: project.id, name: project.data().name ? project.data().name.toLowerCase() : ''})
                    db.collection('sites').where('project', '==', project.id).orderBy("n", "asc").get()
                    .then(sites => {
                      if(sites.size >= 1) {
                        sites.forEach(site => {
                          var URLactual = new URL(site.data().url);
                          var URLdomain = URLactual.host;
                          var pathname = URLactual.pathname;
                          var domainFixed = pathname === '/' ? URLdomain : URLdomain + pathname;
                          if(site.data().project) {
                            var own = project.data().owned.uid === user.uid ? 'me' : 'other'
                            var invitation = project.data().owned.uid === user.uid ? 'own' : objInv
                            var filterInvit = this.arrayInvitations.filter(ele => ele.idsite === project.id)
                            if(site.id && own === 'other' && invitation.statusInv === 'sended' && filterInvit.length === 0) {
                              this.arrayInvitations.push({ id: site.id, img: site.data().img ? site.data().img : 'https://src.seocloud.ai/site-thumb.png', name: site.data().name ? site.data().name : domainFixed, domainFixed: domainFixed, project: project.data().name, status: site.data().status, domain: site.data().url, projectid: project.id, color: project.data().color, own: own, invitation: invitation, label: project.data().name, idsite: project.id, imgSite: site.data().img ? site.data().img : 'https://src.seocloud.ai/site-thumb.png', ownedBy: project.data().owned ? project.data().owned : '' })
                            }
                          }
                        })
                        this.sortProjects()
                        
                        db.collection('Users').doc(user.uid).update({
                          totalInvitation: this.arrayInvitations.length
                        })
                      } else {
                        var own = project.data().owned.uid === user.uid ? 'me' : 'other'
                        var invitation = project.data().owned.uid === user.uid ? 'own' : objInv
                        if(own === 'other' && invitation.statusInv === 'sended') {
                          this.arrayInvitations.push({ id: '', img: '', name: '', domainFixed: '', project: project.data().name, status: '', domain: '', projectid: project.id, color: project.data().color, own: own, invitation: invitation, label: project.data().name, idsite: project.id, imgSite: '', ownedBy: project.data().owned ? project.data().owned : '' })
                          this.sortProjects()
                        }
                      }

                      totalTeams += 1
                      if(totalTeams === userDoc.data().teams.length) {
                        if(this.arrayInvitations.length === 0) {
                          this.$refs.sidebar_accept_invitation.hide()
                        }
                      }
                      // setTimeout(() => {
                      //   if(this.arrayInvitations.length === 0) {
                      //     this.$refs.sidebar_accept_invitation.hide()
                      //   }
                      // }, 2000)
                    })
                  })
                })
              })
            }
          })
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Invitation declined',
            icon: 'BellIcon',
            variant: 'success',
          },
        })

        db.collection('projects').doc(val.projectid).update({
          updated: Date.now(),
        })

        db.collection('Users').doc(val.invitation.uid).update({
          updated: Date.now(),
        })
      })
      .catch(error => console.log(error))
    },
    // validationForm() {
    //   this.$refs.simpleRules.validate().then(success => {
    //     if (success) {
    //       // console.log({nombre: this.firstName + ' ' + this.lastName, firstName: this.firstName, lastName: this.lastName, email: this.email, contact: this.contact, password: this.password, role: this.roleOptions, permissions: this.selectedPermissions})
    //       this.submitEditar({id: this.userData.id, fullName: this.userData.fullName, firstName: this.userData.firstName, lastName: this.userData.lastName, email: this.userData.email, contact: this.userData.contact, permissions: this.userData.permissions})
    //     }
    //   })
    // },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-accept-invitation {
    width: 90%;
    max-width: 593px !important;
}
// label {
//   margin-bottom: 0px !important;
//   height: 25px;
// }
// .col-form-label {
//   margin-bottom: 0px !important;
//   height: 25px;
//   font-size: 0.857rem !important;
// }
// .form-control {
//   height: 35px;
// }
// .v-select {
//   height: 35px;
//   margin-top: 0px;
// }
// .vs__dropdown-toggle {
//   height: 35px;
// }
// .vs__selected {
//   margin-top: 0px !important;
// }
</style>
