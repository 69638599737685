<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <div class="top-nav-login pt-4 pl-5">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          pill
          class="float-right mr-5 btn-auth-non-login"
          style="font-weight: 400 !important;"
          :to="{name:'auth-register'}"
        >
          Sign up for free
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-light"
          class="float-right mr-1 btn-auth-non-login"
          style="color: #fff; font-weight: 400 !important;"
          :to="{name:'auth-login'}"
        >
          Login
        </b-button>

        <!-- <b-link
          class="brand-logo"
          href="/"
        >
          <b-img-lazy
            fluid
            :src="imgUrl"
            alt="logo SEOcloud"
            class="img-logo"
            width="340px"
            height="40px"
          />
        </b-link> -->
      </div>
      <!-- /Brand logo-->

      <div style="width: 100%;">
        <b-link
          class="brand-logo"
          href="/"
          style="position: inherit;"
        >
          <b-img-lazy
            fluid
            :src="imgUrl"
            alt="logo SEOcloud"
            class="img-logo"
            width="340px"
            height="40px"
            style="margin: auto; left: 0px !important; top: 180px;"
          />
        </b-link>
      </div>

      <!-- Register-->
      <b-col
        lg="12"
        class="d-flex px-2 p-lg-5 form-sign"
      >
        <b-col
          sm="8"
          md="6"
          lg="6"
          class="px-0 mx-auto"
          style="max-width: 480px !important;"
        >
          <b-tabs class="tabsLoginNavegation tabsRegister title-tab-nav-login" align="left">
            <b-tab
              title="Login"
              :active="false"
              style="color: #fff !important; font-weight: 300;"
              @click.prevent="getLogin"
              class="title-tab-nav-login"
            >
            </b-tab>
            <b-tab
              title="Sign up"
              :active="true"
              style="color: #fff !important; font-weight: 300;"
              class="title-tab-nav-login"
            >
            </b-tab>
          </b-tabs>

          <b-card-title
            class="font-weight-bold color-text-white title-header-login"
            title-tag="h1"
            style="font-weight: 700; font-size: 28px; margin-top: 20px; line-height: 1.4;"
          >
            Create your free SEOcloud account
          </b-card-title>

          <!-- form -->
          <validation-observer ref="registerForm">
            <!-- #default="{invalid}" -->
            <b-form
              class="auth-register-form mt-2"
            >
              <!-- email -->
              <b-form-group
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter email"
                    style="height: 52px;"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|min:8|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="Enter password"
                      style="height: 52px;"
                    />
                    <b-input-group-append
                      is-text
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    >
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="reset-password-confirm"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|min:8|password|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="Confirm your password"
                      style="height: 52px;"
                    />
                    <b-input-group-append
                      is-text
                      class="cursor-pointer"
                      @click="togglePassword2Visibility"
                    >
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <p class="text-center mt-2 ">
                <span style="color: #fff;">By signing you are agreeing to Seocloud </span>
                <b-link :to="{name:'auth-login'}">
                  <span style="text-decoration: underline;">Terms & Conditions</span>
                </b-link>
              </p>

              <b-button
                variant="primary"
                block
                type="submit"
                class="mt-2 font-weight-bold btn-foot-login"
                style="font-weight: 400; border-radius: 39px; height: 52px; font-size: 20px;"
                @click.prevent="validationForm"
                :disabled="proccessInfo"
              >
                Sign up
                <b-spinner small class="mb-25" v-if="proccessInfo" />
              </b-button>
            </b-form>
          </validation-observer>

          <div class="auth-footer-btn d-flex justify-content-center mt-1">
            <b-button
              variant="google"
              class="btn-foot-login-google"
              style="font-weight: 400; background: #f0f1f1; color: #676b85 !important; font-weight: bold; padding: 15px 30px !important; border-radius: 39px; height: 52px; font-size: 20px;"
              block
              @click="google"
            >
              <b-img
                :src="require('@/assets/images/icons/google.png')"
                style="height: 24px; margin-right: 10px; position: relative; top: -4px;"
                width="23px"
                height="24px"
                alt="img google"
              />
              Sign up
            </b-button>
          </div>
        </b-col>
      </b-col>
      <!-- /Register-->

      <!-- Right Text-->
      <!-- <b-col
        lg="6"
        class="d-none d-lg-flex flex-column m-auto p-5"
      >
        <b-card-title
          class="mb-2 font-weight-bold color-text-white"
          title-tag="h3"
          style="font-weight: 400;"
        >
          The best web scanner to <span style="color: #c31bff;">resolve</span><br>
          technical and SEO problems<span style="color: #c31bff;">.</span>
        </b-card-title>
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <b-img-lazy
            fluid
            :src="ghaphicsImg"
            alt="Login graph"
            width="800"
            height="445"
          />
        </div>
      </b-col> -->
      <!-- /Right Text-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
  BImgLazy, BModal, VBModal, BSpinner, BTabs, BTab
} from 'bootstrap-vue'
import {
  required, email, password, confirmed, min
} from '@validations'
// import { required, minLength, password, confirmed, email, } from 'vuelidate/lib/validators'
// import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import { mapMutations, mapActions, mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { firebase, db } from '@/firebase'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImgLazy,
    BModal,
    BSpinner,
    BTabs,
    BTab,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  // mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      username: '',
      userEmail: '',
      password: '',
      cPassword: '',
      sideImg: require('@/assets/images/logo/logo-seocloud.svg'),
      ghaphicsImg: require('@/assets/images/banner/ghaphics.svg'),
      proccessInfo: false,
      // validation
      min,
      email,
      required,
      password,
      confirmed,
      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  metaInfo: {
    meta: [
      { name: 'title', content: 'Registro' },
    ],
  },
  computed: {
    ...mapState(['usuario']),
    passwordToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/logo/logo-seocloud.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapMutations(['nuevoUsuario']),
    ...mapActions(['crearUsuario', 'setUsuario']),
    getLogin() {
      this.$router.push({name:'auth-login'})
    },
    togglePasswordVisibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    mailWelcome(nameUser, email) {
      const obj = {
        nameUser: nameUser,
        email,
      }
      this.jsonApi = JSON.stringify(obj, null, 2)
      
      // update seocloud-dcdfb
      const config = {
        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/welcomeMail',
        method: 'POST',
        // timeout: 0,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(obj),
      }

      this.axios(config)
      .then(() => {
      // console.log(response.data)
        console.log('email sended')
      })
      .catch(e => {
        console.log(e)
      })
    },
    google() {
      const provider = new firebase.auth.GoogleAuthProvider()
      this.ingresar(provider)
    },
    async ingresar(provider) {
      // ingreso con cuenta de google
      firebase.auth().languageCode = 'en'

      try {
        // Ingreso Usuario
        const result = await firebase.auth().signInWithPopup(provider)
        const { user } = result

        const doc = await db.collection('Users').doc(user.uid).get()

        // Construir usuario
        const usuario = {
          nombre: doc.exists ? doc.data().nombre : user.displayName,
          email: user.email,
          uid: user.uid,
          foto: user.photoURL,
          role: 'user',
          status: 'active',
          typeRegister: 'Regular register page',
          totalSites: 10,
          ability: [
            {
              action: 'manage',
              subject: 'all',
            },
          ],
        }

        // el usuario no existe
        if(!doc.exists) {
          // Guardar el usuario
          await db.collection('Users').doc(usuario.uid).set(
            usuario,
          )
          setTimeout(() => {
            db.collection('Projects').add({
              name: 'My sites',
              color: '#d1d1d1',
              isMySites: true,
              numberSites: 0,
              numberTeamMembers: 0,
              owner: {
                nombre: usuario.nombre,
                email: usuario.email,
                uid: usuario.uid,
                foto: '',
              },
              teamMembers: []
            })
            .then(docRef => {
              // update the uid of the project
              db.collection('Projects').doc(docRef.id).update({
                id: docRef.id,
              })
              .catch(error => {
                console.error(error)
              })
      
              // update teams owner
              db.collection('Users').doc(usuario.uid).update({
                teams: firebase.firestore.FieldValue.arrayUnion(docRef.id),
                updated: Date.now(),
                mySitesId: docRef.id
              })
              .catch(error => {
                console.error(error)
              })
      
              db.collection('Users').doc(usuario.uid).collection('projects').doc(docRef.id).set({
                id: docRef.id,
                name: 'My sites',
                color: '#d1d1d1',
                isMySites: true,
                numberSites: 0,
                // sites: site ? site : '',
                numberTeamMembers: 0,
                owner: {
                  nombre: usuario.nombre,
                  email: usuario.email,
                  uid: usuario.uid,
                  foto: '',
                },
                teamMembers: []
              })
              .catch(error => {
                console.error(error)
              })
            })
          }, 500)
          this.mailWelcome(usuario.nombre ? usuario.nombre : usuario.email, user.email)

          // db.collection('projects').get()
          // .then(projects => {
          //   projects.forEach(project => {
          //     db.collection('projects').doc(project.id).collection('members').where('uid', '!=', 'resume').get()
          //     .then(invitationsProject => {
          //       if(invitationsProject.size >= 1) {
          //         invitationsProject.forEach(invitation => {
          //           if(invitation.data().uid) {
          //             // 
          //           } else {
          //             db.collection('Users').where('email', '==', invitation.data().email).get()
          //             .then(queryUser => {
          //               if(queryUser.size >= 1) {
          //                 queryUser.forEach(userDoc => {
          //                   db.collection('projects').doc(project.id).collection('members').doc(invitation.id).update({
          //                     imgU: userDoc.data().foto ? userDoc.data().foto : '',
          //                     name: userDoc.data().nombre,
          //                     uid: userDoc.id
          //                   })
          //                   // .then(() => console.log('updated'))
          //                 })
          //               }
          //             })
          //           }
          //         })
          //       }
          //     })
          //   })
          // })
        }

        this.nuevoUsuario(usuario)
        localStorage.setItem('firstLogin', Date.now())
        localStorage.setItem('ll', Date.now())
        localStorage.setItem('forgetNotification', true)

        // obtener ruta
        this.$ability.update(usuario.ability)
        this.$router.replace(getHomeRouteForLoggedInUser(doc.exists && (doc.data().teams && doc.data().teams.length > 1) ? doc.data().role : 'staff'))
        setTimeout(() => {
          this.proccessInfo = false
        }, 1500)
      } catch (error) {
        this.proccessInfo = false
        console.log(error)
      }
    },
    async registerWithEmail() {
      try {
        const result = await this.crearUsuario({
          email: this.userEmail, password: this.password, username: this.username, typeRegister: 'Regular register page', totalSites: 10,
        })
        localStorage.setItem('firstLogin', Date.now())
        localStorage.setItem('ll', Date.now())
        localStorage.setItem('forgetNotification', true)
        setTimeout(() => {
          this.proccessInfo = false
        }, 1500)
      } catch (error) {
        this.proccessInfo = false
        console.log(error)
      }
    },
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if(success) {
          this.proccessInfo = true
          this.registerWithEmail()
          // console.log({nombre: this.firstName + ' ' + this.lastName, firstName: this.firstName, lastName: this.lastName, email: this.email, contact: this.contact, password: this.password, role: this.roleOptions, permissions: this.selectedPermissions})
          // this.submitEditar({id: this.userData.id, fullName: this.userData.fullName, firstName: this.userData.firstName, lastName: this.userData.lastName, email: this.userData.email, contact: this.userData.contact, permissions: this.userData.permissions})
        }
      })
    },
  },
  created() {
    this.proccessInfo = false
  }
}
/* eslint-disable global-require */
</script>

<style>
@media  (min-width: 0) and (max-width: 991px) {
  .btn-auth-non-login {
    display: none !important;
  }
}

.form-sign {
  padding-top: 9vh !important;
}
@media only screen and (min-width: 600px) {
  .form-sign {padding-top: 9vh !important;}
}
@media only screen and (min-width: 2300px) {
  .form-sign {padding-top: 32vh !important;}
}
@media only screen and (min-width: 3282px) {
  .form-sign {padding-top: 36vh !important;}
}
@media only screen and (min-width: 4100px) {
  .form-sign {padding-top: 38vh !important;}
}
@media only screen and (min-width: 5400px) {
  .form-sign {padding-top: 42vh !important;}
}
@media only screen and (max-width: 800px) {
  .title-tab-nav-login a {
    font-size: 17.1px !important;
  }
  .title-header-login {
    font-size: 22.4px !important;
  }
  .btn-foot-login {
    font-size: 18px !important;
  }
  .btn-foot-login-google {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 500px) {
  .btn-foot-login-google {
    font-size: 14px !important;
    padding-top: 17px !important;
  }
}
@media only screen and (max-width: 273px) {
  .btn-foot-login-google {
    font-size: 12px !important;
    padding-top: 19px !important;
  }
}
@media only screen and (min-height: 1700px) {
  .img-logo {top: 180px !important;}
}
/* @media only screen and (min-height: 1500px) { */
/* @media  (min-width: 871px) and (max-width: 1200px) {
  .img-logo {top: 120px !important;}
} */
@media  (min-height: 668px) and (max-height: 870px) {
  .img-logo {top: 80px !important;}
}
@media  (min-height: 0) and (max-height: 667px) {
  .img-logo {top: 30px !important;}
}
.tabsLoginNavegation
.nav-tabs
.nav-link.active {
  color: #fff !important;
  font-family: 'Hurme Geometric Sans 3';
  font-weight: 400 !important;
  padding-bottom: 4px;
  font-size: 19px;
  background: none !important;
}
.tabsLoginNavegation
.nav-tabs
.nav-link {
  font-weight: 300 !important;
}
.tabsLoginNavegation
.nav-tabs
.nav-link.active::after {
  background: transparent linear-gradient(90deg, #7838ff 0%, #30CDFF 100%) !important;
}
.tabsLoginNavegation
.nav-tabs
.nav-link {
  color: #fff !important;
  /* font-family: ; */
  padding-bottom: 0px;
  font-size: 19px;
}
body {
  background: url("https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/stripes-bkg.png?alt=media&token=174016c2-d520-4123-b4b7-bead206719ab") 0% 0% no-repeat padding-box,linear-gradient(115deg, #181e38 47%, #7838ff 180%) !important;
}
/* .lnkHv:hover {
  color: #f4d6ff !important;
} */
.modal .modal-header .close {
  transform: none;
  box-shadow: none;
  border-radius: unset;
  background: none;
}
.modal .modal-header .close:hover {
  transform: translate(2px, 2px);
}
.top-nav-login {
  /* background-color: #7838ff; */
  /* background-image: url(/assets/images/pattern-8d0f59e0f30b8e1261b9e36e843460eb.png); */
  /* background-repeat: repeat; */
  border: none;
  height: 55px;
  top: 0px;
  position: absolute;
  width: 100%;
}
.img-logo {
  width: 340px;
  height: 40px;
  position: relative;
  top: 25px;
  left: 20px;
}
.brand-logo-svg{fill: white;height: 38px;}
.account-body {
  position: relative;
  padding: 35px 30px 10px;
  margin-bottom: 1em;
  color: #444;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,.15);
  box-shadow: 1px 1px 1px rgba(0,0,0,.15);
  border-radius: 4px;
  width: 450px
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
