<template>
  <div>
    <b-sidebar
      id="sidebar-right-incomplete-crawl"
      ref="sidebar_info_license"
      :visible="showOpenInfoLicense"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-info-license', val)"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #F0F1F7; height: 91px;"
        >
          <b-card
            no-body
            class="overflow-hidden w-100"
            style="background-color: #F0F1F7;"
          >
            <header-sidebar :idsite-header.sync="idsite" />
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <div class="ml-3 my-50">
          <p
            v-if="idsite.label"
            class="mb-0"
            style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px;"
          >
            Project
          </p>
          <h4
            v-if="idsite.label"
            class=""
            style="font-weight: 700;"
          >
            {{ idsite.label }}
          </h4>
        </div>
        <hr v-if="idsite.label">
        <div
          class="b-sidebar-body mt-0"
          style="padding: 10px 15px;"
        >
          <h3>License</h3>
        </div>

        <b-card
          no-body
          class="overflow-hidden w-100"
          :style="idsite.planType || (idsite.plan !== 'Free' && idsite.plan !== 'free') ? (annualyBill ? 'height: 530px;' : 'height: 500px;') : (annualyBill ? 'height: 530px;' : 'height: 530px;')"
        >
          <div class="d-flex ml-1" v-if="idsite.paidRecurrency !== 'Anually'">
            <span :style="annualyBill ? 'color: #232C51; font-size: 16px;' : 'color: #232C51; font-weight: bold; font-size: 16px;'" class="mr-1">Bill Monthly</span>
            <b-form-checkbox
              v-model="annualyBill"
              :checked="annualyBill"
              name="check-button"
              switch
              inline
              :disabled="processSubmit"
            >
              <span :style="annualyBill ? 'color: #232C51; font-weight: bold; font-size: 16px;' : 'color: #232C51; font-size: 16px;'" class="ml-50">Bill Annualy <span style="font-size: 13px; font-weight: 400; color: rgb(151, 151, 151);">( up to 25% off )</span></span>
            </b-form-checkbox>
          </div>
          <b-row
            no-gutters
            class="mt-1 row-container-configs ml-0 px-1"
            style="height: 180px"
          >
            <!-- v-if="idsite.planType || (idsite.plan !== 'Free' && idsite.plan !== 'free')" -->
            <b-col
              :md="'12'"
              sm="12"
              :class="`cards card-config-license w-100 type-active mr-50 ${crawlType === '0' || crawlType === 0 ? 'type-active' : 'type-inactive'}`"
              :style="annualyBills ? 'height: 67px; background: #f0f0f0; cursor: no-drop;' : 'height: 67px;'"
            >
              <label
                class="w-100 custom-radio-plan-container"
                :style="annualyBills ? 'height: 67px; cursor: no-drop;' : 'height: 67px;'"
                role="button"
                :disabled="annualyBills"
              >
                <input
                  v-model="crawlType"
                  type="radio"
                  value="0"
                  checked
                  class="custom-radio-plan"
                  style="float: left; margin-top: 10px; margin-left: 10px;"
                  :disabled="annualyBills"
                >
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-right: 15px; float: right;">$0<span style="color: #656B85; font-weight: normal; font-size: 13px;">{{annualyBill ? ' / month*' : ' / month'}}</span></span>
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-left: 35px;">Free Plan <span style="font-size: 13px; font-weight: 400; color: rgb(151, 151, 151); padding-left: 6px;" v-if="isActivePlan(0)">( Current )</span></span>
                <div
                  class="pb-50"
                  style="margin-top: 3px;"
                >
                  <p class="mb-0 mt-1" style="margin-left: 35px; color: #656B85;">
                    <feather-icon
                      class=""
                      icon="CheckIcon"
                      size="16"
                      style="color: #A7AAB9; padding-bottom: 4px;"
                    />
                    SEOcloud Copilot for <span style="font-weight: 900; font-size: 14px;">2 pages</span>
                  </p>

                  <span style="color: #CA34FF; position: relative; right: 0px; float: right; top: -20px; margin-right: 13px;">
                    <span style="position: relative; top: 2px; margin-right: 5px;">Downgrade</span>
                    <b-button
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      size="sm"
                      style="background: #CA34FF40; padding: 0px;"
                      
                    >
                      <feather-icon :icon="'ChevronDownIcon'" style="color: #CA34FF;" />
                    </b-button>
                  </span>
                </div>
              </label>
            </b-col>
            <b-col
              :md="'12'"
              sm="12"
              :class="`mt-1 cards card-config-license w-100 ml-0 ${crawlType === '1' || crawlType === 1 ? 'type-active' : 'type-inactive'}`"
              :style="annualyBill && year5 ? 'height: 67px; background: #f0f0f0; cursor: no-drop;' : 'height: 67px;'"
            >
              <label
                class="w-100 custom-radio-plan-container"
                :style="annualyBill && year5 ? 'height: 67px; cursor: no-drop;' : 'height: 67px;'"
                role="button"
                :disabled="annualyBill && year5"
              >
                <input
                  v-model="crawlType"
                  type="radio"
                  value="1"
                  checked
                  class="custom-radio-plan"
                  style="float: left; margin-top: 10px; margin-left: 10px;"
                  :disabled="annualyBill && year5"
                >
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-right: 15px; float: right;">{{ getPlanAmount(1) }}<span style="color: #656B85; font-weight: normal; font-size: 13px;">{{annualyBill ? ' / month*' : ' / month'}}</span></span>
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-left: 35px;">5 pages <span style="font-size: 13px; font-weight: 400; color: rgb(151, 151, 151); padding-left: 6px;" v-if="isActivePlan(5)">( Current )</span></span>
                <div
                  class="pb-50"
                  style="margin-top: 3px;"
                >
                  <p class="mb-0 mt-1" style="margin-left: 35px; color: #656B85;">
                    <feather-icon
                      class=""
                      icon="CheckIcon"
                      size="16"
                      style="color: #A7AAB9; padding-bottom: 4px;"
                    />
                    Unlimited fixes - SEOcloud Copilot for <span style="font-weight: 900; font-size: 14px;">5 pages</span>
                  </p>
                  <span style="color: #CA34FF; position: relative; right: 0px; float: right; top: -20px; margin-right: 13px;">
                    <span style="position: relative; top: 2px; margin-right: 5px;">{{!idsite.planType ? 'Upgrade' : 'Downgrade'}}</span>
                    <b-button
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      size="sm"
                      style="background: #CA34FF40; padding: 0px;"
                      
                    >
                      <feather-icon :icon="!idsite.planType ? 'ChevronUpIcon' : 'ChevronDownIcon'" style="color: #CA34FF;" />
                    </b-button>
                  </span>
                </div>
              </label>
            </b-col>
            <b-col
              md="12"
              sm="12"
              :class="`mt-1 cards card-config-license w-100 ml-0 ${crawlType === '2' || crawlType === 2 ? 'type-active' : 'type-inactive'}`"
              :style="annualyBill && year10 ? 'height: 67px; background: #f0f0f0; cursor: no-drop;' : 'height: 67px;'"
            >
              <label
                class="w-100 custom-radio-plan-container"
                :style="annualyBill && year10 ? 'height: 67px; cursor: no-drop;' : 'height: 67px;'"
                role="button"
                :disabled="annualyBill && year10"
              >
                <input
                  v-model="crawlType"
                  type="radio"
                  value="2"
                  checked
                  class="custom-radio-plan"
                  style="float: left; margin-top: 10px; margin-left: 10px;"
                  :disabled="annualyBill && year10"
                >
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-right: 15px; float: right;">{{ getPlanAmount(2) }}<span style="color: #656B85; font-weight: normal; font-size: 13px;">{{annualyBill ? ' / month*' : ' / month'}}</span></span>
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-left: 35px;">10 pages <span style="font-size: 13px; font-weight: 400; color: rgb(151, 151, 151); padding-left: 6px;" v-if="isActivePlan(10)">( Current )</span></span>
                <div
                  class="pb-50"
                  style="margin-top: 3px;"
                >
                  <p class="mb-0 mt-1" style="margin-left: 35px; color: #656B85;">
                    <feather-icon
                      class=""
                      icon="CheckIcon"
                      size="16"
                      style="color: #A7AAB9; padding-bottom: 4px;"
                    />
                    Unlimited fixes - SEOcloud Copilot for <span style="font-weight: 900; font-size: 14px;">10 pages</span>
                  </p>
                  <span style="color: #CA34FF; position: relative; right: 0px; float: right; top: -20px; margin-right: 13px;">
                    <span style="position: relative; top: 2px; margin-right: 5px;">{{idsite.planType === '5' || idsite.planType === 5 || !idsite.planType ? 'Upgrade' : 'Downgrade'}}</span>
                    <b-button
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      size="sm"
                      style="background: #CA34FF40; padding: 0px;"
                      
                    >
                      <feather-icon :icon="idsite.planType === '5' || idsite.planType === 5 || !idsite.planType ? 'ChevronUpIcon' : 'ChevronDownIcon'" style="color: #CA34FF;" />
                    </b-button>
                  </span>
                </div>
              </label>
            </b-col>
            <b-col
              md="12"
              sm="12"
              :class="`mt-1 cards card-config-license w-100 ml-0 ${crawlType === '3' || crawlType === 3 ? 'type-active' : 'type-inactive'}`"
              :style="annualyBill && year25 ? 'height: 67px; background: #f0f0f0; cursor: no-drop;' : 'height: 67px;'"
            >
              <label
                class="w-100 custom-radio-plan-container"
                :style="annualyBill && year25 ? 'height: 67px; cursor: no-drop;' : 'height: 67px;'"
                role="button"
                :disabled="annualyBill && year25"
              >
                <input
                  v-model="crawlType"
                  type="radio"
                  value="3"
                  checked
                  class="custom-radio-plan"
                  style="float: left; margin-top: 10px; margin-left: 10px;"
                  :disabled="annualyBill && year25"
                >
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-right: 15px; float: right;">{{ getPlanAmount(3) }}<span style="color: #656B85; font-weight: normal; font-size: 13px;">{{annualyBill ? ' / month*' : ' / month'}}</span></span>
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-left: 35px;">25 pages <span style="font-size: 13px; font-weight: 400; color: rgb(151, 151, 151); padding-left: 6px;" v-if="isActivePlan(25)">( Current )</span></span>
                <div
                  class="pb-50"
                  style="margin-top: 3px;"
                >
                  <p class="mb-0 mt-1" style="margin-left: 35px; color: #656B85;">
                    <feather-icon
                      class=""
                      icon="CheckIcon"
                      size="16"
                      style="color: #A7AAB9; padding-bottom: 4px;"
                    />
                    Unlimited fixes - SEOcloud Copilot for <span style="font-weight: 900; font-size: 14px;">25 pages</span>
                  </p>
                  <span style="color: #CA34FF; position: relative; right: 0px; float: right; top: -20px; margin-right: 13px;">
                    <span style="position: relative; top: 2px; margin-right: 5px;">{{idsite.planType === '10' || idsite.planType === 10 || idsite.planType === '5' || idsite.planType === 5 || !idsite.planType ? 'Upgrade' : 'Downgrade'}}</span>
                    <b-button
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      size="sm"
                      style="background: #CA34FF40; padding: 0px;"
                      
                    >
                      <feather-icon :icon="idsite.planType === '10' || idsite.planType === 10 || idsite.planType === '5' || idsite.planType === 5 || !idsite.planType ? 'ChevronUpIcon' : 'ChevronDownIcon'" style="color: #CA34FF;" />
                    </b-button>
                  </span>
                </div>
              </label>
            </b-col>
            <b-col
              md="12"
              sm="12"
              :class="`mt-1 cards card-config-license w-100 ml-0 ${crawlType === '4' || crawlType === 4 ? 'type-active' : 'type-inactive'}`"
              style="height: 135px;"
            >
              <label
                class="w-100 custom-radio-plan-container"
                style="height: 135px;"
                role="button"
              >
                <input
                  v-model="crawlType"
                  type="radio"
                  value="4"
                  checked
                  class="custom-radio-plan"
                  style="float: left; margin-top: 10px; margin-left: 10px;"
                >
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-right: 15px; float: right;">{{ getPlanAmount(4) }}<span style="color: #656B85; font-weight: normal; font-size: 13px;">{{annualyBill ? ' / month*' : ' / month'}}</span></span>
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-left: 35px;">More <span style="font-size: 13px; font-weight: 400; color: rgb(151, 151, 151); padding-left: 6px;" v-if="isActivePlan(customSlider ? parseInt(customSlider, 10) : 50)">( Current )</span></span>
                <div
                  class="pb-50"
                  style="margin-top: 3px;"
                >
                  <p class="mb-0 mt-1" style="margin-left: 35px; color: #656B85;">
                    <feather-icon
                      class=""
                      icon="CheckIcon"
                      size="16"
                      style="color: #A7AAB9; padding-bottom: 4px;"
                    />
                    Unlimited fixes - SEOcloud for more pages
                  </p>
                  <div class="d-flex mt-50" style="text-align: center; align-content: center; margin-left: 37%;">
                    <b-button
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      :style="customSlider === 50 ? 'background: #CFD3E5; padding: 0px; cursor: no-drop; height: 20px; width: 20px;' : 'background: rgb(120, 56, 255); padding: 0px; height: 20px; width: 20px;'"
                      @click.prevent="customSlider === 50 ? null : customSlider -= 50"
                    >
                      <feather-icon icon="ChevronLeftIcon" style="color: #fff;" />
                    </b-button>
                    <span style="position: relative; top: 1px; margin-right: 5px; margin-left: 5px;"><span style="font-weight: 900; font-size: 14px;">{{customSlider}} pages</span> license</span>
                    <b-button
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      :style="customSlider === 1000 ? 'background: #CFD3E5; padding: 0px; cursor: no-drop; height: 20px; width: 20px;' : 'background: rgb(120, 56, 255); padding: 0px; height: 20px; width: 20px;'"
                      @click.prevent="customSlider === 1000 ? null : customSlider += 50"
                    >
                      <feather-icon icon="ChevronRightIcon" style="color: #fff;" />
                    </b-button>
                  </div>
                  <div class="d-flex" style="margin-left: 15px;">
                    <span style="margin-right: 15px;" class="pt-50">50</span>
                    <vue-slider
                      v-model="customSlider"
                      :direction="direction"
                      class="mb-2 mt-50 slider-custom-plan"
                      style="width: 85%;"
                      :marks="false"
                      :adsorb="true"
                      :interval="50"
                      :min="50"
                      :max="1000"
                    />
                    <span style="margin-left: 15px;" class="pt-50">1000</span>
                  </div>
                  <span style="color: #CA34FF; position: relative; right: 0px; float: right; top: -20px; margin-right: 13px;">
                    <span style="position: relative; top: 2px; margin-right: 5px;">{{idsite.planType === '25' || idsite.planType === 25 || idsite.planType === '10' || idsite.planType === 10 || idsite.planType === '5' || idsite.planType === 5 || !idsite.planType ? 'Upgrade' : 'Downgrade'}}</span>
                    <b-button
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      size="sm"
                      style="background: #CA34FF40; padding: 0px;"
                    >
                      <feather-icon :icon="idsite.planType === '25' || idsite.planType === 25 || idsite.planType === '10' || idsite.planType === 10 || idsite.planType === '5' || idsite.planType === 5 || !idsite.planType ? 'ChevronUpIcon' : 'ChevronDownIcon'" style="color: #CA34FF;" />
                    </b-button>
                  </span>
                </div>
              </label>
            </b-col>
            <p v-if="annualyBill" style="color: #7838ff; text-align: right; margin-right: 30px; width: 100%; margin-top: 10px;">* billed anually</p>
          </b-row>
        </b-card>

        <!-- <p v-if="idsite.customerId && idsite.subscriptionId && (crawlType === '0' || crawlType === 0)" style="color: rgb(162, 162, 162);" class="ml-2">You'll keep your free license</p> -->
        <p v-if="(idsite.nextDueCancel && idsite.nextDueCancel !== '-') && idsite.planType > getSelectedActualPlanType()" style="color: #fd9714;" class="ml-2">Your license will downgrade to {{crawlType === '0' || crawlType === 0 ? 2 : crawlType === '1' || crawlType === 1 ? 5 : crawlType === '2' || crawlType === 2 ? 10 : crawlType === '3' || crawlType === 3 ? 25 : customSlider}} pages on {{idsite.nextDueCancel}}</p>
        <b-button
          :style="idsite.customerId && idsite.subscriptionId && (crawlType === '0' || crawlType === 0) ? 'background: #7838ff !important; border: 1px solid #7838ff !important; width: 208px; height: 46px; border-radius: 15px; margin-right: 40px;' : 'background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 15px; margin-right: 40px;'"
          class="float-right"
          variant="primary"
          @click.prevent="idsite.customerId && idsite.subscriptionId && (crawlType === '0' || crawlType === 0) ? submitCancelation() : submitEditar()"
          :disabled="isActivePlanSelected() ? true : processSubmit"
        >
          <span
            class=""
            style="font-weight: 700; color: #FFFFFF;"
          >{{idsite.customerId && idsite.subscriptionId && (crawlType === '0' || crawlType === 0) ? 'Cancel current license' : 'Update license'}}</span>
        </b-button>
        <span style="color: rgb(162, 162, 162); width: 100%; text-align: right; margin-right: 54px; margin-top: 5px;" class="float-right" v-if="idsite.customerId && idsite.subscriptionId && (crawlType === '0' || crawlType === 0)">You'll keep your free license</span>
        <span style="color: rgb(94, 94, 94); width: 100%; text-align: right; margin-right: 66px; margin-top: 5px;" class="float-right" v-else-if="!idsite.customerId || !idsite.subscriptionId">5 days free trial</span>

        <b-modal
          :title="showUpdateCC ? 'Add Credit card information' : showOpenCheckout ? 'Checkout' : 'Update license'"
          v-model="showUpdatePlan"
          hide-footer
          no-close-on-backdrop
          hide-backdrop
        >
          <div v-if="!showUpdateCC && !showOpenCheckout">
            <p style="color: #232c51; font-size: 16px;" class="pb-0">Select payment method: <span style="color: rgb(168, 168, 168)">{{planSelectedPayment}}</span></p>
            
            <b-form style="margin-top: 20px;">
              <b-form-group
                label="Select payment method:"
                label-for="vue-select"
              >
                <v-select
                  id="vue-select"
                  v-model="selectedPaymentMethod"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="optionPaymentMethod"
                  :clearable="false"
                />
              </b-form-group>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="float-right ml-1 update-plan-confirm"
                variant="primary"
                @click="upgradePlan"
                :disabled="processSubmitUpgrade || !selectedPaymentMethod"
              >
                View payment details
              </b-button>
              <!-- redirectToBilling(idsite.customerId) -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="float-right"
                variant="outline-secondary"
                @click="showUpdateCC = true"
                :disabled="processSubmitUpgrade"
              >
                Add card
              </b-button>
            </b-form>
          </div>
          <div v-else-if="showUpdateCC && !showOpenCheckout">
            <feather-icon
              class="cursor-pointer"
              icon="ArrowLeftIcon"
              size="20"
              style="margin-bottom: 20px;"
              @click="showUpdateCC = false, showOpenCheckout = false"
            />

            <form @submit.prevent="handleSubmitCC">
              <div id="card-element"></div>
              <button
                class="mt-2"
                type="submit"
                :style="processSubmitUpgrade ? 'opacity: 0.5; cursor: no-drop; border: 1px solid #7838FF !important; background: #7838ff !important; border-radius: 13px; padding: 0.786rem 1.5rem; color: #fff !important; float: right !important; font-size: 1rem;' : 'border: 1px solid #7838FF !important; background: #7838ff !important; border-radius: 13px; padding: 0.786rem 1.5rem; color: #fff !important; float: right !important; font-size: 1rem;'"
                :disabled="processSubmitUpgrade"
              >Add Payment Method</button>
            </form>
          </div>
          <div v-else-if="!showUpdateCC && showOpenCheckout">
            <feather-icon
              class="cursor-pointer"
              icon="ArrowLeftIcon"
              size="20"
              style="margin-bottom: 20px;"
              @click="showUpdateCC = false, showOpenCheckout = false"
            />

              <div>
                <p>Upgrade price is calculated from a credit of the unused portion of the current plan and billing of the new plan for the same period.</p>
                <p><strong>Subtotal: </strong>{{ amountCheckout }}</p>
              </div>
              <button
                class="mt-2"
                type="submit"
                :style="processSubmitUpgrade ? 'opacity: 0.5; cursor: no-drop; border: 1px solid #7838FF !important; background: #7838ff !important; border-radius: 13px; padding: 0.786rem 1.5rem; color: #fff !important; float: right !important; font-size: 1rem;' : 'border: 1px solid #7838FF !important; background: #7838ff !important; border-radius: 13px; padding: 0.786rem 1.5rem; color: #fff !important; float: right !important; font-size: 1rem;'"
                @click.prevent="upgradePlanConfirmation"
                :disabled="processSubmitUpgrade"
              >Checkout</button>
          </div>
        </b-modal>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BImgLazy,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import VueSlider from 'vue-slider-component'
import storeInd from '@/store/index'
import 'vue-slider-component/theme/default.css'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import { loadStripe } from '@stripe/stripe-js'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import HelpFooter from '@/views/components/footer/HelpFooter'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { db } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BImgLazy,
    VueSlider,
    HelpFooter,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenInfoLicense',
    event: 'update:show-open-info-license',
  },
  props: ['idsite','userData','showOpenInfoLicense'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,

      dir: 'ltr',
      customSlider: 50,
      annualyBills: false,
      annualyBill: true,
      selectedMove: '',
      processSubmit: false,
      numberPages: 0,
      crawlType: 0,
      sitesImg: require('@/assets/images/illustration/i-site.png'),
      siteJS: require('@/assets/images/icons/js.png'),
      marks: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000],
      showUpdatePlan: false,
      planSelectedPayment: '',
      selectedPaymentMethod: '',
      // selectedPaymentMethodId: '',
      optionPaymentMethod: [],
      processSubmitUpgrade: false,

      // card element
      stripe: null,
      cardElement: null,
      showUpdateCC: false,

      agentesAsignados: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      permissionsOptions: ['clinician viewer', 'manage users', 'data specialist'],
      allProject: [
        {
          label: 'My sites',
          color: '#F0F1F7',
        },
        {
          label: 'Facade interactive sites',
          color: '#F39C19',
        },
        {
          label: "Jane's sites",
          color: '#1CA085',
        },
      ],
      selectedProPage: '',
      optionsProPage: [
        'Home',
        'Blog',
        'Contact',
      ],
      selectedError: 'Missing Title',
      optionsErrors: [
        'Meta title',
        'Missing Title',
        'Missing descriptions',
        'Missing alts',
        'Headings H1',
        'Low word count',
        'External links',
      ],
      fixText: '',
      customPlanMonth: 12,
      customPlan: 220,

      infoCheckout: '',
      amountCheckout: '',
      showOpenCheckout: false,

      //   sources
      errSitesImg: require('@/assets/images/pages/robot-thumb.svg'),
      appImgComingSoon: require('@/assets/images/icons/logo-coming-soon.svg'),

      // options plans upgrade/drowngrade
      monthFree: false,
      month5: false,
      month10: false,
      month25: false,
      month50: false,
      month100: false,
      month150: false,
      month200: false,
      month250: false,
      month300: false,
      month350: false,
      month400: false,
      month450: false,
      month500: false,
      month550: false,
      month600: false,
      month650: false,
      month700: false,
      month750: false,
      month800: false,
      month850: false,
      month900: false,
      month950: false,
      month1000: false,

      yearFree: false,
      year5: false,
      year10: false,
      year25: false,
      year50: false,
      year100: false,
      year150: false,
      year200: false,
      year250: false,
      year300: false,
      year350: false,
      year400: false,
      year450: false,
      year500: false,
      year550: false,
      year600: false,
      year650: false,
      year700: false,
      year750: false,
      year800: false,
      year850: false,
      year900: false,
      year950: false,
      year1000: false,
    }
  },
  watch: {
    showOpenInfoLicense(val) {
      this.showOpenCheckout = false
      this.monthFree = false
      this.yearFree = false
      this.month5 = false
      this.month10 = false
      this.month25 = false
      this.month50 = false
      this.month100 = false
      this.month150 = false
      this.month200 = false
      this.month250 = false
      this.month300 = false
      this.month350 = false
      this.month400 = false
      this.month450 = false
      this.month500 = false
      this.month550 = false
      this.month600 = false
      this.month650 = false
      this.month700 = false
      this.month750 = false
      this.month800 = false
      this.month850 = false
      this.month900 = false
      this.month950 = false
      this.month1000 = false

      this.year5 = false
      this.year10 = false
      this.year25 = false
      this.year50 = false
      this.year100 = false
      this.year150 = false
      this.year200 = false
      this.year250 = false
      this.year300 = false
      this.year350 = false
      this.year400 = false
      this.year450 = false
      this.year500 = false
      this.year550 = false
      this.year600 = false
      this.year650 = false
      this.year700 = false
      this.year750 = false
      this.year800 = false
      this.year850 = false
      this.year900 = false
      this.year950 = false
      this.year1000 = false

      this.customSlider = 50
      this.customPlanMonth = 12
      this.customPlan = 220
      // this.annualyBill = true
      this.processSubmit = false
      this.showUpdatePlan = false
      this.showUpdateCC = false
      this.planSelectedPayment = ''
      this.selectedPaymentMethod = ''
      this.optionPaymentMethod = []
      this.processSubmitUpgrade = false
      localStorage.removeItem('jsonInfoLic')
      if(val) {
        if(this.idsite.planType) {
          this.getPlanAmountInteger(this.idsite.planType)
        }
        this.annualyBill = true
        // console.log(this.idsite.customerId);
        document.body.style.overflow = 'hidden'

        if(this.idsite) {
          if(this.idsite.paidRecurrency && this.idsite.paidRecurrency === 'Anually') {
            this.annualyBill = true
          }

          if(this.idsite.planType) {
            if(this.idsite.planType === '5' || this.idsite.planType === 5) {
              this.crawlType = 1
            } else if(this.idsite.planType === '10' || this.idsite.planType === 10) {
              this.crawlType = 2
            } else if(this.idsite.planType === '25' || this.idsite.planType === 25) {
              this.crawlType = 3
            } else {
              this.crawlType = 4
              this.customSlider = parseInt(this.idsite.planType, 10)
            }
          } else {
            this.crawlType = 3
          }
        }
      } else {
        document.body.style.overflow = 'auto'
      }
    },
    showUpdatePlan(val) {
      if(!val) {
        this.processSubmit = false
        this.showUpdateCC = false
        this.showOpenCheckout = false
        this.processSubmitUpgrade = false
      }
    },
    showUpdateCC(val) {
      if(val) {
        // const elements = this.stripe.elements();
        // this.cardElement = elements.create('card');
        // this.cardElement.mount('#card-element');
        this.loadStripeCard()
        this.showOpenCheckout = false
      } else {
        this.processSubmitUpgrade = false
        this.processSubmit = false
      }
    }
  },
  computed: {
    direction() {
      if(storeInd.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
  },
  validations: {
  },
  methods: {
    async loadStripeCard() {
      this.stripe = await loadStripe('pk_test_51PVKa9Eha01YHD9pDFl3O2hsGF5KgINCA5fooZqjlbDqfmmrvWwABRufNHVDdGdl643Wyv3VL0vZuYazlAQrBDeu00sCy4UCuP');
      const elements = this.stripe.elements();
      this.cardElement = elements.create('card', {
        style: {
          base: {
            fontSize: '16px',
            color: '#32325d',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
        },
        hidePostalCode: true
        // placeholder: {
        //   number: '1234 1234 1234 1234',
        //   expiry: 'MM / YY',
        //   cvc: 'CVC',
        //   postal: 'ZIP Code'
        // }
      });
      this.cardElement.mount('#card-element');
    },
    isActivePlan(planSite) {
      if(this.annualyBill) {
        if(this.idsite.paidRecurrency === 'Anually') {
          if((!this.idsite.planType || this.idsite.planType === 0 || this.idsite.planType === '0') && planSite === 0) {
            return true
          } else if(this.idsite.planType === 5 && planSite === 5) {
            return true
          } else if(this.idsite.planType === 10 && planSite === 10) {
            return true
          } else if(this.idsite.planType === 25 && planSite === 25) {
            return true
          } else if(this.idsite.planType === planSite) {
            return true
          }
        }
      } else {
        if(this.idsite.paidRecurrency === 'Monthly') {
          if((!this.idsite.planType || this.idsite.planType === 0 || this.idsite.planType === '0') && planSite === 0) {
            return true
          } else if(this.idsite.planType === 5 && planSite === 5) {
            return true
          } else if(this.idsite.planType === 10 && planSite === 10) {
            return true
          } else if(this.idsite.planType === 25 && planSite === 25) {
            return true
          } else if(this.idsite.planType === planSite) {
            return true
          }
        }
      }

      return false
    },
    isActivePlanSelected() {
      var planSite = 0
      if(this.crawlType === '0' || this.crawlType === 0) {
        planSite = 0
      } else if(this.crawlType === '1' || this.crawlType === 1) {
        planSite = 5
      } else if(this.crawlType === '2' || this.crawlType === 2) {
        planSite = 10
      } else if(this.crawlType === '3' || this.crawlType === 3) {
        planSite = 25
      } else if(this.crawlType === '4' || this.crawlType === 4) {
        planSite = this.customSlider ? parseInt(this.customSlider, 10) : 50
      }
      // console.log(this.idsite.planType,this.crawlType,planSite)

      if(this.annualyBill) {
        if(this.idsite.paidRecurrency === 'Anually') {
          if((!this.idsite.planType || this.idsite.planType === 0 || this.idsite.planType === '0' || this.idsite.plan === 'Free' || this.idsite.plan === 'free') && planSite === 0) {
            return true
          } else if(this.idsite.planType === 5 && planSite === 5) {
            return true
          } else if(this.idsite.planType === 10 && planSite === 10) {
            return true
          } else if(this.idsite.planType === 25 && planSite === 25) {
            return true
          } else if(this.idsite.planType === planSite) {
            return true
          }
        }
      } else {
        if(this.idsite.paidRecurrency === 'Monthly') {
          if((!this.idsite.planType || this.idsite.planType === 0 || this.idsite.planType === '0' || this.idsite.plan === 'Free' || this.idsite.plan === 'free') && planSite === 0) {
            return true
          } else if(this.idsite.planType === 5 && planSite === 5) {
            return true
          } else if(this.idsite.planType === 10 && planSite === 10) {
            return true
          } else if(this.idsite.planType === 25 && planSite === 25) {
            return true
          } else if(this.idsite.planType === planSite) {
            return true
          }
        }
      }

      if(this.idsite.plan === 'Free' || this.idsite.plan === 'free') {
        if(planSite === 0) {
          return true
        }
      }

      return false
    },
    getSelectedActualPlanType() {
      if(this.crawlType === '0' || this.crawlType === 0) {
        return 0
      } else if(this.crawlType === '1' || this.crawlType === 1) {
        return 5
      } else if(this.crawlType === '2' || this.crawlType === 2) {
        return 10
      } else if(this.crawlType === '3' || this.crawlType === 3) {
        return 25
      } else {
        return parseInt(this.customSlider, 10)
      }
    },
    getPlanAmount(typeCrawl) {
      var priceId = ''
      if(this.annualyBill) {
        // yearly
        if(typeCrawl === 1 || typeCrawl === '1') {
          priceId = '$' + 19
        } else if(typeCrawl === 2 || typeCrawl === '2') {
          priceId = '$' + 29
        } else if(typeCrawl === 3 || typeCrawl === '3') {
          priceId = '$' + 49
        } else if(typeCrawl === 4 || typeCrawl === '4') {
          if((this.customPlan + (this.customSlider - 50)) === 220) {
            priceId = '$' + 69
          } else if((this.customPlan + (this.customSlider - 50)) === 270) {
            priceId = '$' + 99
          } else if((this.customPlan + (this.customSlider - 50)) === 320) {
            priceId = '$' + 139
          } else if((this.customPlan + (this.customSlider - 50)) === 370) {
            priceId = '$' + 179
          } else if((this.customPlan + (this.customSlider - 50)) === 420) {
            priceId = '$' + 219
          } else if((this.customPlan + (this.customSlider - 50)) === 470) {
            priceId = '$' + 259
          } else if((this.customPlan + (this.customSlider - 50)) === 520) {
            priceId = '$' + 299
          } else if((this.customPlan + (this.customSlider - 50)) === 570) {
            priceId = '$' + 339
          } else if((this.customPlan + (this.customSlider - 50)) === 620) {
            priceId = '$' + 379
          } else if((this.customPlan + (this.customSlider - 50)) === 670) {
            priceId = '$' + 419
          } else if((this.customPlan + (this.customSlider - 50)) === 720) {
            priceId = '$' + 459
          } else if((this.customPlan + (this.customSlider - 50)) === 770) {
            priceId = '$' + 499
          } else if((this.customPlan + (this.customSlider - 50)) === 820) {
            priceId = '$' + 539
          } else if((this.customPlan + (this.customSlider - 50)) === 870) {
            priceId = '$' + 579
          } else if((this.customPlan + (this.customSlider - 50)) === 920) {
            priceId = '$' + 619
          } else if((this.customPlan + (this.customSlider - 50)) === 970) {
            priceId = '$' + 659
          } else if((this.customPlan + (this.customSlider - 50)) === 1020) {
            priceId = '$' + 699
          } else if((this.customPlan + (this.customSlider - 50)) === 1070) {
            priceId = '$' + 739
          } else if((this.customPlan + (this.customSlider - 50)) === 1120) {
            priceId = '$' + 779
          } else if((this.customPlan + (this.customSlider - 50)) === 1170) {
            priceId = '$' + 819
          }
        }
      } else {
        // monthly
        if(typeCrawl === 1 || typeCrawl === '1') {
          priceId = '$' + 25
        } else if(typeCrawl === 2 || typeCrawl === '2') {
          priceId = '$' + 39
        } else if(typeCrawl === 3 || typeCrawl === '3') {
          priceId = '$' + 65
        } else if(typeCrawl === 4 || typeCrawl === '4') {
          if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 12) {
            priceId = '$' + 89
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 17) {
            priceId = '$' + 129
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 22) {
            priceId = '$' + 179
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 27) {
            priceId = '$' + 229
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 32) {
            priceId = '$' + 279
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 37) {
            priceId = '$' + 329
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 42) {
            priceId = '$' + 379
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 47) {
            priceId = '$' + 429
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 52) {
            priceId = '$' + 479
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 57) {
            priceId = '$' + 529
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 62) {
            priceId = '$' + 579
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 67) {
            priceId = '$' + 629
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 72) {
            priceId = '$' + 679
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 77) {
            priceId = '$' + 729
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 82) {
            priceId = '$' + 779
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 87) {
            priceId = '$' + 829
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 92) {
            priceId = '$' + 879
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 97) {
            priceId = '$' + 929
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 102) {
            priceId = '$' + 979
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 107) {
            priceId = '$' + 999
          }
        }
      }

      return priceId
    },
    getPlanAmountInteger(typeCrawl, pricePlan) {
      var priceId = ''
      if(this.idsite.paidRecurrency && this.idsite.paidRecurrency === 'Anually') {
        this.monthFree = true
        this.month5 = true
        this.month10 = true
        this.month25 = true
        this.month50 = true
        this.month100 = true
        this.month150 = true
        this.month200 = true
        this.month250 = true
        this.month300 = true
        this.month350 = true
        this.month400 = true
        this.month450 = true
        this.month500 = true
        this.month550 = true
        this.month600 = true
        this.month650 = true
        this.month700 = true
        this.month750 = true
        this.month800 = true
        this.month850 = true
        this.month900 = true
        this.month950 = true
        this.month1000 = true

        // yearly
        if(typeCrawl) {
          this.yearFree = true
        }

        if(typeCrawl === 5) {
          priceId = 228
        } else if(typeCrawl === 10) {
          this.year5 = true
          priceId = 348
        } else if(typeCrawl === 25) {
          this.year5 = true
          this.year10 = true
          priceId = 588
        } else if(typeCrawl === 50) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          priceId = 828
        } else if(typeCrawl === 100) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          priceId = 1188
        } else if(typeCrawl === 150) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          priceId = 1668
        } else if(typeCrawl === 200) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          priceId = 2148
        } else if(typeCrawl === 250) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          priceId = 2628
        } else if(typeCrawl === 300) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          priceId = 3108
        } else if(typeCrawl === 350) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          priceId = 3588
        } else if(typeCrawl === 400) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          priceId = 4068
        } else if(typeCrawl === 450) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          priceId = 4548
        } else if(typeCrawl === 500) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          priceId = 5028
        } else if(typeCrawl === 550) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          this.year500 = true
          priceId = 5508
        } else if(typeCrawl === 600) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          this.year500 = true
          this.year550 = true
          priceId = 5988
        } else if(typeCrawl === 650) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          this.year500 = true
          this.year550 = true
          this.year600 = true
          priceId = 6468
        } else if(typeCrawl === 700) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          this.year500 = true
          this.year550 = true
          this.year600 = true
          this.year650 = true
          priceId = 6948
        } else if(typeCrawl === 750) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          this.year500 = true
          this.year550 = true
          this.year600 = true
          this.year650 = true
          this.year700 = true
          priceId = 7428
        } else if(typeCrawl === 800) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          this.year500 = true
          this.year550 = true
          this.year600 = true
          this.year650 = true
          this.year700 = true
          this.year750 = true
          priceId = 7908
        } else if(typeCrawl === 850) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          this.year500 = true
          this.year550 = true
          this.year600 = true
          this.year650 = true
          this.year700 = true
          this.year750 = true
          this.year800 = true
          priceId = 8388
        } else if(typeCrawl === 900) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          this.year500 = true
          this.year550 = true
          this.year600 = true
          this.year650 = true
          this.year700 = true
          this.year750 = true
          this.year800 = true
          this.year850 = true
          priceId = 8868
        } else if(typeCrawl === 950) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          this.year500 = true
          this.year550 = true
          this.year600 = true
          this.year650 = true
          this.year700 = true
          this.year750 = true
          this.year800 = true
          this.year850 = true
          this.year900 = true
          priceId = 9348
        } else if(typeCrawl === 1000) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          this.year500 = true
          this.year550 = true
          this.year600 = true
          this.year650 = true
          this.year700 = true
          this.year750 = true
          this.year800 = true
          this.year850 = true
          this.year900 = true
          this.year950 = true
          priceId = 9828
        }
        // this.year1000 = true
        // }
      } else {
        // monthly
        if(typeCrawl) {
          this.yearFree = true
        }

        if(typeCrawl === 5) {
          priceId = 25
        } else if(typeCrawl === 10) {
          priceId = 39
        } else if(typeCrawl === 25) {
          priceId = 65
        } else if(typeCrawl === 50) {
          priceId = 89
        } else if(typeCrawl === 100) {
          priceId = 129
        } else if(typeCrawl === 150) {
          priceId = 179
        } else if(typeCrawl === 200) {
          this.year5 = true
          priceId = 229
        } else if(typeCrawl === 250) {
          this.year5 = true
          priceId = 279
        } else if(typeCrawl === 300) {
          this.year5 = true
          priceId = 329
        } else if(typeCrawl === 350) {
          this.year5 = true
          this.year10 = true
          priceId = 379
        } else if(typeCrawl === 400) {
          this.year5 = true
          this.year10 = true
          priceId = 429
        } else if(typeCrawl === 450) {
          this.year5 = true
          this.year10 = true
          priceId = 479
        } else if(typeCrawl === 500) {
          this.year5 = true
          this.year10 = true
          priceId = 529
        } else if(typeCrawl === 550) {
          this.year5 = true
          this.year10 = true
          priceId = 579
        } else if(typeCrawl === 600) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          priceId = 629
        } else if(typeCrawl === 650) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          priceId = 679
        } else if(typeCrawl === 700) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          priceId = 729
        } else if(typeCrawl === 750) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          priceId = 779
        } else if(typeCrawl === 800) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          priceId = 829
        } else if(typeCrawl === 850) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          priceId = 879
        } else if(typeCrawl === 900) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          priceId = 929
        } else if(typeCrawl === 950) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          priceId = 979
        } else if(typeCrawl === 1000) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          priceId = 999
        }
        // }
      }

      // var validationDisableDowngrade = priceId < pricePlan ? true : false
      // return validationDisableDowngrade
    },
    upgradePlan() {
      // console.log('test 1')
      this.processSubmit = true
      var selectedPaymentMethodId = this.selectedPaymentMethod.id
      // console.log(selectedPaymentMethodId);
      // this.showUpdatePlan = false
      this.showUpdateCC = false
      this.processSubmitUpgrade = true
      var URLactual = new URL(window.location)
      var domain = URLactual.protocol + '//' + (URLactual.hostname === 'localhost' ? URLactual.hostname + ':8080' : URLactual.hostname)
      // this.crawlType
      var amount = this.crawlType === 1 || this.crawlType === '1' ? (this.annualyBill ? 123*100 : 5*100) : this.crawlType === 2 || this.crawlType === '2' ? (this.annualyBill ? 140*100 : 8*100) : this.crawlType === 3 || this.crawlType === '3' ? (this.annualyBill ? 195*100 : 10*100) : this.crawlType === 4 || this.crawlType === '4' ? (this.annualyBill ? (this.customPlan + (this.customSlider - 50))*100 : (this.customPlanMonth + ((this.customSlider - 50) / 10))*100) : null
      var plan = this.crawlType === 1 || this.crawlType === '1' ? '5 pages' : this.crawlType === 2 || this.crawlType === '2' ? '10 pages' : this.crawlType === 3 || this.crawlType === '3' ? '25 Pages' : this.crawlType === 4 || this.crawlType === '4' ? `${this.customSlider} pages` : null
      var planQ = this.crawlType === 1 || this.crawlType === '1' ? 5 : this.crawlType === 2 || this.crawlType === '2' ? 10 : this.crawlType === 3 || this.crawlType === '3' ? 25 : this.crawlType === 4 || this.crawlType === '4' ? this.customSlider : null

      var priceId = ''
      if(this.annualyBill) {
        // yearly
        if(this.crawlType === 1 || this.crawlType === '1') {
          priceId = 'price_1PbxnfEha01YHD9piIeM5vVS'
        } else if(this.crawlType === 2 || this.crawlType === '2') {
          priceId = 'price_1PbxmUEha01YHD9pP485nQ7U'
        } else if(this.crawlType === 3 || this.crawlType === '3') {
          priceId = 'price_1PePQVEha01YHD9prbkraO7a'
        } else if(this.crawlType === 4 || this.crawlType === '4') {
          if((this.customPlan + (this.customSlider - 50)) === 220) {
            priceId = 'price_1PsDdHEha01YHD9pL55OW9S4'
          } else if((this.customPlan + (this.customSlider - 50)) === 270) {
            priceId = 'price_1PsDiIEha01YHD9p4iEAQG55'
          } else if((this.customPlan + (this.customSlider - 50)) === 320) {
            priceId = 'price_1PsDkTEha01YHD9pB3tBA7DS'
          } else if((this.customPlan + (this.customSlider - 50)) === 370) {
            priceId = 'price_1PsDnrEha01YHD9p871921P2'
          } else if((this.customPlan + (this.customSlider - 50)) === 420) {
            priceId = 'price_1PsDpuEha01YHD9pb9KPgKgS'
          } else if((this.customPlan + (this.customSlider - 50)) === 470) {
            priceId = 'price_1PsDrTEha01YHD9pe6nrSiij'
          } else if((this.customPlan + (this.customSlider - 50)) === 520) {
            priceId = 'price_1PsDsdEha01YHD9pgCfMsgji'
          } else if((this.customPlan + (this.customSlider - 50)) === 570) {
            priceId = 'price_1PsDtwEha01YHD9pPCk5ykJM'
          } else if((this.customPlan + (this.customSlider - 50)) === 620) {
            priceId = 'price_1PsDv0Eha01YHD9pNn5eB4ex'
          } else if((this.customPlan + (this.customSlider - 50)) === 670) {
            priceId = 'price_1PsDwTEha01YHD9pgzDwv3k1'
          } else if((this.customPlan + (this.customSlider - 50)) === 720) {
            priceId = 'price_1PsDxUEha01YHD9pg8Zuy2H5'
          } else if((this.customPlan + (this.customSlider - 50)) === 770) {
            priceId = 'price_1PsDz3Eha01YHD9p8lBWkiTs'
          } else if((this.customPlan + (this.customSlider - 50)) === 820) {
            priceId = 'price_1PsE0CEha01YHD9pJIonhW6i'
          } else if((this.customPlan + (this.customSlider - 50)) === 870) {
            priceId = 'price_1PsE1SEha01YHD9psjuJqVgG'
          } else if((this.customPlan + (this.customSlider - 50)) === 920) {
            priceId = 'price_1PsE3ZEha01YHD9pmH2n1KWg'
          } else if((this.customPlan + (this.customSlider - 50)) === 970) {
            priceId = 'price_1PsE4dEha01YHD9pV4ku6S0R'
          } else if((this.customPlan + (this.customSlider - 50)) === 1020) {
            priceId = 'price_1PsE5yEha01YHD9pA74l2pRb'
          } else if((this.customPlan + (this.customSlider - 50)) === 1070) {
            priceId = 'price_1PsE86Eha01YHD9p0WX3p7DT'
          } else if((this.customPlan + (this.customSlider - 50)) === 1120) {
            priceId = 'price_1PsE9YEha01YHD9pC21sQCvi'
          } else if((this.customPlan + (this.customSlider - 50)) === 1170) {
            priceId = 'price_1PsEAkEha01YHD9pD8bwdiAx'
          }
        }
      } else {
        // monthly
        if(this.crawlType === 1 || this.crawlType === '1') {
          priceId = 'price_1PsYAIEha01YHD9pFMqNcy4D'
        } else if(this.crawlType === 2 || this.crawlType === '2') {
          priceId = 'price_1PsY9MEha01YHD9pdo4NDKoI'
        } else if(this.crawlType === 3 || this.crawlType === '3') {
          priceId = 'price_1PsYAmEha01YHD9pstp3srF0'
        } else if(this.crawlType === 4 || this.crawlType === '4') {
          if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 12) {
            priceId = 'price_1PsChZEha01YHD9pUMdUmApc'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 17) {
            priceId = 'price_1PsCmFEha01YHD9pPbZrt36n'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 22) {
            priceId = 'price_1PsCpGEha01YHD9pnE3YcosF'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 27) {
            priceId = 'price_1PsCqsEha01YHD9pnQuGE1Tq'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 32) {
            priceId = 'price_1PsCsXEha01YHD9pzW1Gb5xU'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 37) {
            priceId = 'price_1PsCuPEha01YHD9pEru3TLFq'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 42) {
            priceId = 'price_1PsCwxEha01YHD9pDNZ7x3VD'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 47) {
            priceId = 'price_1PsCyeEha01YHD9pbKFWi2nt'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 52) {
            priceId = 'price_1PsCzxEha01YHD9p3946nYA4'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 57) {
            priceId = 'price_1PsD0wEha01YHD9pPJJwruw6'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 62) {
            priceId = 'price_1PsD2rEha01YHD9pMIcdlvUf'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 67) {
            priceId = 'price_1PsD5CEha01YHD9pnSjH6221'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 72) {
            priceId = 'price_1PsD7hEha01YHD9pPN9VReGi'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 77) {
            priceId = 'price_1PsDCHEha01YHD9pKKKXG09o'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 82) {
            priceId = 'price_1PsDDbEha01YHD9p1PQREKr7'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 87) {
            priceId = 'price_1PsDEKEha01YHD9ped00r5WZ'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 92) {
            priceId = 'price_1PsDFQEha01YHD9psGgej5B8'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 97) {
            priceId = 'price_1PsDGkEha01YHD9poqhAhxFW'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 102) {
            priceId = 'price_1PsDHzEha01YHD9pDW4vS1wZ'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 107) {
            priceId = 'price_1PsDIyEha01YHD9pMiJM1LvQ'
          }
        }
      }

      var urlDesc = new URL(this.idsite.domain.includes('http') ? this.idsite.domain : this.idsite.domainFix)
      var descFix = urlDesc.hostname + (urlDesc.pathname !== '/' ? urlDesc.pathname : '')
      var projectid = this.idsite.projectid
      var siteid = this.idsite.id
      var email = this.userData.email
      var interval = this.annualyBill ? 'year' : 'month'
      plan += ` - Site: ${descFix}`

      var oldPagesActual = parseInt(this.idsite.pages, 10)
      var newPagesActual = parseInt(planQ, 10)
      var reasonChange = ''
      if(this.idsite.paidRecurrency === 'Monthly' && this.annualyBill) {
        reasonChange = 'upgrade'
      } else if(newPagesActual < oldPagesActual) {
        reasonChange = 'downgrade'
      } else {
        reasonChange = 'upgrade'
      }
      // console.log(newPagesActual, oldPagesActual, reasonChange)

      if(this.idsite.customerId) {
        let configUpdCustomerName = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/update-name-customer',
          headers: { 
            'Content-Type': 'application/json'
          },
          data: JSON.stringify({
            customerId: this.idsite.customerId,
            userName: this.userData.name ? this.userData.name : this.userData.email
          })
        };

        this.axios(configUpdCustomerName)
        .then(() => {
          console.log('update name customer');
        })
        .catch(e => {
          console.log(e.message);
        })
      }

      // console.log(reasonChange,this.idsite.freeTrial);
      // if(reasonChange === 'upgrade') {
      if(reasonChange === 'upgrade' && !this.idsite.freeTrial) {
        let configAmountProration = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/get-amount-due-proration',
          headers: { 
            'Content-Type': 'application/json'
          },
          data: JSON.stringify({
            priceId,
            domain,
            amount,
            plan,
            siteid,
            projectid,
            email,
            customerId: this.idsite.customerId,
            serviceDescription: `Site: ${descFix}`,
            interval,
            user: this.userData.id ? this.userData.id : this.userData.uid,
            subscriptionId: this.idsite.subscriptionId,
            reasonChange: reasonChange
          })
        };

        this.axios(configAmountProration)
        .then((responseAmountProration) => {
          // console.log(responseAmountProration.data.subscription);
          if(responseAmountProration.data.success === true) {
            var upcomingInvoice = responseAmountProration.data.subscription
            // const amountDue = upcomingInvoice.total; // Monto total a cobrar en centavos
            const amountDue = upcomingInvoice; // Monto total a cobrar en centavos
            // const amountDueInDollars = (amountDue / 100).toFixed(2); // Convertir a dólares
            const amountDueInDollars = (amountDue).toFixed(2); // Convertir a dólares
            // console.log(`Monto prorrateado: ${amountDue / 100} ${upcomingInvoice.currency.toUpperCase()}`);
            // console.log(`El monto a cobrar al hacer el upgrade es: $${amountDueInDollars}`);

            // this.boxTwo = ''
            // const message = 
            // `Upgrade price is calculated from a credit of the unused portion of the current plan ` +
            // `and billing of the new plan for the same period.` + 
            // `\n\n` + 
            // `Subtotal: $${amountDueInDollars}.`

            // const title = this.$createElement('div', [
            //   this.$createElement('p', 'Upgrade price is calculated from a credit of the unused portion of the current plan and billing of the new plan for the same period.'),
            //   this.$createElement('p', [this.$createElement('strong', 'Subtotal: '), `$${amountDueInDollars}.`]),
            // ])

            this.showOpenCheckout = true
            this.infoCheckout = 'Upgrade price is calculated from a credit of the unused portion of the current plan and billing of the new plan for the same period.'
            this.amountCheckout = amountDueInDollars
            this.processSubmitUpgrade = false

            // this.$bvModal
            //   .msgBoxConfirm(title, {
            //     title: 'Confirm upgrade',
            //     size: 'sm',
            //     okVariant: 'primary',
            //     okTitle: 'Checkout',
            //     cancelTitle: 'Cancel',
            //     cancelVariant: 'outline-secondary',
            //     hideHeaderClose: false,
            //     centered: true,
            //   })
            //   .then(value => {
            //     // this.boxTwo = value
            //     // console.log(value);
            //     if(value) {
            //       let config = {
            //         method: 'post',
            //         maxBodyLength: Infinity,
            //         url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/upgrage-plan',
            //         headers: { 
            //           'Content-Type': 'application/json'
            //         },
            //         data: JSON.stringify({
            //           priceId,
            //           domain,
            //           amount,
            //           plan,
            //           siteid,
            //           projectid,
            //           email,
            //           customerId: this.idsite.customerId,
            //           serviceDescription: `Site: ${descFix}`,
            //           interval,
            //           user: this.userData.id ? this.userData.id : this.userData.uid,
            //           subscriptionId: this.idsite.subscriptionId,
            //           reasonChange: reasonChange,
            //           selectedPaymentMethodId: selectedPaymentMethodId
            //         })
            //       };

            //       this.axios(config)
            //       .then((response) => {
            //         // console.log(response.data)
            //         if(response.status === 200) {
            //           var interval = this.annualyBill ? 'year' : 'month'
            //           db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.id).update({
            //             plan: this.crawlType === '1' || this.crawlType === 1 ? 'basic' : this.crawlType === '2' || this.crawlType === 2 ? 'pro' : this.crawlType === '3' || this.crawlType === 3 ? 'pro' : 'custom',
            //             planType: this.crawlType === '1' || this.crawlType === 1 ? '5' : this.crawlType === '2' || this.crawlType === 2 ? '10' : this.crawlType === '3' || this.crawlType === 3 ? '25' : this.customSlider.toString(),
            //             failPay: false,
            //             due: Date.now(),
            //             cancelSub: false,
            //             customerId: this.idsite.customerId,
            //             paidRecurrency: interval,
            //             user: this.userData.id ? this.userData.id : this.userData.uid,
            //             subscriptionId: response.data.subscription.id
            //           })

            //           this.$toast({
            //             component: ToastificationContent,
            //             props: {
            //               title: 'Success update plan',
            //               icon: 'BellIcon',
            //               variant: 'success',
            //             },
            //           })

            //           this.$router.push({
            //             name: 'view-errors',
            //             query:{
            //               lic: 'upd'
            //             },
            //             params: {
            //               id: this.idsite.id, imgSite: this.idsite.img, name: this.idsite.name, url: this.idsite.domainFixed, project: this.idsite.projectid, own: 'me'
            //             },
            //           })
            //         } else {
            //           this.$toast({
            //             component: ToastificationContent,
            //             props: {
            //               title: 'Error procesing payment, please try again later',
            //               icon: 'BellIcon',
            //               variant: 'warning',
            //             },
            //           })
            //         }
            //         // localStorage.setItem('siteLicenseChanges', true)
            //         setTimeout(() => {
            //           this.showUpdatePlan = false
            //           this.processSubmitUpgrade = false
            //           this.processSubmit = false
            //         }, 1500);
                    
            //       })
            //       .catch(e => {
            //         console.log(e)
            //         this.showUpdatePlan = false
            //         this.processSubmitUpgrade = false
            //         this.processSubmit = false

            //         this.$toast({
            //           component: ToastificationContent,
            //           props: {
            //             title: 'Error procesing payment, please try again later',
            //             icon: 'BellIcon',
            //             variant: 'warning',
            //           },
            //         })
            //       })
            //     } else {
            //       this.$refs.sidebar_info_license.hide()
            //     }
            //   })
            //   .catch((e) => {
            //     console.log(e.message);
            //   })
          }
        })
        .catch(e => {
          console.log(e.message);
        })
      } else {
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/upgrage-plan',
          headers: { 
            'Content-Type': 'application/json'
          },
          data: JSON.stringify({
            priceId,
            domain,
            amount,
            plan,
            siteid,
            projectid,
            email,
            customerId: this.idsite.customerId,
            serviceDescription: `Site: ${descFix}`,
            interval,
            user: this.userData.id ? this.userData.id : this.userData.uid,
            subscriptionId: this.idsite.subscriptionId,
            reasonChange: reasonChange === 'upgrade' ? 'downgrade-upgrade' : 'downgrade'
            // reasonChange: reasonChange
          })
        };

        this.axios(config)
        .then((response) => {
          // console.log(response.data)
          if(response.status === 200) {
            var interval = this.annualyBill ? 'year' : 'month'
            db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.id).update({
              plan: this.crawlType === '1' || this.crawlType === 1 ? 'basic' : this.crawlType === '2' || this.crawlType === 2 ? 'pro' : this.crawlType === '3' || this.crawlType === 3 ? 'pro' : 'custom',
              planType: this.crawlType === '1' || this.crawlType === 1 ? '5' : this.crawlType === '2' || this.crawlType === 2 ? '10' : this.crawlType === '3' || this.crawlType === 3 ? '25' : this.customSlider.toString(),
              failPay: false,
              due: Date.now(),
              cancelSub: false,
              customerId: this.idsite.customerId,
              paidRecurrency: interval,
              user: this.userData.id ? this.userData.id : this.userData.uid,
              subscriptionId: response.data.subscription.id
            })

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success update plan',
                icon: 'BellIcon',
                variant: 'success',
              },
            })

            this.$router.push({
              name: 'view-errors',
              query:{
                lic: 'upd'
              },
              params: {
                id: this.idsite.id, imgSite: this.idsite.img, name: this.idsite.name, url: this.idsite.domainFixed, project: this.idsite.projectid, own: 'me'
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error procesing payment, please try again later',
                icon: 'BellIcon',
                variant: 'warning',
              },
            })
          }
          // localStorage.setItem('siteLicenseChanges', true)
          setTimeout(() => {
            this.processSubmitUpgrade = false
            this.processSubmit = false
          }, 1500);
          
        })
        .catch(e => {
          console.log(e)
          this.processSubmitUpgrade = false
          this.processSubmit = false

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error procesing payment, please try again later',
              icon: 'BellIcon',
              variant: 'warning',
            },
          })
        })
      }
    },
    upgradePlanConfirmation() {
      // console.log('test')
      this.processSubmit = true
      var selectedPaymentMethodId = this.selectedPaymentMethod.id
      // console.log(selectedPaymentMethodId);
      this.showUpdatePlan = false
      this.processSubmitUpgrade = true
      var URLactual = new URL(window.location)
      var domain = URLactual.protocol + '//' + (URLactual.hostname === 'localhost' ? URLactual.hostname + ':8080' : URLactual.hostname)
      // this.crawlType
      var amount = this.crawlType === 1 || this.crawlType === '1' ? (this.annualyBill ? 123*100 : 5*100) : this.crawlType === 2 || this.crawlType === '2' ? (this.annualyBill ? 140*100 : 8*100) : this.crawlType === 3 || this.crawlType === '3' ? (this.annualyBill ? 195*100 : 10*100) : this.crawlType === 4 || this.crawlType === '4' ? (this.annualyBill ? (this.customPlan + (this.customSlider - 50))*100 : (this.customPlanMonth + ((this.customSlider - 50) / 10))*100) : null
      var plan = this.crawlType === 1 || this.crawlType === '1' ? '5 pages' : this.crawlType === 2 || this.crawlType === '2' ? '10 pages' : this.crawlType === 3 || this.crawlType === '3' ? '25 Pages' : this.crawlType === 4 || this.crawlType === '4' ? `${this.customSlider} pages` : null
      var planQ = this.crawlType === 1 || this.crawlType === '1' ? 5 : this.crawlType === 2 || this.crawlType === '2' ? 10 : this.crawlType === 3 || this.crawlType === '3' ? 25 : this.crawlType === 4 || this.crawlType === '4' ? this.customSlider : null

      var priceId = ''
      if(this.annualyBill) {
        // yearly
        if(this.crawlType === 1 || this.crawlType === '1') {
          priceId = 'price_1PbxnfEha01YHD9piIeM5vVS'
        } else if(this.crawlType === 2 || this.crawlType === '2') {
          priceId = 'price_1PbxmUEha01YHD9pP485nQ7U'
        } else if(this.crawlType === 3 || this.crawlType === '3') {
          priceId = 'price_1PePQVEha01YHD9prbkraO7a'
        } else if(this.crawlType === 4 || this.crawlType === '4') {
          if((this.customPlan + (this.customSlider - 50)) === 220) {
            priceId = 'price_1PsDdHEha01YHD9pL55OW9S4'
          } else if((this.customPlan + (this.customSlider - 50)) === 270) {
            priceId = 'price_1PsDiIEha01YHD9p4iEAQG55'
          } else if((this.customPlan + (this.customSlider - 50)) === 320) {
            priceId = 'price_1PsDkTEha01YHD9pB3tBA7DS'
          } else if((this.customPlan + (this.customSlider - 50)) === 370) {
            priceId = 'price_1PsDnrEha01YHD9p871921P2'
          } else if((this.customPlan + (this.customSlider - 50)) === 420) {
            priceId = 'price_1PsDpuEha01YHD9pb9KPgKgS'
          } else if((this.customPlan + (this.customSlider - 50)) === 470) {
            priceId = 'price_1PsDrTEha01YHD9pe6nrSiij'
          } else if((this.customPlan + (this.customSlider - 50)) === 520) {
            priceId = 'price_1PsDsdEha01YHD9pgCfMsgji'
          } else if((this.customPlan + (this.customSlider - 50)) === 570) {
            priceId = 'price_1PsDtwEha01YHD9pPCk5ykJM'
          } else if((this.customPlan + (this.customSlider - 50)) === 620) {
            priceId = 'price_1PsDv0Eha01YHD9pNn5eB4ex'
          } else if((this.customPlan + (this.customSlider - 50)) === 670) {
            priceId = 'price_1PsDwTEha01YHD9pgzDwv3k1'
          } else if((this.customPlan + (this.customSlider - 50)) === 720) {
            priceId = 'price_1PsDxUEha01YHD9pg8Zuy2H5'
          } else if((this.customPlan + (this.customSlider - 50)) === 770) {
            priceId = 'price_1PsDz3Eha01YHD9p8lBWkiTs'
          } else if((this.customPlan + (this.customSlider - 50)) === 820) {
            priceId = 'price_1PsE0CEha01YHD9pJIonhW6i'
          } else if((this.customPlan + (this.customSlider - 50)) === 870) {
            priceId = 'price_1PsE1SEha01YHD9psjuJqVgG'
          } else if((this.customPlan + (this.customSlider - 50)) === 920) {
            priceId = 'price_1PsE3ZEha01YHD9pmH2n1KWg'
          } else if((this.customPlan + (this.customSlider - 50)) === 970) {
            priceId = 'price_1PsE4dEha01YHD9pV4ku6S0R'
          } else if((this.customPlan + (this.customSlider - 50)) === 1020) {
            priceId = 'price_1PsE5yEha01YHD9pA74l2pRb'
          } else if((this.customPlan + (this.customSlider - 50)) === 1070) {
            priceId = 'price_1PsE86Eha01YHD9p0WX3p7DT'
          } else if((this.customPlan + (this.customSlider - 50)) === 1120) {
            priceId = 'price_1PsE9YEha01YHD9pC21sQCvi'
          } else if((this.customPlan + (this.customSlider - 50)) === 1170) {
            priceId = 'price_1PsEAkEha01YHD9pD8bwdiAx'
          }
        }
      } else {
        // monthly
        if(this.crawlType === 1 || this.crawlType === '1') {
          priceId = 'price_1PsYAIEha01YHD9pFMqNcy4D'
        } else if(this.crawlType === 2 || this.crawlType === '2') {
          priceId = 'price_1PsY9MEha01YHD9pdo4NDKoI'
        } else if(this.crawlType === 3 || this.crawlType === '3') {
          priceId = 'price_1PsYAmEha01YHD9pstp3srF0'
        } else if(this.crawlType === 4 || this.crawlType === '4') {
          if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 12) {
            priceId = 'price_1PsChZEha01YHD9pUMdUmApc'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 17) {
            priceId = 'price_1PsCmFEha01YHD9pPbZrt36n'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 22) {
            priceId = 'price_1PsCpGEha01YHD9pnE3YcosF'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 27) {
            priceId = 'price_1PsCqsEha01YHD9pnQuGE1Tq'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 32) {
            priceId = 'price_1PsCsXEha01YHD9pzW1Gb5xU'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 37) {
            priceId = 'price_1PsCuPEha01YHD9pEru3TLFq'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 42) {
            priceId = 'price_1PsCwxEha01YHD9pDNZ7x3VD'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 47) {
            priceId = 'price_1PsCyeEha01YHD9pbKFWi2nt'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 52) {
            priceId = 'price_1PsCzxEha01YHD9p3946nYA4'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 57) {
            priceId = 'price_1PsD0wEha01YHD9pPJJwruw6'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 62) {
            priceId = 'price_1PsD2rEha01YHD9pMIcdlvUf'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 67) {
            priceId = 'price_1PsD5CEha01YHD9pnSjH6221'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 72) {
            priceId = 'price_1PsD7hEha01YHD9pPN9VReGi'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 77) {
            priceId = 'price_1PsDCHEha01YHD9pKKKXG09o'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 82) {
            priceId = 'price_1PsDDbEha01YHD9p1PQREKr7'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 87) {
            priceId = 'price_1PsDEKEha01YHD9ped00r5WZ'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 92) {
            priceId = 'price_1PsDFQEha01YHD9psGgej5B8'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 97) {
            priceId = 'price_1PsDGkEha01YHD9poqhAhxFW'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 102) {
            priceId = 'price_1PsDHzEha01YHD9pDW4vS1wZ'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 107) {
            priceId = 'price_1PsDIyEha01YHD9pMiJM1LvQ'
          }
        }
      }

      var urlDesc = new URL(this.idsite.domain.includes('http') ? this.idsite.domain : this.idsite.domainFix)
      var descFix = urlDesc.hostname + (urlDesc.pathname !== '/' ? urlDesc.pathname : '')
      var projectid = this.idsite.projectid
      var siteid = this.idsite.id
      var email = this.userData.email
      var interval = this.annualyBill ? 'year' : 'month'
      plan += ` - Site: ${descFix}`

      var oldPagesActual = parseInt(this.idsite.pages, 10)
      var newPagesActual = parseInt(planQ, 10)
      var reasonChange = ''
      if(this.idsite.paidRecurrency === 'Monthly' && this.annualyBill) {
        reasonChange = 'upgrade'
      } else if(newPagesActual < oldPagesActual) {
        reasonChange = 'downgrade'
      } else {
        reasonChange = 'upgrade'
      }
      // console.log(newPagesActual, oldPagesActual, reasonChange)

      if(this.idsite.customerId) {
        let configUpdCustomerName = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/update-name-customer',
          headers: { 
            'Content-Type': 'application/json'
          },
          data: JSON.stringify({
            customerId: this.idsite.customerId,
            userName: this.userData.name ? this.userData.name : this.userData.email
          })
        };

        this.axios(configUpdCustomerName)
        .then(() => {
          console.log('update name customer');
        })
        .catch(e => {
          console.log(e.message);
        })
      }

      // console.log(reasonChange,this.idsite.freeTrial);
      if(reasonChange === 'upgrade') {
        let configAmountProration = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/get-amount-due-proration',
          headers: { 
            'Content-Type': 'application/json'
          },
          data: JSON.stringify({
            priceId,
            domain,
            amount,
            plan,
            siteid,
            projectid,
            email,
            customerId: this.idsite.customerId,
            serviceDescription: `Site: ${descFix}`,
            interval,
            user: this.userData.id ? this.userData.id : this.userData.uid,
            subscriptionId: this.idsite.subscriptionId,
            reasonChange: reasonChange
          })
        };

        this.axios(configAmountProration)
        .then((responseAmountProration) => {
          // console.log(responseAmountProration.data.subscription);
          if(responseAmountProration.data.success === true) {
            var upcomingInvoice = responseAmountProration.data.subscription
            // const amountDue = upcomingInvoice.total; // Monto total a cobrar en centavos
            const amountDue = upcomingInvoice; // Monto total a cobrar en centavos
            // const amountDueInDollars = (amountDue / 100).toFixed(2); // Convertir a dólares
            const amountDueInDollars = (amountDue).toFixed(2); // Convertir a dólares
            // console.log(`Monto prorrateado: ${amountDue / 100} ${upcomingInvoice.currency.toUpperCase()}`);
            // console.log(`El monto a cobrar al hacer el upgrade es: $${amountDueInDollars}`);

            // this.boxTwo = ''
            // const message = 
            // `Upgrade price is calculated from a credit of the unused portion of the current plan ` +
            // `and billing of the new plan for the same period.` + 
            // `\n\n` + 
            // `Subtotal: $${amountDueInDollars}.`

            // const title = this.$createElement('div', [
            //   this.$createElement('p', 'Upgrade price is calculated from a credit of the unused portion of the current plan and billing of the new plan for the same period.'),
            //   this.$createElement('p', [this.$createElement('strong', 'Subtotal: '), `$${amountDueInDollars}.`]),
            // ])

            // this.$bvModal
            //   .msgBoxConfirm(title, {
            //     title: 'Confirm upgrade',
            //     size: 'sm',
            //     okVariant: 'primary',
            //     okTitle: 'Checkout',
            //     cancelTitle: 'Cancel',
            //     cancelVariant: 'outline-secondary',
            //     hideHeaderClose: false,
            //     centered: true,
            //   })
            //   .then(value => {
            //     // this.boxTwo = value
            //     // console.log(value);
            //     if(value) {
                  let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/upgrage-plan',
                    headers: { 
                      'Content-Type': 'application/json'
                    },
                    data: JSON.stringify({
                      priceId,
                      domain,
                      amount,
                      plan,
                      siteid,
                      projectid,
                      email,
                      customerId: this.idsite.customerId,
                      serviceDescription: `Site: ${descFix}`,
                      interval,
                      user: this.userData.id ? this.userData.id : this.userData.uid,
                      subscriptionId: this.idsite.subscriptionId,
                      reasonChange: reasonChange,
                      selectedPaymentMethodId: selectedPaymentMethodId
                    })
                  };

                  this.axios(config)
                  .then((response) => {
                    // console.log(response.data)
                    if(response.status === 200) {
                      var interval = this.annualyBill ? 'year' : 'month'
                      db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.id).update({
                        plan: this.crawlType === '1' || this.crawlType === 1 ? 'basic' : this.crawlType === '2' || this.crawlType === 2 ? 'pro' : this.crawlType === '3' || this.crawlType === 3 ? 'pro' : 'custom',
                        planType: this.crawlType === '1' || this.crawlType === 1 ? '5' : this.crawlType === '2' || this.crawlType === 2 ? '10' : this.crawlType === '3' || this.crawlType === 3 ? '25' : this.customSlider.toString(),
                        failPay: false,
                        due: Date.now(),
                        cancelSub: false,
                        customerId: this.idsite.customerId,
                        paidRecurrency: interval,
                        user: this.userData.id ? this.userData.id : this.userData.uid,
                        subscriptionId: response.data.subscription.id
                      })

                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Success update plan',
                          icon: 'BellIcon',
                          variant: 'success',
                        },
                      })

                      this.$router.push({
                        name: 'view-errors',
                        query:{
                          lic: 'upd'
                        },
                        params: {
                          id: this.idsite.id, imgSite: this.idsite.img, name: this.idsite.name, url: this.idsite.domainFixed, project: this.idsite.projectid, own: 'me'
                        },
                      })
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Error procesing payment, please try again later',
                          icon: 'BellIcon',
                          variant: 'warning',
                        },
                      })
                    }
                    // localStorage.setItem('siteLicenseChanges', true)
                    setTimeout(() => {
                      this.processSubmitUpgrade = false
                      this.processSubmit = false
                    }, 1500);
                    
                  })
                  .catch(e => {
                    console.log(e)
                    this.processSubmitUpgrade = false
                    this.processSubmit = false

                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Error procesing payment, please try again later',
                        icon: 'BellIcon',
                        variant: 'warning',
                      },
                    })
                  })
                // } else {
                //   this.$refs.sidebar_info_license.hide()
                // }
              // })
              // .catch((e) => {
              //   console.log(e.message);
              // })
          }
        })
        .catch(e => {
          console.log(e.message);
        })
      } else {
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/upgrage-plan',
          headers: { 
            'Content-Type': 'application/json'
          },
          data: JSON.stringify({
            priceId,
            domain,
            amount,
            plan,
            siteid,
            projectid,
            email,
            customerId: this.idsite.customerId,
            serviceDescription: `Site: ${descFix}`,
            interval,
            user: this.userData.id ? this.userData.id : this.userData.uid,
            subscriptionId: this.idsite.subscriptionId,
            reasonChange: reasonChange
          })
        };

        this.axios(config)
        .then((response) => {
          // console.log(response.data)
          if(response.status === 200) {
            var interval = this.annualyBill ? 'year' : 'month'
            db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.id).update({
              plan: this.crawlType === '1' || this.crawlType === 1 ? 'basic' : this.crawlType === '2' || this.crawlType === 2 ? 'pro' : this.crawlType === '3' || this.crawlType === 3 ? 'pro' : 'custom',
              planType: this.crawlType === '1' || this.crawlType === 1 ? '5' : this.crawlType === '2' || this.crawlType === 2 ? '10' : this.crawlType === '3' || this.crawlType === 3 ? '25' : this.customSlider.toString(),
              failPay: false,
              due: Date.now(),
              cancelSub: false,
              customerId: this.idsite.customerId,
              paidRecurrency: interval,
              user: this.userData.id ? this.userData.id : this.userData.uid,
              subscriptionId: response.data.subscription.id
            })

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success update plan',
                icon: 'BellIcon',
                variant: 'success',
              },
            })

            this.$router.push({
              name: 'view-errors',
              query:{
                lic: 'upd'
              },
              params: {
                id: this.idsite.id, imgSite: this.idsite.img, name: this.idsite.name, url: this.idsite.domainFixed, project: this.idsite.projectid, own: 'me'
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error procesing payment, please try again later',
                icon: 'BellIcon',
                variant: 'warning',
              },
            })
          }
          // localStorage.setItem('siteLicenseChanges', true)
          setTimeout(() => {
            this.processSubmitUpgrade = false
            this.processSubmit = false
          }, 1500);
          
        })
        .catch(e => {
          console.log(e)
          this.processSubmitUpgrade = false
          this.processSubmit = false

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error procesing payment, please try again later',
              icon: 'BellIcon',
              variant: 'warning',
            },
          })
        })
      }
    },
    redirectToBilling(customerId) {
      // this.processInvoice = customerId
      // this.processInvoice = idsite
      this.processSubmitUpgrade = true
      var URLactual = new URL(window.location)
      var domain = URLactual.protocol + '//' + (URLactual.hostname === 'localhost' ? URLactual.hostname + ':8080' : URLactual.hostname)
      // const stripe = await loadStripe('sk_test_51PVKa9Eha01YHD9pNslDNQ1kuqFaEeF0tpDlaoVDEi6tNIRUU0pzEcCkkWovrVDr9rXvbdmJxAD0kLEmAC1GIt4G00uIJjXPQK');
      
      // Aquí llamas a tu backend para obtener el enlace de facturación
      // const response = await fetch('https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/create-billing-session', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({ customerId: 'cus_QVUpm1tcsGouMe', domain: domain})
      // });

      // const { url } = await response.json();

      let config = {
        method: 'post',
        // maxBodyLength: Infinity,
        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/create-billing-session',
        headers: { 
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({customerId: customerId, domain: domain})
      };
  
      this.axios(config)
      .then((response) => {
        // Redirigir al usuario a la URL de facturación de Stripe
        // window.location.href = response
        // console.log(response.data.url);
        var url = response.data.url
        if(url) {
          window.location.href = url

          setTimeout(() => {
            this.processSubmitUpgrade = false
          }, 1500)
        }
      })
      .catch(e => console.log(e.message))

      // // Redirigir al usuario a la URL de facturación de Stripe
      // window.location.href = url;
    },
    goToStripe() {
      var URLactual = new URL(window.location)
      var domain = URLactual.protocol + '//' + (URLactual.hostname === 'localhost' ? URLactual.hostname + ':8080' : URLactual.hostname)
      // this.crawlType
      var amount = this.crawlType === 1 || this.crawlType === '1' ? (this.annualyBill ? 123*100 : 5*100) : this.crawlType === 2 || this.crawlType === '2' ? (this.annualyBill ? 140*100 : 8*100) : this.crawlType === 3 || this.crawlType === '3' ? (this.annualyBill ? 195*100 : 10*100) : this.crawlType === 4 || this.crawlType === '4' ? (this.annualyBill ? (this.customPlan + (this.customSlider - 50))*100 : (this.customPlanMonth + ((this.customSlider - 50) / 10))*100) : null
      var plan = this.crawlType === 1 || this.crawlType === '1' ? '5 pages' : this.crawlType === 2 || this.crawlType === '2' ? '10 pages' : this.crawlType === 3 || this.crawlType === '3' ? '25 Pages' : this.crawlType === 4 || this.crawlType === '4' ? `${this.customSlider} pages` : null

      var priceId = ''
      if(this.annualyBill) {
        // yearly
        if(this.crawlType === 1 || this.crawlType === '1') {
          priceId = 'price_1PbxnfEha01YHD9piIeM5vVS'
        } else if(this.crawlType === 2 || this.crawlType === '2') {
          priceId = 'price_1PbxmUEha01YHD9pP485nQ7U'
        } else if(this.crawlType === 3 || this.crawlType === '3') {
          priceId = 'price_1PePQVEha01YHD9prbkraO7a'
        } else if(this.crawlType === 4 || this.crawlType === '4') {
          if((this.customPlan + (this.customSlider - 50)) === 220) {
            priceId = 'price_1PsDdHEha01YHD9pL55OW9S4'
          } else if((this.customPlan + (this.customSlider - 50)) === 270) {
            priceId = 'price_1PsDiIEha01YHD9p4iEAQG55'
          } else if((this.customPlan + (this.customSlider - 50)) === 320) {
            priceId = 'price_1PsDkTEha01YHD9pB3tBA7DS'
          } else if((this.customPlan + (this.customSlider - 50)) === 370) {
            priceId = 'price_1PsDnrEha01YHD9p871921P2'
          } else if((this.customPlan + (this.customSlider - 50)) === 420) {
            priceId = 'price_1PsDpuEha01YHD9pb9KPgKgS'
          } else if((this.customPlan + (this.customSlider - 50)) === 470) {
            priceId = 'price_1PsDrTEha01YHD9pe6nrSiij'
          } else if((this.customPlan + (this.customSlider - 50)) === 520) {
            priceId = 'price_1PsDsdEha01YHD9pgCfMsgji'
          } else if((this.customPlan + (this.customSlider - 50)) === 570) {
            priceId = 'price_1PsDtwEha01YHD9pPCk5ykJM'
          } else if((this.customPlan + (this.customSlider - 50)) === 620) {
            priceId = 'price_1PsDv0Eha01YHD9pNn5eB4ex'
          } else if((this.customPlan + (this.customSlider - 50)) === 670) {
            priceId = 'price_1PsDwTEha01YHD9pgzDwv3k1'
          } else if((this.customPlan + (this.customSlider - 50)) === 720) {
            priceId = 'price_1PsDxUEha01YHD9pg8Zuy2H5'
          } else if((this.customPlan + (this.customSlider - 50)) === 770) {
            priceId = 'price_1PsDz3Eha01YHD9p8lBWkiTs'
          } else if((this.customPlan + (this.customSlider - 50)) === 820) {
            priceId = 'price_1PsE0CEha01YHD9pJIonhW6i'
          } else if((this.customPlan + (this.customSlider - 50)) === 870) {
            priceId = 'price_1PsE1SEha01YHD9psjuJqVgG'
          } else if((this.customPlan + (this.customSlider - 50)) === 920) {
            priceId = 'price_1PsE3ZEha01YHD9pmH2n1KWg'
          } else if((this.customPlan + (this.customSlider - 50)) === 970) {
            priceId = 'price_1PsE4dEha01YHD9pV4ku6S0R'
          } else if((this.customPlan + (this.customSlider - 50)) === 1020) {
            priceId = 'price_1PsE5yEha01YHD9pA74l2pRb'
          } else if((this.customPlan + (this.customSlider - 50)) === 1070) {
            priceId = 'price_1PsE86Eha01YHD9p0WX3p7DT'
          } else if((this.customPlan + (this.customSlider - 50)) === 1120) {
            priceId = 'price_1PsE9YEha01YHD9pC21sQCvi'
          } else if((this.customPlan + (this.customSlider - 50)) === 1170) {
            priceId = 'price_1PsEAkEha01YHD9pD8bwdiAx'
          }
        }
      } else {
        // monthly
        if(this.crawlType === 1 || this.crawlType === '1') {
          priceId = 'price_1PsYAIEha01YHD9pFMqNcy4D'
        } else if(this.crawlType === 2 || this.crawlType === '2') {
          priceId = 'price_1PsY9MEha01YHD9pdo4NDKoI'
        } else if(this.crawlType === 3 || this.crawlType === '3') {
          priceId = 'price_1PsYAmEha01YHD9pstp3srF0'
        } else if(this.crawlType === 4 || this.crawlType === '4') {
          if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 12) {
            priceId = 'price_1PsChZEha01YHD9pUMdUmApc'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 17) {
            priceId = 'price_1PsCmFEha01YHD9pPbZrt36n'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 22) {
            priceId = 'price_1PsCpGEha01YHD9pnE3YcosF'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 27) {
            priceId = 'price_1PsCqsEha01YHD9pnQuGE1Tq'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 32) {
            priceId = 'price_1PsCsXEha01YHD9pzW1Gb5xU'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 37) {
            priceId = 'price_1PsCuPEha01YHD9pEru3TLFq'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 42) {
            priceId = 'price_1PsCwxEha01YHD9pDNZ7x3VD'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 47) {
            priceId = 'price_1PsCyeEha01YHD9pbKFWi2nt'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 52) {
            priceId = 'price_1PsCzxEha01YHD9p3946nYA4'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 57) {
            priceId = 'price_1PsD0wEha01YHD9pPJJwruw6'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 62) {
            priceId = 'price_1PsD2rEha01YHD9pMIcdlvUf'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 67) {
            priceId = 'price_1PsD5CEha01YHD9pnSjH6221'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 72) {
            priceId = 'price_1PsD7hEha01YHD9pPN9VReGi'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 77) {
            priceId = 'price_1PsDCHEha01YHD9pKKKXG09o'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 82) {
            priceId = 'price_1PsDDbEha01YHD9p1PQREKr7'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 87) {
            priceId = 'price_1PsDEKEha01YHD9ped00r5WZ'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 92) {
            priceId = 'price_1PsDFQEha01YHD9psGgej5B8'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 97) {
            priceId = 'price_1PsDGkEha01YHD9poqhAhxFW'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 102) {
            priceId = 'price_1PsDHzEha01YHD9pDW4vS1wZ'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 107) {
            priceId = 'price_1PsDIyEha01YHD9pMiJM1LvQ'
          }
        }
      }

      var urlDesc = new URL(this.idsite.domain.includes('http') ? this.idsite.domain : this.idsite.domainFix)
      var descFix = urlDesc.hostname + (urlDesc.pathname !== '/' ? urlDesc.pathname : '')
      var projectid = this.idsite.projectid
      var siteid = this.idsite.id
      var email = this.userData.email
      var interval = this.annualyBill ? 'year' : 'month'
      plan += ` - Site: ${descFix}`
      // console.log(plan);

      // console.log(JSON.stringify({
      //     priceId,
      //     domain,
      //     amount,
      //     plan,
      //     siteid,
      //     projectid,
      //     email
      //   }));
      // console.log(this.idsite.customerId);

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/create-checkout-session',
        headers: { 
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          userName: this.userData.name ? this.userData.name : this.userData.email,
          priceId,
          domain,
          amount,
          plan,
          siteid,
          projectid,
          email,
          customerId: this.idsite.customerId,
          serviceDescription: `Site: ${descFix}`,
          interval,
          user: this.userData.id ? this.userData.id : this.userData.uid
        })
      };

      this.axios(config)
      .then((response) => {
        const data = response.data;
        localStorage.setItem('licenseProcess', JSON.stringify(this.idsite))
        // console.log(data.url);
        window.location.href = data.url;
        this.processSubmit = false
        this.$refs.sidebar_info_license.hide()
      })
      .catch(e => {
        console.log(e)
        this.processSubmit = false
        this.$refs.sidebar_info_license.hide()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error procesing payment, please try again later',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      })
    
    },
    submitEditar() {
      this.processSubmit = true
      if(this.crawlType === 0 || this.crawlType === '0') {
        this.cancelSubscription()
        db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.id).update({
          // plan: 'free',
          // planType: '0',
          // due: null
          failPay: false,
          cancelSub: true,
          cancelationReason: 'Downgrade subscription'
        })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Plan succesfully changed',
            icon: 'BellIcon',
            variant: 'success',
          },
        })

        this.$refs.sidebar_info_license.hide()

        // localStorage.setItem('successLicense', JSON.stringify(this.idsite))

        setTimeout(() => {
          this.processSubmit = false
        }, 2000)
      } else {
        // console.log(this.idsite.planType)
        if(this.idsite.customerId && this.idsite.subscriptionId && this.idsite.planType) {
          this.selectedPaymentMethod = ''
          this.optionPaymentMethod = []

          var atomCustomerId = this.idsite.customerId
          let configpayments = {
            method: 'post',
            url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/get-payments-methods',
            headers: { 
              'Content-Type': 'application/json'
            },
            data: JSON.stringify({customerId: atomCustomerId})
          };
      
          this.axios(configpayments)
          .then((responsepayments) => {
            // console.log(responsepayments.data.paymentMethods);
            var responsePaymentsArray = responsepayments.data.paymentMethods.data
            var paymentId = responsepayments.data.defaultPaymentMethod
            var atomArrayPayments = []
            var isDefault = false
            var counterDefCard = 0
            // console.log(paymentId,responsepayments.data)
            this.showUpdatePlan = true
            this.selectedPaymentMethod = ''
            this.optionPaymentMethod = []
            var plan = this.crawlType === 1 || this.crawlType === '1' ? '5 pages license' : this.crawlType === 2 || this.crawlType === '2' ? '10 pages license' : this.crawlType === 3 || this.crawlType === '3' ? '25 pages license' : this.crawlType === 4 || this.crawlType === '4' ? `${this.customSlider} pages license` : null
            this.planSelectedPayment = 'Upgrade to: '+ plan

            if(responsePaymentsArray.length) {
              responsePaymentsArray.forEach(ele => {
                if(paymentId) {
                  if(paymentId === ele.id) {
                    isDefault = true
                  } else {
                    isDefault = false
                  }
                } else {
                  if(counterDefCard === 0) {
                    isDefault = true
                  } else {
                    isDefault = false
                  }
                  counterDefCard += 1
                }

                if(isDefault) {
                  this.selectedPaymentMethod = {id: ele.id, label: ele.card.brand.toUpperCase() + ' ending ' + ele.card.last4 + ' expires ' + ele.card.exp_month + '/' + ele.card.exp_year}
                  this.optionPaymentMethod.unshift({id: ele.id, label: ele.card.brand.toUpperCase() + ' ending ' + ele.card.last4 + ' expires ' + ele.card.exp_month + '/' + ele.card.exp_year})
                } else {
                  this.optionPaymentMethod.push({id: ele.id, label: ele.card.brand.toUpperCase() + ' ending ' + ele.card.last4 + ' expires ' + ele.card.exp_month + '/' + ele.card.exp_year})
                }
            
                // let configCard = {
                //   method: 'post',
                //   maxBodyLength: Infinity,
                //   url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/get-saved-card',
                //   headers: { 
                //     'Content-Type': 'application/json'
                //   },
                //   data: JSON.stringify({
                //     customerId: this.idsite.customerId
                //   })
                // };
          
                // this.axios(configCard)
                // .then((response) => {
                //   if(response.status === 200) {
                    // this.showUpdatePlan = true
                    // this.selectedPaymentMethod = ''
                    // this.optionPaymentMethod = []
                    // var plan = this.crawlType === 1 || this.crawlType === '1' ? '5 pages license' : this.crawlType === 2 || this.crawlType === '2' ? '10 pages license' : this.crawlType === 3 || this.crawlType === '3' ? '25 pages license' : this.crawlType === 4 || this.crawlType === '4' ? `${this.customSlider} pages license` : null
                    // this.planSelectedPayment = 'Upgrade to: '+ plan
                    // console.log(response.data);
                    // var counterOnlyOne = 0
                    // response.data.cards.forEach(ele => {
                    //   // console.log(ele)
                    //   // this.selectedPaymentMethod = ele.brand.toUpperCase() + ' ending ' + ele.last4 + ' expires ' + ele.exp_month + '/' + ele.exp_year
                    //   if(counterOnlyOne === 0) {
                    //     counterOnlyOne += 1
                    //     this.selectedPaymentMethod = {id: ele.id, label: ele.brand.toUpperCase() + ' ending ' + ele.last4 + ' expires ' + ele.exp_month + '/' + ele.exp_year}
                    //   }
                    //   this.optionPaymentMethod.push({id: ele.id, label: ele.brand.toUpperCase() + ' ending ' + ele.last4 + ' expires ' + ele.exp_month + '/' + ele.exp_year})
                    // })
                    // this.upgradePlan()
                //   } else {
                //     this.goToStripe()
                //   }
                // })
                // .catch(e => {
                //   console.log(e);
                // })
              })
            } else {
              this.goToStripe()
            }
          })
          .catch(e => {
            console.log(e);
          })
        } else {
          this.goToStripe()
        }
      }
    },
    submitCancelation() {
      // console.log('object');
      localStorage.setItem('cancelInfoLicense', JSON.stringify(this.idsite))
      this.$refs.sidebar_info_license.hide()
    },
    reactivateSubscription() {
      let config = {
        method: 'post',
        // maxBodyLength: Infinity,
        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/reactivate-subscription',
        headers: { 
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({subscriptionId: this.idsite.subscriptionId})
      };
  
      this.axios(config)
      .then((response) => {
        console.log(response.data);
        db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.id).update({
          // plan: 'free',
          // planType: '0',
          // due: null
          failPay: false,
          cancelSub: false
          // cancelationReason: 'Downgrade subscription'
        })

        this.$router.push({
          name: 'view-errors',
          query:{
            lic: 'upd'
          },
          params: {
            id: this.idsite.id, imgSite: this.idsite.img, name: this.idsite.name, url: this.idsite.domainFixed, project: this.idsite.projectid, own: 'me'
          },
        })
      })
      .catch(e => {
        console.log(e.message);
      })
    },
    cancelSubscription() {
      let config = {
        method: 'post',
        // maxBodyLength: Infinity,
        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/cancel-subscription',
        headers: { 
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({subscriptionId: this.idsite.subscriptionId})
      };
  
      this.axios(config)
      .then((response) => {
        console.log(response.data);
        this.$router.push({
          name: 'view-errors',
          query:{
            lic: 'upd'
          },
          params: {
            id: this.idsite.id, imgSite: this.idsite.img, name: this.idsite.name, url: this.idsite.domainFixed, project: this.idsite.projectid, own: 'me'
          },
        })
      })
      .catch(e => {
        console.log(e.message);
      })
    },
    async handleSubmitCC() {
      // console.log('trigger 1',this.cardElement);
      this.processSubmitUpgrade = true
      // Crear un token o PaymentMethod con los datos de la tarjeta
      setTimeout(() => {
        // console.log(paymentMethod.id);
        // if(!paymentMethod.id) {
          this.processSubmitUpgrade = false
        // }
      }, 2500);
      const { paymentMethod, error } = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.cardElement,
      });

      if(error) {
        // console.log('object1');
        console.error(error);
        // this.showUpdateCC = false
        this.processSubmitUpgrade = false

        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.code === 'validation_error' ? 'Incorrect data' : error.code === 'invalid_expiry_year_past' ? 'The card expiration year is wrong' : error.code === 'invalid_number' ? 'Your card number is not valid' : error.code === 'incomplete_number' ? 'Your card number is incomplete' : error.code === 'incomplete_zip' ? 'Your zip code is incomplete' : 'Incorrect data',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      } else {
        // console.log('object2');
        // Enviar el paymentMethod.id a tu backend
        this.savePaymentMethod(paymentMethod.id);
        // this.processSubmitUpgrade = true
      }
    },
    async savePaymentMethod(paymentMethodId) {
      // console.log('trigger 2');
      let config = {
        method: 'post',
        // maxBodyLength: Infinity,
        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/save-payment-method',
        headers: { 
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ paymentMethodId: paymentMethodId, customerId: this.idsite.customerId })
      };
  
      this.axios(config)
      .then((response) => {
        // console.log(response.data, response.data.status);
        if(response.status === 200) {
          this.submitEditar()
          setTimeout(() => {
            this.processSubmitUpgrade = false
            this.showUpdateCC = false
            // console.log(JSON.stringify(response))
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Payment method added successfully',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }, 2000)
        } else {
          // console.log(e.message);
          this.processSubmitUpgrade = false
          this.showUpdateCC = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to add payment method',
              icon: 'BellIcon',
              variant: 'warning',
            },
          })
        }
      })
      .catch(e => {
        console.log(e.message);
        this.processSubmitUpgrade = false
        this.showUpdateCC = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to add payment method',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      })
    }
  },
  mounted() {
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
#card-element {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
input[type="radio"]:checked {
  background-color: #7838FF;
  padding: 4px;
  border: 5px solid #7838FF;
}
input[type=radio]{
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-clip: content-box;
  border: 2px solid #DCDCF2;
  background-color: #F0F1F7;
}
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
.slider-custom-plan .vue-slider-process {
  background-color: #7838ff;
}
.slider-custom-plan .vue-slider-dot-tooltip-inner {
  background-color: #7838ff;
  border-color: #7838ff;
}
.update-plan-confirm:hover {
  border: 1px solid #7838FF !important;
  opacity: 0.8;
}
.modal-open .modal.fade.show {
  background: #0000004f !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-incomplete-crawl {
  width: 90%;
  max-width: 593px !important;
}
</style>