/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * @fileoverview This file contains helpers for constructing and rendering the
 * critical request chains network tree.
 */

import {formatBytesToKiB, formatMilliseconds} from "@/views/apps/sites/sites-edit/site-errors/Performance/@core/utils/tableUtils";
const crcLongestDurationLabel = 'Maximum critical path latency:';

/** @typedef {import('./details-renderer.js').DetailsRenderer} DetailsRenderer */
/**
 * @typedef CRCSegment
 * @property {LH.Audit.Details.SimpleCriticalRequestNode[string]} node
 * @property {boolean} isLastChild
 * @property {boolean} hasChildren
 * @property {number} startTime
 * @property {number} transferSize
 * @property {boolean[]} treeMarkers
 */

class CriticalRequestChainRenderer {
    /**
     * Create render context for critical-request-chain tree display.
     * @param {LH.Audit.Details.SimpleCriticalRequestNode} tree
     * @return {{tree: LH.Audit.Details.SimpleCriticalRequestNode, startTime: number, transferSize: number}}
     */
    static initTree(tree) {
        let startTime = 0;
        const rootNodes = Object.keys(tree);
        if (rootNodes.length > 0) {
            const node = tree[rootNodes[0]];
            startTime = node.request.startTime;
        }

        return {tree, startTime, transferSize: 0};
    }

    /**
     * Helper to create context for each critical-request-chain node based on its
     * parent. Calculates if this node is the last child, whether it has any
     * children itself and what the tree looks like all the way back up to the root,
     * so the tree markers can be drawn correctly.
     * @param {LH.Audit.Details.SimpleCriticalRequestNode} parent
     * @param {string} id
     * @param {number} startTime
     * @param {number} transferSize
     * @param {Array<boolean>=} treeMarkers
     * @param {boolean=} parentIsLastChild
     * @return {CRCSegment}
     */
    static createSegment(parent, id, startTime, transferSize, treeMarkers, parentIsLastChild) {
        const node = parent[id];
        const siblings = Object.keys(parent);
        const isLastChild = siblings.indexOf(id) === (siblings.length - 1);
        const hasChildren = !!node.children && Object.keys(node.children).length > 0;

        // Copy the tree markers so that we don't change by reference.
        const newTreeMarkers = Array.isArray(treeMarkers) ? treeMarkers.slice(0) : [];

        // Add on the new entry.
        if (typeof parentIsLastChild !== 'undefined') {
            newTreeMarkers.push(!parentIsLastChild);
        }

        return {
            node,
            isLastChild,
            hasChildren,
            startTime,
            transferSize: transferSize + node.request.transferSize,
            treeMarkers: newTreeMarkers,
        };
    }

    /**
     * Creates the HTML string for a tree segment.
     * @param {CRCSegment} segment
     * @param {DetailsRenderer} detailsRenderer
     * @return {string}
     */
    static createChainNode(segment, detailsRenderer) {
        const url = segment.node.request.url;
        const linkEl = `<a href="${url}" target="_blank" rel="noopener">${url}</a>`;

        const treeMarkersHtml = segment.treeMarkers.map(separator => {
            const classSeparator = separator ? 'lh-tree-marker lh-vert' : 'lh-tree-marker';
            return `<span class="${classSeparator}"></span><span class="lh-tree-marker"></span>`;
        }).join('');

        const classLastChild = segment.isLastChild ? 'lh-tree-marker lh-up-right' : 'lh-tree-marker lh-vert-right';
        const classHasChildren = segment.hasChildren ? 'lh-tree-marker lh-horiz-down' : 'lh-tree-marker lh-right';

        const durationHtml = !segment.hasChildren ? `
            <span class="lh-crc-node__chain-duration"> - ${formatMilliseconds((segment.node.request.endTime - segment.node.request.startTime) * 1000)}, </span>
            <span class="lh-crc-node__chain-duration">${formatBytesToKiB(segment.node.request.transferSize, 0.01)}</span>
        ` : '';

        return `
            <div class="lh-crc-node" title="${url}">
                <div class="lh-crc-node__tree-marker">
                    ${treeMarkersHtml}
                    <span class="${classLastChild}"></span>
                    <span class="lh-tree-marker lh-right"></span>
                    <span class="${classHasChildren}"></span>
                </div>
                <div class="lh-crc-node__tree-value">
                    ${linkEl}
                    ${durationHtml}
                </div>
            </div>
        `;
    }

    /**
     * Recursively builds a tree from segments.
     * @param {CRCSegment} segment
     * @param {DetailsRenderer} detailsRenderer
     * @return {string}
     */
    static buildTree(segment, detailsRenderer) {
        let html = this.createChainNode(segment, detailsRenderer);
        if (segment.node.children) {
            for (const key of Object.keys(segment.node.children)) {
                const childSegment = this.createSegment(segment.node.children, key, segment.startTime, segment.transferSize, segment.treeMarkers, segment.isLastChild);
                html += this.buildTree(childSegment, detailsRenderer);
            }
        }
        return html;
    }

    /**
     * @param {LH.Audit.Details.CriticalRequestChain} details
     * @param {DetailsRenderer} detailsRenderer
     * @return {string}
     */
    static render(details, detailsRenderer) {
        if(!details || !details.chains) {
            return '';
        }
        const root = this.initTree(details.chains);
        let html = `
            <div class="lh-crc-container">
                <div class="lh-crc">
                    <div class="lh-crc-initial-nav">Initial Navigation</div>
                    <div class="lh-crc__longest_duration_label">${crcLongestDurationLabel}</div>
                    <div class="lh-crc__longest_duration">${formatMilliseconds(details.longestChain.duration)}</div>
                </div>
        `;

        for (const key of Object.keys(root.tree)) {
            const segment = this.createSegment(root.tree, key, root.startTime, root.transferSize);
            html += this.buildTree(segment, detailsRenderer);
        }

        html += '</div>';
        return html;
    }
}

// Alias b/c the name is really long.
const CRCRenderer = CriticalRequestChainRenderer;

export {
    CriticalRequestChainRenderer,
}
