<template>
    <div>
        <create-alert
            :idsite.sync="idsite"
            :show-open-create-alert.sync="showOpenCreateAlert"
        />
        <register-sidebar :show-open-register-sidebar.sync="showOpenRegisterSidebar" />
        <add-site-project :show-open-add-site-project.sync="showOpenAddSiteProject" :idsite="idsite" />

        <b-card
            no-body
            class="overflow-hidden w-100 mt-0"
            style="background-color: transparent;"
        >
            <b-overlay
              :show="!idsiteLocalData.id ? true : false"
              rounded="sm"
              variant="transparent"
              :opacity="0.94"
              blur="2px"
            >
                <b-row
                    no-gutters
                    class="mt-1 mx-1"
                >
                    <b-col
                        md="12"
                        class="m-auto"
                    >
                        <div class="float-right" style="margin-top: 7px;">
                        <b-button
                            variant="info"
                            class=""
                            style="border-radius: 50px; vertical-align: middle; display: flex; position: absolute; right: 16px; top: 2px; width: 106px !important;"
                            @click="actionShowOpenCreateAlert()"
                        >
                            <feather-icon
                            icon="BellIcon"
                            class="mr-50"
                            />
                            <span class="align-middle">Alerts</span>
                        </b-button>
                        </div>
                    </b-col>

                    <h3 style="text-align: center;" class="mt-1">Index status</h3>
                    <div class="d-flex w-100">
                        <b-button
                            variant="outline-secondary"
                            :class="(crawlsId.includes(idsite.id) || crawls.includes(idsite.id)) ? 'btn-icon ml-50 cursor-auto mt-1' : 'btn-icon ml-50 cursor-pointer mt-1'"
                            :disabled="(crawlsId.includes(idsite.id) || crawls.includes(idsite.id)) ? true : false"
                            :style="'border: 0px !important; padding: 0px !important; position: relative; top: -1px; margin-left: 5px !important; margin-bottom: 5px;'"
                            @click.prevent.stop="crawlSite(idsite.id)"
                        >
                            <b-img-lazy
                                fluid
                                :src="refreshIcon"
                                alt="refresh"
                                width="20"
                                height="24"
                                :class="(crawlsId.includes(idsite.id) || crawls.includes(idsite.id)) ? 'rotate' : ''"
                                loading="lazy"
                            />
                        </b-button>
                        
                        <p v-if="(crawlsId.includes(idsite.id) || crawls.includes(idsite.id))" class="mb-0" style="text-overflow: ellipsis; overflow: hidden; font-size: 14px; white-space: nowrap; text-align: center;padding-top: 12px; padding-left: 10px;">
                            <b style="font-weight: 300 !important;">checking</b>
                        </p>
                        <p v-else-if="crawlNow && siteCrawlNow === idsite.id" class="mb-0" style="text-overflow: ellipsis; overflow: hidden; font-size: 14px; white-space: nowrap; text-align: center; padding-top: 12px; padding-left: 10px;">
                            <b :style="siteCrawlNow === idsite.id && getFromNowLastCrawl() === 'Now' ? 'color: #7838FF !important; font-weight: 300 !important;' : getFromNowLastCrawl() === 'Now' || getFromNowLastCrawl().includes('seconds') || getFromNowLastCrawl().includes('second') || getFromNowLastCrawl().includes('minute') || getFromNowLastCrawl().includes('hour') || getFromNowLastCrawl().includes('hours') || getFromNowLastCrawl().includes('minutes') || getFromNowLastCrawl() === 'a day ago' || getFromNowLastCrawl() === '1 day ago' || getFromNowLastCrawl() === '1 days ago' || getFromNowLastCrawl() === '2 days ago' || getFromNowLastCrawl() === '3 days ago' || getFromNowLastCrawl() === '4 days ago' || getFromNowLastCrawl() === '5 days ago' || getFromNowLastCrawl() === '6 days ago' || getFromNowLastCrawl() === '7 days ago' ? 'font-weight: 300 !important; color: #A7AAB9 !important;' : 'color: rgb(252, 125, 125) !important; font-weight: 300 !important;'">{{getFromNowLastCrawl()}}</b>
                        </p>
                        <p v-else-if="arrayCrawlDate.filter(ele => ele.url === idsite.domainFix).length" class="mb-0" style="text-overflow: ellipsis; overflow: hidden; font-size: 14px; white-space: nowrap; text-align: center; padding-top: 12px; padding-left: 10px;">
                            <b :style="getFromNowLastCrawlHistory(idsite.domainFix) === 'Now' ? 'color: #7838FF !important; font-weight: 300 !important;' : getFromNowLastCrawlHistory(idsite.domainFix) === 'Now' || getFromNowLastCrawlHistory(idsite.domainFix).includes('seconds') || getFromNowLastCrawlHistory(idsite.domainFix).includes('second') || getFromNowLastCrawlHistory(idsite.domainFix).includes('minute') || getFromNowLastCrawlHistory(idsite.domainFix).includes('hour') || getFromNowLastCrawlHistory(idsite.domainFix).includes('hours') || getFromNowLastCrawlHistory(idsite.domainFix).includes('minutes') || getFromNowLastCrawlHistory(idsite.domainFix) === 'a day ago' || getFromNowLastCrawlHistory(idsite.domainFix) === '1 day ago' || getFromNowLastCrawlHistory(idsite.domainFix) === '1 days ago' || getFromNowLastCrawlHistory(idsite.domainFix) === '2 days ago' || getFromNowLastCrawlHistory(idsite.domainFix) === '3 days ago' || getFromNowLastCrawlHistory(idsite.domainFix) === '4 days ago' || getFromNowLastCrawlHistory(idsite.domainFix) === '5 days ago' || getFromNowLastCrawlHistory(idsite.domainFix) === '6 days ago' || getFromNowLastCrawlHistory(idsite.domainFix) === '7 days ago' ? 'font-weight: 300 !important; color: #A7AAB9 !important;' : 'color: rgb(252, 125, 125) !important; font-weight: 300 !important;'">{{getFromNowLastCrawlHistory(idsite.domainFix)}}</b>
                        </p>
                    </div>
        
                    <table style="width: 100%;" class="my-1" v-if="idsite.lci !== 'timeout'">
                        <tr>
                            <td style="width: 150px;">
                                <b>Noindex tag:</b>
                            </td>
                            <td>
                                <span
                                v-if="(crawlsId.includes(idsite.id) || crawls.includes(idsite.id))"
                                :class="'cursor-initial'"
                                :style="'font-size: 15px; text-align: center; position: relative; top: 1px; color: #ff9f43 !important;'"
                                >
                                <b-spinner
                                small
                                class=""
                                style="margin-bottom: 2px; font-size: 5px;"
                                variant="primary"
                                /></span>
                                <span
                                v-else
                                class=""
                                :style="crawlNow && siteCrawlNow === idsite.id ? 'font-size: 15px; position: relative; top: 2px;' : 'font-size: 15px; position: relative; top: 2px;'"
                                >
                                <b-spinner
                                small
                                class=""
                                style="margin-bottom: 2px; font-size: 5px;"
                                variant="primary"
                                v-if="(crawlsId.includes(idsite.id) || crawls.includes(idsite.id))"
                                />
                                <feather-icon icon="CheckIcon" size="22" v-else-if="idsiteLocalData.s_index === 'index' || idsiteLocalData.s_index === 'noset'" style="color: #28c76f; top: -3px; position: relative; margin-right: 3px;" />
                                <feather-icon icon="XIcon" size="22" v-else style="color: #ea5455; top: -3px; position: relative; margin-right: 3px;" />
                                {{idsiteLocalData.s_index === 'index' ? 'index' : idsiteLocalData.s_index === 'noset' ? 'not set' : 'noindex'}}</span>
                                <!-- </span> -->
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 150px;">
                                <b>robots.txt:</b>
                            </td>
                            <td>
                                <div>
                                    <span
                                        v-if="(crawlsId.includes(idsite.id) || crawls.includes(idsite.id))"
                                        :class="'cursor-initial'"
                                        :style="'font-size: 15px; text-align: center; position: relative; top: 1px; color: #ff9f43 !important;'"
                                    >
                                    <b-spinner
                                        small
                                        class=""
                                        style="margin-bottom: 2px; font-size: 5px;"
                                        variant="primary"
                                    /></span>
                                    <span
                                        v-else
                                        :class="idsiteLocalData.s_robot === 'Disallow' || idsiteLocalData.s_robot === 'DisallowH' ? 'cursor-pointer' : 'cursor-initial'"
                                        :style="crawlNow && siteCrawlNow === idsite.id ? (idsiteLocalData.s_robot === 'Allow' ? 'font-size: 15px; text-align: center; position: relative; top: 1px;' : idsiteLocalData.s_robot === 'blocked' || idsiteLocalData.s_robot === 'notfound' ? 'font-size: 15px; text-align: center; position: relative; top: 1px; color: rgb(167, 170, 185);' : idsiteLocalData.s_robot === 'DisallowH' ? 'font-size: 15px; text-align: center; color: #ea5455 !important; position: relative; top: 1px;' : 'font-size: 15px; text-align: center; color: #ff9f43 !important; position: relative; top: 1px;') : (idsiteLocalData.s_robot === 'Allow' ? 'font-size: 15px; text-align: center; position: relative; top: 1px;' : idsiteLocalData.s_robot === 'blocked' || idsiteLocalData.s_robot === 'notfound' ? 'font-size: 15px; text-align: center; position: relative; top: 1px; color: rgb(167, 170, 185);' : idsiteLocalData.s_robot === 'DisallowH' ? 'font-size: 15px; text-align: center; position: relative; top: 1px; color: #ea5455 !important;' : 'font-size: 15px; text-align: center; position: relative; top: 1px; color: #ff9f43 !important;')"
                                        @click.prevent.stop="idsiteLocalData.s_robot === 'Disallow' || idsiteLocalData.s_robot === 'DisallowH' ? logIdRobots(idsite) : null"
                                    >
                                    <b-spinner
                                        small
                                        class=""
                                        style="margin-bottom: 2px; font-size: 5px;"
                                        variant="primary"
                                        v-if="(crawlsId.includes(idsite.id) || crawls.includes(idsite.id))"
                                    />
                                    <feather-icon icon="CheckIcon" size="22" v-else-if="idsiteLocalData.s_robot === 'Allow'" style="color: #28c76f; top: -3.5px; position: relative; margin-right: 5px;" />
                                    <feather-icon icon="CheckIcon" size="22" v-else-if="idsiteLocalData.s_robot === 'blocked' || idsiteLocalData.s_robot === 'notfound'" style="color: #28c76f; top: -3.5px; position: relative; margin-right: 5px;" />
                                    <feather-icon :icon="idsiteLocalData.s_robot === 'DisallowH' ? 'XIcon' : 'AlertTriangleIcon'" size="22" v-else :style="idsiteLocalData.s_robot === 'DisallowH' ? 'color: #ea5455 !important; top: -3.5px; position: relative; margin-right: 5px;' : 'color: #ff9f43 !important; top: -3.5px; position: relative; margin-right: 5px;'" />
                                    {{idsiteLocalData.s_robot === 'DisallowH' ? 'Disallow' : idsiteLocalData.s_robot === 'notfound' ? 'not found' : idsiteLocalData.s_robot}}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 150px;">
                                <b>HTTP Header:</b>
                            </td>
                            <td>
                                <div>
                                    <span
                                        v-if="(crawlsId.includes(idsite.id) || crawls.includes(idsite.id))"
                                        :class="'cursor-initial'"
                                        :style="'font-size: 15px; text-align: center; position: relative; top: 1px; color: #ff9f43 !important;'"
                                    >
                                    <b-spinner
                                        small
                                        class=""
                                        style="margin-bottom: 2px; font-size: 5px;"
                                        variant="primary"
                                    /></span>
                                    <span
                                        v-else
                                        class=""
                                        :style="crawlNow && siteCrawlNow === idsite.id ? 'font-size: 15px; position: relative; top: 2px;' : 'font-size: 15px; position: relative; top: 2px;'"
                                    >
                                    <b-spinner
                                        small
                                        class=""
                                        style="margin-bottom: 3px; font-size: 5px;"
                                        variant="primary"
                                        v-if="(crawlsId.includes(idsite.id) || crawls.includes(idsite.id))"
                                    />
                                    <feather-icon icon="CheckIcon" size="22" v-else-if="idsiteLocalData.s_httphead === 'index' || idsiteLocalData.s_httphead === 'noset'" style="color: #28c76f; top: -3px; position: relative; margin-right: 3px;" />
                                    <feather-icon icon="XIcon" size="22" v-else style="color: #ea5455; top: -3px; position: relative; margin-right: 3px;" />
                                    {{idsiteLocalData.s_httphead === 'index' ? 'index' : idsiteLocalData.s_httphead === 'noset' ? 'not set' : 'noindex'}}</span>
                                </div>
                            </td>
                        </tr>
                    </table>
        
                    <div v-if="idsiteLocalData.lci === 'timeout'" style="width: 100%; font-size: 18px;" class="mt-1">
                        <b-badge variant="warning">
                        timeout
                        </b-badge>
                    </div>
        
                    <!-- <b-button
                        :style="'background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 15px; margin-right: 40px;'"
                        class="float-left mt-2"
                        variant="primary"
                        @click.prevent="submitEditar()"
                    >
                        <span
                        class=""
                        style="font-weight: 700; color: #FFFFFF;"
                        >Subscribe</span>
                    </b-button>
                    <span style="color: rgb(162, 162, 162); width: 100%; text-align: left; margin-top: 5px; margin-left: 10px;" class="float-left">for automated checks</span> -->
        
                    <div
                        class="b-sidebar-body mt-2"
                        style="padding-top: 20px; padding-bottom: 20px; width: 100%;"
                    >
                        <h3>Index history</h3>
            
                        <history :siteid.sync="idsite.id" />
                    </div>
                </b-row>
            </b-overlay>
        </b-card>
    </div>
  </template>
  
  <script>
  import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BImgLazy, BSpinner, BOverlay,
    BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow, BBadge,
  } from 'bootstrap-vue'
  import { avatarText } from '@core/utils/filter'
  import flatPickr from 'vue-flatpickr-component'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { ref } from '@vue/composition-api'
  import {
    required, minLength, password, confirmed, email,
  } from 'vuelidate/lib/validators'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
//   import HelpFooter from '@/views/components/footer/HelpFooter'
  import History from '@/views/apps/sites/sites-edit/IndexHistory.vue'
  import CreateAlert from '@/views/apps/sites/sites-edit/CreateAlert.vue'
  import RegisterSidebar from '@/views/pages/authentication/RegisterSidebar.vue'
  import AddSiteProject from '@/views/extensions/acl/table/sidebar/AddSiteProject.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { mapActions } from 'vuex'
  import countries from '@/@fake-db/data/other/countries'
  import store from '@/store'
  import moment from 'moment'
  import { db, auth, firebase } from '@/firebase'
  
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      BFormTextarea,
      vSelect,
      BFormCheckbox,
      BFormCheckboxGroup,
      BFormGroup,
      BFormTimepicker,
      BAvatar,
      BCard,
      BCardImgLazy,
      BCardText,
      BCol,
      BRow,
      BListGroup,
      BListGroupItem,
      BImgLazy,
      BSpinner,
      BOverlay,
      BBadge,
      CreateAlert,
      RegisterSidebar,
      AddSiteProject,
    //   HelpFooter,
    //   HeaderSidebar,
      History,
  
      // date
      flatPickr,
  
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
      'b-toggle': VBToggle,
      'b-tooltip': VBTooltip,
    },
    props: ['idsite'],
    data() {
      return {
        email,
        required,
        password,
        confirmed,
        userData: '',
  
        //   sources
        appImgComingSoon: require('@/assets/images/icons/logo-coming-soon.svg'),
        refreshIcon: require('@/assets/images/icons/i-refesh.svg'),
        crawls: [],
        crawlNow: '',
        siteCrawlNow: '',
        lastCrawlDate: '',
        enabledAlert: false,
        openSide: false,
        showOpenCreateAlert: false,
        showOpenRegisterSidebar: false,
        showOpenAddSiteProject: false,

        arrayCrawlDate: [],
        // idsite: {},
        crawlsId: [],

        idsiteLocalData: {},
        localArrayCrawlDate: {},
      }
    },
    watch: {
      idsite(val) {
        // console.log(val)
        if(val && val.id) {
          // console.log(val);

          // this.$emit('update:idsite', val)
          if(!this.idsiteLocalData.id) {
            localStorage.setItem('idsiteIndexInfo', JSON.stringify(val))
          }

          this.localArrayCrawlDate = val;
        //   document.body.style.overflow = 'hidden'
          db.collection('Sites').doc(val.id)
          .get().then(siteDoc => {
        //   .onSnapshot(siteDoc => {
            // console.log(siteDoc.exists)
            if(siteDoc.exists) {
                this.arrayCrawlDate = []
                if(siteDoc.data().mostRecentCrawlDateIndex || siteDoc.data().mostRecentCrawlDate) {
                  // console.log(siteDoc.data().homepage,siteDoc.data().mostRecentCrawlDateIndex)
                  this.arrayCrawlDate.push({
                    url: siteDoc.data().homepage,
                    date: siteDoc.data().mostRecentCrawlDateIndex ? siteDoc.data().mostRecentCrawlDateIndex : siteDoc.data().mostRecentCrawlDate
                  })
                  // this.getFromNowLastCrawl()
                }
                var lci = siteDoc.data().lci ? siteDoc.data().lci : 'success'

                db.collection('Sites_cache').doc(siteDoc.id).get()
                .then(docSiteCache => {
                    this.idsiteLocalData = {
                        lci: lci,
                        imgSite: siteDoc.data().thumbnail ? siteDoc.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png',
                        name: siteDoc.data().name ? siteDoc.data().name : siteDoc.data().homepage,
                        domainFix: siteDoc.data().homepage,
                        domain: siteDoc.data().homepage ? siteDoc.data().homepage : siteDoc.data().WebsiteURL,
                        s_robot: lci === 'timeout' ? 'Allow' : siteDoc.data().s_robot ? siteDoc.data().s_robot : 'Allow',
                        s_index: lci === 'timeout' ? 'noset' : siteDoc.data().s_index ? siteDoc.data().s_index : 'index',
                        s_httphead: lci === 'timeout' ? 'noset' : siteDoc.data().s_httphead ? siteDoc.data().s_httphead : 'noset',
                        is_s_robot: siteDoc.data().s_robot ? (siteDoc.data().s_robot === 'Allow' ? false : true) : false,
                        is_s_index: siteDoc.data().s_index ? (siteDoc.data().s_index === 'index' || siteDoc.data().s_index === 'noset' ? false : true) : false,
                        id: siteDoc.id,
                        h_index: docSiteCache.data().h_index ? docSiteCache.data().h_index : [],
                        sug_robots: docSiteCache.data().sug_robots ? docSiteCache.data().sug_robots : ''
                    }
                    // console.log(this.idsiteLocalData)
                })
            }
          })
        }
      }
    },
    validations: {
    },
    methods: {
        getDataSite(val) {
            if(val && val.id) {
                if(!this.idsiteLocalData.id) {
                    localStorage.setItem('idsiteIndexInfo', JSON.stringify(val))
                }

                this.localArrayCrawlDate = val;
                db.collection('Sites').doc(val.id)
                .get().then(siteDoc => {
                    if(siteDoc.exists) {
                        this.arrayCrawlDate = []
                        if(siteDoc.data().mostRecentCrawlDateIndex || siteDoc.data().mostRecentCrawlDate) {
                        // console.log(siteDoc.data().homepage,siteDoc.data().mostRecentCrawlDateIndex)
                        this.arrayCrawlDate.push({
                            url: siteDoc.data().homepage,
                            date: siteDoc.data().mostRecentCrawlDateIndex ? siteDoc.data().mostRecentCrawlDateIndex : siteDoc.data().mostRecentCrawlDate
                        })
                        // this.getFromNowLastCrawl()
                        }
                        var lci = siteDoc.data().lci ? siteDoc.data().lci : 'success'

                        db.collection('Sites_cache').doc(siteDoc.id).get()
                        .then(docSiteCache => {
                            this.idsiteLocalData = {
                                lci: lci,
                                imgSite: siteDoc.data().thumbnail ? siteDoc.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png',
                                name: siteDoc.data().name ? siteDoc.data().name : siteDoc.data().homepage,
                                domainFix: siteDoc.data().homepage,
                                domain: siteDoc.data().homepage ? siteDoc.data().homepage : siteDoc.data().WebsiteURL,
                                s_robot: lci === 'timeout' ? 'Allow' : siteDoc.data().s_robot ? siteDoc.data().s_robot : 'Allow',
                                s_index: lci === 'timeout' ? 'noset' : siteDoc.data().s_index ? siteDoc.data().s_index : 'index',
                                s_httphead: lci === 'timeout' ? 'noset' : siteDoc.data().s_httphead ? siteDoc.data().s_httphead : 'noset',
                                is_s_robot: siteDoc.data().s_robot ? (siteDoc.data().s_robot === 'Allow' ? false : true) : false,
                                is_s_index: siteDoc.data().s_index ? (siteDoc.data().s_index === 'index' || siteDoc.data().s_index === 'noset' ? false : true) : false,
                                id: siteDoc.id,
                                h_index: docSiteCache.data().h_index ? docSiteCache.data().h_index : [],
                                sug_robots: docSiteCache.data().sug_robots ? docSiteCache.data().sug_robots : ''
                            }
                        })
                    }
                })
            }
        },
        actionShowOpenCreateAlert() {
            var user = auth.currentUser
            if(user && user.uid) {
                if(this.$route.name === 'shared-site') {
                    this.showOpenAddSiteProject = true
                } else {
                    this.showOpenCreateAlert = true
                }
            } else {
                this.showOpenRegisterSidebar = true
            }
            // localStorage.setItem('actionOpenAlert', true)
            // this.$refs.sidebar_coming_index.hide()
        },
        submitEditar(payload) {
            // localStorage.setItem('actionOpenLicenseIndexInfo', JSON.stringify({site: this.idsite.id}))
            // this.$refs.sidebar_coming_index.hide()
        },
        getFromNowLastCrawl() {
            if(this.lastCrawlDate) {
            var fromNow = moment(this.lastCrawlDate).fromNow()
            return (fromNow === 'a few seconds ago' ? 'Now' : fromNow)
            } else {
            return ''
            }
        },
        getFromNowLastCrawlHistory(payload) {
            var filter = this.arrayCrawlDate.filter(ele => ele.url === payload)
            // console.log(filter.length)
            if(filter.length) {
            var fromNow = ''
            var dateDiff = ''
            filter.forEach(ele => {
                // console.log(ele.date)
                fromNow = moment(ele.date).fromNow()
    
                var duration = moment.duration(moment(Date.now()).diff(moment(ele.date)));
                var hours = duration.asHours();
                dateDiff = hours
            })
    
            // console.log(dateDiff)
            if(dateDiff >= 24) {
                var crawlInfo24 = localStorage.getItem('crawlInfo24')
                if(crawlInfo24) {
                localStorage.removeItem('crawlInfo24')
                this.crawlSite(this.idsite.id)
                // console.log(this.idsite.id)
                }
            }
            return (fromNow === 'a few seconds ago' ? 'Now' : fromNow)
            } else {
            return ''
            }
        },
        logIdRobots(val) {
            // console.log(val);
            localStorage.setItem('openRobotsTab', true)
            // this.showOpenSidebarRobots = true
        },
    
        // crawl
        crawlSite(idSite) {
            var user = auth.currentUser
            if(user && user.uid) {
                if(this.$route.name === 'shared-site') {
                    this.showOpenAddSiteProject = true
                } else {
                    if(this.crawls.filter(ele => ele === idSite).length === 0) {
                        this.crawls.push(idSite)
                        this.crawlNow = ''
                        this.siteCrawlNow = ''
                        this.lastCrawlDate = ''
                        db.collection('Sites').doc(idSite).get()
                        .then(eleSite => {
                            // exist site
                            var countSites = 0
                            var pageSizes = eleSite.data().pageSizes && eleSite.data().pageSizes.length ? eleSite.data().pageSizes : []
                            var pagesSite = eleSite.data().pages && eleSite.data().pages.length ? eleSite.data().pages : []
                            var filterInternals = pagesSite.filter(ele => ele.isSitemap === true)
                            var filterNoInternals = pagesSite.filter(ele => ele.isSitemap === false)
                            var filterProPages = filterNoInternals.filter(ele => ele.pro === true)
                            var filterNoProPages = filterNoInternals.filter(ele => ele.pro === false)
                            var filterInlinks = filterNoProPages
                
                            var condicionParaOrdenar = (pageA, pageB) => {
                                return pageB.inlinks.length - pageA.inlinks.length;
                            }
                            filterInlinks.sort(condicionParaOrdenar)
                
                            var arrayFoundPages = []
                            var arrayProPages = []
                            filterProPages.forEach(ele => {
                                var getUrl = new URL(ele.url)
                                if(getUrl.pathname !== '/') {
                                    arrayProPages.push(ele.url)
                                }
                
                                arrayFoundPages.unshift(ele.url)
                            })
                
                            var body = {
                                url: '',
                                type: 'full',
                                forceNotFound: 'yes',
                                pageSize: 999999999,
                                id: 'index-check-single-' + Date.now(),
                                takeScreenshots: 'no',
                                source: 'seo-cloud-full-' + Date.now(),
                                triggeredBy: 'test triggered',
                                screenshotType: 'test',
                                screenshotComment: '',
                                stringSearch: ["PHP ERROR",'Lorem','Ipsum'],
                                ignoreIndexNoFollow: "yes"
                            }
                            var getUrl = new URL(eleSite.data().homepage);
                            var hostUrl = getUrl.host;
                            var httpsString = getUrl.protocol + '//';
                            var urlConcat = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                            var urlBody = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                            var ultimoCaracterUrl = urlConcat.charAt(urlConcat.length - 1);
                            if(ultimoCaracterUrl != "/") {
                                urlConcat += "/";
                            }
                
                            body.id = 'index-check-single-' + Date.now()
                            body.source = 'index-check-single-' + Date.now()
                            body.url = getUrl.protocol + "//" + hostUrl
                
                            if(getUrl.pathname === "/") {
                                body.takeScreenshots = 'no'
                                body.screenshotComment = ""
                            }
                            
                            db.collection('Crawls').where('WebsiteURL', '==', hostUrl).where('status', '==', 'running-index').get()
                            .then(queryCrawl => {
                                // only 1 loop
                                if(countSites === 0 && !queryCrawl.size) {
                                    countSites += 1
                                    var stringsArray = eleSite.data().stringSearch ? eleSite.data().stringSearch : []
                                    var suspiciousTextArray = eleSite.data().suspiciousText ? eleSite.data().suspiciousText : []
                                    var arrayStringsSuspicious = []
                                    stringsArray.forEach(ele => {
                                        arrayStringsSuspicious.push(ele)
                                    })
                
                                    suspiciousTextArray.forEach(ele => {
                                        arrayStringsSuspicious.push(ele)
                                    })
                
                                    var getConfigCrawl = localStorage.getItem('configCrawl')
                                    var limitCrawl = 100
                                    var configCrawlType = 'JS FREE'
                                    var configCrawlPages = 'FREE'
                                    var setCrawl = false
                                    if(getConfigCrawl) {
                                        setCrawl = true
                                        var parseConfig = JSON.parse(getConfigCrawl)
                                        var logicTime = moment(parseConfig.date)
                                        var actualTime = moment(new Date())
                                        var diffSeconds = actualTime.diff(logicTime, 'seconds');
                                        if(diffSeconds < 600) {
                                            limitCrawl = parseConfig.pages
                                            configCrawlType = parseConfig.crawl
                                            configCrawlPages = parseConfig.pages === 25 ? 'GUESS/FREE' : parseConfig.pages === 100 ? 'FREE' : parseConfig.pages === 600 ? 'LICENSE' : 'FREE'
                                        }
                                        var getConfigCrawl = localStorage.removeItem('configCrawl')
                                    }
                                    
                                    var limitCrawlSetSite = eleSite.data().limitCrawl ? eleSite.data().limitCrawl : 0
                                    if(limitCrawlSetSite) {
                                        if(configCrawlPages === 'GUESS/FREE' && limitCrawlSetSite < 25) {
                                            limitCrawl = limitCrawlSetSite
                                        } else if(configCrawlPages === 'FREE' && limitCrawlSetSite < 100) {
                                            limitCrawl = limitCrawlSetSite
                                        } else if(configCrawlPages === 'LICENSE') {
                                            limitCrawl = limitCrawlSetSite
                                        }
                                    }
                
                                    // add Crawl
                                    db.collection('Crawls').add({
                                        'IDcrawl': body.id,
                                        'dateStart': Date.now(),
                                        'dateStartFormat': new Date(),
                                        'dateEnd': null,
                                        'crawlDuration': null,
                                        'status': 'running',
                                        'crawltype': 'only-robots',
                                        'ignoreindexnofollow': 'yes',
                                        'Requests': 1,
                                        'SiteID': eleSite.id,
                                        'WebsiteURL': hostUrl,
                                        'blockedByRobots': false,
                                        'logicRequests': 1,
                                        'proPages': eleSite.data().proPages.length ? eleSite.data().proPages : [urlBody],
                                        'totalErrors': 0,
                                        'limitCrawl': limitCrawl,
                                        'stringSearch': arrayStringsSuspicious,
                                        'founds': arrayFoundPages,
                                        'requests': [],
                                        'pages': [],
                                        'inlinks': [],
                                        'external': [],
                                        'pageSizes': pageSizes,
                                        'updated': Date.now(),
                                        'plus1k': false,
                                        'errorsPages': [],
                                        'source': 'index-check-site',
                                        'configCrawlType': 'index',
                                        'configCrawlPages': 'FREE',
                                        'errorsTypePage': [],
                                        'timeoutPages': 0,
                                        'errorsCategory': {}
                                    })
                                    .then((docRefCrawl) => {
                                    this.lastCrawlDate = Date.now()
                                    this.crawlNow = moment(Date.now()).fromNow()
                                    this.siteCrawlNow = eleSite.id
                                        var URLsfound = [
                                            {
                                                'id': null,
                                                'title': null,
                                                'runPosition': 1,
                                                'lastRequest': null,
                                                'pro': true,
                                                'inlinks': [],
                                                'status': null,
                                                'redirectTo': null,
                                                'blockedByRobots': false,
                                                // 'totalErrors': 0,
                
                                                'crawleable': true,
                                                'crawled': false,
                                                'crawlerInvoke': true,
                                                'url': urlBody
                                                
                                            },
                                            // {
                                            //   'id': null,
                                            //   'title': null,
                                            //   'runPosition': 2,
                                            //   'lastRequest': null,
                                            //   'pro': false,
                                            //   'inlinks': [],
                                            //   'status': null,
                                            //   'redirectTo': null,
                                            //   'blockedByRobots': false,
                
                                            //   'crawleable': true,
                                            //   'crawled': false,
                                            //   'crawlerInvoke': true,
                                            //   'url': urlConcat + "sitemap.xml"
                                            // }
                                        ]
                
                                        db.collection('Sites').doc(eleSite.id).update({
                                            mostRecentCrawlIndex: docRefCrawl.id,
                                            n_log_crawls_ind: eleSite.data().n_log_crawls_ind ? firebase.firestore.FieldValue.increment(1) : 1,
                                            mostRecentCrawlDateIndex: Date.now()
                                            // totalErrors: 0
                                        })
                
                                        // add pages crawl
                                        URLsfound.forEach(eleC => {
                                            db.collection('Crawls').doc(docRefCrawl.id).update({
                                                requests: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                                            })
                
                                            db.collection('Crawls').doc(docRefCrawl.id).update({
                                                founds: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                                            })
                
                                            db.collection('Crawls').doc(docRefCrawl.id).update({
                                                pages: firebase.firestore.FieldValue.arrayUnion({
                                                    id: '',
                                                    title: '',
                                                    runPosition: null,
                                                    lastRequest: null,
                                                    pro: false,
                                                    inlinks: [],
                                                    status: null,
                                                    redirectTo: '',
                                                    blockedByRobots: false,
                                                    crawled: true,
                                                    crawleable: true,
                                                    url: eleC.url,
                                                    isSitemap: false,
                                                    hasRun: false,
                                                    active: false,
                                                    size: 999999999,
                                                    tries: 1,
                                                    tryTimestamp: Date.now()
                                                })
                                            })
                                            .catch(e => console.log('Error update master page: ', e.message))
                                        })
                
                                        // LOGIC RUN INVOKE CRAWL
                                        var protocolSite = ''
                                        var homepageSite = ''
                                        if(eleSite.data().stringSearch.length > 0) {
                                            body.stringSearch = eleSite.data().stringSearch
                                        }
                                        protocolSite = eleSite.data().prot ? eleSite.data().prot : getUrl.protocol
                                        body.url = protocolSite + "//" + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                                        homepageSite = eleSite.data().homepage ? eleSite.data().homepage : ''
                                        body.takeScreenshots = 'no'
                                        body.triggeredBy = eleSite.id
                                        body.source = docRefCrawl.id
                                        body.type = 'only-urls'
                                        body.useFastCrawler = 'no'
                                        body.typePage = 'homeXML'
                                        body.screenshotComment = ''
                
                                        var urlOrigin = body.url
                                        
                                        // var config = {
                                        //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                                        //   "method": "POST",
                                        //   "timeout": 0,
                                        //   "headers": {
                                        //     "Content-Type": "application/json",
                                        //     "Accept": "application/json"
                                        //   },
                                        //   "data": JSON.stringify(body),
                                        // };
                
                                        var config = {
                                            method: 'POST',
                                            url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl-index',
                                            maxBodyLength: Infinity,
                                            timeout: 0,
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'Accept': 'application/json'
                                            },
                                            data: JSON.stringify(body)
                                        };
                    
                                        this.axios(config)
                                        .then((response) => {
                                            body.url = urlOrigin
                                            body.takeScreenshots = 'no'
                                            body.type = 'full'
                                            body.typePage = 'normal'
                                            // console.log(response.data)
                                            console.log('invoke home')

                                            db.collection('Crawls').doc(docRefCrawl.id).update({
                                                updated: Date.now(),
                                                dateEnd: Date.now(),
                                                status: 'success'
                                            })
                                            .catch(e => console.log('Error update crawl: ', e.message))
                
                                            setTimeout(() => {
                                                this.crawls = this.crawls.filter(ele => ele !== idSite)
                                                this.getDataSite(this.idsite)
                                            }, 15000)
                                        })
                                        .catch(e => {
                                            console.log(e)
                                            this.crawls = this.crawls.filter(ele => ele !== idSite)
                                        })
                                        
                                        // run crawl three initials xml
                                        // body.typePage = 'normal'
                                        var atomicBody = body
                                        var countInvocked = 0
                                        // let arraySites = [atomicBody.url + "/site-map.xml", atomicBody.url + "/sitemap.xml", atomicBody.url + "/page-sitemap.xml"]
                                        // let arraySites = [atomicBody.url + (atomicBody.url.endsWith('/') ? 'sitemap.xml' : '/sitemap.xml')]
                                        let arraySites = []
                                        arraySites.forEach(res => {
                                            var objXMLCrawl = {
                                                forceNotFound: 'yes',
                                                id: body.id,
                                                ignoreIndexNoFollow: 'yes',
                                                pageSize: 999999999,
                                                screenshotComment: '',
                                                screenshotType: 'test',
                                                source: body.source,
                                                stringSearch: body.stringSearch,
                                                takeScreenshots: 'no',
                                                triggeredBy: body.triggeredBy,
                                                type: 'only-urls',
                                                url: res,
                                                useFastCrawler: 'no',
                                                typePage: 'normal',
                                                parseAsTextContent: 'no'
                                            }
                                            atomicBody.url = res
                                            atomicBody.type = 'only-urls'
                                            atomicBody.takeScreenshots = 'no'
                                            atomicBody.typePage = 'normal'
                                            
                                            // var config = {
                                            //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                                            //   "method": "POST",
                                            //   "timeout": 0,
                                            //   "headers": {
                                            //     "Content-Type": "application/json",
                                            //     "Accept": "application/json"
                                            //   },
                                            //   "data": JSON.stringify(atomicBody),
                                            // };
                                            body.url = urlOrigin
                                            body.type = 'full'
                
                                            var config = {
                                                method: 'POST',
                                                url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl-index',
                                                maxBodyLength: Infinity,
                                                timeout: 0,
                                                headers: {
                                                'Content-Type': 'application/json',
                                                'Accept': 'application/json'
                                                },
                                                data: JSON.stringify(objXMLCrawl)
                                            };
                            
                                            this.axios(config)
                                            .then((response) => {
                                                countInvocked += 1
                                                // body.url = urlOrigin
                                                // if(countInvocked === 3) {
                                                // console.log(response.data)
                                                console.log('invoke init xml')
                                                body.stringSearch = ["PHP ERROR",'Lorem','Ipsum']
                                                body.takeScreenshots = 'no'
                                                body.screenshotComment = ""
                                                body.url = urlOrigin
                                                // }
                                            })
                                            .catch(e => {
                                                console.log(e)
                                            })
                                        })
                
                                        // this.$toast({
                                        //     component: ToastificationContent,
                                        //     position: 'top-right',
                                        //     props: {
                                        //         title: `Crawl running`,
                                        //         icon: 'CoffeeIcon',
                                        //         variant: 'success'
                                        //     },
                                        // })
                
                                        // setTimeout(() => {
                                        //     this.crawls = this.crawls.filter(ele => ele !== idSite)
                                        // }, 15000)
                                    })
                                    .catch(e => {
                                        console.log('Error add crawl site: ', e)
                                        this.crawls = this.crawls.filter(ele => ele !== idSite)
                                    })
                                } else if(queryCrawl.size) {
                                    this.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: `Crawl running already`,
                                            icon: 'CoffeeIcon',
                                            variant: 'warning'
                                        },
                                    })
                                    console.log('Crawl running already!')
                                    this.crawls = this.crawls.filter(ele => ele !== idSite)
                                }
                            })
                            .catch(e => {
                                console.log(e.message)
                                this.crawls = this.crawls.filter(ele => ele !== idSite)
                            })
                        })
                        .catch(e => {
                            this.crawls = this.crawls.filter(ele => ele !== idSite)
                            // this.execCrawl = false
                            console.log('Error query sites host: ', e)
                        })
                    }
                }
            } else {
                this.showOpenRegisterSidebar = true
            }
        }
    },
    setup() {
      return {
        avatarText,
      }
    },
  }
  </script>
  
  <style>
  .b-sidebar-backdrop {
    background-color: #181E38BF !important;
    opacity: 1 !important;
  }
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  
  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  #sidebar-right-coming-soon {
    width: 90%;
    max-width: 593px !important;
  }
  </style>