<template>
    <div>
      <create-redirection
        :show-open-redirect.sync="showOpenRedirect"
        :idsite="idsite"
        :hostname.sync="hostUrlPage"
      />
      <sidebar-ownership
        :idsite="siteObj.length >= 1 ? siteObj[0] : ''"
        :show-open.sync="showOpenOwnership"
      />
      <coming-soon
        :idsite="siteObj.length >= 1 ? siteObj[0] : ''"
        :show-open-coming-soon.sync="showOpenComingSoon"
      />
      <register-sidebar :show-open-register-sidebar.sync="showOpenRegisterSidebar" />
      <add-site-project :show-open-add-site-project.sync="showOpenAddSiteProject" :idsite="siteObj.length >= 1 ? siteObj[0] : ''" />
      <sidebar-activate-redirect :show-open-activate-redirect.sync="showOpenActivateRedirect" :idredirect.sync="idredirect" :idsite="siteObj.length >= 1 ? siteObj[0] : ''" />
      <sidebar-disable-redirect :show-open-disable-redirect.sync="showOpenDisableRedirect" :idredirect.sync="idredirect" :idsite="siteObj.length >= 1 ? siteObj[0] : ''" />
      <sidebar-remove-redirect :show-open-remove-redirect.sync="showOpenRemoveRedirect" :idredirect.sync="idredirect" :idsite="siteObj.length >= 1 ? siteObj[0] : ''" />
      
      <b-row class="mt-25 tabs-responsive">
        <b-col md="12">
            <b-button
                variant="info"
                class=""
                style="border-radius: 50px; vertical-align: middle; display: flex; position: absolute; right: 10px; top: -10px;"
                @click="showOpenComingSoon = true"
            >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">Create</span>
                <!-- @click="$route.name === 'shared-site' && !userObj ? openRegister() : $route.name === 'shared-site' && userObj ? showOpenAddSiteProject = true : (siteObj.length >= 1 ? (siteObj[0].permission === 'owner' ? showOpenRedirect = true : showOpenOwnership = true) : '')" -->
            </b-button>
            <h1>Redirections</h1>
            <div style="text-align: center;" v-if="loading">
                <b-spinner
                    class="mt-2"
                    style="color: #bbb !important;"
                />
            </div>
            <div class="top-scroll-horizontal-tabs-responsive" style="overflow-x: scroll;"><div class="div1" id="tab-err-sc2" style=" height: 20px;"></div></div>
            <b-table-simple
                small
                responsive
                id="tab-err-sc1"
                class="vgt-table tableErrors"
                :style="'margin-bottom: 60px !important;'"
                v-if="arrayInfiniteData.length >= 1 && !loading"
            >
                <b-thead>
                  <b-tr>
                    <b-th class="thPageTableErrors">Source URL</b-th>
                    <b-th class="thPageTableErrors">Target URL</b-th>
                    <b-th class="thPageTableErrors">Comment</b-th>
                    <b-th class=""></b-th>
                    <b-th class=""></b-th>
                  </b-tr>
                </b-thead>
                <b-tbody v-if="arrayInfiniteData.length === 0">
                  <b-tr>
                    <b-td>
                      No pages
                    </b-td>
                  </b-tr>
                </b-tbody>
                <b-tbody v-for="(dataList, index) in arrayInfiniteData" :key="index" :style="index % 2 ? 'background: #fff !important;' : 'background: rgb(240, 241, 243) !important;'" v-else>
                  <b-tr>
                    <td :style="dataList.status === 'disabled' ? 'color: rgb(167, 170, 185) !important;' : ''">{{dataList.source}}</td>
                    <td :style="dataList.status === 'disabled' ? 'color: rgb(167, 170, 185) !important;' : ''">{{dataList.target}}</td>
                    <td :style="dataList.status === 'disabled' ? 'color: rgb(167, 170, 185) !important;' : ''">{{dataList.comment}}</td>
                    <td>
                      <b-badge v-if="dataList.status === 'disabled'" variant="dark" style="background: #a7aab9 !important;">
                        Disabled
                      </b-badge>
                    </td>
                    <td>
                      <b-dropdown
                        dropleft
                        no-caret
                        variant="flat-dark"
                        class="btn-icon dropdown-sites rounded-circle"
                      >
                        <template
                          #button-content
                          class="pt-0"
                        >
                          <feather-icon
                            icon="MoreVerticalIcon"
                            class="mt-0 pt-0"
                            size="20"
                          />
                        </template>
                        <b-dropdown-item link-class="d-flex align-items-center item-dropdown-action-sites" @click.prevent="$route.name === 'shared-site' && !userObj ? openRegister() : $route.name === 'shared-site' && userObj ? showOpenAddSiteProject = true : (siteObj.length >= 1 ? (siteObj[0].permission === 'owner' ? (dataList.status === 'active' ? disableRedirect(dataList) : activateRedirect(dataList)) : showOpenOwnership = true) : '')">
                          <feather-icon
                            :icon="dataList.status === 'active' ? 'EyeIcon' : 'EyeOffIcon'"
                            class="mr-50"
                            style="position: relative !important; left: -7px !important;"
                          />
                          {{dataList.status === 'active' ? 'Disable' : 'Activate'}}
                        </b-dropdown-item>
                        <b-dropdown-item link-class="d-flex align-items-center item-dropdown-action-sites" @click.prevent="$route.name === 'shared-site' && !userObj ? openRegister() : $route.name === 'shared-site' && userObj ? showOpenAddSiteProject = true : (siteObj.length >= 1 ? (siteObj[0].permission === 'owner' ? removeRedirect(dataList) : showOpenOwnership = true) : '')">
                          <feather-icon
                            icon="XIcon"
                            class="mr-50"
                            style="position: relative !important; left: -7px !important;"
                          />
                          Remove
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-col>
      </b-row>
    </div>
  </template>
  
<script>
import {
  BButton, BTab, BCard, BCardHeader, BRow, BCol, BCardText, BCardBody, BAvatar, BTabs, BTd, BTbody, BTableSimple, BThead, BImgLazy, BTh, BTr, BBadge, BSpinner,
  BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//   import InfoResponsive from '@/views/extensions/acl/table/sidebar/InfoResponsive.vue'
import CreateRedirection from '@/views/extensions/acl/table/sidebar/CreateRedirection.vue'
import RegisterSidebar from '@/views/pages/authentication/RegisterSidebar.vue'
import SidebarOwnership from '@/views/extensions/acl/table/sidebar/SidebarOwnership.vue'
import AddSiteProject from '@/views/extensions/acl/table/sidebar/AddSiteProject.vue'
import SidebarRemoveRedirect from './SidebarRemoveRedirect.vue'
import SidebarActivateRedirect from './SidebarActivateRedirect.vue'
import SidebarDisableRedirect from './SidebarDisableRedirect.vue'
import ComingSoon from '@/views/extensions/acl/table/sidebar/ComingSoon.vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { db, auth, firebase, storage, dbErrors } from '@/firebase'
  
  export default {
    name: 'Performance',
    components: {
      BButton,
      BTabs,
      BTab,
      BCard,
      BCardHeader,
      BRow,
      BCol,
      BCardText,
      BCardBody,
      BAvatar,
      BTd,
      BTbody,
      BTableSimple,
      BThead,
      BTh,
      BTr,
      BImgLazy,
      BBadge,
      BSpinner,
      BDropdown,
      BDropdownItem,
      VueApexCharts,
      // InfoResponsive,
      CreateRedirection,
      RegisterSidebar,
      AddSiteProject,
      SidebarOwnership,
      SidebarRemoveRedirect,
      SidebarActivateRedirect,
      SidebarDisableRedirect,
      ComingSoon,
    },
    props: [],
    data() {
      return {
          loading: true,
          showOpenRegisterSidebar: false,
          showOpenAddSiteProject: false,
          showOpenOwnership: false,
          showOpenActivateRedirect: false,
          showOpenDisableRedirect: false,
          showOpenRemoveRedirect: false,
          showOpenComingSoon: false,
          proIcon: require('@/assets/images/icons/i-pro-badge.svg'),
          proIconNone: require('@/assets/images/icons/i-pro-badge-none.svg'),
          Mobile: true,
          Desktop: false,
          History: false,
          showOpenRedirect: false,
          typeSelectedResponsive: '',
          dataResponsive: '',
          arrayInfiniteData: [],
          timerInfinite: null,
          loadingInfiniteScroll: false,
          arrayInfiniteDataFilter: [],
          allData: [],
          idsite: '',
          userObj: '',
          siteObj: [],
          hostUrlPage: '',
          idredirect: ''
      }
    },
    watch: {
    },
    methods: {
      infiniteScroll() {
        var listElm = document.querySelector('#app');
        let bottomOfWindow = document.documentElement.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 200
        // if(this.selectedFilteredPages.length === 0) {
          if(this.allData.length >= 1 && this.active) {
            if(bottomOfWindow) {
              if(!this.loadingInfiniteScroll && this.allData.length !== this.arrayInfiniteData.length) {
                // console.log('first')
                this.loadingInfiniteScroll = true
                var counterFound = 0
                var arrayInfinite = []
                this.allData.forEach(ele => {
                  var alreadyAdd = arrayInfinite.filter(item => item.idPageOverview === ele.idPageOverview && item.page === ele.page)
                  if(alreadyAdd.length === 0) {
                    if(counterFound <= 10) {
                      counterFound += 1
                      arrayInfinite.push(ele)
                    }
                  }
                })
                this.arrayInfiniteData = arrayInfinite
  
                setTimeout(() => {
                  this.loadingInfiniteScroll = false
                }, 1000)
              }
            }
          }
        // }
      },
      openRegister() {
        // console.log('open register')
        this.showOpenRegisterSidebar = true
      },
      activateRedirect(val) {
        this.idredirect = val.id
        this.showOpenActivateRedirect = true
        
        // db.collection('Redirections').doc(val.id).update({
        //   status: 'active'
        // })
        // .then(() => {
        //   this.$toast({
        //     component: ToastificationContent,
        //     props: {
        //       title: 'Redirection activated',
        //       icon: 'BellIcon',
        //       variant: 'success',
        //     },
        //   })
        // })
      },
      disableRedirect(val) {
        this.idredirect = val.id
        this.showOpenDisableRedirect = true
        
        // db.collection('Redirections').doc(val.id).update({
        //   status: 'disabled'
        // })
        // .then(() => {
        //   this.$toast({
        //     component: ToastificationContent,
        //     props: {
        //       title: 'Redirection disabled',
        //       icon: 'BellIcon',
        //       variant: 'success',
        //     },
        //   })
        // })
      },
      removeRedirect(val) {
        this.idredirect = val.id
        this.showOpenRemoveRedirect = true

        // db.collection('Redirections').doc(val.id).delete()
        // .then(() => {
        //   this.$toast({
        //     component: ToastificationContent,
        //     props: {
        //       title: 'Redirection deleted',
        //       icon: 'BellIcon',
        //       variant: 'success',
        //     },
        //   })
        // })
      }
    },
    mounted() {
      this.timerInfinite = setInterval(() => {
        this.infiniteScroll()
      }, 600)
    },
    destroyed() {
      clearInterval(this.timerInfinite)
    },
    created() {
      this.loading = true
      this.arrayInfiniteData = []
      this.timerInfinite = null
      this.loadingInfiniteScroll = true
      this.showOpenRegisterSidebar = false
      this.showOpenAddSiteProject = false
      this.showOpenOwnership = false
      this.showOpenActivateRedirect = false
      this.showOpenRemoveRedirect = false
      this.showOpenRemoveRedirect = false
      this.showOpenComingSoon = false
      this.idredirect = ''
      this.hostUrlPage = ''

      var user = auth.currentUser
      var atomUser = ''
      if(user && user.uid) {
        this.userObj = user.uid
        atomUser = user.uid
      } else {
        this.userObj = ''
        atomUser = ''
      }
      // console.log(atomUser);

      if(this.$route.name === 'shared-site') {
        db.collection('Sites').where('WebsiteURL', '==', this.$route.query.site).limit(1).get()
        .then(siteQueryWebsite => {
          if(siteQueryWebsite.size) {
            siteQueryWebsite.forEach(site => {
              if(site.id) {
                var urlTransform = new URL(site.data().homepage)
                this.hostUrlPage = urlTransform.hostname

                this.siteObj = [{
                  imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png',
                  name: site.data().name ? site.data().name : site.data().homepage,
                  nameFixed: site.data().name ? site.data().name : site.data().WebsiteURL,
                  domain: site.data().WebsiteURL,
                  domainFix: site.data().homepage,
                  currentErrors: 0,
                  fixedIssues: '79',
                  key: site.id,
                  alerts: '3',
                  membership: `${site.data().plan ? site.data().plan : 'Free'} license`,
                  ownedBy: site.data().owner && site.data().owner.uid ? site.data().owner : '',
                  id: site.id,
                  owner: 'owner',
                  permission: site.data().owner && site.data().owner.uid === atomUser ? 'owner' : 'edit',
                  projectid: ''
                }]

                db.collection('Redirections').where('idsite', '==', site.id).where('iduser', '==', atomUser).onSnapshot(docs => {
                  this.idsite = site.id
                  var atomArray = []
                  docs.forEach(ele => {
                    if(atomArray.filter(item => item.id === ele.id).length === 0) {
                      var transformSource = new URL(ele.data().source)

                      // console.log(ele.data());
                      atomArray.push({
                        id: ele.id,
                        target: ele.data().target,
                        source: transformSource.pathname,
                        comment: ele.data().comment,
                        status: ele.data().status ? ele.data().status : 'active'
                      })
                    }
                  })
                  this.allData = atomArray

                  if(atomArray && atomArray.length) {
                    var counter = 0
                    atomArray.forEach(element => {
                      counter += 1
                      if(counter <= 13 && this.arrayInfiniteData.filter(ele => ele.id === element.id).length === 0) {
                        this.arrayInfiniteData.push(element)
                      } else {
                        var filter = this.arrayInfiniteData.filter(ele => ele.id === element.id)
                        if(filter.length) {
                          filter[0].target = element.target
                          filter[0].source = element.source
                          filter[0].comment = element.comment
                          filter[0].status = element.status
                        }
                      }
                    });
            
                    setTimeout(() => {
                      this.loadingInfiniteScroll = false
                    }, 1000)
                  }
                })
              }
              
              setTimeout(() => {
                this.loading = false
              }, 2500)
            })
          }
        })
      } else {
        db.collection('Sites').doc(this.$route.params.id).get()
        .then(site => {
          if(site.id) {
            var urlTransform = new URL(site.data().homepage)
            this.hostUrlPage = urlTransform.hostname

            this.siteObj = [{
              imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png',
              name: site.data().name ? site.data().name : site.data().homepage,
              nameFixed: site.data().name ? site.data().name : site.data().WebsiteURL,
              domain: site.data().WebsiteURL,
              domainFix: site.data().homepage,
              currentErrors: 0,
              fixedIssues: '79',
              key: site.id,
              alerts: '3',
              membership: `${site.data().plan ? site.data().plan : 'Free'} license`,
              ownedBy: site.data().owner && site.data().owner.uid ? site.data().owner : '',
              id: site.id,
              owner: 'owner',
              permission: site.data().owner && site.data().owner.uid === atomUser ? 'owner' : 'edit',
              projectid: ''
            }]

            db.collection('Redirections').where('idsite', '==', site.id).where('iduser', '==', atomUser).onSnapshot(docs => {
              // console.log(docs.size);
              this.idsite = site.id
              var atomArray = []
              docs.forEach(ele => {
                if(atomArray.filter(item => item.id === ele.id).length === 0) {
                  var transformSource = new URL(ele.data().source)

                  // console.log(ele.data());
                  atomArray.push({
                    id: ele.id,
                    target: ele.data().target,
                    source: transformSource.pathname,
                    comment: ele.data().comment,
                    status: ele.data().status ? ele.data().status : 'active'
                  })
                }
              })
              this.allData = atomArray

              if(atomArray && atomArray.length) {
                var counter = 0
                atomArray.forEach(element => {
                  counter += 1
                  if(counter <= 13 && this.arrayInfiniteData.filter(ele => ele.id === element.id).length === 0) {
                    this.arrayInfiniteData.push(element)
                  } else {
                    var filter = this.arrayInfiniteData.filter(ele => ele.id === element.id)
                    if(filter.length) {
                      filter[0].target = element.target
                      filter[0].source = element.source
                      filter[0].comment = element.comment
                      filter[0].status = element.status
                    }
                  }
                });
        
                setTimeout(() => {
                  this.loadingInfiniteScroll = false
                }, 1000)
              }
            })
          }
          setTimeout(() => {
            this.loading = false
          }, 2500)
        })
      }
    }
  }
</script>
  
<style>
.content-comment-responsive-td p {
  border-bottom: 10px !important;
  margin-bottom: 0px !important;
}
.thTableResponsiveIssue {
  min-width: 160px;
}
.tdTableResponsiveIssue {
  min-width: 160px;
}
</style>