<template>
  <div class="__performance-module-seocloud">
    <p v-if="noData" class="p-2" show variant="info">Check again soon.</p>
    <IniSkeleton v-show="!dataLoaded && !noData" />
    <h2 v-if="website">
      Page: <b>{{ website }}</b>
    </h2>
    <Navigation :type.sync="actualType" />
    <transition>
      <History
        v-if="performanceResults"
        v-show="actualType === 'history'"
        :history="performanceResults.chartData"
        :group-dates="performanceResults.average"
        :site-key="siteKey"
        :cache-data="dataCache"
        :range-found="range"
        @getData="updateChartByRange"
        @update="tryUpdate"
        @changeType="updateType"
      />
    </transition>

    <section v-if="actualType !== 'history' && currentItem">
      <b-row style="margin: 0 -1rem 2rem -1rem !important">
        <b-col class="score-col" md="8">
          <score
            :updates="chartUpdate"
            :date="date"
          />
        </b-col>
        <b-col md="4">
          <statics
            :times="currentItem.times"
            :scores="currentItem.scores"
          />
        </b-col>
      </b-row>
      <AdditionalStats
        :times="currentItem.times"
        :scores="currentItem.scores"
      />
    </section>
    <testResults
      v-if="currentItem && actualType !== 'history'"
      class="mt-2"
      :opportunities="currentItem.opportunities"
      :approves="currentItem.approved"
      :diagnostics="currentItem.diagnostic"
    />
  </div>
</template>

<script>
import { BCol, BRow, BAlert } from 'bootstrap-vue'
import performanceRepository from '@/views/apps/sites/sites-edit/site-errors/Performance/services/psi'
import statics from './Performance/Statics.vue'
import AdditionalStats from './Performance/Components/AdditionalStats.vue'
import score from './Performance/Score.vue'
import testResults from './Performance/Components/Resume.vue'
import History from './Performance/Components/HistoryChart.vue'
import functions from './Performance/Components/performanceFunctions'
import Navigation from './Performance/Components/Navigation.vue'
import IniSkeleton from './Performance/Components/iniSkeleton.vue'
import store from '@/views/apps/sites/sites-edit/site-errors/Performance/store'


export default {
  components: {
    BCol,
    BRow,
    testResults,
    History,
    statics,
    score,
    AdditionalStats,
    Navigation,
    IniSkeleton,
    BAlert,
  },
  data() {
    return {
      currentItem: null,
      actualType: null,
      website: '',
      date: '',
      performanceResults: null,
      siteKey: null,
      chartUpdate: {
        scores: [0],
        colors: [],
        labels: [],
      },
      dataQuery: null,
      dataCache: {},
      range: 'week',
      noData: false,
    }
  },
  computed: {
    dataLoaded() {
      return this.website && this.actualType && this.performanceResults
    },
  },
  props: ['urlid'],
  watch: {
    actualType() {

      // update store
      store.commit('results/SET_ACTUAL_TYPE', this.actualType)
      // console.log(this.actualType)
      if (this.actualType === 'history') {
        return
      }
      this.date = functions.generateDateString(
        this.dataQuery[this.actualType].timestamp,
      )
      this.currentItem = this.dataQuery[this.actualType].data
      this.renderCurrentItem()
    },
    urlid(val) {
      this.construcObject(val)
    }
  },
  async created() {
    // default site if site id is wrong
    // this.siteKey = this.$route.params.id
    //   ? this.$route.params.id
    //   : 'citymap_com_gt'
    // console.log(this.siteKey)
    // // detect if set key to show specific result
    // const mobileResult = this.$route.query.m ? this.$route.query.m : null
    // const desktopResult = this.$route.query.d ? this.$route.query.d : null
    // let modeShow = this.$route.query.selected
    //   ? this.$route.query.selected
    //   : 'history'
    // modeShow = modeShow.toLowerCase()
    // // request data to api
    // let t = await performanceRepository.actions.getHistory(this.siteKey)
    // this.dataCache.week = t.data || null

    // if (!t.data || Object.keys(t.data).length === 0) {
    //   t = await performanceRepository.actions.getHistory(this.siteKey, 'month')
    //   this.dataCache.month = t.data || null
    //   this.range = 'month'
    // }

    // if (!t.data || Object.keys(t.data).length === 0) {
    //   t = await performanceRepository.actions.getHistory(this.siteKey, 'year')
    //   this.dataCache.month = t.data || null
    //   this.range = 'year'
    // }

    // if (!t.data || Object.keys(t.data).length === 0) {
    //   t = await performanceRepository.actions.getHistory(this.siteKey, 'all')
    //   this.dataCache.month = t.data || null
    //   this.range = 'all'
    // }
    // // Descomentar cuando se pase a prod
    // if (!t.data || Object.keys(t.data).length === 0) {
    //   // this.$router.push({ path: '/error/404' })
    //   this.noData = true
    //   return
    // }

    // this.website = t.data.ssiteUrl
    // // this.website = 'wordpress-1150886-4365638_cloudwaysapps_com'
    // // render results
    // this.performanceResults = functions.formatPerformance(t.data)
    // if (mobileResult && desktopResult) {
    //   // Show results if request the key and site id is correctly
    //   await this.getDataUI(modeShow, desktopResult, mobileResult)
    // } else {
    //   // Default data if not have a key or is wrong
    //   this.actualType = 'history'
    //   if (t.data.lastDesktop && t.data.lastMobile) {
    //     // Obtener ultimos registros
    //     const response = await performanceRepository.actions.getItems(
    //       this.siteKey,
    //       t.data.lastDesktop,
    //       t.data.lastMobile,
    //     )
    //     this.dataQuery = response.data

    //     // set store
    //     store.commit('results/SET_PDI_RESULTS', response.data)
    //   }
    // }
  },
  mounted() {
    history.pushState({ customNavigation: true }, '', window.location.pathname);
    window.addEventListener('popstate', this.handleBackNavigation);
  },
  beforeDestroy() {
    // Elimina el listener al destruir el componente
    window.removeEventListener('popstate', this.handleBackNavigation);
  },
  methods: {
    async construcObject(paylod) {
      this.siteKey = paylod
        ? paylod
        : 'citymap_com_gt'
      // console.log(this.siteKey)

      // detect if set key to show specific result
      const mobileResult = this.$route.query.m ? this.$route.query.m : null
      const desktopResult = this.$route.query.d ? this.$route.query.d : null
      let modeShow = this.$route.query.selected
        ? this.$route.query.selected
        : 'history'
      modeShow = modeShow.toLowerCase()
      // request data to api
      let t = await performanceRepository.actions.getHistory(this.siteKey)
      this.dataCache.week = t.data || null

      if (!t.data || Object.keys(t.data).length === 0) {
        t = await performanceRepository.actions.getHistory(this.siteKey, 'month')
        this.dataCache.month = t.data || null
        this.range = 'month'
      }

      if (!t.data || Object.keys(t.data).length === 0) {
        t = await performanceRepository.actions.getHistory(this.siteKey, 'year')
        this.dataCache.month = t.data || null
        this.range = 'year'
      }

      if (!t.data || Object.keys(t.data).length === 0) {
        t = await performanceRepository.actions.getHistory(this.siteKey, 'all')
        this.dataCache.month = t.data || null
        this.range = 'all'
      }
      // Descomentar cuando se pase a prod
      if (!t.data || Object.keys(t.data).length === 0) {
        // this.$router.push({ path: '/error/404' })
        this.noData = true
        return
      }

      this.website = t.data.ssiteUrl
      // this.website = 'wordpress-1150886-4365638_cloudwaysapps_com'
      // render results
      this.performanceResults = functions.formatPerformance(t.data)
      if (mobileResult && desktopResult) {
        // Show results if request the key and site id is correctly
        await this.getDataUI(modeShow, desktopResult, mobileResult)
      } else {
        // Default data if not have a key or is wrong
        this.actualType = 'history'
        if (t.data.lastDesktop && t.data.lastMobile) {
          // Obtener ultimos registros
          const response = await performanceRepository.actions.getItems(
            this.siteKey,
            t.data.lastDesktop,
            t.data.lastMobile,
          )
          this.dataQuery = response.data

          // set store
          store.commit('results/SET_PDI_RESULTS', response.data)
        }
      }
    },
    async getDataUI(modeShow, desktopResult, mobileResult) {
      const response = await performanceRepository.actions.getItems(
        this.siteKey,
        desktopResult,
        mobileResult,
      )
      this.dataQuery = response.data

      // set store
      store.commit('results/SET_PDI_RESULTS', response.data)

      const resultRequested = response.data[modeShow]
      this.actualType = modeShow
      this.date = functions.generateDateString(resultRequested.timestamp)
      this.currentItem = resultRequested.data
      this.renderCurrentItem()
    },
    renderCurrentItem() {
      if (this.currentItem) {
        const valuePerformance = this.currentItem.scores.performance
        this.chartUpdate = {
          ...this.chartUpdate,
          scores: [valuePerformance],
          labels: valuePerformance,
          colors: functions.getColorChart(valuePerformance),
        }
      }
    },
    updateType(event){
      this.actualType = event
    },
    async tryUpdate(event) {
      await this.getDataUI(event.selected.toLowerCase(), event.d, event.m)
    },
    async updateChartByRange(value) {
      if (this.dataCache[value]) {
        this.performanceResults = functions.formatPerformance(
          this.dataCache[value],
        )
      } else {
        const data = await performanceRepository.actions.getHistory(
          this.siteKey,
          value,
        )
        this.dataCache[value] = data.data
        this.performanceResults = functions.formatPerformance(data.data)
      }
    },
    handleBackNavigation(event) {
      if (this.actualType !== 'history') {
        this.actualType = 'history';
        history.pushState({ customNavigation: true }, '', window.location.pathname);
      } else {
        history.back();
      }
    }
  },
}
</script>
