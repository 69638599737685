<template>
  <div>
    <StaticsCard
      v-if="times"
      icon="LoaderIcon"
      :statistic="times.speed_index"
      statistic-title="Page Load Time"
      statisticSubTitle="(Speed Index)"
      class="shadow-none"
    />
    <StaticsCard
      v-if="scores"
      icon="CheckCircleIcon"
      :statistic="parseToMb(scores.pageSize)"
      statistic-title="Total Page Size"
      class="shadow-none"
    />
    <StaticsCard
      v-if="scores"
      icon="Minimize2Icon"
      :statistic="scores.requests"
      statistic-title="Requests"
      class="mb-0 shadow-none"
    />
  </div>
</template>

<script>
import StaticsCard from './Components/Chart/StaticsCard.vue'

export default {
  components: {
    StaticsCard,
  },
  props: {
    scores: {
      type: Object,
      required: true,
      default: () => {},
    },
    times: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods: {
    parseToMb(val) {
      return `${(val / 1000000).toFixed(2)}MB`
    },
  },
}
</script>
