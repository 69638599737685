<template>
  <div>
    <register-sidebar
      :show-open-register-sidebar.sync="showOpenRegisterSidebar"
      :gologin="gologin"
    />
    <!-- <archive-site
      :idsite="idsite"
      :show-open-archive-site.sync="showOpenArchiveSite"
    /> -->
    <activate-site
      :idsite="idsite"
      :show-open-archive-site.sync="showOpenArchiveSite"
    />

    <span class="d-none">
      {{ nameUser = ($route.name === 'shared-site' || $route.name === 'contact-invited') && !usuario.uid ? 'Guess' : usuario.nombre ? usuario.nombre.trim() : usuario.email }}
    </span>

    <div :style="!nameUser ? 'position: absolute; right: 170px; top: -1.8px;' : nameUser.length <= 8 ? 'position: absolute; right: 195px; top: -1.8px;' : nameUser.length > 8 && nameUser.length <= 12 ? 'position: absolute; right: 200px; top: -1.8px;' : nameUser.length > 12 && nameUser.length <= 16 ? 'position: absolute; right: 240px; top: -1.8px;' : nameUser.length > 16 ? 'position: absolute; right: 270px; top: -1.8px;' : 'position: absolute; right: 180px; top: -1.8px;'" :class="!nameUser ? 'container-dropdown-search-sites-header' : nameUser.length > 16 ? 'container-dropdown-search-sites-header container-dropdown-search-sites-header-nlg' : 'container-dropdown-search-sites-header'" v-if="!isDevSite || uidUserShared">
      <b-dropdown
        id="dropdown-form"
        ref="dropdown_search"
        left
        variant="light"
        :class="!nameUser ? 'dropdown-search-sites-header' : nameUser.length > 16 ? 'dropdown-search-sites-header dropdown-search-sites-header-lg-name' : 'dropdown-search-sites-header'"
        style="width: 40px; height: 40px;"
      >
        <template #button-content>
          <feather-icon
            icon="SearchIcon"
            size="16"
            class="align-middle icon-search-btn"
            color="#000"
            style="position: absolute; left: 7px; top: 7px; color: #000;"
          />
        </template>

        <vue-autosuggest
          v-model="searchSite"
          :suggestions="suggestionsSites"
          :input-props="inputProps"
          :section-configs="sectionConfigs"
          :render-suggestion="renderSuggestion"
          @input="fetchResults"
          style="width: 227px; height: 44px; border: 1px solid transparent; border-radius: 22px;"
          class="autosuggest-sites-dropdown"
        >
        </vue-autosuggest>
      </b-dropdown>

      <svg xmlns="http://www.w3.org/2000/svg" width="1" height="30" :class="!nameUser ? 'line-vet-header' : nameUser.length > 16 ? 'line-vet-header line-vet-header-lg-name' : 'line-vet-header'">
        <line x1="0" y1="0" x2="0" y2="30" stroke="white" stroke-width="1" />
      </svg>


      <vue-autosuggest
        v-model="searchSite"
        :suggestions="suggestionsSites"
        :input-props="inputProps"
        :section-configs="sectionConfigs"
        :render-suggestion="renderSuggestion"
        @input="fetchResults"
        style="width: 227px; height: 44px; border: 1px solid transparent; border-radius: 22px;"
        class="autosuggest-sites-dropdown search-sites-header"
      >
      </vue-autosuggest>
      <svg xmlns="http://www.w3.org/2000/svg" width="1" height="35" class="line-vet-header-desktop">
        <line x1="0" y1="0" x2="0" y2="35" stroke="white" stroke-width="1" />
      </svg>
    </div>

    <b-nav-item-dropdown
      id="dropdown-1"
      ref="dropdown"
      right
      toggle-class="d-flex align-items-center dropdown-user-link nav-link-custom"
      class="dropdown-user dropdown-user-header pb-75"
      style="z-index: 30;"
    >
      <template #button-content>
        <b-avatar
          size="40"
          :src="usuario.foto ? usuario.foto : ''"
          variant="light-primary"
          :text="avatarText($route.name === 'shared-site' || $route.name === 'contact-invited' ? (usuario.nombre ? (usuario.nombre.includes(' ') ? usuario.nombre : usuario.nombre + ' ' + usuario.nombre.charAt(1)) :  usuario.email ? usuario.email + ' ' + usuario.email.charAt(1): 'G') : usuario.nombre ? (usuario.nombre.includes(' ') ? usuario.nombre : usuario.nombre + ' ' + usuario.nombre.charAt(1)) :  usuario.email ? usuario.email + ' ' + usuario.email.charAt(1): 'G')"
          badge
          class="badge-minimal mr-50"
          badge-variant="success"
          alt="img user"
          :style="usuario.foto ? 'background: #fff !important;' : 'background: #242150;'"
        >
          <feather-icon
            v-if="!usuario.nombre && !usuario.foto && !usuario.email"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>

        <div class="d-sm-flex d-none user-nav ml-50 user-dropdown-text">
          <p
            class="mb-0"
            :style="'font-size: 14px; font-weight: 300 !important; margin-right: 15px; max-width: 145px; text-overflow: ellipsis; overflow: hidden;'"
          >
            {{ ($route.name === 'shared-site' || $route.name === 'contact-invited') && !usuario.uid ? 'Guess' : usuario.nombre ? usuario.nombre : usuario.email }}
          </p>
        </div>
      </template>

      <b-dropdown-item
        link-class="dropdown-name-info"
        class="item-dropdown-user item-dropdown-name-info"
        style="font-size: 13px; max-width: 180px; text-overflow: ellipsis; overflow: hidden; line-height: 10px;"
        v-if="$route.name !== 'shared-site' && $route.name !== 'contact-invited' && usuario.nombre"
      >
        <p style="font-weight: bold;" class="mb-0">{{usuario.nombre}}</p>
        <p style="line-height: 15px; max-width: 120px; overflow: hidden; text-overflow: ellipsis;" class="mb-0">{{usuario.email}}</p>
      </b-dropdown-item>

      <b-dropdown-divider class="dropdown-name-info"></b-dropdown-divider>

      <b-dropdown-item
        :to="{ name: 'sites-no-project' }"
        link-class="d-flex align-items-center item-dropdown-user"
        class="item-dropdown-user"
        style="font-size: 13px;"
        v-if="$route.name !== 'shared-site' && $route.name !== 'contact-invited'"
      >
        <span>My Sites</span>
      </b-dropdown-item>

      <b-dropdown-item
        :to="{ name: 'projects' }"
        link-class="d-flex align-items-center item-dropdown-user"
        class="item-dropdown-user"
        style="font-size: 13px;"
        v-if="$route.name !== 'shared-site' && $route.name !== 'contact-invited' && hasProjects"
      >
        <span>My Projects</span>
      </b-dropdown-item>

      <b-dropdown-item
        :to="{ name: 'pages-account-setting' }"
        link-class="d-flex align-items-center item-dropdown-user"
        style="font-size: 13px;"
        v-if="$route.name !== 'shared-site' && $route.name !== 'contact-invited'"
      >
        <span>Profile</span>
      </b-dropdown-item>

      <b-dropdown-item
        :to="{ name: 'memberships' }"
        link-class="d-flex align-items-center item-dropdown-user"
        style="font-size: 13px;"
        v-if="$route.name !== 'shared-site' && $route.name !== 'contact-invited'"
      >
        <span>Licenses</span>
      </b-dropdown-item>

      <b-dropdown-item
        link-class="d-flex align-items-center item-dropdown-user"
        style="font-size: 13px;"
        v-if="$route.name !== 'shared-site' && $route.name !== 'contact-invited'"
        @click="logout"
      >
        <span>Sign out</span>
      </b-dropdown-item>

      <b-dropdown-item
        link-class="d-flex align-items-center item-dropdown-user"
        style="font-size: 13px;"
        v-if="($route.name === 'shared-site' || $route.name === 'contact-invited') && usuario.uid"
        @click="logout"
      >
        <span>Sign out</span>
      </b-dropdown-item>

      <b-dropdown-item
        @click="openRegister"
        link-class="d-flex align-items-center item-dropdown-user"
        class="item-dropdown-user"
        style="font-size: 13px;"
        v-if="($route.name === 'shared-site' || $route.name === 'contact-invited') && !usuario.uid"
      >
        <span>Sign up</span>
      </b-dropdown-item>

      <b-dropdown-item
        @click="openRegister('login')"
        link-class="d-flex align-items-center item-dropdown-user"
        class="item-dropdown-user"
        style="font-size: 13px;"
        v-if="($route.name === 'shared-site' || $route.name === 'contact-invited') && !usuario.uid"
      >
        <span>Login</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import {
  BRow, BCol, BProgress, BSpinner, BOverlay, BCardText, BCard, BLink, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BImgLazy,
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BButton,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import { avatarText } from '@core/utils/filter'
import { mapActions, mapState } from 'vuex'
import { initialAbility } from '@/libs/acl/config'
import RegisterSidebar from '@/views/pages/authentication/RegisterSidebar.vue'
// import ArchiveSite from '@/views/extensions/acl/table/sidebar/ArchiveSite.vue'
import ActivateSite from '@/views/extensions/acl/table/sidebar/ActivateSite.vue'
import useJwt from '@/auth/jwt/useJwt'
import { db, auth } from '@/firebase'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
    BRow,
    BCol,
    BProgress,
    BSpinner,
    BOverlay,
    BCardText,
    BCard,
    BLink,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BImgLazy,
    VueAutosuggest,
    RegisterSidebar,
    // ArchiveSite,
    ActivateSite,
  },
  data() {
    return {
      // userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      belongsTeams: '',
      show: false,
      showOpenRegisterSidebar: false,
      showOpenArchiveSite: false,
      gologin: false,
      isDevSite: false,
      userData: '',
      idsite: '',
      nameUser: '',
      uidUserShared: '',
      hasProjects: false,
      timerUpdateSearch: null,

      // autosuggest
      datasuggest: {
        data: [{
          sites: [],
        }],
      },
      searchSite: '',
      suggestionsSites: [],
      selected: null,
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: "Search site",
      },
      sectionConfigs: {
        sites: {
          limit: 60,
          // label: 'Sites',
          onSelected: selected => {
            // console.log(selected.item)
            this.selected = selected.item
          },
        },
        default: {
          limit: 60,
          onSelected: selected => {
            // console.log(selected)
            this.selected = selected.item
          }
        },
      },
      limit: 60,
      loaderSearch: false
    }
  },
  computed: {
    ...mapState(['usuario']),
  },
  watch: {
    // searchSite(val) {
    //   setTimeout(() => {
    //     const collection = document.getElementsByClassName('vgt-text-disabled')
    //     if (collection[0] && val) {
    //       collection[0].innerHTML = 'No results found'
    //     }
    //   }, 1000)
    // },
    selected(val) {
      // console.log(val)
      if(val) {
        this.getSite(val)
        this.closeDropdown()
      }
    },
    searchSite(val) {      
      if(!val || val.length <= 2) {
        // setTimeout(() => {
          // const collection = document.getElementsByClassName('.autosuggest__results-container')
          // if(collection[0]) {
          //   collection[0].style.display = 'none'
          // }

          var x = document.getElementsByClassName('autosuggest__results-container')
          var i
          for(i = 0; i < x.length; i++) {
            x[i].style.display = 'none'
          }
        // }, 200)

        var x = document.getElementsByClassName('dropdown-menu')
        var i
        // console.log(x.length)
        for(i = 0; i < x.length; i++) {
          x[i].style.height = 'auto'
        }
      } else {
        var x = document.getElementsByClassName('autosuggest__results-container')
        var i
        for(i = 0; i < x.length; i++) {
          x[i].style.display = 'block'
        }

        setTimeout(() => {
          //   const collection = document.getElementsByClassName('autosuggest-autosuggest__results')
          //   if (collection[0] && val) {
          //     collection[0].innerHTML = 'No results found'
          //   }
          if(!this.suggestionsSites.length) {
            this.renderSuggestion()
          }
        }, 600)
      }
    }
  },
  methods: {
    ...mapActions(['cerrarSesion']),
    closeDropdown() {
      this.$refs.dropdown_search.hide()
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Reset ability
      // this.$ability.update(initialAbility)

      // Redirect to login page
      // this.$router.push({ name: 'auth-login' })
      localStorage.setItem('userData', JSON.stringify({ teams: 0 }))
      this.cerrarSesion()
    },
    openRegister(payload) {
      // console.log('open register')
      if(payload === 'login') {
        this.gologin = true
      } else {
        this.gologin = false
      }
      this.showOpenRegisterSidebar = true
    },
    fetchResults(text) {
      if(this.searchSite) {
        const { searchSite } = this
        if(searchSite) {
          this.suggestionsSites = []
        }

        const filteredDevelopers = this.datasuggest.data[0].sites.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 || item.domain.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
        filteredDevelopers.length && this.suggestionsSites.push({ data: filteredDevelopers })
        // name: 'sites',
      }
    },
    getSite(val) {
      // console.log(val)
      if(val.id) {
        if(val.id && val.own === 'other' && val.invitation.statusInv === 'sended') {
          this.logIdAcceptInvitation(val)
        } else if(val.status === 'archive') {
          this.logIdArchive(val)
        } else {
          var urlWindowPath = window.location.pathname
          // console.log(urlWindowPath)
          if(urlWindowPath.includes('/my-sites/view-errors/')) {
            this.$router.push({ name: 'view-errors', params: {id: val.id, imgSite: val.img, name: val.name, url: val.domain, project: val.projectid, own: val.own}, force: true })
            this.$router.go()
            this.searchSite = ''
            this.selected = null
          } else {
            this.$router.push({ name: 'view-errors', params: {id: val.id, imgSite: val.img, name: val.name, url: val.domain, project: val.projectid, own: val.own} })
            // this.$router.go()
            this.searchSite = ''
            this.selected = null
          }
        }
      }
    },
    renderSuggestion(suggestion) {
      if(!suggestion) {
        // console.log('first')
        this.suggestionsSites = [{data: [{name: null, title: null, domain: null}]}]
        var x = document.getElementsByClassName('dropdown-menu')
        var i
        // console.log(x.length)
        for(i = 0; i < x.length; i++) {
          x[i].style.height = '40vh'
        }
        var x = document.getElementsByClassName('autosuggest__results-container')
        var i
        for(i = 0; i < x.length; i++) {
          x[i].style.display = 'block'
        }
        
        return (
          <div class="py-50">
            <span style="font-weight: 700; font-size: 12px !important; color: #181E38 !important; display: flex; margin-top: 0px; line-height: 0.8rem !important;">
            <span style="color: #181E38 !important; line-height: 0.8rem !important; padding-top: 6px;">{'No results'}</span>
            </span>
          </div>
        )
      } else {
        const site = suggestion.item
        if(this.searchSite) {
          // console.log(0)
          if(this.loaderSearch && this.datasuggest.data[0].sites.length === 0) {
            // console.log(0.1)
            return (
              <div class="py-50">
                <span style="font-weight: 700; font-size: 12px !important; color: #181E38 !important; display: flex; margin-top: 0px; line-height: 0.8rem !important;">
                  <b-spinner class="align-middle"></b-spinner>
                </span>
              </div>
            )
          } else {
            if(suggestion.item.id) {
              // console.log(1)
              if(this.suggestionsSites.length && (this.searchSite.length >= 3)) {
                var x = document.getElementsByClassName('dropdown-menu')
                var i
                // console.log(x.length)
                for(i = 0; i < x.length; i++) {
                  x[i].style.height = '40vh'
                }
              }

              return (
                <div class="py-50">
                  {site.project !== 'My sites' ?
                  <span class="mr-50" style="line-height: 0.8rem !important; font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 12px; display: flex; margin-top: 0px;">
                    <div style="width: 12px; height: 12px; border-radius: 4px; margin-top: 6px;" class="mr-50"></div>
                    <span style="color: #A7AAB9 !important; line-height: 0.8rem !important;">Project</span>
                  </span>
                  : ''}
                  <span style="font-weight: 700; font-size: 12px !important; color: #181E38 !important; display: flex; margin-top: 0px; line-height: 0.8rem !important;">
                    <div style={"width: 12px; height: 12px; border-radius: 4px; background: "+site.color+"; margin-top: 6px;"} class="mr-50"></div>
                    <span style="color: #181E38 !important; line-height: 0.8rem !important; padding-top: 6px;">{site.project.length > 35 ? site.project.substring(0, 35) + '...' : site.project}</span>
                  </span>
                  <div class="d-flex align-items-center my-50">
                    <img src={site.img} alt="" class="mr-50" width="35" height="35" />
                    <div class="detail" style="line-height: 0.8rem !important;">
                      <b-card-text class="mb-0" style="font-size: 16px; line-height: 1.2rem !important;">{site.name.length > 55 ? site.name.substring(0, 55) + '...' : site.name}</b-card-text>
                      <b-card-text class="text-muted mb-0" style="font-size: 14px; line-height: 1rem !important; text-overflow: ellipsis; max-width: 160px; overflow: hidden;">{site.domainFixed.length > 35 ? site.domainFixed.substring(0, 35) + '...' : site.domainFixed}</b-card-text>
                    </div>
                  </div>
                  { site.status === 'archive' ? 
                    <b-button
                      variant="secondary"
                      style="background: #F0F1F7 0% 0% no-repeat padding-box !important; border: 1px solid #CFD3E5 !important; color: #656B85 !important; padding: 2px 7px; border-radius: initial;"
                      class="p-auto mr-50"
                    >
                      <feather-icon
                        icon="ArchiveIcon"
                        class="mr-50"
                      />
                      <span class="align-middle ">Archived</span>
                    </b-button>
                  : '' }
                </div>
              )
            } else {
              // console.log(2)
              if(this.loaderSearch) {
                // console.log(2.1)
                return (
                  <div class="py-50">
                    <span style="font-weight: 700; font-size: 12px !important; color: #181E38 !important; display: flex; margin-top: 0px; line-height: 0.8rem !important;">
                      <b-spinner class="align-middle"></b-spinner>
                    </span>
                  </div>
                )
              } else {
                // console.log(2.2)
                return (
                  <div class="py-50">
                    <span style="font-weight: 700; font-size: 12px !important; color: #181E38 !important; display: flex; margin-top: 0px; line-height: 0.8rem !important;">
                    <span style="color: #181E38 !important; line-height: 0.8rem !important; padding-top: 6px;">{'No results'}</span>
                    </span>
                  </div>
                )
              }
            }
          }
        }
      }
    },
    logIdArchive(val) {
      this.idsite = val
      this.showOpenArchiveSite = true
    },
    loadSuggestSites() {
      const user = auth.currentUser
      if(user) {
        db.collection('Users').doc(user.uid).collection('projects').get()
        .then(userDoc => {
          // console.log(userDoc.size)
          this.datasuggest.data[0].sites = []
          if(userDoc.size && userDoc.size >= 2) {
            this.hasProjects = true
          }

          userDoc.forEach(eleUserProject => {
            if(eleUserProject.data().name !== 'My sites') {
              this.hasProjects = true
            }

            db.collection('Projects').doc(eleUserProject.data().id).collection('sites')
            // .onSnapshot(queryArchiveProject => {
            .get().then(queryArchiveProject => {
              queryArchiveProject.forEach(site => {
                if(this.datasuggest.data[0].sites.filter(ele => ele.id === site.id).length === 0) {
                  site.data().thumbnail === 'https://api.urlbox.io/v1/iWVAo1U5wUbLhzWJ/2127a95078df8968de280364103e64acdd6a23b1/jpg?url=https://menara.gt&thumb_width=600&quality=70&delay=500' ? site.data().thumbnail = 'https://src.seocloud.ai/site-thumb.png' : null
                  if(site.data().thumbnail && site.data().thumbnail === 'https://api.urlbox.io/v1/iWVAo1U5wUbLhzWJ/2127a95078df8968de280364103e64acdd6a23b1/jpg?url=https://menara.gt&thumb_width=600&quality=70&delay=500') {
                    function imgExists(url, callback) {
                      var img = new Image()
                      img.onerror = function() {
                        callback(false)
                      }
                      img.onload = function () {
                        callback(true);
                      }
                      img.src = url
                    }

                    var checkImage = (exists) => {
                      // console.log(site.name, exists)
                      this.datasuggest.data[0].sites.push({ id: site.id, img: exists ? site.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png', name: site.data().name ? site.data().name : site.data().WebsiteURL, domainFixed: site.data().WebsiteURL, project: eleUserProject.data().name, status: site.data().status, domain: site.data().homepage, projectid: eleUserProject.data().id, color: eleUserProject.data().color ? eleUserProject.data().color : 'rgb(240, 241, 247)', own: 'me', invitation: '', imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png' })
                    }

                    imgExists(site.data().thumbnail, checkImage)
                  } else {
                    this.datasuggest.data[0].sites.push({ id: site.id, img: site.data().thumbnail ? site.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png', name: site.data().name ? site.data().name : site.data().WebsiteURL, domainFixed: site.data().WebsiteURL, project: eleUserProject.data().name, status: site.data().status, domain: site.data().homepage, projectid: eleUserProject.data().id, color: eleUserProject.data().color ? eleUserProject.data().color : 'rgb(240, 241, 247)', own: 'me', invitation: '', imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png' })
                  }
                } else {
                  var filterPage = this.datasuggest.data[0].sites.filter(ele => ele.id === site.id)
                  filterPage.forEach(ele => {
                    ele.img = site.data().thumbnail ? site.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png'
                    ele.name = site.data().name ? site.data().name : site.data().WebsiteURL
                    ele.status = site.data().status
                    ele.domain = site.data().homepage
                    ele.imgSite = site.data().thumbnail ? site.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png'
                  })
                }
              })
              
              setTimeout(() => {
                this.loaderSearch = false
                this.fetchResults(this.searchSite)
              }, 1500)
            })
          })
        })
      }
    },
  },
  created() {
    // console.log('first')
    this.timerUpdateSearch = null
    this.hasProjects = false
    this.showOpenRegisterSidebar = false
    this.showOpenArchiveSite = false
    this.idsite = ''
    this.isDevSite = false
    this.loaderSearch = true
    var urlWindow = window.location.href
    var urlWindowTransform = new URL(urlWindow)
    var urlWindowDomain = urlWindowTransform.hostname
    var urlWindowPath = window.location.pathname
    this.uidUserShared = ''
    // console.log(urlWindowDomain)
    // if(urlWindowDomain === 'app.seocloud.ai' || urlWindowDomain === 'localhosts') {
      urlWindowPath === '/share' ? this.isDevSite = true : this.isDevSite = false
    // }

    setTimeout(() => {
      this.loaderSearch = false
    }, 1500)

    // console.log(urlWindowPath)
    if(urlWindowPath !== '/share') {
      const user = auth.currentUser
      if(user) {
        this.userData = {
          uid: user.uid
        }
        this.loadErrors = false

        this.loadSuggestSites()
      } else {
        this.logout()
      }
    } else {
      const userSharedPage = auth.currentUser
      if(userSharedPage) {
        this.uidUserShared = userSharedPage.uid
        this.loadSuggestSites()
      }
    }
  },
  mounted() {
    this.timerUpdateSearch = setInterval(() => {
      var reloadSearchSites = localStorage.getItem('reloadSearchSites')
      // console.log(reloadSearchSites)
      if(reloadSearchSites) {
        this.loadSuggestSites()
        localStorage.removeItem('reloadSearchSites')
      }
    }, 1000)
  },
  destroyed() {
    clearInterval(this.timerUpdateSearch)
  },
}
</script>

<style>
.header-navbar .navbar-container .show .dropdown-menu {
  width: 125px !important;
  min-width: 125px !important;
  /* right: 0px; */
  left: unset !important;
  margin-right: 12px;
}
.dropdown-menu-right {
  width: 115px !important;
  min-width: 101px !important;
  margin-top: 0px !important;
  border-radius: 10px;
  border: 1px solid #bbbbb9 !important;
  z-index: 30;
}
.item-dropdown-user {
  padding: 0px auto;
}
.item-dropdown-user:hover {
  background: none !important;
  background-color: none !important;
  color: #000 !important;
}
.user-dropdown-text::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
  position: absolute;
  margin-top: 10px;
}
.autosuggest-sites-dropdown > div > input {
  width: 227px;
  height: 44px;
  border: 1px solid #A7AAB9;
  border-radius: 22px;
}
.autosuggest__results-item:hover {
  background: #e8e8e8 !important;
}
.autosuggest-sites-dropdown.autosuggest__results-container {
  background: #fff !important;
  /* width: 100% !important; */
  /* max-width: 350px !important; */
}
.header-navbar .navbar-container .container-dropdown-search-sites-header .show .dropdown-menu {
  width: 231px !important;
  min-width: 220px !important;
  /* margin-top: 7px; */
  position: absolute;
  /* left: -150px !important; */
  left: -190px !important;
  background: transparent !important;
  border: none !important;
  /* background: #fff !important; */
  border-radius: 0px 0px 10px 10px;
  top: -14px;
  box-shadow: none !important;
  z-index: 11;
}
.container-dropdown-search-sites-header .autosuggest-sites-dropdown .autosuggest__results-container {
  background: #fff !important;
  border: none !important;
  box-shadow: none !important;
}
.dropdown-search-sites-header button {
  width: 40px !important;
  height: 40px !important;
  margin-top: 2px;
  border-radius: 40px;
  border: 0px;
  background: #fff !important;
}
.dropdown-search-sites-header button:hover {
  background: #d9d9d9 !important;
}
.dropdown-search-sites-header button::after {
  display: none !important;
}
.dropdown-user-header .dropdown-menu {
  height: auto !important;
}
/* .dropdown-search-sites-header .autosuggest-autosuggest__results {
  height: 40vh !important;
  max-height: 40vh !important;
  background: transparent !important;
} */
@media (min-width: 1200px) {
  .dropdown-search-sites-header {
    display: none !important;
  }
  .line-vet-header-desktop {
    position: absolute;
    right: -26px;
    top: 5px;
    margin-right: 12px;
    margin-left: 12px;
  }
  .line-vet-header {
    display: none !important;
  }
}
@media (max-width: 1199px) {
  .user-dropdown-text p {
    max-width: 100px !important;
    /* width: 100px !important; */
  }
  .container-dropdown-search-sites-header {
    right: 170px !important;
  }
  .line-vet-header-desktop {
    display: none !important;
  }
  .dropdown-search-sites-header {
    right: 12px !important;
    position: absolute !important;
  }
  .dropdown-search-sites-header-lg-name {
    right: 60px !important;
    position: absolute !important;
  }
  .search-sites-header {
    display: none !important;
  }
  .line-vet-header {
    position: absolute;
    right: -15px;
    top: 8px;
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
  .line-vet-header-lg-name {
    position: absolute;
    right: 33px;
    top: 8px;
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
  .icon-search-btn {
    left: 12px !important;
    top: 12px !important;
  }
  .header-navbar .navbar-container .container-dropdown-search-sites-header .show .dropdown-menu {
    width: 231px !important;
    min-width: 220px !important;
    /* margin-top: 7px; */
    position: absolute;
    /* left: -150px !important; */
    left: -186px !important;
    background: transparent !important;
    border: none !important;
    /* background: #fff !important; */
    border-radius: 0px 0px 10px 10px;
    top: -7px;
    box-shadow: none !important;
    z-index: 11;
  }
}
@media (min-width: 769px) {
  /* .dropdown-search-sites-header {
    display: none !important;
  } */
  /* .line-vet-header {
    display: none !important;
  } */
  .dropdown-name-info {
    display: none !important;
  }
  .dropdown-email-info {
    display: none !important;
  }
}
@media (max-width: 768px) {
  .user-dropdown-text p {
    max-width: 50px !important;
    /* width: 100px !important; */
  }
  .header-navbar .navbar-container .container-dropdown-search-sites-header .show .dropdown-menu {
    width: 231px !important;
    min-width: 220px !important;
    /* margin-top: 7px; */
    position: absolute;
    /* left: -150px !important; */
    left: -197px !important;
    background: transparent !important;
    border: none !important;
    /* background: #fff !important; */
    border-radius: 0px 0px 10px 10px;
    top: -14px;
    box-shadow: none !important;
    z-index: 11;
  }
  .icon-search-btn {
    left: 7px !important;
    top: 7px !important;
  }
  .header-navbar .navbar-container .show .dropdown-menu {
    width: 165px !important;
    min-width: 165px !important;
    /* right: 0px; */
    /* left: unset !important; */
    /* margin-right: 12px; */
  }
  .dropdown-user-header .item-dropdown-name-info a {
    padding-top: 3px !important;
  }
  .container-dropdown-search-sites-header .dropdown-search-sites-header {
    width: 30px !important;
    height: 30px !important;
  }
  .container-dropdown-search-sites-header .dropdown-search-sites-header button {
    width: 30px !important;
    height: 30px !important;
    padding: 0px !important;
  }
  /* .container-dropdown-search-sites-header .line-vet-header {
    width: 25px !important;
    height: 25px !important;
  } */
  .dropdown-user-header a .b-avatar {
    width: 30px !important;
    height: 30px !important;
  }
  .search-sites-header {
    display: none !important;
  }
  .dropdown-user-header a {
    margin-right: 0px !important;
    padding-right: 0px !important;
  }
  .dropdown-user-header a .user-dropdown-text {
    margin-right: 0px !important;
  }
}
@media (max-width: 768px) and (min-width: 576px) {
  .line-vet-header {
    position: absolute;
    right: -43px;
    top: 2px;
  }
  .line-vet-header-lg-name {
    position: absolute;
    right: 0px !important;
    top: 2px !important;
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
  .dropdown-search-sites-header {
    right: -17px !important;
    position: absolute !important;
  }
  .dropdown-search-sites-header-lg-name {
    right: 20px !important;
    position: absolute !important;
  }
  .user-dropdown-text p {
    max-width: 100px !important;
    /* width: 100px !important; */
  }
  /* .dropdown-search-sites-header-lg-name {
    right: 60px !important;
    position: absolute !important;
  } */
}
@media (max-width: 575px) {
  .dropdown-search-sites-header {
    right: -93px !important;
    position: absolute !important;
  }
  .line-vet-header {
    position: absolute;
    right: -118px;
    top: 2px;
  }
}
</style>