import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/database'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyDI2X8VrdMflIO97BLY_EqomktjXmgaeSc',
  authDomain: 'seocloud-dcdfb.firebaseapp.com',
  // authDomain: 'app.seocloud.ai',
  databaseURL: 'https://seocloud-dcdfb.firebaseapp.com',
  projectId: 'seocloud-dcdfb',
  storageBucket: 'src.seocloud.ai',
  messagingSenderId: '536750948109',
  appId: '1:536750948109:web:81ecd208ad33d9aaa13a44',
}

firebase.initializeApp(firebaseConfig)

const secondaryApp = firebase.initializeApp(firebaseConfig, 'Secondary')

const firebaseErrorConfig = {
  apiKey: "AIzaSyAllirPWr4rok-A0x1eQEDNspuZljSbWIE",
  authDomain: "seocloud-7e24d.firebaseapp.com",
  databaseURL: 'https://seocloud-7e24d.firebaseapp.com',
  projectId: "seocloud-7e24d",
  storageBucket: "seocloud-7e24d.appspot.com",
  messagingSenderId: "678073539367",
  appId: "1:678073539367:web:7471140a9a9a192e57a702"
};


const firebaseError = firebase.initializeApp(firebaseErrorConfig, 'third')

const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()
const realtime = firebase.database()
const functions = firebase.functions()
const dbErrors = firebaseError.firestore()

export {
  firebase, db, auth, storage, realtime, functions, secondaryApp, firebaseError, dbErrors,
}