export const validatorRequiredString = val => {
  if (val === undefined || val === null || val.length === 0) {
    return false
  }
  return true
}

export const validatorMinString = value => {
  if(!value) {
    return false
  } else if (value.length < 5) {
    return false
  } else {
    return true
  }
}

export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

// export const validatorUrlValidator = val => {
//   if (val === undefined || val === null || val.length === 0) {
//     return true
//   }
//   /* eslint-disable no-useless-escape */
//   const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
//   /* eslint-enable no-useless-escape */
//   return re.test(val)
// }

export const validatorUrlValidator = (val) => {
  if (val === undefined || val === null || val.length === 0) {
    return true; // Considerar válido si está vacío, opcional
  }

  const re = /^(https?:\/\/)(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/;

  return re.test(val);
};

export const validatorUrlValidatorTarget = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true;
  }

  if (val === '#') {
    return true;
  }
  
  /* eslint-disable no-useless-escape */
  const re = /^#?(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
  /* eslint-enable no-useless-escape */
  return re.test(val);
};

export const validatorVariableValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^[a-zA-Z0-9_]*$/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const validatorImgValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /\.(gif|jpe?g|tiff?|png|webp|bmp|svg|jpg|jfif|pjpeg|pjp|avif|apng)$/i;
  /* eslint-enable no-useless-escape */
  return re.test(val)
}