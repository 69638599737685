<template>
    <div class="p-6">
        <div class="mb-8 collapse-sugg-copilot">
            <button @click="isOpenA = ! isOpenA" class="collapse-sugg-copilot-header" style="">
                Click to Toggle
            </button>
            <collapse-transition class="collapse-sugg-copilot-content">
                <ul v-show="isOpenA" class="list-reset">
                    <li>Example!</li>
                    <li>Example!</li>
                    <li>Example!</li>
                    <li>Example!</li>
                    <li>Example!</li>
                    <li>Example!</li>
                </ul>
            </collapse-transition>
        </div>
    </div>
</template>

<script>
import { CollapseTransition } from "@ivanv/vue-collapse-transition"

export default {
    components: {
        CollapseTransition
    },
    data() {
        return {
            isOpenA: false,
            
            isOpenTitle: false,
            isOpenDescription: false,
            isOpenMissingImg: false,
            isOpenAlts: false,
            isOpenDeadLink: false,
            isOpenStrings: false,
            isOpenHeadings: false,
            isOpenCanonical: false,
            isOpenGrammar: false,
            isOpenImages: false,
        }
    },
}
</script>

<style>
/* collapse class */
.collapse-sugg-copilot {
    padding: 1rem 2.8rem 1rem 1rem;
    padding-left: 16px !important;
    border-radius: 0.358rem;
    background: white !important;
    max-width: 1300px;
    border: 1px solid rgb(232, 232, 232) !important;
    width: 100%;
}
.collapse-sugg-copilot-header {
    /* padding: 1rem 2.8rem 1rem 1rem;
    padding-left: 16px !important;
    border-radius: 0.358rem; */
    background-color: transparent;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    border: none;
}
.collapse-sugg-copilot-content {
    margin-top: 15px;
}
</style>