<template>
  <div>
    <b-sidebar
      id="sidebar-right-crawl-settings"
      ref="sidebar_crawl_settings"
      :visible="showOpenCrawlSettings"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-crawl-settings', val)"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #F0F1F7; height: 91px;"
        >
          <b-card
            no-body
            class="overflow-hidden w-100"
            style="background-color: #F0F1F7;"
          >
            <header-sidebar :idsite-header.sync="idsite" />
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <div class="ml-3 my-2 mt-3 crawl-body-settings-container">
          <h3 class="mb-1">Crawl settings</h3>
          <b-row>
            <b-col
              md="12"
              sm="12"
            >
              <h5
                class="float-left pl-1 pt-50"
                style="font-weight: 700; font-size: 16px; color: #A7AAB9 !important;"
              >
                Number to pages:
              </h5>

              <b-card
                no-body
                class="overflow-hidden w-100"
              >
                <b-row
                  no-gutters
                  class="mt-1 row-container-configs"
                  style="height: 110px;"
                >
                  <b-col
                    md="3"
                    sm="3"
                    :class="`cards card-config-crawl w-100 type-active mr-50 ${numberPages === '0' || numberPages === 0 ? 'type-active' : 'type-inactive'}`"
                    style="height: 85px;"
                  >
                    <label
                      class="w-100"
                      style="height: 85px;"
                      role="button"
                    >
                      <input
                        v-model="numberPages"
                        type="radio"
                        value="0"
                        checked
                        style="float: left; margin-top: 15px; display: none;"
                      >
                      <div
                        class="pb-50"
                        style="margin-top: 3px;"
                      >
                        <p
                          class="color-text-dark mb-0"
                          :style="numberPages === '0' || numberPages === 0 ? 'color: rgb(120, 56, 255) !important; padding-top: 20px; font-size: 35px; font-weight: bold; text-align: center; margin-bottom: 10px !important;' : 'color: #7f7f7e !important; padding-top: 20px; font-size: 35px; font-weight: bold; text-align: center; margin-bottom: 10px !important;'"
                        >
                          25
                        </p>
                        <b-list-group
                          class="list-group-circle mb-50 mt-50"
                          style="width: 100% !important; display: block; text-align: center;"
                        >
                          <span
                            :style="`padding-bottom: 0px; ${numberPages === '0' || numberPages === 0 ? 'color: #7838FF !important; background: rgb(243, 238, 255) !important;' : 'color: #656B85 !important; background: #fff !important;'}`"
                          >
                            Pages
                          </span>
                        </b-list-group>
                      </div>
                    </label>
                    <span style="width: 100%; display: block; text-align: center;">{{ isloggedIn ? 'Free' : 'As Guess' }}</span>
                  </b-col>
                  <b-col
                    md="3"
                    sm="3"
                    :class="`cards card-config-crawl w-100 ml-50 ${numberPages === '1' || numberPages === 1 ? 'type-active' : 'type-inactive'}`"
                    style="height: 85px;"
                  >
                    <label
                      class="w-100"
                      style="height: 85px;"
                      role="button"
                    >
                      <input
                        v-model="numberPages"
                        type="radio"
                        value="1"
                        checked
                        style="float: left; margin-top: 15px; display: none;"
                      >
                      <div
                        class="pb-50"
                        style="margin-top: 3px;"
                      >
                        <p
                          class="color-text-dark mb-0"
                          :style="numberPages === '1' || numberPages === 1 ? 'color: rgb(120, 56, 255) !important; padding-top: 20px; font-size: 35px; font-weight: bold; text-align: center; margin-bottom: 10px !important;' : 'color: #7f7f7e !important; padding-top: 20px; font-size: 35px; font-weight: bold; text-align: center; margin-bottom: 10px !important;'"
                        >
                          100
                        </p>
                        <b-list-group
                          class="list-group-circle mb-50 mt-50"
                          style="width: 100% !important; display: block; text-align: center;"
                        >
                          <span
                            :style="`padding-bottom: 0px; ${numberPages === '1' || numberPages === 1 ? 'color: #7838FF !important; background: rgb(243, 238, 255) !important;' : 'color: #656B85 !important; background: #fff !important;'}`"
                          >
                            Pages
                          </span>
                        </b-list-group>
                      </div>
                    </label>
                    <span style="width: 100%; display: block; text-align: center;">Free</span>
                  </b-col>
                  <b-col
                    md="3"
                    sm="3"
                    :class="`cards card-config-crawl w-100 ml-50 ${numberPages === '2' || numberPages === 2 ? 'type-active' : 'type-inactive'}`"
                    style="height: 85px;"
                  >
                    <label
                      class="w-100"
                      style="height: 85px;"
                      role="button"
                    >
                      <input
                        v-model="numberPages"
                        type="radio"
                        value="2"
                        checked
                        style="float: left; margin-top: 15px; display: none;"
                      >
                      <div
                        class="pb-50"
                        style="margin-top: 3px;"
                      >
                        <p
                          class="color-text-dark mb-0"
                          :style="numberPages === '2' || numberPages === 2 ? 'color: rgb(120, 56, 255) !important; padding-top: 20px; font-size: 35px; font-weight: bold; text-align: center; margin-bottom: 10px !important;' : 'color: #7f7f7e !important; padding-top: 20px; font-size: 35px; font-weight: bold; text-align: center; margin-bottom: 10px !important;'"
                        >
                          100+
                        </p>
                        <b-list-group
                          class="list-group-circle mb-50 mt-50"
                          style="width: 100% !important; display: block; text-align: center;"
                        >
                          <span
                            :style="`padding-bottom: 0px; ${numberPages === '2' || numberPages === 2 ? 'color: #7838FF !important; background: rgb(243, 238, 255) !important;' : 'color: #656B85 !important; background: #fff !important;'}`"
                          >
                            Pages
                          </span>
                        </b-list-group>
                      </div>
                    </label>
                    <span style="width: 100%; display: block; text-align: center;">License</span>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col
              md="12"
              sm="12"
            >
              <h5
                class="float-left pl-1 pt-50"
                style="font-weight: 700; font-size: 16px; color: #A7AAB9 !important;"
              >
                Crawl type:
              </h5>

              <b-card
                no-body
                class="overflow-hidden w-100"
              >
                <b-row
                  no-gutters
                  class="mt-1 row-container-configs"
                  style="height: 140px"
                >
                  <b-col
                    :md="isloggedIn ? '3' : '3'"
                    sm="3"
                    :class="`cards card-config-crawl w-100 type-active mr-50 ${crawlType === '0' || crawlType === 0 ? 'type-active' : 'type-inactive'}`"
                    style="height: 85px;"
                  >
                    <label
                      class="w-100"
                      style="height: 85px;"
                      role="button"
                    >
                      <input
                        v-model="crawlType"
                        type="radio"
                        value="0"
                        checked
                        style="float: left; margin-top: 15px; display: none;"
                      >
                      <div
                        class="pb-50"
                        style="margin-top: 3px;"
                      >
                        <p
                          class="color-text-dark mb-0"
                          style="color: #181E38 !important; padding-top: 10px; font-size: 15px; font-weight: bold; text-align: center;"
                        >
                          <b-img-lazy
                            fluid
                            :src="sitesImg"
                            alt="no sites img"
                            style="width: 61px; height: 40px;"
                            loading="lazy"
                          />
                        </p>
                        <b-list-group
                          class="list-group-circle mb-50 mt-50"
                          style="width: 100% !important; display: block; text-align: center;"
                        >
                          <span
                            :style="`padding-bottom: 0px; ${crawlType === '0' || crawlType === 0 ? 'color: #7838FF !important; background: rgb(243, 238, 255) !important;' : 'color: #656B85 !important; background: #fff !important;'}`"
                          >
                            Only HTML
                          </span>
                        </b-list-group>
                      </div>
                    </label>
                    <span style="width: 100%; display: block; text-align: center;">{{ isloggedIn ? 'Free' : 'As Guess' }}</span>
                  </b-col>
                  <b-col
                    :md="isloggedIn ? '3' : '3'"
                    sm="3"
                    :class="`cards card-config-crawl w-100 ml-50 ${crawlType === '1' || crawlType === 1 ? 'type-active' : 'type-inactive'}`"
                    style="height: 85px;"
                  >
                    <label
                      class="w-100"
                      style="height: 85px;"
                      role="button"
                    >
                      <input
                        v-model="crawlType"
                        type="radio"
                        value="1"
                        checked
                        style="float: left; margin-top: 15px; display: none;"
                      >
                      <div
                        class="pb-50"
                        style="margin-top: 3px;"
                      >
                        <p
                          class="color-text-dark mb-0"
                          style="color: #181E38 !important; padding-top: 10px; font-size: 15px; font-weight: bold; text-align: center;"
                        >
                          <b-img-lazy
                            fluid
                            :src="siteJS"
                            alt="no sites img"
                            style="width: 30px; height: 40px;"
                            loading="lazy"
                          />
                        </p>
                        <b-list-group
                          class="list-group-circle mb-50 mt-50"
                          style="width: 100% !important; display: block; text-align: center;"
                        >
                          <span
                            :style="`padding-bottom: 0px; ${crawlType === '1' || crawlType === 1 ? 'color: #7838FF !important; background: rgb(243, 238, 255) !important;' : 'color: #656B85 !important; background: #fff !important;'}`"
                          >
                            JS Crawl
                          </span>
                        </b-list-group>
                      </div>
                    </label>
                    <span style="width: 100%; display: block; text-align: center;">Free</span>
                    <span style="width: 100%; display: block; text-align: center; color: #30cdff;" v-if="isloggedIn" class="desc-config-crawl">AI SEO Copilot</span>
                  </b-col>
                  <b-col
                    md="3"
                    sm="3"
                    :class="`cards card-config-crawl w-100 ml-50 ${crawlType === '2' || crawlType === 2 ? 'type-active' : 'type-inactive'}`"
                    style="height: 85px;"
                    v-if="isloggedIn"
                  >
                    <label
                      class="w-100"
                      style="height: 85px;"
                      role="button"
                    >
                      <input
                        v-model="crawlType"
                        type="radio"
                        value="2"
                        checked
                        style="float: left; margin-top: 15px; display: none;"
                      >
                      <div
                        class="pb-50"
                        style="margin-top: 3px;"
                      >
                        <p
                          class="color-text-dark mb-0"
                          style="color: #181E38 !important; padding-top: 10px; font-size: 15px; font-weight: bold; text-align: center;"
                        >
                          <b-img-lazy
                            fluid
                            :src="siteJS"
                            alt="no sites img"
                            style="width: 30px; height: 40px;"
                            loading="lazy"
                          />
                        </p>
                        <b-list-group
                          class="list-group-circle mb-50 mt-50"
                          style="width: 100% !important; display: block; text-align: center;"
                        >
                          <span
                            :style="`padding-bottom: 0px; ${crawlType === '2' || crawlType === 2 ? 'color: #7838FF !important; background: rgb(243, 238, 255) !important;' : 'color: #656B85 !important; background: #fff !important;'}`"
                          >
                            JS Crawl
                          </span>
                        </b-list-group>
                      </div>
                    </label>
                    <span style="width: 100%; display: block; text-align: center;">License</span>
                    <span style="width: 100%; display: block; text-align: center; color: #30cdff;" class="desc-config-crawl">All pages (slow)</span>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-button
            :style="$route.name === 'shared-site' && (numberPages === 1 || numberPages === 2 || numberPages === '1' || numberPages === '2' || crawlType === 1 || crawlType === 2 || crawlType === '1' || crawlType === '2') ? 'background: #7838ff !important; border: 1px solid #7838ff !important; width: 208px; height: 46px; border-radius: 15px; margin-right: 40px;' : ((!license || license === 'free' || license === 'Free')) && (numberPages === '2' || numberPages === 2 || crawlType === '2' || crawlType === 2) ? 'background: #7838ff !important; border: 1px solid #7838ff !important; width: 248px; height: 46px; border-radius: 15px; margin-right: 40px;' : 'background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 15px; margin-right: 40px;'"
            class="float-right"
            variant="primary"
            @click.prevent="submitEditar"
            :disabled="processSubmit"
          >
            <span
              class=""
              style="font-weight: 700; color: #FFFFFF;"
            >{{ $route.name === 'shared-site' && (numberPages === 1 || numberPages === 2 || numberPages === '1' || numberPages === '2' || crawlType === 1 || crawlType === 2 || crawlType === '1' || crawlType === '2') && !isloggedIn ? 'Sign up and Run Crawl' : ((!license || license === 'free' || license === 'Free')) && (numberPages === '2' || numberPages === 2 || crawlType === '2' || crawlType === 2) ? 'Get a license and run crawl' : 'Run Crawl' }}</span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BImgLazy, BListGroup,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ClaimOwnership from '@/views/apps/sites/sites-add/ClaimOwnership.vue'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { db, auth } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BImgLazy,
    BListGroup,
    ClaimOwnership,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenCrawlSettings',
    event: 'update:show-open-crawl-settings',
  },
  props: ['idsite', 'showOpenCrawlSettings', 'configcrawl', 'license'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,

      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      crawls: '',
      processSubmit: false,
      numberPages: 0,
      crawlType: 0,
      sitesImg: require('@/assets/images/illustration/i-site.png'),
      siteJS: require('@/assets/images/icons/js.png'),
      isloggedIn: false,
    }
  },
  watch: {
    showOpenCrawlSettings(val) {
      this.isloggedIn = false
      const user = auth.currentUser
      if(val) {
        document.body.style.overflow = 'hidden'
        if(user && user.uid) {
          this.isloggedIn = true
          this.numberPages = 1
          
          if(this.$route.name === 'shared-site') {
            this.crawlType = 1
          } else {
            this.crawlType = 1
          }
        } else {
          if(this.$route.name === 'shared-site') {
            this.numberPages = 0
            this.crawlType = 0
          }
        }
      } else {
        document.body.style.overflow = 'auto'
      }
    }
  },
  validations: {
  },
  methods: {
    submitEditar(payload) {
      if(this.$route.name === 'shared-site') {
        const user = auth.currentUser
        if(user && user.uid) {
          localStorage.setItem('configRunLicenseOwner', true)
          db.collection('Sites').where('WebsiteURL', '==', this.$route.query.site).where('teamMembers', 'array-contains', user.uid).get()
          .then((querySite) => {
            if(querySite.size) {
              localStorage.setItem('configCrawlViewSite', true)
            }
          })
          this.$refs.sidebar_crawl_settings.hide()

          // if((this.numberPages === 0 || this.numberPages === '0') && (this.crawlType === 0 || this.crawlType === '0')) {
          //   localStorage.setItem('configRunLicenseOwner', true)
          //   this.$refs.sidebar_crawl_settings.hide()
          // }
          // else if(this.idsite.permission !== 'owner') {
          //   localStorage.setItem('configCrawlViewSite', true)
          //   this.$refs.sidebar_crawl_settings.hide()
          // }
          // else if(this.numberPages === 2 || this.numberPages === '2') {
          //   localStorage.setItem('configLicense', 'create')
          //   this.$refs.sidebar_crawl_settings.hide()
          // } else if(this.numberPages === 1 || this.numberPages === '1' || this.crawlType === 1 || this.crawlType === 2 || this.crawlType === '1' || this.crawlType === '2') {
          //   localStorage.setItem('configRun', 'create')
          //   this.$refs.sidebar_crawl_settings.hide()
          // } else {
          //   this.$emit('update:configcrawl', true)
          //   this.$refs.sidebar_crawl_settings.hide()
          // }

          localStorage.setItem('configCrawl', JSON.stringify({pages: this.numberPages === 1 || this.numberPages === '1' ? 100 : this.numberPages === 2 || this.numberPages === '2' ? 600 : 25, crawl:  this.crawlType === 1 || this.crawlType === '1' ? 'JS FREE' : this.crawlType === 2 || this.crawlType === '2' ? 'JS LICENSE' : 'HTML', date: new Date()}))
        } else {
          // console.log(this.idsite.permission,this.numberPages === 2 || this.numberPages === '2',this.numberPages === 1 || this.numberPages === '1' || this.crawlType === 1 || this.crawlType === 2 || this.crawlType === '1' || this.crawlType === '2')
          // console.log(this.crawlType, this.numberPages);
          // if(this.idsite.permission !== 'owner' && (this.numberPages === '2' || this.numberPages === 2 || this.crawlType === '2' || this.crawlType === 2)) {
          if((this.numberPages === 0 || this.numberPages === '0') && (this.crawlType === 0 || this.crawlType === '0')) {
            localStorage.setItem('configRunLicenseOwner', true)
            this.$refs.sidebar_crawl_settings.hide()
          }
          else if(this.idsite.permission !== 'owner') {
            // console.log(1)
            // localStorage.setItem('configRunLicense', true)
            // localStorage.setItem('configLicense', 'create')
            localStorage.setItem('configCrawlViewSite', true)
            this.$refs.sidebar_crawl_settings.hide()
          }
          // else if(this.idsite.permission !== 'owner' && (this.numberPages === '1' || this.numberPages === 1 || this.crawlType === '1' || this.crawlType === 1)) {
          //   // console.log(1)
          //   // localStorage.setItem('configRunLicense', true)
          //   // localStorage.setItem('configLicense', 'create')
          //   localStorage.setItem('configCrawlViewSite', true)
          //   this.$refs.sidebar_crawl_settings.hide()
          // }
          else if(this.numberPages === 2 || this.numberPages === '2') {
            // this.$emit('update:configcrawl', true)
            // console.log(2)
            localStorage.setItem('configLicense', 'create')
            this.$refs.sidebar_crawl_settings.hide()
          } else if(this.numberPages === 1 || this.numberPages === '1' || this.crawlType === 1 || this.crawlType === 2 || this.crawlType === '1' || this.crawlType === '2') {
            // this.$emit('update:configcrawl', true)
            // console.log(3)
            localStorage.setItem('configRun', 'create')
            this.$refs.sidebar_crawl_settings.hide()
          } else {
            // console.log(4)
            this.$emit('update:configcrawl', true)
            this.$refs.sidebar_crawl_settings.hide()
          }

          
          // localStorage.setItem('configCrawlViewSite', true)
          localStorage.setItem('configCrawl', JSON.stringify({pages: this.numberPages === 1 || this.numberPages === '1' ? 100 : this.numberPages === 2 || this.numberPages === '2' ? 600 : 25, crawl:  this.crawlType === 1 || this.crawlType === '1' ? 'JS FREE' : this.crawlType === 2 || this.crawlType === '2' ? 'JS LICENSE' : 'HTML', date: new Date()}))
        }
        // this.$refs.sidebar_crawl_settings.hide()
      } else {
        // if((this.idsite.permission !== 'owner' && (!this.license || this.license === 'free' || this.license === 'Free')) && (this.numberPages === '2' || this.numberPages === 2 || this.crawlType === '2' || this.crawlType === 2)) {
        if(((!this.license || this.license === 'free' || this.license === 'Free')) && (this.numberPages === '2' || this.numberPages === 2 || this.crawlType === '2' || this.crawlType === 2)) {
          // localStorage.setItem('configRunLicense', true)
          localStorage.setItem('configRunLicenseUpgrade', true)
          this.$refs.sidebar_crawl_settings.hide()
        } else {
          localStorage.setItem('configCrawl', JSON.stringify({pages: this.numberPages === 1 || this.numberPages === '1' ? 100 : this.numberPages === 2 || this.numberPages === '2' ? 600 : 25, crawl:  this.crawlType === 1 || this.crawlType === '1' ? 'JS FREE' : this.crawlType === 2 || this.crawlType === '2' ? 'JS LICENSE' : 'HTML', date: new Date()}))
          this.$emit('update:configcrawl', true)
          this.$refs.sidebar_crawl_settings.hide()
        }
      }
    }
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
@media (min-width: 769px) {
  .crawl-body-settings-container {
    margin-right: 3rem !important;;
  }
  .row-container-configs {
    /* margin-left: 40px !important; */
    margin-right: auto;
    margin-left: auto;
  }
  .card-config-crawl {
    width: 120px !important;
    min-width: 120px !important;
    max-width: 120px !important;
  }
}
@media (max-width: 768px) {
  .crawl-body-settings-container {
    margin-right: 3rem !important;;
  }
  .row-container-configs {
    /* margin-left: 40px !important; */
    margin-right: auto;
    margin-left: auto;
  }
  .card-config-crawl {
    width: 96px !important;
    min-width: 96px !important;
    max-width: 96px !important;
  }
  .desc-config-crawl {
    font-size: 13px !important;
  }
}
@media (max-width: 458px) {
  .crawl-body-settings-container {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .card-config-crawl {
    width: 92px !important;
    min-width: 92px !important;
    max-width: 92px !important;
  }
  .desc-config-crawl {
    font-size: 12px !important;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-crawl-settings {
    width: 90%;
    max-width: 593px !important;
}
</style>