<!-- eslint-disable no-irregular-whitespace -->
<template>
  <b-card
    no-body
    :class="{'open': visible}"
    class="shadow-none"
    @mouseenter="collapseOpen"
    @mouseleave="collapseClose"
  >
    <b-card-header

      :class="{'collapsed': !visible}"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"

      class="collapse_head--"
      @click="updateVisible(!visible)"
    >
      <slot name="header">
        <div class="d-flex justify-content-between w-100">
          <span class="lead collapse-title w-9/12 md:w-11/12">{{ title }}
            <span
              v-if="type !== 'opportunity' && grade && grade !== '0 ms'"
              class="be-better"
            >
              — {{ grade }}</span>
          </span>
          <template v-if="type === 'opportunity'">
            <span
              v-if="scoreDisplayMode === 'numeric' && numericValue && value !== '0ms'"
              class="collapse-pill w-3/12 md:w-1/12"
              :class="colorChart(score)"
            >{{ value }}</span>
          </template>
          <template v-if="type === 'approved'">
            <span
              v-if="score"
              class="collapse-pill w-3/12 md:w-1/12"
              :class="colorChart(score)"
            >{{ score }}</span>
          </template>
        </div>
      </slot>
    </b-card-header>

    <b-collapse
      v-if="better || opportunities"
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body class="collapse_body--">
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCollapse,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    grade: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    better: {
      type: Boolean,
      required: true,
      default: false,
    },
    opportunities: {
      type: Boolean,
      required: false,
      default: false,
    },
    scoreDisplayMode: {
      type: String,
      required: true,
      default: '',
    },
    numericValue: {
      type: Number,
      required: false,
      default: 0,
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
    score: {
      type: Number,
      required: false,
      default: 0,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    unique: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    colorChart(val) {
      if (val >= 0 && val <= 39) return 'bad'
      if (val >= 40 && val <= 59) return 'average'
      if (val >= 60 && val <= 89) return 'good'
      if (val >= 90) return 'excellent'
      return 'bad'
    },
    updateVisible(val = true) {
      if (this.better || this.opportunities) {
        this.visible = val
      } else {
        this.visible = false
      }
      this.autoScroll()
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
    autoScroll() {
      if (!this.unique) {
        return
      }
      const top = document.getElementById(this.unique).offsetTop
      window.scroll({
        top,
        left: 0,
        behavior: 'smooth',
      })
    },
  },
}
</script>
