/**
 * @license
 * Copyright 2020 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * @fileoverview These functions define {Rect}s and {Size}s using two different coordinate spaces:
 *   1. Screenshot coords (SC suffix): where 0,0 is the top left of the screenshot image
 *   2. Display coords (DC suffix): that match the CSS pixel coordinate space of the LH report's page.
 */

/** @typedef {LH.Audit.Details.Rect} Rect */
/** @typedef {{width: number, height: number}} Size */

/**
 * @param {LH.Result.FullPageScreenshot['screenshot']} screenshot
 * @param {LH.Audit.Details.Rect} rect
 * @return {boolean}
 */
function screenshotOverlapsRect(screenshot, rect) {
    return rect.left <= screenshot.width &&
        0 <= rect.right &&
        rect.top <= screenshot.height &&
        0 <= rect.bottom;
}

/**
 * @param {number} value
 * @param {number} min
 * @param {number} max
 */
function clamp(value, min, max) {
    if (value < min) return min;
    if (value > max) return max;
    return value;
}

/**
 * @param {Rect} rect
 */
function getElementRectCenterPoint(rect) {
    return {
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2,
    };
}

export class ElementScreenshotRenderer {
    /**
     * Given the location of an element and the sizes of the preview and screenshot,
     * compute the absolute positions (in screenshot coordinate scale) of the screenshot content
     * and the highlighted rect around the element.
     * @param {Rect} elementRectSC
     * @param {Size} elementPreviewSizeSC
     * @param {Size} screenshotSize
     */
    static getScreenshotPositions(elementRectSC, elementPreviewSizeSC, screenshotSize) {
        const elementRectCenter = getElementRectCenterPoint(elementRectSC);

        // Try to center clipped region.
        const screenshotLeftVisibleEdge = clamp(
            elementRectCenter.x - elementPreviewSizeSC.width / 2,
            0, screenshotSize.width - elementPreviewSizeSC.width
        );
        const screenshotTopVisisbleEdge = clamp(
            elementRectCenter.y - elementPreviewSizeSC.height / 2,
            0, screenshotSize.height - elementPreviewSizeSC.height
        );

        return {
            screenshot: {
                left: screenshotLeftVisibleEdge,
                top: screenshotTopVisisbleEdge,
            },
            clip: {
                left: elementRectSC.left - screenshotLeftVisibleEdge,
                top: elementRectSC.top - screenshotTopVisisbleEdge,
            },
        };
    }

    /**
     * Render a clipPath SVG element to assist marking the element's rect.
     * The elementRect and previewSize are in screenshot coordinate scale.
     * @param clipId
     * @param {{left: number, top: number}} positionClip
     * @param {Rect} elementRect
     * @param {Size} elementPreviewSize
     * @return {string}
     */
    static renderClipPathInScreenshot(clipId, positionClip, elementRect, elementPreviewSize) {
        const top = positionClip.top / elementPreviewSize.height;
        const bottom = top + elementRect.height / elementPreviewSize.height;
        const left = positionClip.left / elementPreviewSize.width;
        const right = left + elementRect.width / elementPreviewSize.width;

        const polygonsPoints = [
            `0,0             1,0            1,${top}          0,${top}`,
            `0,${bottom}     1,${bottom}    1,1               0,1`,
            `0,${top}        ${left},${top} ${left},${bottom} 0,${bottom}`,
            `${right},${top} 1,${top}       1,${bottom}       ${right},${bottom}`,
        ];

        return `
            <svg width="0" height="0">
                <defs>
                    <clipPath id="${clipId}" clipPathUnits="objectBoundingBox">
                        ${polygonsPoints.map(points => `<polygon points="${points}"></polygon>`).join('')}
                    </clipPath>
                </defs>            
            </svg>            
        `;
    }

    /**
     * Called by report renderer. Defines a css variable used by any element screenshots
     * in the provided report element.
     * Allows for multiple Lighthouse reports to be rendered on the page, each with their
     * own full page screenshot.
     * @param {LH.Result.FullPageScreenshot['screenshot']} screenshot
     * @return {string}
     */
    static installFullPageScreenshot(screenshot) {
        return `--element-screenshot-url: url('${screenshot.data}');`;
    }

    /**
     * Installs the lightbox elements and wires up click listeners to all .lh-element-screenshot elements.
     * @param {InstallOverlayFeatureParams} opts
     * @return {string}
     */
    static installOverlayFeature(opts) {
        const {fullPageScreenshot} = opts;
        const screenshotOverlayClass = 'lh-screenshot-overlay--enabled';

        return `
            <div class="${screenshotOverlayClass}">
                <div class="lh-element-screenshot__overlay"></div>
            </div>
        `;
    }

    /**
     * Given the size of the element in the screenshot and the total available size of our preview container,
     * compute the factor by which we need to zoom out to view the entire element with context.
     * @param {Rect} elementRectSC
     * @param {Size} renderContainerSizeDC
     * @return {number}
     */
    static _computeZoomFactor(elementRectSC, renderContainerSizeDC) {
        const targetClipToViewportRatio = 0.75;
        const zoomRatioXY = {
            x: renderContainerSizeDC.width / elementRectSC.width,
            y: renderContainerSizeDC.height / elementRectSC.height,
        };
        const zoomFactor = targetClipToViewportRatio * Math.min(zoomRatioXY.x, zoomRatioXY.y);
        return Math.min(1, zoomFactor);
    }

    /**
     * Renders an element with surrounding context from the full page screenshot.
     * Used to render both the thumbnail preview in details tables and the full-page screenshot in the lightbox.
     * Returns null if element rect is outside screenshot bounds.
     * @param {LH.Result.FullPageScreenshot['screenshot']} screenshot
     * @param {Rect} elementRectSC Region of screenshot to highlight.
     * @param {Size} maxRenderSizeDC e.g. maxThumbnailSize or maxLightboxSize.
     * @return {string|null}
     */
    static render(screenshot, elementRectSC, maxRenderSizeDC) {
        if (!screenshotOverlapsRect(screenshot, elementRectSC)) {
            return null;
        }

        // generate a small ramdon id
        const clipId = `clip-${Math.random().toString(36).substring(7)}`;

        const zoomFactor = this._computeZoomFactor(elementRectSC, maxRenderSizeDC);

        const elementPreviewSizeSC = {
            width: maxRenderSizeDC.width / zoomFactor,
            height: maxRenderSizeDC.height / zoomFactor,
        };

        elementPreviewSizeSC.width = Math.min(screenshot.width, elementPreviewSizeSC.width);
        elementPreviewSizeSC.height = Math.min(screenshot.height, elementPreviewSizeSC.height);

        const elementPreviewSizeDC = {
            width: elementPreviewSizeSC.width * zoomFactor,
            height: elementPreviewSizeSC.height * zoomFactor,
        };

        const positions = ElementScreenshotRenderer.getScreenshotPositions(
            elementRectSC,
            elementPreviewSizeSC,
            {width: screenshot.width, height: screenshot.height}
        );

        const clipPath = this.renderClipPathInScreenshot(
            clipId,
            positions.clip,
            elementRectSC,
            elementPreviewSizeSC
        );



        return `
            <div class="lh-element-screenshot relative inline-block " data-rect-width="${elementRectSC.width}" data-rect-height="${elementRectSC.height}" data-rect-left="${elementRectSC.left}" data-rect-top="${elementRectSC.top}">
                <div class="lh-element-screenshot__image" 
                style="background-image: url(${screenshot.data}) ;width: ${elementPreviewSizeDC.width}px; height: ${elementPreviewSizeDC.height}px; background-position-y: -${positions.screenshot.top * zoomFactor}px; background-position-x: -${positions.screenshot.left * zoomFactor}px; background-size: ${screenshot.width * zoomFactor}px ${screenshot.height * zoomFactor}px;"></div>
                <div class="lh-element-screenshot__element-marker border border-yellow-400 absolute" 
                style="width: ${elementRectSC.width * zoomFactor}px; height: ${elementRectSC.height * zoomFactor}px; left: ${positions.clip.left * zoomFactor}px; top: ${positions.clip.top * zoomFactor}px;"></div>
                <div class="lh-element-screenshot__mask bg-black absolute top-0 left-0 opacity-50" 
                style="clip-path: url(#${clipId}) ;width: ${elementPreviewSizeDC.width}px; height: ${elementPreviewSizeDC.height}px;">
                    ${clipPath}
                </div>
            </div>
        `;
    }
}
