<script>
import TWHeaderCollapse from "@/views/apps/sites/sites-edit/site-errors/Performance/views/Performance/Components/Collapse/TWCollapseParts/TWHeaderCollapse.vue";
import { CollapseTransition } from "@ivanv/vue-collapse-transition"
import {convertMarkdownCodeSnippets} from "@/views/apps/sites/sites-edit/site-errors/Performance/views/Performance/Components/performanceFunctions";
import FilmsStrip from "@/views/apps/sites/sites-edit/site-errors/Performance/views/Performance/Components/Collapse/TWCollapseParts/FilmsStrip.vue";
import Table from "@/views/apps/sites/sites-edit/site-errors/Performance/views/Performance/Components/Collapse/TWCollapseParts/Table.vue";
import List from "@/views/apps/sites/sites-edit/site-errors/Performance/views/Performance/Components/Collapse/TWCollapseParts/List.vue";
import CriticalRequests from "@/views/apps/sites/sites-edit/site-errors/Performance/views/Performance/Components/Collapse/TWCollapseParts/CriticalRequests.vue";



export default {
  components: {CriticalRequests, List, Table, FilmsStrip, TWHeaderCollapse, CollapseTransition},
  props: {
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: '',
    }
  },
  data() {
    return {
      isOpen: false,
      defaultHeight: 0
    }
  },
  methods: {
    convertMarkdownCodeSnippets,
    fixAcronym(acronym) {

      // remove blank spaces
      acronym = acronym.replace(/\s/g, '')


      // remove :
      acronym = acronym.replace(/:/g, '')

      // original is  FCP:
      // return FCP
      return acronym.replace(/:/g, '')
    },
    onItemClick() {
      //this.isOpen = !this.isOpen

      if(this.value === this.item.auditRef.id) {
        this.$emit('input', '')
        return
      }

      this.$emit('input', this.item.auditRef.id)

      // scroll to the clicked item
      this.$nextTick(() => {
        const top = this.$refs.itemAudit.offsetTop
        window.scroll({
          top: top - 200,
          left: 0,
          behavior: 'smooth',
        })

      })
    },
    getDetails() {
      return this.item.auditRef.result.details || { type: '' }
    }
  },
}

</script>

<template>
  <div class="cursor-pointer"
       ref="itemAudit"
       :id="item.auditRef.id"
       :class="[
           'audit-'+item.auditRef.ratingLabel,
           value === item.auditRef.id ? 'audit-active' : 'border-b',
           getDetails().type
           ]"
  >

    <TWHeaderCollapse
        :title="item.auditRef.result.title"
        :rating-label="item.auditRef.ratingLabel"
        @click="onItemClick"
        :score="item.auditRef.ratingLabel === 'pass' ? '100' : ''"
        :is-open="value === item.auditRef.id"
        :display-value="item.auditRef.result.displayValue"
    />

    <collapse-transition>
      <div class=""
            v-show="value === item.auditRef.id"
      >
        <div class="px-5 py-1">
          <span v-html="convertMarkdownCodeSnippets(item.auditRef.result.description)"></span>

          <span v-for="relevantMetric of item.auditRef.relevantMetrics" :key="relevantMetric.id">
            <span class="border-1 border-black adorn rounded-sm" :title="relevantMetric.result.title">
              {{ fixAcronym(relevantMetric.acronym) || relevantMetric.id }}:
            </span>
          </span>

          <FilmsStrip
              :details="item.auditRef.result.details"
              v-if="getDetails().type === 'filmstrip'" />

          <Table
              :details="item.auditRef.result.details"
              v-if="getDetails().type === 'table' || getDetails().type === 'opportunity'" />

          <List
              v-if="getDetails().type === 'list'"
              :details="item.auditRef.result.details"
          />

          <CriticalRequests :details="item.auditRef.result.details" v-if="getDetails().type === 'criticalrequestchain'" />

          <div v-if="item.auditRef.stackPacks">
            <div v-for="(stackPack, i) in item.auditRef.stackPacks" :key="i" class="d-flex gap-4 align-items-center py-2">
              <img :src="stackPack.iconDataURL" :alt="stackPack.title" class="w-8" style="width: 2rem; margin-right: 10px;">
              <div v-html="convertMarkdownCodeSnippets(stackPack.description)"></div>
            </div>
          </div>

        </div>
      </div>
    </collapse-transition>

  </div>
</template>

<style scoped lang="scss">
.border-1 {
  border-width: 1px;
}

.py-item {
  padding-top: 8px;
  padding-bottom: 8px;
}

.adorn {
  padding: 3px 3px 0 3px;
  margin: 0 3px;
  align-items: center;
  display: inline-flex;
  font-size: 85%;
  box-sizing: border-box;
  line-height: 1.1;
}

.audit-active {
  box-shadow: inset 0 0 0 1px black;
}
</style>
