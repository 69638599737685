import {ElementScreenshotRenderer} from "@/views/apps/sites/sites-edit/site-errors/Performance/@core/utils/psi-render/screenshot-render";


export const _cachedNumberFormatters = new Map();
const KiB = 1024;
const MiB = KiB * KiB;
const NBSP2 = '\xa0';
const ELLIPSIS = '\u2026';
/**
 * @param {number} size
 * @param {number=} granularity Controls how coarse the displayed value is.
 *                              If undefined, the number will be displayed in full.
 * @return {string}
 */
export const formatBytesToKiB = (size, granularity = undefined) => {
    return _formatNumberWithGranularity(size / KiB, granularity) + `${NBSP2}KiB`;
}

/**
 * @param {number} size
 * @param {number=} granularity Controls how coarse the displayed value is.
 *                              If undefined, the number will be displayed in full.
 * @return {string}
 */
export const formatBytes = (size, granularity = 1) => {
    return _formatNumberWithGranularity(size, granularity, {
        style: 'unit',
        unit: 'byte',
        unitDisplay: 'long',
    });
}

/**
 * @param {number} number
 * @param {number|undefined} granularity
 * @param {Intl.NumberFormatOptions=} opts
 * @return {string}
 */
export const _formatNumberWithGranularity = (number, granularity, opts = {}) => {
    if (granularity !== undefined) {
        const log10 = -Math.log10(granularity);
        if (!Number.isInteger(log10)) {
            console.warn(`granularity of ${granularity} is invalid. Using 1 instead`);
            granularity = 1;
        }

        if (granularity < 1) {
            opts = {...opts};
            opts.minimumFractionDigits = opts.maximumFractionDigits = Math.ceil(log10);
        }

        number = Math.round(number / granularity) * granularity;

        // Avoid displaying a negative value that rounds to zero as "0".
        if (Object.is(number, -0)) number = 0;
    } else if (Math.abs(number) < 0.0005) {
        // Also avoids "-0".
        number = 0;
    }

    let formatter;
    // eslint-disable-next-line max-len
    const cacheKey = [
        opts.minimumFractionDigits,
        opts.maximumFractionDigits,
        opts.style,
        opts.unit,
        opts.unitDisplay,
        'en-US',
    ].join('');

    formatter = _cachedNumberFormatters.get(cacheKey);
    if (!formatter) {
        formatter = new Intl.NumberFormat('en-US', opts);
        _cachedNumberFormatters.set(cacheKey, formatter);
    }

    return formatter.format(number).replace(' ', NBSP2);
}


/**
 * @param {{value: number, granularity?: number}} details
 * @return {string}
 */
export const _renderBytes = (details) => {
    // TODO: handle displayUnit once we have something other than 'KiB'
    return formatBytesToKiB(details.value, details.granularity || 0.1)
}


/**
 * @param {URL} parsedUrl
 * @param {{numPathParts?: number, preserveQuery?: boolean, preserveHost?: boolean}=} options
 * @return {string}
 */
export const getURLDisplayName = (parsedUrl, options) => {
    // Closure optional properties aren't optional in tsc, so fallback needs undefined  values.
    options = options || { numPathParts: undefined, preserveQuery: undefined,
        preserveHost: undefined };
    const numPathParts = options.numPathParts !== undefined ? options.numPathParts : 2;
    const preserveQuery = options.preserveQuery !== undefined ? options.preserveQuery : true;
    const preserveHost = options.preserveHost || false;
    let name;
    if (parsedUrl.protocol === 'about:' || parsedUrl.protocol === 'data:') {
        // Handle 'about:*' and 'data:*' URLs specially since they have no path.
        name = parsedUrl.href;
    }
    else {
        name = parsedUrl.pathname;
        const parts = name.split('/').filter((part) => part.length);
        if (numPathParts && parts.length > numPathParts) {
            name = ELLIPSIS + parts.slice(-1 * numPathParts).join('/');
        }
        if (preserveHost) {
            name = `${parsedUrl.host}/${name.replace(/^\//, '')}`;
        }
        if (preserveQuery) {
            name = `${name}${parsedUrl.search}`;
        }
    }
    const MAX_LENGTH = 64;
    if (parsedUrl.protocol !== 'data:') {
        // Even non-data uris can be 10k characters long.
        name = name.slice(0, 200);
        // Always elide hexadecimal hash
        name = name.replace(/([a-f0-9]{7})[a-f0-9]{13}[a-f0-9]*/g, `$1${ELLIPSIS}`);
        // Also elide other hash-like mixed-case strings
        name = name.replace(/([a-zA-Z0-9-_]{9})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9-_]{10,}/g, `$1${ELLIPSIS}`);
        // Also elide long number sequences
        name = name.replace(/(\d{3})\d{6,}/g, `$1${ELLIPSIS}`);
        // Merge any adjacent ellipses
        name = name.replace(/\u2026+/g, ELLIPSIS);
        // Elide query params first
        if (name.length > MAX_LENGTH && name.includes('?')) {
            // Try to leave the first query parameter intact
            name = name.replace(/\?([^=]*)(=)?.*/, `?$1$2${ELLIPSIS}`);
            // Remove it all if it's still too long
            if (name.length > MAX_LENGTH) {
                name = name.replace(/\?.*/, `?${ELLIPSIS}`);
            }
        }
    }
    // Elide too long names next
    if (name.length > MAX_LENGTH) {
        const dotIndex = name.lastIndexOf('.');
        if (dotIndex >= 0) {
            name = name.slice(0, MAX_LENGTH - 1 - (name.length - dotIndex)) +
                // Show file extension
                `${ELLIPSIS}${name.slice(dotIndex)}`;
        }
        else {
            name = name.slice(0, MAX_LENGTH - 1) + ELLIPSIS;
        }
    }
    return name;
};


/**
 * Split a URL into a file, hostname and origin for easy display.
 * @param {string} url
 * @return {{file: string, hostname: string, origin: string}}
 */
export const parseURL = (url) => {
    const parsedUrl = new URL(url);
    return {
        file: getURLDisplayName(parsedUrl),
        hostname: parsedUrl.hostname,
        // Node's URL parsing behavior is different than Chrome and returns 'null'
        // for chrome-extension:// URLs. See https://github.com/nodejs/node/issues/21955.
        origin: parsedUrl.origin,
    };
}

/**
 * @param {string} text
 * @return {string}
 */
export const renderTextURL = (text) => {
    let url = text;

    let displayedPath;
    let displayedHost;
    let title;
    try {
        const parsed = parseURL(url);
        displayedPath = parsed.file === '/' ? parsed.origin : parsed.file;
        displayedHost = parsed.file === '/' || parsed.hostname === '' ? '' : `(${parsed.hostname})`;
        title = url;
    } catch (e) {
        displayedPath = url;
    }

    let resultUrl = ''


    if (displayedHost) {
        resultUrl += displayedHost;
    }

    resultUrl += url

    return '<a href="' + url + '" title="' + title + '" target="_blank" rel="noopener">' + displayedPath + '</a>';
}


/***
 ************** MS AND DURATIONS ********
 * /
 *
 * */

/**
 * Converts a time in milliseconds into a duration string, i.e. `1d 2h 13m 52s`
 * @param {number} timeInMilliseconds
 * @return {string}
 */
export const formatDuration = (timeInMilliseconds) => {
    // There is a proposal for a Intl.DurationFormat.
    // https://github.com/tc39/proposal-intl-duration-format
    // Until then, we do things a bit more manually.

    let timeInSeconds = timeInMilliseconds / 1000;
    if (Math.round(timeInSeconds) === 0) {
        return 'None';
    }

    /** @type {Array<string>} */
    const parts = [];
    /** @type {Record<string, number>} */
    const unitToSecondsPer = {
        day: 60 * 60 * 24,
        hour: 60 * 60,
        minute: 60,
        second: 1,
    };

    Object.keys(unitToSecondsPer).forEach(unit => {
        const secondsPerUnit = unitToSecondsPer[unit];
        const numberOfUnits = Math.floor(timeInSeconds / secondsPerUnit);
        if (numberOfUnits > 0) {
            timeInSeconds -= numberOfUnits * secondsPerUnit;
            const part = _formatNumberWithGranularity(numberOfUnits, 1, {
                style: 'unit',
                unit,
                unitDisplay: 'narrow',
            });
            parts.push(part);
        }
    });

    return parts.join(' ');
}

/**
 * @param {number} ms
 * @param {number=} granularity Controls how coarse the displayed value is.
 *                              If undefined, the number will be displayed in full.
 * @return {string}
 */
export const formatMilliseconds = (ms, granularity = undefined) => {
    return _formatNumberWithGranularity(ms, granularity, {
        style: 'unit',
        unit: 'millisecond',
        unitDisplay: 'short',
    });
}

/**
 * Format number.
 * @param {number} number
 * @param {number=} granularity Controls how coarse the displayed value is.
 *                              If undefined, the number will be displayed as described
 *                              by the Intl defaults: tinyurl.com/7s67w5x7
 * @return {string}
 */
export const formatNumber = (number, granularity) => {
    return _formatNumberWithGranularity(number, granularity);
}

/**
 * @param {{value: number, granularity?: number, displayUnit?: string}} details
 * @return {Element}
 */
export const _renderMilliseconds = (details) => {
    let value;
    if (details.displayUnit === 'duration') {
        value = formatDuration(details.value);
    } else {
        value = formatMilliseconds(details.value, details.granularity || 10);
    }

    return value;
}


/**
 * @param {LH.Audit.Details.NodeValue} item
 * @return {Element}
 */
export const renderNode = (item, _fullPageScreenshot = []) => {
    let htmlElement = '<span class="inline-block">'

    if (item.snippet) {
        //htmlElement += '<div>' + item.snippet + '</div>'
    }





    const rect = item.lhId && _fullPageScreenshot.nodes[item.lhId];
    if (!rect || rect.width === 0 || rect.height === 0) return htmlElement;

    // console.log('Node screenshot rect item', rect)
    const maxThumbnailSize = {width: 147, height: 100};
    const elementScreenshot = ElementScreenshotRenderer.render(
        _fullPageScreenshot.screenshot,
        rect,
        maxThumbnailSize
    );

    // console.log('Node screenshot element', elementScreenshot)

    //if (elementScreenshot) element.prepend(elementScreenshot);
    if (elementScreenshot) htmlElement += elementScreenshot;

    if (item.nodeLabel) {
        htmlElement += '<span class="inline-block">' + item.nodeLabel + '</span>'
    }

    return {
        hasPlain: true,
        htmlElement,
        snippet: item.snippet,
    };
}


/**
 * @param {LH.Audit.Details.SourceLocationValue} item
 * @return {Element|null}
 * @protected
 */
export const renderSourceLocation = (item) => {
    if (!item.url) {
        return null;
    }

    // Lines are shown as one-indexed.
    const generatedLocation = `${item.url}:${item.line + 1}:${item.column}`;
    let sourceMappedOriginalLocation;
    if (item.original) {
        const file = item.original.file || '<unmapped>';
        sourceMappedOriginalLocation = `${file}:${item.original.line + 1}:${item.original.column}`;
    }

    // We render slightly differently based on presence of source map and provenance of URL.
    let element;
    if (item.urlProvider === 'network' && sourceMappedOriginalLocation) {
        element = `<a href="${item.url}" title="maps to generated location ${generatedLocation}" target="_blank">${sourceMappedOriginalLocation}</a>`

    } else if (item.urlProvider === 'network' && !sourceMappedOriginalLocation) {
        element = renderTextURL(item.url);
    } else if (item.urlProvider === 'comment' && sourceMappedOriginalLocation) {
        element = `${sourceMappedOriginalLocation} (from source map)`
    } else if (item.urlProvider === 'comment' && !sourceMappedOriginalLocation) {
        element = `${generatedLocation} (from sourceURL)`
    } else {
        return null;
    }

    return element;
}
