<template>
    <div class="row no-gutters mt-2 ml-0 mr-0" style="z-index: 11;">
        <div class="my-auto ml-0 mr-75">
            <b-link
                class="link_claim"
                style="text-decoration: underline; color: #A7AAB9; font-size: 15px;"
                :href="idsiteHeader.domain"
                target="_blank"
            >
                <b-card-img-lazy
                    :src="idsiteHeader.imgSite"
                    alt="Image"
                    style="width: 100px !important; height: 58px;"
                    class="rounded-0"
                    onerror="this.onerror=null;this.src='https://src.seocloud.ai/site-thumb.png';"
                />
            </b-link>
        </div>
        <div class="pl-0">
            <p class="card-text mb-0 pt-50 name-site-header-sidebar" style="font-weight: 700; color: #181E38; font-size: 15px;">
                {{ idsiteHeader.name }}
            </p>
            <a :href="idsiteHeader.domainFix ? idsiteHeader.domainFix : idsiteHeader.domain" target="_blank">
                <span
                    class="url-header-sidebar"
                    style="text-decoration: underline; color: #A7AAB9; font-size: 15px;"
                >
                    {{ idsiteHeader.domain }}
                </span>
            </a>
            <b-button
                style="background: transparent !important; border: 0px; display: inline !important; margin-top: 3px !important; color: rgb(247, 185, 185) !important;"
                class="pt-0 pr-0 pb-0 ml-0 pl-0 cursor-pointer"
                @click.prevent="getClaimSite()"
                v-if="!isNotAlreadyOwner"
            >
                <feather-icon
                    icon="AlertCircleIcon"
                    class="cursor-pointer pl-0 ml-0"
                    style="color: rgb(247, 185, 185) !important;"
                    size="15"
                /> Site disconnected
            </b-button>
        </div>
    </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BLink,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import { db, auth, firebase, storage, dbErrors } from '@/firebase'

export default {
    data() {
        return {
            isNotAlreadyOwner: true
        }
    },
    props: ['idsiteHeader'],
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BFormTextarea,
        BLink,
        BFormCheckbox,
        BFormCheckboxGroup,
        BFormTimepicker,
        BAvatar,
        VBToggle,
        VBTooltip,
        BCard,
        BCardImgLazy,
        BCardText,
        BCol,
        BRow,
    },
    watch: {
        idsiteHeader(payload) {
            this.isNotAlreadyOwner = true

            const user = auth.currentUser
            if(this.$route.name === 'view-errors' && payload) {
                db.collection('Sites').doc(this.$route.params.id).get()
                .then(site => {
                    if(site.data().owner && site.data().owner.uid) {
                        // check script exitense
                        if(user.uid === site.data().owner.uid) {
                            var getFirstUser = user.uid.slice(0, 4);
                            var getFirstSite = this.$route.params.id.slice(0, 4);
                            var keyID = getFirstUser + getFirstSite

                            var obj = {
                                "url": site.data().homepage,
                                "scriptSrc": `https://cdn.seocloud.ai/${keyID}`
                            }

                            var config = {
                                method: 'POST',
                                url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/check-script',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                data: JSON.stringify(obj),
                            }

                            this.axios(config)
                            .then(response => {
                                // console.log('header: ', response.data)
                                if(!response.data.scriptPresent) {
                                    this.isNotAlreadyOwner = false
                                } else {
                                    this.isNotAlreadyOwner = true
                                }
                            })
                        } else {
                            this.isNotAlreadyOwner = false
                        }
                    } else {
                        // history owner lost
                        if(site.data().owners && site.data().owners.length) {
                            if(site.data().owners.includes(user.uid)) {
                                this.isNotAlreadyOwner = false
                            }
                        }
                    }
                })
            }
        }
    },
    methods: {
        getClaimSite() {
            localStorage.setItem('configClaimSite', true)
        }
    }
}
</script>

<style>
@media (min-width: 640px) {
    .name-site-header-sidebar {
        max-width: 400px !important;
        display: block;
        max-height: 49px !important;
        width: 100%;
        overflow: hidden;
    }
    .url-header-sidebar {
        max-width: 400px !important;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
}
@media (min-width: 1100px) {
    .name-site-header-sidebar-copilot {
        max-width: 850px !important;
        display: block;
        max-height: 49px !important;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .url-header-sidebar-copilot {
        max-width: 850px !important;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
}
@media (min-width: 930px) and (max-width: 1099px) {
    .name-site-header-sidebar-copilot {
        max-width: 80% !important;
        display: block;
        max-height: 49px !important;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .url-header-sidebar-copilot {
        max-width: 80% !important;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
}
@media (min-width: 740px) and (max-width: 929px) {
    .name-site-header-sidebar-copilot {
        max-width: 80% !important;
        display: block;
        max-height: 49px !important;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .url-header-sidebar-copilot {
        max-width: 80% !important;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
}
@media (min-width: 660px) and (max-width: 739px) {
    .name-site-header-sidebar-copilot {
        max-width: 80% !important;
        display: block;
        max-height: 49px !important;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .url-header-sidebar-copilot {
        max-width: 80% !important;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
}
@media (min-width: 496px)  and (max-width: 659px) {
    .name-site-header-sidebar-copilot {
        max-width: 80% !important;
        display: block;
        max-height: 49px !important;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .url-header-sidebar-copilot {
        max-width: 80% !important;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
}
@media (min-width: 396px)  and (max-width: 495px) {
    .name-site-header-sidebar-copilot {
        max-width: 80% !important;
        display: block;
        max-height: 49px !important;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .url-header-sidebar-copilot {
        max-width: 80% !important;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
}
@media (min-width: 286px)  and (max-width: 395px) {
    .name-site-header-sidebar-copilot {
        max-width: 60% !important;
        display: block;
        max-height: 49px !important;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .url-header-sidebar-copilot {
        max-width: 60% !important;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
}
@media (min-width: 196px)  and (max-width: 285px) {
    .name-site-header-sidebar-copilot {
        max-width: 60% !important;
        display: block;
        max-height: 49px !important;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .url-header-sidebar-copilot {
        max-width: 60% !important;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
}
@media  (max-width: 195px) {
    .name-site-header-sidebar-copilot {
        max-width: 60% !important;
        display: block;
        max-height: 49px !important;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .url-header-sidebar-copilot {
        max-width: 60% !important;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
}
@media (min-width: 476px)  and (max-width: 639px) {
    .name-site-header-sidebar {
        max-width: 250px !important;
        display: block;
        max-height: 49px !important;
        width: 100%;
        overflow: hidden;
    }
    .url-header-sidebar {
        max-width: 250px !important;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
}
@media (min-width: 401px) and (max-width: 475px) {
    .name-site-header-sidebar {
        max-width: 180px !important;
        display: block;
        max-height: 49px !important;
        overflow: hidden;
        width: 100%;
    }
    .url-header-sidebar {
        max-width: 180px !important;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
}
@media  (min-width: 358px) and (max-width: 400px) {
    .name-site-header-sidebar {
        max-width: 150px !important;
        display: block;
        max-height: 49px !important;
        overflow: hidden;
        width: 100%;
    }
    .url-header-sidebar {
        max-width: 150px !important;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
}

@media  (max-width: 357px) {
    .name-site-header-sidebar {
        max-width: 80px !important;
        display: block;
        max-height: 49px !important;
        overflow: hidden;
        width: 100%;
    }
    .url-header-sidebar {
        max-width: 80px !important;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
}
</style>