<template>
    <div style="padding-bottom: 80px;" class="index-history-section">
      <b-overlay
        :show="loadingSites"
        rounded="xl"
        no-fade
      >
        <hr class="mb-25">

        <!--  -->
        <div class="top-scroll-horizontal-history" style="overflow-x: scroll;"><div class="div1" id="tab-err-sc2-history" style="height: 20px;"></div></div>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          id="tab-err-sc1"
          style-class="vgt-table condensed bordered vgt-table bordered striped table-wproject-sites-history"
          :group-options="{
              enabled: true,
              headerPosition: 'bottom',
              collapsable: false,
          }"
          :sort-options="{
            enabled: true,
          }"
          class="table-show-project-index cursor-initial"
        >
            <div slot="emptystate" style="background: #fff !important; border: 0px !important;">
              no results
            </div>
            <template
              slot="table-header-row"
              slot-scope="props"
              class="p-0"
            >
              <div
                :style="`background: ${props.row.color};`"
                class="mr-50"
              />
              <span v-if="rows.length >= 1 && rows[0].children.length === 0">
              No sites
              </span>
            </template>
            <template
              slot="table-row"
              slot-scope="props"
            >
                <span v-if="props.column.field == 'name'">
                    <div :style="props.row.crawl && props.row.crawl.estatus === 'running' ? 'top: -12px; position: relative;' : ''">
                    <span class="" style="font-size: 15px; color: #181E38;">{{ props.row.name }}</span>
                    <div style="width: 200px; position: absolute;" v-if="props.row.crawl && props.row.crawl.estatus === 'running'">
                        <b-progress
                            :value="props.row.crawl.requests ? props.row.crawl.requests : 0"
                            :max="props.row.crawl.availableURL ? props.row.crawl.availableURL : 1"
                            class="progress-bar-info mt-25"
                            variant="info"
                            style="height: 5px;"
                        />
                        <span class="" style="color: #A7AAB9; font-size: 14px;">Crawling {{props.row.crawl.requests ? props.row.crawl.requests : 0}} of {{props.row.crawl.availableURL ? props.row.crawl.availableURL : 1}} pages</span>
                    </div>
                    </div>
                </span>
                <span v-else-if="props.column.field == 'date'">
                  <p class="m-0">{{ props.row.date }}</p>
                  <p class="m-0">{{ props.row.datehour }}</p>
                </span>
                <span v-else-if="props.column.field == 'index'">
                  <span
                    class=""
                    :style="crawlNow && siteCrawlNow === props.row.id ? 'font-size: 15px; position: relative; top: 2px;' : 'font-size: 15px; position: relative; top: 2px;'"
                  >
                  <feather-icon icon="CheckIcon" size="22" v-if="props.row.s_index === 'index' || props.row.s_index === 'noset'" style="color: #28c76f; top: -3px; position: relative; margin-right: 3px;" />
                  <feather-icon icon="XIcon" size="22" v-else style="color: #ea5455; top: -3px; position: relative; margin-right: 3px;" />
                  {{props.row.s_index === 'index' ? 'index' : props.row.s_index === 'noset' ? 'not set' : 'noindex'}}</span>
                </span>
                <span v-else-if="props.column.field == 'robot'">
                  <span
                      :class="props.row.s_robot === 'Disallow' || props.row.s_robot === 'DisallowH' ? 'ml-50 cursor-pointer' : 'ml-50 cursor-initial'"
                      :style="crawlNow && siteCrawlNow === props.row.id ? (props.row.s_robot === 'Allow' ? 'font-size: 15px; text-align: center; position: relative; top: 1px;' : props.row.s_robot === 'blocked' || props.row.s_robot === 'notfound' ? 'font-size: 15px; text-align: center; position: relative; top: 1px; color: rgb(167, 170, 185);' : props.row.s_robot === 'DisallowH' ? 'font-size: 15px; text-align: center; color: #ea5455 !important; position: relative; top: 1px;' : 'font-size: 15px; text-align: center; color: #ff9f43 !important; position: relative; top: 1px;') : (props.row.s_robot === 'Allow' ? 'font-size: 15px; text-align: center; position: relative; top: 1px;' : props.row.s_robot === 'blocked' || props.row.s_robot === 'notfound' ? 'font-size: 15px; text-align: center; position: relative; top: 1px; color: rgb(167, 170, 185);' : props.row.s_robot === 'DisallowH' ? 'font-size: 15px; text-align: center; position: relative; top: 1px; color: #ea5455 !important;' : 'font-size: 15px; text-align: center; position: relative; top: 1px; color: #ff9f43 !important;')"
                      @click.prevent.stop="props.row.s_robot === 'Disallow' || props.row.s_robot === 'DisallowH' ? logIdRobots(props.row) : null"
                    >
                    <feather-icon icon="CheckIcon" size="22" v-if="props.row.s_robot === 'Allow'" style="color: #28c76f; top: -3.5px; position: relative; margin-right: 5px;" />
                    <feather-icon icon="CheckIcon" size="22" v-else-if="props.row.s_robot === 'blocked' || props.row.s_robot === 'notfound'" style="color: #28c76f; top: -3.5px; position: relative; margin-right: 5px;" />
                    <feather-icon :icon="props.row.s_robot === 'DisallowH' ? 'XIcon' : 'AlertTriangleIcon'" size="22" v-else :style="props.row.s_robot === 'DisallowH' ? 'color: #ea5455 !important; top: -3.5px; position: relative; margin-right: 5px;' : 'color: #ff9f43 !important; top: -3.5px; position: relative; margin-right: 5px;'" />
                    <!-- <feather-icon icon="LockIcon" size="22" v-if="props.row.s_robot === 'blocked'" style="color: rgb(167, 170, 185); top: -3.5px; position: relative; margin-right: 5px;" /> -->
                    {{props.row.s_robot === 'DisallowH' ? 'Disallow' : props.row.s_robot === 'notfound' ? 'not found' : props.row.s_robot}}</span>
                </span>
                <span v-else-if="props.column.field == 'httpHeader'">
                  <span
                    class=""
                    :style="crawlNow && siteCrawlNow === props.row.id ? 'font-size: 15px; position: relative; top: 2px;' : 'font-size: 15px; position: relative; top: 2px;'"
                  >
                  <feather-icon icon="CheckIcon" size="22" v-if="props.row.s_httphead === 'index' || props.row.s_httphead === 'noset'" style="color: #28c76f; top: -3px; position: relative; margin-right: 3px;" />
                  <feather-icon icon="XIcon" size="22" v-else style="color: #ea5455; top: -3px; position: relative; margin-right: 3px;" />
                  {{props.row.s_httphead === 'index' ? 'index' : props.row.s_httphead === 'noset' ? 'not set' : 'noindex'}}</span>
                </span>
            </template>
        </vue-good-table>
        <div class="text-center text-primary my-2" v-if="loadingInfiniteScroll">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-1">Loading History...</strong>
        </div>
      </b-overlay>
    </div>
  </template>
  
  <script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import {
    BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdownItem, BDropdown, BImgLazy, BButton,
    BRow, BCol, BProgress, BSpinner, BOverlay, BCardText, BCard, BLink,
  } from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { VueGoodTable } from 'vue-good-table'
  import Ripple from 'vue-ripple-directive'
  import { avatarText } from '@core/utils/filter'
  import { VueAutosuggest } from 'vue-autosuggest'
  import vSelect from 'vue-select'
//   import SidebarRobots from './SidebarRobots.vue'
  import CreateAlert from '@/views/extensions/acl/table/sidebar/CreateAlert.vue'
//   import YouLostAccessSidebar from '@/views/extensions/acl/table/sidebar/YouLostAccessSidebar.vue'
//   import SidebarOwnership from '@/views/extensions/acl/table/sidebar/SidebarOwnership.vue'
//   import ChangeProject from '@/views/extensions/acl/table/sidebar/ChangeProject.vue'
//   import ArchiveSite from '@/views/extensions/acl/table/sidebar/ArchiveSite.vue'
//   import ActivateSite from '@/views/extensions/acl/table/sidebar/ActivateSite.vue'
//   import MembersProject from '@/views/extensions/acl/table/sidebar/MembersProject.vue'
  import InfoLicense from '@/views/extensions/acl/table/sidebar/InfoLicense.vue'
  import 'vue-good-table/dist/vue-good-table.css'
  import moment from 'moment'
  import store from '@/store/index'
  import Urlbox from 'urlbox'
  import { db, auth, storage, firebase } from '@/firebase'
import { props } from 'vue-prism-component'
  
  export default {
    components: {
      BCardCode,
      VueGoodTable,
      BAvatar,
      BBadge,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BDropdownItem,
      BDropdown,
      BImgLazy,
      BButton,
      BRow,
      BCol,
      BProgress,
      BSpinner,
      BOverlay,
      BCardText,
      BCard,
      BLink,
      vSelect,
    //   SidebarRobots,
      CreateAlert,
      InfoLicense,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: '',
        selectedPlan: 'Basic',
        proIcon: require('@/assets/images/icons/i-pro-badge.svg'),
        refreshIcon: require('@/assets/images/icons/i-refesh.svg'),
        logoIconAIColor: require('@/assets/images/logo/SEOcloud-isotype.png'),
        optionsPlan: [
          'Free',
          'Basic',
          'Pro',
          'Custom license',
        ],
        loaderSearch: true,
        existArchivedSites: false,
        showOpenCreateAlert: false,
  
        // table
        columns: [
          {
            label: 'Date',
            field: 'date',
            sortable: false,
            thClass: 'thTableSitesNameShowHHistory',
            tdClass: 'tdTableSitesNameShowHHistory',
          },
          {
            label: 'noindex tag',
            field: 'index',
            sortable: false,
            thClass: 'thTableSitesDomainHHistory',
            tdClass: 'tdTableSitesDomainHHistory',
          },
          {
            label: 'robots.txt',
            field: 'robot',
            sortable: false,
            thClass: 'thTableSitesDomainHHistory',
            tdClass: 'tdTableSitesDomainHHistory',
          },
          {
            label: 'HTTP Header',
            field: 'httpHeader',
            sortable: false,
            thClass: 'thTableSitesDomainHHistory',
            tdClass: 'tdTableSitesDomainHHistory',
          },
        ],
        rows: [],
        dir: false,
        searchTerm: '',
        pageLength: 5,
        objPr: '',
        idsite: '',
        projectsData: '',
        invitationsProjectData: '',
  
        showOpen: false,
        showOpenChangeProject: false,
        showOpenArchiveSite: false,
        showOpenActiveSite: false,
        showOpenMembersProject: false,
        showOpenSidebarRobots: false,
        showOpenYouLostAccessSidebar: false,
        showOpenInfoLicense: false,
        loadErrors: true,
        initDate: 0,
        initDate2: 0,
        events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
  
        // infinite scroll
        latestDocClient: null,
        loadingInfiniteScroll: false,
        loadingSites: false,
        totalSites: 0,
  
        // autosuggest
        datasuggest: {
          data: [{
            sites: [],
          }],
        },
        searchSite: '',
        suggestionsSites: [],
        selected: null,
        limit: 60,
        siteObj: [],
        pages200: [],
  
        // new vars migration firebase
        SiteID: '',
        showOpenComingSoon: false,
        isDevSite: false,

        crawls: [],
        crawlNow: '',
        lastCrawlDate: '',
        timeScroll: null,
      }
    },
    setup() {
      return {
          avatarText,
      }
    },
    props: ['siteid'],
    computed: {
      statusVariant() {
        const statusColor = {
          /* eslint-disable key-spacing */
          Current      : 'light-primary',
          Professional : 'light-success',
          Rejected     : 'light-danger',
          Resigned     : 'light-warning',
          Applied      : 'light-info',
          /* eslint-enable key-spacing */
        }
  
        return status => statusColor[status]
      },
      direction() {
        if (store.state.appConfig.isRTL) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.dir = true
          return this.dir
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = false
        return this.dir
      },
    },
    watch: {
      searchSite(val) {
        setTimeout(() => {
          const collection = document.getElementsByClassName('vgt-text-disabled')
          if (collection[0] && val) {
            collection[0].innerHTML = 'No results found'
          }
        }, 1000)
      },
      selected(val) {
        // console.log(val)
        // this.getSite(val)
      },
      siteid(val) {
        // console.log(val)
        if(val) {
          var urlWindow = window.location.href
          var urlWindowTransform = new URL(urlWindow)
          var urlWindowDomain = urlWindowTransform.hostname
          const user = auth.currentUser
          if(user) {
            this.loadErrors = false
      
            db.collection('Users').doc(user.uid).get()
            // .onSnapshot((userDoc) => {
            .then(userDoc => {
              this.userData = {
                uid: user.uid,
                email: userDoc.data().email
              }
              var xUserCustomer = userDoc.data().customerId ? userDoc.data().customerId : ''
    
              this.rows = []

              this.rows.push({
                mode: 'span',
                html: false,
                label: 'My sites',
                color: '#ddd',
                id: '',
                count: 6,
                children: [],
                permission: 'owner',
              })

              this.rows[0].children = []
              this.existArchivedSites = false

              db.collection('Sites_cache').doc(val)
              //   .get()
              .onSnapshot(docSiteCache => {
                if(docSiteCache.exists) {
                  db.collection('Sites').doc(val).get()
                  .then(siteDoc => {
                    db.collection('Users').doc(user.uid).collection('subs').doc('index').get()
                    .then(userPlan => {
                        if(userPlan.exists) {
                            // console.log('exists sub doc')
                            this.idsite = {
                              imgSite: docSiteCache.data().thumbnail ? docSiteCache.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png',
                              name: docSiteCache.data().name ? docSiteCache.data().name : docSiteCache.data().homepage,
                              domainFix: docSiteCache.data().homepage,
                              // domain: docSiteCache.data().homepage ? docSiteCache.data().homepage : docSiteCache.data().WebsiteURL,
                              id: docSiteCache.id,
                              projectid: 'index',
                              domain: docSiteCache.data().homepage,
                              paidRecurrency: userPlan.data().paidRecurrency ? (userPlan.data().paidRecurrency === 'month' || userPlan.data().paidRecurrency === 'Month' ? 'Monthly' : 'Anually') : '',
                              plan: userPlan.data().plan ? userPlan.data().plan : 'Free',
                              permission: 'owner',
                              amount: '',
                              status: 'active',
                              own: 'me',
                              planSite: userPlan.data().plan ? userPlan.data().plan : '',
                              freeTrial: userPlan.data().freeTrial ? userPlan.data().freeTrial : false,
                              customerId: xUserCustomer ? xUserCustomer : '',
                              customerIdSite: xUserCustomer ? xUserCustomer : '',
                              subscriptionId: userPlan.data().subscriptionId ? userPlan.data().subscriptionId : '',
                              planType: userPlan.data().planType ? parseInt(userPlan.data().planType, 10) : '',
                              pages: userPlan.data().planType ? userPlan.data().planType : ''
                            }
                        } else {
                            this.idsite = {
                              imgSite: docSiteCache.data().thumbnail ? docSiteCache.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png',
                              name: docSiteCache.data().name ? docSiteCache.data().name : docSiteCache.data().homepage,
                              domainFix: docSiteCache.data().homepage,
                              // domain: docSiteCache.data().homepage ? docSiteCache.data().homepage : docSiteCache.data().WebsiteURL,
                              id: docSiteCache.id,
                              projectid: 'index',
                              domain: docSiteCache.data().homepage,
                              paidRecurrency: 'Monthly',
                              plan: 'Free',
                              permission: 'owner',
                              amount: 'free',
                              status: 'active',
                              own: 'me',
                              planSite: '',
                              freeTrial: false,
                              customerId: xUserCustomer ? xUserCustomer : '',
                              customerIdSite: xUserCustomer ? xUserCustomer : '',
                              subscriptionId: '',
                              planType: '',
                              pages: ''
                            }

                            db.collection('Users').doc(user.uid).collection('subs').doc('index').set({
                              upd: Date.now()
                            })
                            .then(() => {
                              console.log('add sub doc')
                            })
                        }
                    })

                    this.siteObj = {
                      imgSite: docSiteCache.data().thumbnail ? docSiteCache.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png',
                      name: docSiteCache.data().name ? docSiteCache.data().name : docSiteCache.data().homepage,
                      domainFix: docSiteCache.data().homepage,
                      domain: docSiteCache.data().homepage ? docSiteCache.data().homepage : docSiteCache.data().WebsiteURL,
                      id: docSiteCache.id,
                    }

                    var h_index = docSiteCache.data().h_index && docSiteCache.data().h_index.length ? docSiteCache.data().h_index : 'Empty'
                    var array_h_index = []
                    // console.log(h_index)
                    if(h_index === 'Empty') {
                      // this.siteHomepage = docSiteCache.data().homepage
                      // this.arrayRequest = h_index
                      this.crawlSite(val)
                    } else {
                      // this.siteHomepage = docSiteCache.data().homepage
                      
                      h_index.forEach(ele => {
                        array_h_index.push({
                          // date: moment(ele.d).format('DD/MM HH:mm'),
                          dateUnformat: ele.d,
                          date: moment(ele.d).format('Do MMM, YYYY'),
                          datehour: moment(ele.d).format('h:mm a'),
                          text: (ele.si === 'index' ? 'indexed' : 'no indexed') + ', ' + ele.sr + ' in robots',
                          // index: ele.si === 'index' ? 'indexed' : 'no indexed',
                          robot: ele.sr,
                          header: ele.shh,
                          meta: ele.si
                        })
                      })
                      // this.arrayRequest = array_h_index
                      array_h_index.sort(function(a,b) {
                        return new Date(b.dateUnformat) - new Date(a.dateUnformat);
                      })
                    }
                    // console.log(h_index, array_h_index.length)

                    if(array_h_index.length) {
                      array_h_index.forEach(ele => {
                        if(this.rows[0].children.filter(item => item.dateUnformat === ele.dateUnformat).length === 0) {
                          this.rows[0].children.push({
                            imgSite: docSiteCache.data().thumbnail ? docSiteCache.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png',
                            name: docSiteCache.data().name ? docSiteCache.data().name : docSiteCache.data().homepage,
                            domainFix: docSiteCache.data().homepage,
                            domain: docSiteCache.data().homepage ? docSiteCache.data().homepage : docSiteCache.data().WebsiteURL,
                            // s_robot: siteDoc.data().s_robot ? siteDoc.data().s_robot : 'Allow',
                            // s_index: siteDoc.data().s_index ? siteDoc.data().s_index : 'index',
                            id: docSiteCache.id,
                            h_index: docSiteCache.data().h_index ? docSiteCache.data().h_index : [],
                            sug_robots: docSiteCache.data().sug_robots ? docSiteCache.data().sug_robots : '',
                            date: ele.date,
                            datehour: ele.datehour,
                            log: ele.text,
                            index: ele.index,
                            robot: ele.robot,
                            s_robot: ele.robot ? ele.robot : 'Allow',
                            s_index: ele.meta ? ele.meta : 'index',
                            s_httphead: ele.header ? ele.header : 'noset',
                            is_s_robot: ele.robot ? (ele.robot === 'Allow' ? false : true) : false,
                            is_s_index: ele.meta ? (ele.meta === 'index' || ele.meta === 'noset' ? false : true) : false,
                            dateUnformat: ele.dateUnformat,
                            // id: siteDoc.id,
                            // h_index: docSiteCache.data().h_index ? docSiteCache.data().h_index : [],
                            // sug_robots: docSiteCache.data().sug_robots ? docSiteCache.data().sug_robots : ''
                          })

                          this.rows[0].children.sort(function(a,b) {
                            return new Date(b.dateUnformat) - new Date(a.dateUnformat);
                          })
                        }
                      })
                    }

                    // this.$nextTick(() => {
                    //   $(".top-scroll-horizontal-history").scroll(() => {
                    //     $(".vgt-responsive").scrollLeft($(".top-scroll-horizontal-history").scrollLeft());
                    //   });
                
                    //   $(".vgt-responsive").scroll(() => {
                    //     $(".top-scroll-horizontal-history").scrollLeft($(".vgt-responsive").scrollLeft());
                    //   });
                    // });
                  })
                }
              })
            })
          }
        }
      }
    },
    created() {
      this.crawls = []
      this.crawlNow = ''
      this.lastCrawlDate = ''
      // horizontal scroll
      // this.$nextTick(() => {
      //   $(".top-scroll-horizontal-history").scroll(() => {
      //     $(".vgt-responsive").scrollLeft($(".top-scroll-horizontal-history").scrollLeft());
      //   });
  
      //   $(".vgt-responsive").scroll(() => {
      //     $(".top-scroll-horizontal-history").scrollLeft($(".vgt-responsive").scrollLeft());
      //   });
      // });
      this.existArchivedSites = false
      this.SiteID = ''
      this.loadErrors = true
      this.initDate = 0
      this.initDate2 = 0
      this.rows = []
      this.showOpenInfoLicense = false
      this.loadingSites = true
  
      setTimeout(() => {
        this.loadingSites = false
      }, 1500)
  
      this.showOpenComingSoon = false
    },
    methods: {
        clearSearch() {
            this.searchTerm = ''
        },
        logIdRobots(val) {
          // console.log(val);
          localStorage.setItem('openRobotsTab', true)
          // this.idsite = val
          // this.showOpenSidebarRobots = true
        },
        getWithScroll() {        
          setTimeout(() => {
            const primerElemento = document.querySelector('.table-wproject-sites-history');
            const segundoElemento = document.getElementById('tab-err-sc2-history');

            if(primerElemento) {
              const ancho = primerElemento.offsetWidth; // Obtiene el ancho del primer elemento
              segundoElemento.style.width = ancho + 'px';
            }
          }, 500)

          this.$nextTick(() => {
            $(".top-scroll-horizontal-history").scroll(() => {
              $(".vgt-responsive").scrollLeft($(".top-scroll-horizontal-history").scrollLeft());
            });

            // $(".vgt-table").scroll(() => {
            //   $(".top-scroll-horizontal-history").scrollLeft($(".vgt-table").scrollLeft());
            // });
          });

          // horizontal scroll
          // this.$nextTick(() => {
          //   $(".vgt-responsive").scroll(() => {
          //     // console.log(1)
          //     $(".top-scroll-horizontal-history").scrollLeft($(".vgt-responsive").scrollLeft());
          //   });

          //   // $(".tabs-errs > .vgt-table").scroll(() => {
          //   //   console.log(2)
          //   //   $(".top-scroll-horizontal-history").scrollLeft($(".vgt-table").scrollLeft());
          //   // });
          // });

          // setTimeout(() => {
            const miElemento = document.querySelector('.index-history-section .vgt-responsive');
            // console.log(miElemento)
            if(miElemento) {
              miElemento.addEventListener('scroll', () => {
                // console.log(2);
                document.querySelector('.top-scroll-horizontal-history').scrollLeft = document.querySelector('.index-history-section .vgt-responsive').scrollLeft;
              });
            }
          // }, 1200)
        },
        crawlSite(idSite) {
          var user = auth.currentUser
          if(user && user.uid) {
            // if(this.$route.name === 'shared-site') {
            //   // this.showOpenAddSiteProject = true
            // } else {
            if(this.crawls.filter(ele => ele === idSite).length === 0) {
              this.crawls.push(idSite)
              this.crawlNow = ''
              this.siteCrawlNow = ''
              this.lastCrawlDate = ''
              db.collection('Sites').doc(idSite).get()
              .then(eleSite => {
                  // exist site
                  var countSites = 0
                  var pageSizes = eleSite.data().pageSizes && eleSite.data().pageSizes.length ? eleSite.data().pageSizes : []
                  var pagesSite = eleSite.data().pages && eleSite.data().pages.length ? eleSite.data().pages : []
                  var filterInternals = pagesSite.filter(ele => ele.isSitemap === true)
                  var filterNoInternals = pagesSite.filter(ele => ele.isSitemap === false)
                  var filterProPages = filterNoInternals.filter(ele => ele.pro === true)
                  var filterNoProPages = filterNoInternals.filter(ele => ele.pro === false)
                  var filterInlinks = filterNoProPages
      
                  var condicionParaOrdenar = (pageA, pageB) => {
                    return pageB.inlinks.length - pageA.inlinks.length;
                  }
                  filterInlinks.sort(condicionParaOrdenar)
      
                  var arrayFoundPages = []
                  var arrayProPages = []
                  filterProPages.forEach(ele => {
                    var getUrl = new URL(ele.url)
                    if(getUrl.pathname !== '/') {
                      arrayProPages.push(ele.url)
                    }
    
                    arrayFoundPages.unshift(ele.url)
                  })
      
                  var body = {
                    url: '',
                    type: 'full',
                    forceNotFound: 'yes',
                    pageSize: 999999999,
                    id: 'index-check-single-' + Date.now(),
                    takeScreenshots: 'no',
                    source: 'seo-cloud-full-' + Date.now(),
                    triggeredBy: 'test triggered',
                    screenshotType: 'test',
                    screenshotComment: '',
                    stringSearch: ["PHP ERROR",'Lorem','Ipsum'],
                    ignoreIndexNoFollow: "yes"
                  }
                  var getUrl = new URL(eleSite.data().homepage);
                  var hostUrl = getUrl.host;
                  var httpsString = getUrl.protocol + '//';
                  var urlConcat = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                  var urlBody = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                  var ultimoCaracterUrl = urlConcat.charAt(urlConcat.length - 1);
                  if(ultimoCaracterUrl != "/") {
                    urlConcat += "/";
                  }
      
                  body.id = 'index-check-single-' + Date.now()
                  body.source = 'index-check-single-' + Date.now()
                  body.url = getUrl.protocol + "//" + hostUrl
      
                  if(getUrl.pathname === "/") {
                      body.takeScreenshots = 'no'
                      body.screenshotComment = ""
                  }
                  
                  db.collection('Crawls').where('WebsiteURL', '==', hostUrl).where('status', '==', 'running-index').get()
                  .then(queryCrawl => {
                      // only 1 loop
                      if(countSites === 0 && !queryCrawl.size) {
                          countSites += 1
                          var stringsArray = eleSite.data().stringSearch ? eleSite.data().stringSearch : []
                          var suspiciousTextArray = eleSite.data().suspiciousText ? eleSite.data().suspiciousText : []
                          var arrayStringsSuspicious = []
                          stringsArray.forEach(ele => {
                              arrayStringsSuspicious.push(ele)
                          })
      
                          suspiciousTextArray.forEach(ele => {
                              arrayStringsSuspicious.push(ele)
                          })
      
                          var getConfigCrawl = localStorage.getItem('configCrawl')
                          var limitCrawl = 100
                          var configCrawlType = 'JS FREE'
                          var configCrawlPages = 'FREE'
                          var setCrawl = false
                          if(getConfigCrawl) {
                            setCrawl = true
                            var parseConfig = JSON.parse(getConfigCrawl)
                            var logicTime = moment(parseConfig.date)
                            var actualTime = moment(new Date())
                            var diffSeconds = actualTime.diff(logicTime, 'seconds');
                            if(diffSeconds < 600) {
                              limitCrawl = parseConfig.pages
                              configCrawlType = parseConfig.crawl
                              configCrawlPages = parseConfig.pages === 25 ? 'GUESS/FREE' : parseConfig.pages === 100 ? 'FREE' : parseConfig.pages === 600 ? 'LICENSE' : 'FREE'
                            }
                            var getConfigCrawl = localStorage.removeItem('configCrawl')
                          }
                          
                          var limitCrawlSetSite = eleSite.data().limitCrawl ? eleSite.data().limitCrawl : 0
                          if(limitCrawlSetSite) {
                            if(configCrawlPages === 'GUESS/FREE' && limitCrawlSetSite < 25) {
                              limitCrawl = limitCrawlSetSite
                            } else if(configCrawlPages === 'FREE' && limitCrawlSetSite < 100) {
                              limitCrawl = limitCrawlSetSite
                            } else if(configCrawlPages === 'LICENSE') {
                              limitCrawl = limitCrawlSetSite
                            }
                          }
      
                          // add Crawl
                          db.collection('Crawls').add({
                            'IDcrawl': body.id,
                            'dateStart': Date.now(),
                            'dateStartFormat': new Date(),
                            'dateEnd': null,
                            'crawlDuration': null,
                            'status': 'running',
                            'crawltype': 'only-robots',
                            'ignoreindexnofollow': 'yes',
                            'Requests': 1,
                            'SiteID': eleSite.id,
                            'WebsiteURL': hostUrl,
                            'blockedByRobots': false,
                            'logicRequests': 1,
                            'proPages': eleSite.data().proPages.length ? eleSite.data().proPages : [urlBody],
                            'totalErrors': 0,
                            'limitCrawl': limitCrawl,
                            'stringSearch': arrayStringsSuspicious,
                            'founds': arrayFoundPages,
                            'requests': [],
                            'pages': [],
                            'inlinks': [],
                            'external': [],
                            'pageSizes': pageSizes,
                            'updated': Date.now(),
                            'plus1k': false,
                            'errorsPages': [],
                            'source': 'index-check-site',
                            'configCrawlType': 'index',
                            'configCrawlPages': 'FREE',
                            'errorsTypePage': [],
                            'timeoutPages': 0,
                            'errorsCategory': {}
                          })
                          .then((docRefCrawl) => {
                            this.lastCrawlDate = Date.now()
                            this.crawlNow = moment(Date.now()).fromNow()
                            this.siteCrawlNow = eleSite.id
                              var URLsfound = [
                                  {
                                      'id': null,
                                      'title': null,
                                      'runPosition': 1,
                                      'lastRequest': null,
                                      'pro': true,
                                      'inlinks': [],
                                      'status': null,
                                      'redirectTo': null,
                                      'blockedByRobots': false,
                                      // 'totalErrors': 0,
      
                                      'crawleable': true,
                                      'crawled': false,
                                      'crawlerInvoke': true,
                                      'url': urlBody
                                      
                                  },
                                  // {
                                  //   'id': null,
                                  //   'title': null,
                                  //   'runPosition': 2,
                                  //   'lastRequest': null,
                                  //   'pro': false,
                                  //   'inlinks': [],
                                  //   'status': null,
                                  //   'redirectTo': null,
                                  //   'blockedByRobots': false,
      
                                  //   'crawleable': true,
                                  //   'crawled': false,
                                  //   'crawlerInvoke': true,
                                  //   'url': urlConcat + "sitemap.xml"
                                  // }
                              ]
      
                              db.collection('Sites').doc(eleSite.id).update({
                                  mostRecentCrawlIndex: docRefCrawl.id,
                                  n_log_crawls_ind: eleSite.data().n_log_crawls_ind ? firebase.firestore.FieldValue.increment(1) : 1,
                                  mostRecentCrawlDateIndex: Date.now()
                                  // totalErrors: 0
                              })
      
                              // add pages crawl
                              URLsfound.forEach(eleC => {
                                  db.collection('Crawls').doc(docRefCrawl.id).update({
                                      requests: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                                  })
      
                                  db.collection('Crawls').doc(docRefCrawl.id).update({
                                      founds: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                                  })
      
                                  db.collection('Crawls').doc(docRefCrawl.id).update({
                                      pages: firebase.firestore.FieldValue.arrayUnion({
                                          id: '',
                                          title: '',
                                          runPosition: null,
                                          lastRequest: null,
                                          pro: false,
                                          inlinks: [],
                                          status: null,
                                          redirectTo: '',
                                          blockedByRobots: false,
                                          crawled: true,
                                          crawleable: true,
                                          url: eleC.url,
                                          isSitemap: false,
                                          hasRun: false,
                                          active: false,
                                          size: 999999999,
                                          tries: 1,
                                          tryTimestamp: Date.now()
                                      })
                                  })
                                  .catch(e => console.log('Error update master page: ', e.message))
                              })
      
                              // LOGIC RUN INVOKE CRAWL
                              var protocolSite = ''
                              var homepageSite = ''
                              if(eleSite.data().stringSearch.length > 0) {
                                  body.stringSearch = eleSite.data().stringSearch
                              }
                              protocolSite = eleSite.data().prot ? eleSite.data().prot : getUrl.protocol
                              body.url = protocolSite + "//" + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                              homepageSite = eleSite.data().homepage ? eleSite.data().homepage : ''
                              body.takeScreenshots = 'no'
                              body.triggeredBy = eleSite.id
                              body.source = docRefCrawl.id
                              body.type = 'only-urls'
                              body.useFastCrawler = 'no'
                              body.typePage = 'homeXML'
                              body.screenshotComment = ''
      
                              var urlOrigin = body.url
                              
                              // var config = {
                              //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                              //   "method": "POST",
                              //   "timeout": 0,
                              //   "headers": {
                              //     "Content-Type": "application/json",
                              //     "Accept": "application/json"
                              //   },
                              //   "data": JSON.stringify(body),
                              // };
      
                              var config = {
                                  method: 'POST',
                                  url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl-index',
                                  maxBodyLength: Infinity,
                                  timeout: 0,
                                  headers: {
                                      'Content-Type': 'application/json',
                                      'Accept': 'application/json'
                                  },
                                  data: JSON.stringify(body)
                              };
          
                              this.axios(config)
                              .then((response) => {
                                  body.url = urlOrigin
                                  body.takeScreenshots = 'no'
                                  body.type = 'full'
                                  body.typePage = 'normal'
                                  // console.log(response.data)
                                  console.log('invoke home')

                                  db.collection('Crawls').doc(docRefCrawl.id).update({
                                    updated: Date.now(),
                                    dateEnd: Date.now(),
                                    status: 'success'
                                  })
                                  .catch(e => console.log('Error update crawl: ', e.message))
      
                                  setTimeout(() => {
                                    this.crawls = this.crawls.filter(ele => ele !== idSite)
                                    this.getDataSite(this.idsite)
                                  }, 15000)
                              })
                              .catch(e => {
                                console.log(e)
                                this.crawls = this.crawls.filter(ele => ele !== idSite)
                              })
                              
                              // run crawl three initials xml
                              // body.typePage = 'normal'
                              var atomicBody = body
                              var countInvocked = 0
                              // let arraySites = [atomicBody.url + "/site-map.xml", atomicBody.url + "/sitemap.xml", atomicBody.url + "/page-sitemap.xml"]
                              // let arraySites = [atomicBody.url + (atomicBody.url.endsWith('/') ? 'sitemap.xml' : '/sitemap.xml')]
                              let arraySites = []
                              arraySites.forEach(res => {
                                var objXMLCrawl = {
                                  forceNotFound: 'yes',
                                  id: body.id,
                                  ignoreIndexNoFollow: 'yes',
                                  pageSize: 999999999,
                                  screenshotComment: '',
                                  screenshotType: 'test',
                                  source: body.source,
                                  stringSearch: body.stringSearch,
                                  takeScreenshots: 'no',
                                  triggeredBy: body.triggeredBy,
                                  type: 'only-urls',
                                  url: res,
                                  useFastCrawler: 'no',
                                  typePage: 'normal',
                                  parseAsTextContent: 'no'
                                }
                                atomicBody.url = res
                                atomicBody.type = 'only-urls'
                                atomicBody.takeScreenshots = 'no'
                                atomicBody.typePage = 'normal'
                                
                                // var config = {
                                //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                                //   "method": "POST",
                                //   "timeout": 0,
                                //   "headers": {
                                //     "Content-Type": "application/json",
                                //     "Accept": "application/json"
                                //   },
                                //   "data": JSON.stringify(atomicBody),
                                // };
                                body.url = urlOrigin
                                body.type = 'full'
    
                                var config = {
                                  method: 'POST',
                                  url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl-index',
                                  maxBodyLength: Infinity,
                                  timeout: 0,
                                  headers: {
                                  'Content-Type': 'application/json',
                                  'Accept': 'application/json'
                                  },
                                  data: JSON.stringify(objXMLCrawl)
                                };
                
                                this.axios(config)
                                .then((response) => {
                                  countInvocked += 1
                                  // body.url = urlOrigin
                                  // if(countInvocked === 3) {
                                  // console.log(response.data)
                                  console.log('invoke init xml')
                                  body.stringSearch = ["PHP ERROR",'Lorem','Ipsum']
                                  body.takeScreenshots = 'no'
                                  body.screenshotComment = ""
                                  body.url = urlOrigin
                                  // }
                                })
                                .catch(e => {
                                    console.log(e)
                                })
                              })
      
                              // this.$toast({
                              //     component: ToastificationContent,
                              //     position: 'top-right',
                              //     props: {
                              //         title: `Crawl running`,
                              //         icon: 'CoffeeIcon',
                              //         variant: 'success'
                              //     },
                              // })
      
                              // setTimeout(() => {
                              //     this.crawls = this.crawls.filter(ele => ele !== idSite)
                              // }, 15000)
                          })
                          .catch(e => {
                            console.log('Error add crawl site: ', e)
                            this.crawls = this.crawls.filter(ele => ele !== idSite)
                          })
                      } else if(queryCrawl.size) {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                              title: `Crawl running already`,
                              icon: 'CoffeeIcon',
                              variant: 'warning'
                          },
                        })
                        console.log('Crawl running already!')
                        this.crawls = this.crawls.filter(ele => ele !== idSite)
                      }
                  })
                  .catch(e => {
                    console.log(e.message)
                    this.crawls = this.crawls.filter(ele => ele !== idSite)
                  })
              })
              .catch(e => {
                this.crawls = this.crawls.filter(ele => ele !== idSite)
                // this.execCrawl = false
                console.log('Error query sites host: ', e)
              })
            }
            // }
          } else {
            // this.showOpenRegisterSidebar = true
          }
        }
    },
    mounted() {
      // this.events.forEach((event) => {
      //   window.addEventListener(event, this.getWithScroll)
      // })

      this.timeScroll = setInterval(() => {
        this.getWithScroll()
      }, 300)
    },
    destroyed() {
      // this.events.forEach((event) => {
      //   window.removeEventListener(event, this.getWithScroll)
      // })

      clearInterval(this.timeScroll)
    },
  }
  </script>
  
<style>
.table-wproject-sites-history tr:nth-of-type(2n+2) {
  background-color: rgba(51,68,109,.03) !important;
}
.clear-input {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 30px;
  color: #999;
}

.clear-input:hover {
  color: #000;
}
.input-search-site-table {
    padding: 0.438rem 1.6rem 0.438rem 1rem;
}
.robot-home-btn:hover {
  background: transparent !important;
}
.robot-home-btn:active {
  background: transparent !important;
}
.robot-home-btn:focus {
  background: transparent !important;
}
.copilot-icon-svg-home:hover {
  opacity: 0.5 !important;
}
.rotate {
  animation: rotation 8s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
/* .top-scroll-horizontal-history {
  min-width: 450px !important;
  max-width: 640px !important;
} */
.table-wproject-sites-history {
  min-width: 450px !important;
  max-width: 640px !important;
  /* width: 640px !important; */
}
.table-wproject-sites-history td {
padding-top: .5em !important;
/* padding-right: .1em !important; */
padding-bottom: .5em !important;
/* padding-left: .1em !important; */
cursor: initial !important;
}
/* .table-show-project .vgt-inner-wrap { */
/* overflow-x: auto; */
/* scrollbar-width: thin; */
/* -ms-overflow-style: none; */
/* scrollbar-color: #181e38 #d6deff; */
/* } */
.vgt-inner-wrap::-webkit-scrollbar {
height: 5px;
}
.table-wproject-sites-history tr {
background-color: #fff !important;
border: 0px !important;
}

.table-wproject-sites-history td {
border: 0px !important;
}
.autosuggest-sites .autosuggest__results-container {
max-width: 350px !important;
}
.thTableSitesNameShowHHistory {
background: transparent !important;
border: 1px solid transparent !important;
color: #A7AAB9 !important;
font-size: 15px !important;
font-weight: 300;
padding-right: 0.75em !important;
min-width: 100px !important;
max-width: 200px !important;
width: 200px !important;
}
.tdTableSitesNameShowHHistory {
background: transparent !important;
border: 1px solid transparent !important;
border-bottom: 1px solid #DCDCF2 !important;
cursor: pointer;
vertical-align: middle !important;
min-width: 100px !important;
max-width: 200px !important;
width: 200px !important;
padding-left: 12px !important;
padding-right: 8px !important;
}
.thTableSitesDomainHHistory {
background: transparent !important;
border: 1px solid transparent !important;
color: #A7AAB9 !important;
font-size: 15px !important;
/* max-width: 425px; */
font-weight: 300;
padding-right: 0.75em !important;
}
.tdTableSitesDomainHHistory {
background: transparent !important;
border: 1px solid transparent !important;
border-bottom: 1px solid #DCDCF2 !important;
cursor: pointer;
/* max-width: 425px; */
min-width: 100px !important;
max-width: 145px !important;
width: 145px !important;
word-wrap: break-word;
vertical-align: middle !important;
/* margin: 20px !important; */
}
.vgt-table.bordered th {
background: #fff !important;
border: 1px solid transparent !important;
margin-top: 20px !important;
/* font-size: 16px !important; */
/* color: #181E38 !important; */
}
.item-dropdown-action-sites:hover {
background: none !important;
background-color: none !important;
}
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
background: #fff;
border: none;
padding: 0px !important;
/* color: #394066; */
/* text-transform: lowercase; */
/* font-variant: small-caps; */
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
fill: #394066;
padding: 0px !important;
}
.style-chooser .dropdown-toggle {
background: transparent !important;
border: 0px !important;
padding: 0px !important;
color: #656B85 !important;
}
.style-chooser .dropdown-toggle::after {
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black' stroke='' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}
.style-chooser .dropdown-item {
padding: 0px 5px;
}
.style-chooser .dropdown-menu {
border-radius: 10px;
border: 1px solid #bbbbb9 !important;
width: 180px !important;
}
.dropdown-sites button {
padding: 0px !important;
}
.dropdown-sites button:hover {
background: transparent !important;
}
.autosuggest-sites > div > input {
width: 325px;
height: 44px;
border: 1px solid #A7AAB9;
border-radius: 22px;
}
.autosuggest-sites.autosuggest__results-container {
background: #fff !important;
}
/* .table-show-project .vgt-responsive {
overflow-x: initial !important;
} */
/* .vgt-table.bordered th {
border: 1px solid #dcdfe6;
} */
/* .vgt-table thead th {
border-bottom: 1px solid #dcdfe6;
background: linear-gradient(#f4f5f8,#f1f3f6);
} */
</style>