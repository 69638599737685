<template>
  <div style="padding-bottom: 10px; height: 200px !important;">
    <b-overlay
      id="overlay-background"
      :show="processingData"
      :variant="'light'"
      :opacity="0.85"
      :blur="'2px'"
      rounded="sm"
    >
      <div>
        <div
          v-if="step === 1"
          class="m-auto block"
          style="max-width: 439px;"
        >
        <!-- counterSites -->
          <div v-if="counterSites === 1">
            <h2
              class="color-text-secondary font-weight-bolder"
              style="font-weight: 700; font-size: 32px"
            >
              Let´s get started<span class="text-primary">.</span>
            </h2>
            <span class="">Hooray! We´re glad to welcome you <span class="text-primary">{{ userData.email }}</span> in Seocloud. Add your first site</span>
          </div>
          <div v-else>
            <h2
              class="color-text-secondary font-weight-bolder"
              style="font-weight: 700; font-size: 32px"
            >
              Add a site<span class="text-primary">.</span>
            </h2>
          </div>
        </div>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row
              v-if="step === 1"
              class="m-auto block"
              style="max-width: 439px;"
            >
              <b-col
                md="12"
                class="mt-1"
              >
                <b-form-group
                  label-for="siteName"
                  class=""
                  style="font-weight: 700;"
                >
                  <template v-slot:label>
                    <span
                      style="font-weight: 700; font-size: 15px; color: #656B85;"
                      class=""
                    >Site name</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    :name="'Site name'"
                    rules="required|max: 72"
                  >
                    <b-form-input
                      v-model="siteName"
                      class="mt-1"
                      placeholder="Write you site name"
                      size="lg"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
                class="mt-1"
              >
                <b-form-group
                  label-for="siteURL"
                  class=""
                  style="font-weight: 700;"
                >
                  <template v-slot:label>
                    <span
                      style="font-weight: 700; font-size: 15px; color: #656B85;"
                      class=""
                    >Homepage URL</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="URL"
                    rules="required|url|max: 72"
                  >
                    <b-form-input
                      v-model="siteURL"
                      class="mt-1 "
                      placeholder="Add your URL"
                      size="lg"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <small class="text-danger">{{ errorExistSite }}</small>
              </b-col>
              <b-col
                md="12"
                class="mt-1"
                v-if="originAddButton !== 'my-sites'"
              >
                <div
                  :class="!siteProject ? '' : siteProject && $route.params.idPro === siteProject.uid ? 'p-1' : ''"
                  :style="!siteProject ? '' : siteProject && $route.params.idPro === siteProject.uid ? 'background: #CA34FF1A; border: 1px solid #7838ff; border-radius: 10px;' : ''"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Project"
                    rules="required"
                  >
                    <b-form-group
                      label-for="siteProject"
                      :state="errors.length > 0 ? false:null"
                    >
                      <template v-slot:label>
                        <feather-icon
                          v-if="!siteProject ? '' : siteProject && $route.params.idPro ? '' : ''"
                          icon="AlertCircleIcon"
                          class="mr-50"
                          style="color: #7838FF;"
                        />
                        <span
                          style="font-weight: 700; font-size: 15px; color: #656B85;"
                          class=""
                        >Add your site to a Project</span>
                        <span style="color: #c1c1c1;" class="ml-50">(optional)</span>
                      </template>
                      <v-select
                        v-model="siteProject"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="arrayProjects"
                        :clearable="false"
                        label="title"
                        class="mt-50 select-project-add-site"
                      >
                        <template #option="{ desc, icon, shared }">
                          <span
                            class=""
                            :style="`display: flex;`"
                          >
                            <div
                              v-if="icon.includes('#')"
                              :style="`width: 12px; height: 12px; border-radius: 4px; background: ${icon}; margin-top: 4px;`"
                              class="mr-50"
                            />
                            <feather-icon
                              v-else
                              :icon="icon"
                              size="16"
                              class="align-middle mr-50"
                            />
                            <span> {{ desc }}<span style="color: #CA34FF !important;">{{ shared ? ' (Shared access)' : '' }}</span></span>
                          </span>
                        </template>
                      </v-select>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </div>
              </b-col>
              <div class="w-100 mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  :disabled="processingData ? true : false"
                  class="btn-continue-addsite float-right"
                  @click.prevent="validationStepOne"
                >
                  <!-- @click.prevent="step += 1" -->
                  Add site
                </b-button>
                <!-- <span
                  class="float-right mt-50 mr-1"
                  style="color: #656B85;"
                >Step {{ step }} of 1</span> -->
              </div>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BAvatar, BListGroup, BListGroupItem, BLink, BImg, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormTextarea,
  BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, VBTooltip, BImgLazy, BFormTags, BFormInvalidFeedback, BProgress, BBadge, BFormCheckbox,
  BAlert, BOverlay, BCardImgLazy,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { required, url, max } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import VSwatches from 'vue-swatches'
import moment from 'moment'
import ClaimOwnership from './ClaimOwnership.vue'
import ProPages from './ProPages.vue'
import axios from '@axios'
import {
  db, auth, firebase, storage,
} from '@/firebase'

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BLink,
    BImg,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormTextarea,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BImgLazy,
    BFormTags,
    BFormInvalidFeedback,
    BProgress,
    BBadge,
    BFormCheckbox,
    BAlert,
    BOverlay,
    BCardImgLazy,

    VBModal,
    VSwatches,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    AppCollapseItem,
    AppCollapse,
    ClaimOwnership,
    ProPages,
  },
  mixins: [heightTransition],
  data() {
    return {
      // config page
      userData: '',
      required,
      url,
      step: 1,
      planActive: 0,
      max,
      processingData: false,
      counterSites: '',

      // form new site
      // firststep
      siteName: '',
      siteURL: '',
      siteProject: '',
      // {
      //   title: 'My sites',
      //   desc: 'My sites',
      //   icon: 'ApertureIcon',
      //   uid: '',
      // },

      // thirdstep
      status: 'monthly',
      monthlyPlanShow: true,
      pricing: {
        basicPlan: {
          title: 'Free',
          img: require('@/assets/images/illustration/Pot1.svg'),
          subtitle: 'A simple start for everyone',
          monthlyPrice: 0,
          yearlyPlan: {
            perMonth: 0,
            totalAnual: 0,
          },
          planBenefits: [
            'Unlimited fixes',
            'Up to 3 pages crawl',
          ],
          popular: false,
        },
        standardPlan: {
          title: 'Basic',
          img: require('@/assets/images/illustration/Pot2.svg'),
          subtitle: 'For small to medium businesses',
          monthlyPrice: 123,
          yearlyPlan: {
            perMonth: 115,
            totalAnual: 1380,
          },
          planBenefits: [
            'Unlimited fixes',
            'Up to 10 pages crawl',
          ],
          popular: true,
        },
        enterprisePlan: {
          title: 'Pro',
          img: require('@/assets/images/illustration/Pot3.svg'),
          subtitle: 'Solution for big organizations',
          monthlyPrice: 140,
          yearlyPlan: {
            perMonth: 130,
            totalAnual: 1560,
          },
          planBenefits: [
            'Unlimited',
            'Up to 100 pages crawl',
          ],
          popular: false,
        },
        customPlan: {
          title: 'Custom License',
          img: require('@/assets/images/illustration/Pot3.svg'),
          subtitle: 'Solution for big organizations',
          monthlyPrice: 140,
          yearlyPlan: {
            perMonth: 130,
            totalAnual: 1560,
          },
          planBenefits: [
            'Unlimited',
            'Up to 100 pages crawl',
          ],
          popular: false,
        },
        qandA: [
          {
            question: 'Does my license automatically renew?',
            ans:
                        'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
          },
          {
            question: 'Can I store the item on an intranet so everyone has access?',
            ans:
                        'Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. Chocolate cake bear claw muffin chupa chups pudding.',
          },
          {
            question: 'Am I allowed to modify the item that I purchased?',
            ans:
                        'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan. Carrot cake marshmallow pastry. Bonbon biscuit pastry topping toffee dessert gummies. Topping apple pie pie croissant cotton candy dessert tiramisu.',
          },
        ],
      },
      verifySite: false,
      imgSite: '',
      overlayImgSite: true,

      // fourthstep
      flagImg: require('@/assets/images/svg/flag.svg'),

      arrayProjects: [
        // {
        //   title: 'My sites',
        //   desc: 'My sites',
        //   icon: 'ApertureIcon',
        //   uid: '',
        // },
      ],
      errorExistSite: '',
      idsite: '',
      allProjects: [],
      ownedSite: '',
      body: {
        url: '',
        type: 'full',
        forceNotFound: 'yes',
        pageSize: 999999999,
        id: 'seo-shops-full-' + new Date().toLocaleString('es-us', { month: 'long' }) + new Date().getDate() + '-' + Date.now(),
        takeScreenshots: 'no',
        source: 'seo-shops-' + new Date().getDate() + '-de-' + new Date().toLocaleString('es-us', { month: 'long' }) + '-full-' + Date.now(),
        triggeredBy: 'test triggered',
        screenshotType: 'test',
        screenshotComment: '',
        stringSearch: ["PHP ERROR",'Lorem','Ipsum'],
        ignoreIndexNoFollow: "yes"
      },
      originAddButton: '',
    }
  },
  watch: {
    siteURL(val) {
      val.includes('http') || val.includes('ht') ? this.siteURL = val : this.siteURL = `https://${this.siteURL}`
      // console.log(val.includes('http'))
      this.errorExistSite = ''
    },
    step(val) {
      if(val === 3) {
        var itemVerify = localStorage.getItem('verify')
        if(itemVerify && itemVerify === 'active') {
          this.verifySite = true
        }
      }
    }
  },
  created() {
    localStorage.removeItem('openClaim')
    // window.addEventListener('resize', this.initTrHeight)
    const user = auth.currentUser
    if(user) {
      db.collection('Users').doc(user.uid).get()
      .then(userDoc => {
        this.arrayProjects = []
        this.userData = {}
        this.userData.uid = userDoc.id
        this.userData.foto = userDoc.data().foto ? userDoc.data().foto : ''
        this.userData.name = userDoc.data().nombre ? userDoc.data().nombre : ''
        this.userData.status = userDoc.data().status ? userDoc.data().status : ''
        this.userData.email = userDoc.data().email ? userDoc.data().email : ''

        db.collection('Users').doc(user.uid).collection('projects').get()
        .then(userDocsProjects => {
          // console.log(userDoc.size)
          userDocsProjects.forEach(eleUserProject => {
            if(eleUserProject.id === userDoc.data().mySitesId || eleUserProject.data().name === 'My sites') {
              // console.log(this.$route.params.idPro,eleUserProject.data().id)
              if(!this.$route.params.idPro) {
                this.siteProject = {
                  title: 'My sites',
                  desc: 'My sites',
                  shared: false,
                  icon: eleUserProject.data().color,
                  uid: userDoc.data().mySitesId,
                }
              } else if(this.$route.params.idPro === eleUserProject.id) {
                this.siteProject = {
                  title: eleUserProject.data().name,
                  desc: eleUserProject.data().name,
                  shared: false,
                  icon: eleUserProject.data().color,
                  uid: eleUserProject.data().id,
                }
              }

              this.arrayProjects.unshift({
                title: eleUserProject.data().name,
                desc:  eleUserProject.data().name,
                shared: false,
                icon: eleUserProject.data().color,
                uid: eleUserProject.data().id,
              })

              var condicionParaOrdenarAlpha = (pageA, pageB) => {
                if(pageA.title === 'My sites') {
                  return -1
                }
                if(pageB.title === 'My sites') {
                  return 1
                }
                
                return pageA.title.toLowerCase().localeCompare(pageB.title.toLowerCase())
              }
              this.arrayProjects.sort(condicionParaOrdenarAlpha)
            } else if(eleUserProject.data().owner.uid === user.uid) {
              if(!this.$route.params.idPro) {
                this.siteProject = {
                  title: 'My sites',
                  desc: 'My sites',
                  shared: false,
                  icon: eleUserProject.data().color,
                  uid: userDoc.data().mySitesId,
                }
              } else if(this.$route.params.idPro === eleUserProject.id) {
                this.siteProject = {
                  title: eleUserProject.data().name,
                  desc: eleUserProject.data().name,
                  shared: false,
                  icon: eleUserProject.data().color,
                  uid: eleUserProject.data().id,
                }
              }

              this.arrayProjects.push({
                title: eleUserProject.data().name,
                desc:  eleUserProject.data().name,
                shared: false,
                icon: eleUserProject.data().color,
                uid: eleUserProject.data().id,
              })

              var condicionParaOrdenarAlpha = (pageA, pageB) => {
                if(pageA.title === 'My sites') {
                  return -1
                }
                if(pageB.title === 'My sites') {
                  return 1
                }
                
                return pageA.title.toLowerCase().localeCompare(pageB.title.toLowerCase())
              }
              this.arrayProjects.sort(condicionParaOrdenarAlpha)

              // this.rowsAllProjects.push({
              //   mode: 'span',
              //   html: false,
              //   label: eleUserProject.data().name,
              //   color: eleUserProject.data().color,
              //   idsite: eleUserProject.data().id,
              //   id: eleUserProject.data().id,
              //   title: eleUserProject.data().name,
              //   count: eleUserProject.data().numberSites,
              //   permission: 'owner',
              //   totalInv: eleUserProject.data().numberTeamMembers,
              //   members: eleUserProject.data().teamMembers,
              //   own: 'me',
              //   ownedBy: eleUserProject.data().owner,
              //   counterErr: 0,
              //   loadErrors: false,
              //   counterSites: eleUserProject.data().numberSites,
              //   invitation: 'own',
              // })
            } else {
              var filterUserMember = eleUserProject.data().teamMembers.filter(ele => ele.id === user.uid)

              if(filterUserMember.length >= 1) {
                this.arrayProjects.push({
                  title: eleUserProject.data().name,
                  desc:  eleUserProject.data().name,
                  shared: false,
                  icon: eleUserProject.data().color,
                  uid: eleUserProject.data().id,
                })

                var condicionParaOrdenarAlpha = (pageA, pageB) => {
                  if(pageA.title === 'My sites') {
                    return -1
                  }
                  if(pageB.title === 'My sites') {
                    return 1
                  }
                  
                  return pageA.title.toLowerCase().localeCompare(pageB.title.toLowerCase())
                }
                this.arrayProjects.sort(condicionParaOrdenarAlpha)

                // var memberPerm = filterUserMember[0].role
                // this.rowsAllProjects.push({
                //   mode: 'span',
                //   html: false,
                //   label: eleUserProject.data().name,
                //   color: eleUserProject.data().color,
                //   idsite: eleUserProject.data().id,
                //   id: eleUserProject.data().id,
                //   title: eleUserProject.data().name,
                //   count: eleUserProject.data().numberSites,
                //   permission: memberPerm,
                //   totalInv: eleUserProject.data().numberTeamMembers,
                //   members: eleUserProject.data().teamMembers,
                //   own: 'other',
                //   ownedBy: eleUserProject.data().owner,
                //   counterErr: 0,
                //   loadErrors: false,
                //   counterSites: eleUserProject.data().numberSites,
                //   invitation: filterUserMember[0]
                // })
              }
            }
          })
        })

        // this.arrayProjects.push({
        //   title: project.data().name,
        //   desc:  project.data().name,
        //   shared: true,
        //   icon: project.data().color,
        //   uid: project.id,
        // })
      })

      // db.collection('Requests').where('data', '==', 'found').where('RequestURL', '==', 'https://www.growthlinemarketing.com/category/ppc-advertising/').orderBy('Date', 'desc').limit(1).get()
      // .then(res => {
      //   console.log(res.size)
      //   res.forEach(ele => {
      //     console.log(ele.id)
      //   })
      // })

      // db.collection('sites').where('owned.uid', '==', user.uid).get()
      // .then(siteQuery => {
      //   this.counterSites = siteQuery.size
      // })
    }

    // console.log(this.$route.params.idPro,this.$route.params.objPr)
    if(this.$route.params.idPro) {
      this.siteProject = this.$route.params.objPr ? this.$route.params.objPr : this.$route.params.idPro
    }

    if (this.$route.params.urlSite) {
      this.siteURL = this.$route.params.urlSite
    }

    if (this.$route.params.nameSite) {
      this.siteName = this.$route.params.nameSite
    }

    if(this.siteURL && this.siteName) {
      this.step = 1
    }

    if(this.$route.params.origin) {
      this.originAddButton = this.$route.params.origin
    }
  },
  methods: {
    tooglePlan() {
      if (this.status === 'monthly') {
        this.monthlyPlanShow = true
      } else {
        this.monthlyPlanShow = false
      }
    },
    validationStepOne() {
      // console.log(this.siteProject)
      this.$refs.simpleRules.validate().then(success => {
        if(success) {
          this.processingData = true
          this.errorExistSite = ''
          this.createSiteURL()
          // console.log(this.siteProject,this.siteName,this.siteURL)
        }
      })
      // if(this.siteName && this.siteURL && this.siteURL.includes('.')) {
      //     // if()
      //     this.step += 1
      // } else {
      //     this.$toast({
      //         component: ToastificationContent,
      //         position: 'top-right',
      //         props: {
      //             title: `Check the required fields`,
      //             icon: 'CoffeeIcon',
      //             variant: 'danger'
      //         },
      //     })
      // }
    },
    // getIMG() {
    //   var obj = {
    //     url: this.siteURL,
    //   }

    //   var config = {
    //     method: 'POST',
    //     url: 'https://luguzxy83e.execute-api.us-east-2.amazonaws.com/prod/imgseocloud',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       // 'Access-Control-Allow-Origin': '*'
    //     },
    //     data: JSON.stringify(obj),
    //   }

    //   this.axios(config)
    //   .then(async response => {
    //     const refImg = await storage.ref().child('sitesImgs').child(`${Date.now()}siteImg`)
    //     const upl = await refImg.putString(response.data, 'data_url')
    //     const urlDescarga = await refImg.getDownloadURL()
    //     this.imgSite = urlDescarga
    //     this.processingData = false
    //     this.step += 1
    //   })
    // },
    createSiteURL() {
      var itemVerify = localStorage.getItem('verify')
      if(itemVerify) {
        localStorage.removeItem('verify')
      }

      // var url = site
      var URLactual = new URL(this.siteURL)
      var URLdomain = URLactual.host
      var URLpathname = URLactual.pathname
      var URLprotocol = URLactual.protocol
      var httpsString = URLprotocol + '//'

      if(URLpathname === '/') {
        var urlBody = httpsString + URLdomain
        var urlBodyx = httpsString + URLdomain
        var URLwww = ''
        var URLwithoutWWW = ''
        var urlPrefix = 'www'
        if(URLdomain.includes('www.')) {
          urlPrefix = 'www'
          URLwww = URLdomain
          URLwithoutWWW = URLdomain.slice(4)
        } else {
          urlPrefix = 'non-www'
          URLwww = 'www.'+URLdomain
          URLwithoutWWW = URLdomain
        }

        // QUERY EXIST SITE
        db.collection('Sites').where('WebsiteURL', 'in', [URLdomain,URLwww,URLwithoutWWW]).get()
        .then((querySiteThumb) => {
          var existThumbnail = ''
          var idSite =  ''
          if(querySiteThumb.size) {
            querySiteThumb.forEach(itemSite => {
              idSite = itemSite.id
              if(itemSite.data().thumbnail) {
                existThumbnail = itemSite.data().thumbnail
              }
            })
          }

          db.collection('Sites').where('WebsiteURL', 'in', [URLdomain,URLwww,URLwithoutWWW]).where('createdBy', '==', this.userData.uid).get()
          .then((querySite) => {
            if(!querySite.size) {
              if(querySiteThumb.size) {
                db.collection('Sites').doc(idSite).update({
                  teamMembers: firebase.firestore.FieldValue.arrayUnion(this.userData.uid),
                  projects: firebase.firestore.FieldValue.arrayUnion(this.siteProject.uid)
                })
                .catch(e => console.log('Error update ref site: ', e))

                this.takeScreenshotNow(urlBodyx)

                db.collection('Projects').doc(this.siteProject.uid).collection('sites').doc(idSite).set({
                  'SiteID': idSite,
                  'WebsiteURL': URLdomain,
                  'homepage': urlBodyx,
                  'name': this.siteName,
                  'n': this.siteName ? this.siteName.toLowerCase() : '',
                  thumbnail: existThumbnail,
                  'totalErrors': 0,
                  'plan': '',
                  'paidRecurrence': '',
                  'claimed': false,
                  'owner': '',
                  'status': 'active',
                  // projectId: this.siteProject.uid,
                })
                .catch(error => {
                  console.error(error)
                })

                this.crawlSite(idSite)

                localStorage.setItem('reloadSearchSites', true)

                if(idSite) {
                  var body = {
                    url: '',
                    type: 'full',
                    forceNotFound: 'yes',
                    pageSize: 999999999,
                    id: 'seo-shops-full-' + Date.now(),
                    takeScreenshots: 'no',
                    source: 'seo-cloud-full-' + Date.now(),
                    triggeredBy: 'test triggered',
                    screenshotType: 'test',
                    screenshotComment: '',
                    stringSearch: ["PHP ERROR",'Lorem','Ipsum'],
                    ignoreIndexNoFollow: "yes"
                  }
                  var getUrl = new URL(this.siteURL);
                  var hostUrl = getUrl.host;
                  var httpsString = getUrl.protocol + '//';
                  var urlConcat = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                  var urlBody = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                  var ultimoCaracterUrl = urlConcat.charAt(urlConcat.length - 1);
                  if(ultimoCaracterUrl != "/") {
                    urlConcat += "/";
                  }

                  body.id = 'seo-shops-full-' + Date.now()
                  body.source = 'seo-shops-full-' + Date.now()
                  body.url = getUrl.protocol + "//" + hostUrl

                  if(getUrl.pathname === "/") {
                    body.takeScreenshots = 'no'
                    body.screenshotComment = ""
                  }

                  db.collection('Sites').doc(idSite).get()
                  .then(eleSite => {
                    // exist site
                      var countSites = 0
                      var pageSizes = eleSite.data().pageSizes && eleSite.data().pageSizes.length ? eleSite.data().pageSizes : []
                      var pagesSite = eleSite.data().pages && eleSite.data().pages.length ? eleSite.data().pages : []
                      var filterInternals = pagesSite.filter(ele => ele.isSitemap === true)
                      var filterNoInternals = pagesSite.filter(ele => ele.isSitemap === false)
                      var filterProPages = filterNoInternals.filter(ele => ele.pro === true)
                      var filterNoProPages = filterNoInternals.filter(ele => ele.pro === false)
                      var filterInlinks = filterNoProPages

                      var condicionParaOrdenar = (pageA, pageB) => {
                        return pageB.inlinks.length - pageA.inlinks.length;
                      }
                      filterInlinks.sort(condicionParaOrdenar)

                      var arrayFoundPages = []
                      var arrayProPages = []
                      filterProPages.forEach(ele => {
                        var getUrl = new URL(ele.url)
                        if(getUrl.pathname !== '/') {
                          arrayProPages.push(ele.url)
                        }

                        arrayFoundPages.unshift(ele.url)
                      })
                      
                        db.collection('Crawls').where('WebsiteURL', '==', hostUrl).where('status', '==', 'running').where('crawltype', '==', 'full').get()
                        .then(queryCrawl => {
                          // only 1 loop
                          if(countSites === 0 && !queryCrawl.size) {
                            countSites += 1
                            var stringsArray = eleSite.data().stringSearch ? eleSite.data().stringSearch : []
                            var suspiciousTextArray = eleSite.data().suspiciousText ? eleSite.data().suspiciousText : []
                            var arrayStringsSuspicious = []
                            stringsArray.forEach(ele => {
                              arrayStringsSuspicious.push(ele)
                            })

                            suspiciousTextArray.forEach(ele => {
                              arrayStringsSuspicious.push(ele)
                            })

                            var getConfigCrawl = localStorage.getItem('configCrawl')
                            var limitCrawl = 100
                            var configCrawlType = 'JS FREE'
                            var configCrawlPages = 'FREE'
                            var setCrawl = false
                            if(getConfigCrawl) {
                              setCrawl = true
                              var parseConfig = JSON.parse(getConfigCrawl)
                              var logicTime = moment(parseConfig.date)
                              var actualTime = moment(new Date())
                              var diffSeconds = actualTime.diff(logicTime, 'seconds');
                              if(diffSeconds < 600) {
                                limitCrawl = parseConfig.pages
                                configCrawlType = parseConfig.crawl
                                configCrawlPages = parseConfig.pages === 25 ? 'GUESS/FREE' : parseConfig.pages === 100 ? 'FREE' : parseConfig.pages === 600 ? 'LICENSE' : 'FREE'
                              }
                              var getConfigCrawl = localStorage.removeItem('configCrawl')
                            }
                            
                            var limitCrawlSetSite = eleSite.data().limitCrawl ? eleSite.data().limitCrawl : 0
                            if(limitCrawlSetSite) {
                              if(configCrawlPages === 'GUESS/FREE' && limitCrawlSetSite < 25) {
                                limitCrawl = limitCrawlSetSite
                              } else if(configCrawlPages === 'FREE' && limitCrawlSetSite < 100) {
                                limitCrawl = limitCrawlSetSite
                              } else if(configCrawlPages === 'LICENSE') {
                                limitCrawl = limitCrawlSetSite
                              }
                            }

                            // add Crawl
                            db.collection('Crawls').add({
                              'IDcrawl': body.id,
                              'dateStart': Date.now(),
                              'dateStartFormat': new Date(),
                              'dateEnd': null,
                              'crawlDuration': null,
                              'status': 'running',
                              'crawltype': 'full',
                              'ignoreindexnofollow': 'yes',
                              'Requests': 1,
                              'SiteID': eleSite.id,
                              'WebsiteURL': hostUrl,
                              'blockedByRobots': false,
                              'logicRequests': 1,
                              'proPages': eleSite.data().proPages.length ? eleSite.data().proPages : [urlBody],
                              'totalErrors': 0,
                              'limitCrawl': limitCrawl,
                              'stringSearch': arrayStringsSuspicious,
                              'founds': arrayFoundPages,
                              'requests': [],
                              'pages': [],
                              'inlinks': [],
                              'external': [],
                              'pageSizes': pageSizes,
                              'updated': Date.now(),
                              'plus1k': false,
                              'errorsPages': [],
                              'source': 'seo',
                              'configCrawlType': 'JS FREE',
                              'configCrawlPages': 'FREE',
                              'errorsTypePage': [],
                              'timeoutPages': 0,
                              'errorsCategory': {}
                            })
                            .then((docRefCrawl) => {
                              var URLsfound = [
                                {
                                  'id': null,
                                  'title': null,
                                  'runPosition': 1,
                                  'lastRequest': null,
                                  'pro': true,
                                  'inlinks': [],
                                  'status': null,
                                  'redirectTo': null,
                                  'blockedByRobots': false,
                                  // 'totalErrors': 0,

                                  'crawleable': true,
                                  'crawled': false,
                                  'crawlerInvoke': true,
                                  'url': urlBody
                                  
                                },
                                {
                                  'id': null,
                                  'title': null,
                                  'runPosition': 2,
                                  'lastRequest': null,
                                  'pro': false,
                                  'inlinks': [],
                                  'status': null,
                                  'redirectTo': null,
                                  'blockedByRobots': false,

                                  'crawleable': true,
                                  'crawled': false,
                                  'crawlerInvoke': true,
                                  'url': urlConcat + "sitemap.xml"
                                }
                              ]

                              db.collection('Sites').doc(eleSite.id).update({
                                mostRecentCrawl: docRefCrawl.id,
                                mostRecentCrawlDate: Date.now(),
                                n_log_crawls: eleSite.data().n_log_crawls ? firebase.firestore.FieldValue.increment(1) : 1,
                                errorsTypePage: [],
                                totalErrors: 0,
                                errorsPages: [],
                                externalLinks: [],
                                errorsCategory: {
                                  title: 0,
                                  alts: 0,
                                  desc: 0,
                                  h1: 0,
                                  h2: 0,
                                  wCount: 0,
                                  missImgs: 0,
                                  dLink: 0,
                                  gPadlock: 0,
                                  urlCan: 0,
                                  strings: 0,
                                  Imgs: 0,
                                  externalLinks: 0,
                                  titleCache: 0,
                                  altsCache: 0,
                                  descCache: 0,
                                  h1Cache: 0,
                                  h2Cache: 0,
                                  wCountCache: 0,
                                  missImgsCache: 0,
                                  dLinkCache: 0,
                                  gPadlockCache: 0,
                                  urlCanCache: 0,
                                  stringsCache: 0,
                                  ImgsCache: 0,
                                  suspCache: 0
                                }
                              })

                              // add pages crawl
                              URLsfound.forEach(eleC => {
                                db.collection('Crawls').doc(docRefCrawl.id).update({
                                  requests: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                                })

                                db.collection('Crawls').doc(docRefCrawl.id).update({
                                  founds: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                                })

                                db.collection('Crawls').doc(docRefCrawl.id).update({
                                  pages: firebase.firestore.FieldValue.arrayUnion({
                                    id: '',
                                    title: '',
                                    runPosition: null,
                                    lastRequest: null,
                                    pro: false,
                                    inlinks: [],
                                    status: null,
                                    redirectTo: '',
                                    blockedByRobots: false,
                                    crawled: true,
                                    crawleable: true,
                                    url: eleC.url,
                                    isSitemap: false,
                                    hasRun: false,
                                    active: false,
                                    size: 999999999,
                                    tries: 1,
                                    tryTimestamp: Date.now()
                                  })
                                })
                                .catch(e => console.log('Error update master page: ', e.message))
                              })

                              // LOGIC RUN INVOKE CRAWL
                              var protocolSite = ''
                              var homepageSite = ''
                              if(eleSite.data().stringSearch.length > 0) {
                                body.stringSearch = eleSite.data().stringSearch
                              }
                              protocolSite = eleSite.data().prot ? eleSite.data().prot : getUrl.protocol
                              body.url = protocolSite + "//" + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                              homepageSite = eleSite.data().homepage ? eleSite.data().homepage : ''
                              body.takeScreenshots = 'no'
                              body.triggeredBy = eleSite.id
                              body.source = docRefCrawl.id
                              body.type = 'only-urls'
                              body.useFastCrawler = 'no'
                              body.typePage = 'homeXML'
                              body.screenshotComment = ''

                              var urlOrigin = body.url
                              
                              // var config = {
                              //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                              //   "method": "POST",
                              //   "timeout": 0,
                              //   "headers": {
                              //     "Content-Type": "application/json",
                              //     "Accept": "application/json"
                              //   },
                              //   "data": JSON.stringify(body),
                              // };

                              var config = {
                                method: 'POST',
                                url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl',
                                maxBodyLength: Infinity,
                                timeout: 0,
                                headers: {
                                  'Content-Type': 'application/json',
                                  'Accept': 'application/json'
                                },
                                data: JSON.stringify(body)
                              };
            
                              this.axios(config)
                              .then((response) => {
                                body.url = urlOrigin
                                body.takeScreenshots = 'no'
                                body.type = 'full'
                                body.typePage = 'normal'
                                // console.log(response.data)
                                console.log('invoke home')
                              })
                              .catch(e => {
                                console.log(e)
                              })
                              
                              // run crawl three initials xml
                              // body.typePage = 'normal'
                              var atomicBody = body
                              var countInvocked = 0
                              // let arraySites = [atomicBody.url + "/site-map.xml", atomicBody.url + "/sitemap.xml", atomicBody.url + "/page-sitemap.xml"]
                              let arraySites = [atomicBody.url + (atomicBody.url.endsWith('/') ? 'sitemap.xml' : '/sitemap.xml')]
                              // let arraySites = []
                              arraySites.forEach(res => {
                                var objXMLCrawl = {
                                  forceNotFound: 'yes',
                                  id: body.id,
                                  ignoreIndexNoFollow: 'yes',
                                  pageSize: 999999999,
                                  screenshotComment: '',
                                  screenshotType: 'test',
                                  source: body.source,
                                  stringSearch: body.stringSearch,
                                  takeScreenshots: 'no',
                                  triggeredBy: body.triggeredBy,
                                  type: 'only-urls',
                                  url: res,
                                  useFastCrawler: 'no',
                                  typePage: 'normal',
                                  parseAsTextContent: 'no'
                                }
                                atomicBody.url = res
                                atomicBody.type = 'only-urls'
                                atomicBody.takeScreenshots = 'no'
                                atomicBody.typePage = 'normal'
                                
                                // var config = {
                                //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                                //   "method": "POST",
                                //   "timeout": 0,
                                //   "headers": {
                                //     "Content-Type": "application/json",
                                //     "Accept": "application/json"
                                //   },
                                //   "data": JSON.stringify(atomicBody),
                                // };
                                body.url = urlOrigin
                                body.type = 'full'

                                var config = {
                                  method: 'POST',
                                  url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl',
                                  maxBodyLength: Infinity,
                                  timeout: 0,
                                  headers: {
                                    'Content-Type': 'application/json',
                                    'Accept': 'application/json'
                                  },
                                  data: JSON.stringify(objXMLCrawl)
                                };
              
                                this.axios(config)
                                .then((response) => {
                                  countInvocked += 1
                                  // body.url = urlOrigin
                                  // if(countInvocked === 3) {
                                    // console.log(response.data)
                                    console.log('invoke init xml')
                                    body.stringSearch = ["PHP ERROR",'Lorem','Ipsum']
                                    body.takeScreenshots = 'no'
                                    body.screenshotComment = ""
                                    body.url = urlOrigin
                                  // }
                                })
                                .catch(e => {
                                  console.log(e)
                                })
                              })

                              // this.itemsPagesOverview = []
                              db.collection('Sites').doc(eleSite.id).update({
                                mostRecentCrawlStatus: 'running',
                                mostRecentCrawl: docRefCrawl.id,
                                mostRecentCrawlId: body.id,
                                pages: [],
                                // pageSizes: [],
                                externalLinks: []
                              })
                              .catch(e => console.log('Error update site: ', e))

                              db.collection('Sites_cache').doc(eleSite.id).update({
                                mostRecentCrawlStatus: 'running',
                                mostRecentCrawl: docRefCrawl.id,
                                mostRecentCrawlId: body.id,
                                inlinks: [],
                                pages: []
                              })
                              .catch(e => console.log('Error update site: ', e))

                              URLsfound.forEach(eleC => {
                                var objCache = {}
                                objCache.id = null
                                objCache.title = null
                                objCache.url = eleC.url
                                objCache.inlinks = null
                                objCache.inlinksNumber = null
                                objCache.pro = false
                                objCache.status = null
                                objCache.redirectTo = null
                                objCache.blockedByRobots = null
                                objCache.totalErrors = 0
                                objCache.errorByType = {}
                                objCache.isSitemap = eleC.url.includes('.xml') ? true : false
                                db.collection('Sites_cache').doc(eleSite.id).update({
                                  pages: firebase.firestore.FieldValue.arrayUnion(objCache)
                                })

                                var objPageCollection = {
                                  id: null,
                                  title: null,
                                  url: eleC.url,
                                  inlinks: [],
                                  inlinksNumber: 0,
                                  pro: false,
                                  // status: itemPage.status,
                                  status: null,
                                  redirectTo: null,
                                  blockedByRobots: null,
                                  totalErrors: 0,
                                  errorByType: {},
                                  active: false,
                                  crawleable: true,
                                  size: null,
                                  isSitemap: eleC.url.includes('.xml') ? true : false
                                }

                                db.collection('Sites').doc(eleSite.id).collection('pages').where('url', '==', eleC.url).limit(1).get()
                                .then(docSite => {
                                  if(docSite.size) {
                                    var counterLoop = 0
                                    docSite.forEach(ele => {
                                      if(counterLoop === 0) {
                                        db.collection('Sites').doc(eleSite.id).collection('pages').doc(ele.id).update(objPageCollection)
                                      }
                                    })
                                  } else {
                                    db.collection('Sites').doc(eleSite.id).collection('pages').add(objPageCollection)
                                    .then((docRefSite) => {
                                        db.collection('Sites').doc(eleSite.id).collection('pages').doc(docRefSite.id).update({
                                          id: docRefSite.id
                                        })
                                    })
                                  }
                                })
                              })
                            })
                            .catch(e => console.log('Error add crawl site: ', e))
                          } else if(queryCrawl.size) {
                            console.log('Crawl running already!')
                          }
                        })
                        .catch(e => console.log(e.message))
                    // })
                  })
                  .catch(e => {
                    // this.execCrawl = false
                    console.log('Error query sites host: ', e)
                  })
                }

                db.collection('Projects').doc(this.siteProject.uid).update({
                  numberSites: firebase.firestore.FieldValue.increment(1),
                })

                db.collection('Users').doc(this.userData.uid).collection('projects').doc(this.siteProject.uid).update({
                  numberSites: firebase.firestore.FieldValue.increment(1),
                })

                setTimeout(() => {
                  db.collection('Users').doc(this.userData.uid).get()
                  .then(docUser => {
                    var objnewSiteMail = {
                      nameUser: docUser.data().nombre ? docUser.data().nombre : docUser.data().email,
                      email: docUser.data().email,
                      existUser: true,
                      urlSite: URLdomain,
                      idsite: idSite,
                      idproject: this.siteProject.uid
                    }

                    // update seocloud-dcdfb
                    var confignewSiteMail = {
                      method: 'POST',
                      url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/newSiteMail',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      data: JSON.stringify(objnewSiteMail),
                    }

                    this.axios(confignewSiteMail)
                    .then(() => {
                      console.log('email sended')
                    })
                    .catch(error => {
                      console.log(error)
                    })
                  })
                  
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Site created successfully`,
                      icon: 'CoffeeIcon',
                      variant: 'success'
                    },
                  })
                  this.$router.push({
                    name: 'view-errors',
                    params: {
                      id: idSite, imgSite: existThumbnail, name: this.siteName ? this.siteName.toLowerCase() : '', url: URLdomain, project: this.siteProject.uid, own: 'me'
                    },
                  })
                }, 1500)
              } else {
                db.collection('Sites').add({
                  'SiteID': '',
                  'name': this.siteName,
                  'n': this.siteName ? this.siteName.toLowerCase() : '',
                  'WebsiteURL': URLdomain,
                  'homepage': urlBodyx,
                  thumbnail: existThumbnail,
                  'Blockedbyrobot': 'no',
                  // 'pagesFound': '',
                  'proPages': [],
                  'asignatedProPages': false,
                  'mostRecentCrawl': '',
                  'mostRecentCrawlStatus': '',
                  'totalErrors': 0,
                  'errorsCategory': {},
                  'claimed': false,
                  'owner': '',
                  'plan': '',
                  'paidRecurrence': '',
                  'totalPages': 0,
                  'createdBy': this.userData.uid,
                  'created': Date.now(),
                  'registrationDay': moment().format('dddd'),

                  'owners': [],
                  'limitCrawl': 600,
                  'prot': URLprotocol,
                  'domainPrefix': urlPrefix,
                  'stringSearch': ['PHP ERROR','Lorem','Ipsum','Consectetur'],
                  'totalSuccessCrawl': 0,
                  'externalLinks': [],
                  'pageSizes': [],
                  'projects': [this.siteProject.uid],
                  'plus1k': false,
                  'teamMembers': [],
                  'errorsPages': [],
                  'suspiciousText': ['viagra'],
                  'n_guess_crawls': 0,
                  'n_log_crawls': 0
                  
                  // 'FirstFound': Date.now(),
                  // 'sizeValidate': true,
                  // 'Mostrecentfullcrawl': body.id,
                  // 'Mostrecentfullcrawldatestart': Date.now(),
                  // 'Mostrecentfullcrawldateend': null,
                  // 'Mostrecentfullcrawlsuccessid': null,
                  // 'Mostrecentfullcrawlsuccess': false,
                  // 'Mostrecentcrawltype': bodySite.type ? bodySite.type : 'full',
                  // 'Mostrecentsinglecrawl': null,
                  // 'MostrecentsinglecrawlURL': null,
                  // 'Mostrecentsinglecrawldate': null
                })
                .then(docRefSite => {
                  this.takeScreenshotNow(urlBodyx)

                  this.crawlSite(docRefSite.id)

                  // update Sites id
                  db.collection('Sites').doc(docRefSite.id).update({
                    SiteID: docRefSite.id,
                    teamMembers: firebase.firestore.FieldValue.arrayUnion(this.userData.uid)
                  })
                  .catch(e => console.log('Error update ref site: ', e))

                  db.collection('Users').doc(this.userData.uid).get()
                  .then(docUser => {
                    var objnewSiteMail = {
                      nameUser: docUser.data().nombre ? docUser.data().nombre : docUser.data().email,
                      email: docUser.data().email,
                      existUser: true,
                      urlSite: URLdomain,
                      idsite: docRefSite.id,
                      idproject: this.siteProject.uid
                    }

                    // update seocloud-dcdfb
                    var confignewSiteMail = {
                      method: 'POST',
                      url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/newSiteMail',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      data: JSON.stringify(objnewSiteMail),
                    }

                    this.axios(confignewSiteMail)
                    .then(() => {
                      console.log('email sended')
                    })
                    .catch(error => {
                      console.log(error)
                    })
                  })
                  
                  if(docRefSite.id) {
                    var body = {
                      url: '',
                      type: 'full',
                      forceNotFound: 'yes',
                      pageSize: 999999999,
                      id: 'seo-shops-full-' + Date.now(),
                      takeScreenshots: 'no',
                      source: 'seo-cloud-full-' + Date.now(),
                      triggeredBy: 'test triggered',
                      screenshotType: 'test',
                      screenshotComment: '',
                      stringSearch: ["PHP ERROR",'Lorem','Ipsum'],
                      ignoreIndexNoFollow: "yes"
                    }
                    var getUrl = new URL(this.siteURL);
                    var hostUrl = getUrl.host;
                    var httpsString = getUrl.protocol + '//';
                    var urlConcat = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                    var urlBody = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                    var ultimoCaracterUrl = urlConcat.charAt(urlConcat.length - 1);
                    if(ultimoCaracterUrl != "/") {
                      urlConcat += "/";
                    }

                    body.id = 'seo-shops-full-' + Date.now()
                    body.source = 'seo-shops-full-' + Date.now()
                    body.url = getUrl.protocol + "//" + hostUrl

                    if(getUrl.pathname === "/") {
                      body.takeScreenshots = 'no'
                      body.screenshotComment = ""
                    }

                    db.collection('Sites').doc(docRefSite.id).get()
                    .then(eleSite => {
                      // exist site
                      // db.collection('Crawls').where('status', '==', 'running').get()
                      // .then(queryCrawlRunning => {
                        // if(querySites.) {
                        var countSites = 0
                        var pageSizes = eleSite.data().pageSizes && eleSite.data().pageSizes.length ? eleSite.data().pageSizes : []
                        var pagesSite = eleSite.data().pages && eleSite.data().pages.length ? eleSite.data().pages : []
                        var filterInternals = pagesSite.filter(ele => ele.isSitemap === true)
                        var filterNoInternals = pagesSite.filter(ele => ele.isSitemap === false)
                        var filterProPages = filterNoInternals.filter(ele => ele.pro === true)
                        var filterNoProPages = filterNoInternals.filter(ele => ele.pro === false)
                        var filterInlinks = filterNoProPages

                        var condicionParaOrdenar = (pageA, pageB) => {
                          return pageB.inlinks.length - pageA.inlinks.length;
                        }
                        filterInlinks.sort(condicionParaOrdenar)

                        var arrayFoundPages = []
                        var arrayProPages = []
                        filterProPages.forEach(ele => {
                          var getUrl = new URL(ele.url)
                          if(getUrl.pathname !== '/') {
                            arrayProPages.push(ele.url)
                          }

                          arrayFoundPages.unshift(ele.url)
                        })

                        // filterInternals.forEach(ele => {
                        //   var filterPage = arrayFoundPages.filter(item => item === ele.url)
                        //   if(!filterPage.length) {
                        //     arrayFoundPages.push(ele.url)
                        //   }
                        // })
                        
                          db.collection('Crawls').where('WebsiteURL', '==', hostUrl).where('status', '==', 'running').where('crawltype', '==', 'full').get()
                          .then(queryCrawl => {
                            // only 1 loop
                            if(countSites === 0 && !queryCrawl.size) {
                              countSites += 1
                              var stringsArray = eleSite.data().stringSearch ? eleSite.data().stringSearch : []
                              var suspiciousTextArray = eleSite.data().suspiciousText ? eleSite.data().suspiciousText : []
                              var arrayStringsSuspicious = []
                              stringsArray.forEach(ele => {
                                arrayStringsSuspicious.push(ele)
                              })

                              suspiciousTextArray.forEach(ele => {
                                arrayStringsSuspicious.push(ele)
                              })

                              var getConfigCrawl = localStorage.getItem('configCrawl')
                              var limitCrawl = 100
                              var configCrawlType = 'JS FREE'
                              var configCrawlPages = 'FREE'
                              var setCrawl = false
                              if(getConfigCrawl) {
                                setCrawl = true
                                var parseConfig = JSON.parse(getConfigCrawl)
                                var logicTime = moment(parseConfig.date)
                                var actualTime = moment(new Date())
                                var diffSeconds = actualTime.diff(logicTime, 'seconds');
                                if(diffSeconds < 600) {
                                  limitCrawl = parseConfig.pages
                                  configCrawlType = parseConfig.crawl
                                  configCrawlPages = parseConfig.pages === 25 ? 'GUESS/FREE' : parseConfig.pages === 100 ? 'FREE' : parseConfig.pages === 600 ? 'LICENSE' : 'FREE'
                                }
                                var getConfigCrawl = localStorage.removeItem('configCrawl')
                              }
                              
                              var limitCrawlSetSite = eleSite.data().limitCrawl ? eleSite.data().limitCrawl : 0
                              if(limitCrawlSetSite) {
                                if(configCrawlPages === 'GUESS/FREE' && limitCrawlSetSite < 25) {
                                  limitCrawl = limitCrawlSetSite
                                } else if(configCrawlPages === 'FREE' && limitCrawlSetSite < 100) {
                                  limitCrawl = limitCrawlSetSite
                                } else if(configCrawlPages === 'LICENSE') {
                                  limitCrawl = limitCrawlSetSite
                                }
                              }

                              // add Crawl
                              db.collection('Crawls').add({
                                'IDcrawl': body.id,
                                'dateStart': Date.now(),
                                'dateStartFormat': new Date(),
                                'dateEnd': null,
                                'crawlDuration': null,
                                'status': 'running',
                                'crawltype': 'full',
                                'ignoreindexnofollow': 'yes',
                                'Requests': 1,
                                'SiteID': eleSite.id,
                                'WebsiteURL': hostUrl,
                                'blockedByRobots': false,
                                'logicRequests': 1,
                                'proPages': eleSite.data().proPages.length ? eleSite.data().proPages : [urlBody],
                                'totalErrors': 0,
                                'limitCrawl': limitCrawl,
                                'stringSearch': arrayStringsSuspicious,
                                'founds': arrayFoundPages,
                                'requests': [],
                                'pages': [],
                                'inlinks': [],
                                'external': [],
                                'pageSizes': pageSizes,
                                'updated': Date.now(),
                                'plus1k': false,
                                'errorsPages': [],
                                'source': 'seo',
                                'configCrawlType': 'JS FREE',
                                'configCrawlPages': 'FREE',
                                'errorsTypePage': [],
                                'timeoutPages': 0,
                                'errorsCategory': {}
                              })
                              .then((docRefCrawl) => {
                                var URLsfound = [
                                  {
                                    'id': null,
                                    'title': null,
                                    'runPosition': 1,
                                    'lastRequest': null,
                                    'pro': true,
                                    'inlinks': [],
                                    'status': null,
                                    'redirectTo': null,
                                    'blockedByRobots': false,
                                    // 'totalErrors': 0,

                                    'crawleable': true,
                                    'crawled': false,
                                    'crawlerInvoke': true,
                                    'url': urlBody
                                    
                                  },
                                  // {
                                  //   'id': null,
                                  //   'title': null,
                                  //   'runPosition': 2,
                                  //   'lastRequest': null,
                                  //   'pro': false,
                                  //   'inlinks': [],
                                  //   'status': null,
                                  //   'redirectTo': null,
                                  //   'blockedByRobots': false,

                                  //   'crawleable': true,
                                  //   'crawled': false,
                                  //   'crawlerInvoke': true,
                                  //   'url': urlConcat + "site-map.xml"
                                  // },
                                  {
                                    'id': null,
                                    'title': null,
                                    'runPosition': 2,
                                    'lastRequest': null,
                                    'pro': false,
                                    'inlinks': [],
                                    'status': null,
                                    'redirectTo': null,
                                    'blockedByRobots': false,

                                    'crawleable': true,
                                    'crawled': false,
                                    'crawlerInvoke': true,
                                    'url': urlConcat + "sitemap.xml"
                                  },
                                  // {
                                  //   'id': null,
                                  //   'title': null,
                                  //   'runPosition': 4,
                                  //   'lastRequest': null,
                                  //   'pro': false,
                                  //   'inlinks': [],
                                  //   'status': null,
                                  //   'redirectTo': null,
                                  //   'blockedByRobots': false,

                                  //   'crawleable': true,
                                  //   'crawled': false,
                                  //   'crawlerInvoke': true,
                                  //   'url': urlConcat + "page-sitemap.xml"
                                  // }
                                ]

                                db.collection('Sites').doc(eleSite.id).update({
                                  mostRecentCrawl: docRefCrawl.id,
                                  mostRecentCrawlDate: Date.now(),
                                  n_log_crawls: eleSite.data().n_log_crawls ? firebase.firestore.FieldValue.increment(1) : 1,
                                  errorsTypePage: [],
                                  totalErrors: 0,
                                  errorsPages: [],
                                  externalLinks: [],
                                  errorsCategory: {
                                    title: 0,
                                    alts: 0,
                                    desc: 0,
                                    h1: 0,
                                    h2: 0,
                                    wCount: 0,
                                    missImgs: 0,
                                    dLink: 0,
                                    gPadlock: 0,
                                    urlCan: 0,
                                    strings: 0,
                                    Imgs: 0,
                                    externalLinks: 0,
                                    titleCache: 0,
                                    altsCache: 0,
                                    descCache: 0,
                                    h1Cache: 0,
                                    h2Cache: 0,
                                    wCountCache: 0,
                                    missImgsCache: 0,
                                    dLinkCache: 0,
                                    gPadlockCache: 0,
                                    urlCanCache: 0,
                                    stringsCache: 0,
                                    ImgsCache: 0,
                                    suspCache: 0
                                  }
                                })

                                // add pages crawl
                                URLsfound.forEach(eleC => {
                                  db.collection('Crawls').doc(docRefCrawl.id).update({
                                    requests: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                                  })

                                  db.collection('Crawls').doc(docRefCrawl.id).update({
                                    founds: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                                  })

                                  db.collection('Crawls').doc(docRefCrawl.id).update({
                                    pages: firebase.firestore.FieldValue.arrayUnion({
                                      id: '',
                                      title: '',
                                      runPosition: null,
                                      lastRequest: null,
                                      pro: false,
                                      inlinks: [],
                                      status: null,
                                      redirectTo: '',
                                      blockedByRobots: false,
                                      crawled: true,
                                      crawleable: true,
                                      url: eleC.url,
                                      isSitemap: false,
                                      hasRun: false,
                                      active: false,
                                      size: 999999999,
                                      tries: 1,
                                      tryTimestamp: Date.now()
                                    })
                                  })
                                  .catch(e => console.log('Error update master page: ', e.message))
                                })

                                // LOGIC RUN INVOKE CRAWL
                                var protocolSite = ''
                                var homepageSite = ''
                                if(eleSite.data().stringSearch.length > 0) {
                                  body.stringSearch = eleSite.data().stringSearch
                                }
                                protocolSite = eleSite.data().prot ? eleSite.data().prot : getUrl.protocol
                                body.url = protocolSite + "//" + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                                homepageSite = eleSite.data().homepage ? eleSite.data().homepage : ''
                                body.takeScreenshots = 'no'
                                body.triggeredBy = eleSite.id
                                body.source = docRefCrawl.id
                                body.type = 'only-urls'
                                body.useFastCrawler = 'no'
                                body.typePage = 'homeXML'
                                body.parseAsTextContent = 'yes'
                                body.screenshotComment = ''

                                var urlOrigin = body.url
                                
                                // var config = {
                                //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                                //   "method": "POST",
                                //   "timeout": 0,
                                //   "headers": {
                                //     "Content-Type": "application/json",
                                //     "Accept": "application/json"
                                //   },
                                //   "data": JSON.stringify(body),
                                // };

                                var config = {
                                  method: 'POST',
                                  url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl',
                                  maxBodyLength: Infinity,
                                  timeout: 0,
                                  headers: {
                                    'Content-Type': 'application/json',
                                    'Accept': 'application/json'
                                  },
                                  data: JSON.stringify(body)
                                };
              
                                this.axios(config)
                                .then((response) => {
                                  body.url = urlOrigin
                                  body.takeScreenshots = 'no'
                                  body.type = 'full'
                                  body.typePage = 'normal'
                                  // console.log(response.data)
                                  console.log('invoke home')
                                })
                                .catch(e => {
                                  console.log(e)
                                })
                                
                                // run crawl three initials xml
                                var atomicBody = body
                                var countInvocked = 0
                                // let arraySites = [atomicBody.url + "/site-map.xml", atomicBody.url + "/sitemap.xml", atomicBody.url + "/page-sitemap.xml"]
                                let arraySites = [atomicBody.url + (atomicBody.url.endsWith('/') ? 'sitemap.xml' : '/sitemap.xml')]
                                // let arraySites = []
                                arraySites.forEach(res => {
                                  var objXMLCrawl = {
                                    forceNotFound: 'yes',
                                    id: body.id,
                                    ignoreIndexNoFollow: 'yes',
                                    pageSize: 999999999,
                                    screenshotComment: '',
                                    screenshotType: 'test',
                                    source: body.source,
                                    stringSearch: body.stringSearch,
                                    takeScreenshots: 'no',
                                    triggeredBy: body.triggeredBy,
                                    type: 'only-urls',
                                    url: res,
                                    useFastCrawler: 'no',
                                    typePage: 'normal',
                                    parseAsTextContent: 'no'
                                  }
                                  atomicBody.url = res
                                  atomicBody.type = 'only-urls'
                                  atomicBody.takeScreenshots = 'no'
                                  atomicBody.typePage = 'normal'
                                  
                                  // var config = {
                                  //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                                  //   "method": "POST",
                                  //   "timeout": 0,
                                  //   "headers": {
                                  //     "Content-Type": "application/json",
                                  //     "Accept": "application/json"
                                  //   },
                                  //   "data": JSON.stringify(atomicBody),
                                  // };
                                  body.url = urlOrigin
                                  body.type = 'full'

                                  var config = {
                                    method: 'POST',
                                    url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl',
                                    maxBodyLength: Infinity,
                                    timeout: 0,
                                    headers: {
                                      'Content-Type': 'application/json',
                                      'Accept': 'application/json'
                                    },
                                    data: JSON.stringify(objXMLCrawl)
                                  };
                
                                  this.axios(config)
                                  .then((response) => {
                                    countInvocked += 1
                                    // body.url = urlOrigin
                                    // if(countInvocked === 3) {
                                      // console.log(response.data)
                                      console.log('invoke init xml')
                                      body.stringSearch = ["PHP ERROR",'Lorem','Ipsum']
                                      body.takeScreenshots = 'no'
                                      body.screenshotComment = ""
                                      body.url = urlOrigin
                                    // }
                                  })
                                  .catch(e => {
                                    console.log(e)
                                  })
                                })

                                // this.itemsPagesOverview = []
                                db.collection('Sites').doc(eleSite.id).update({
                                  mostRecentCrawlStatus: 'running',
                                  mostRecentCrawl: docRefCrawl.id,
                                  mostRecentCrawlId: body.id,
                                  pages: [],
                                  // pageSizes: [],
                                  externalLinks: []
                                })
                                .catch(e => console.log('Error update site: ', e))

                                db.collection('Sites_cache').doc(eleSite.id).update({
                                  mostRecentCrawlStatus: 'running',
                                  mostRecentCrawl: docRefCrawl.id,
                                  mostRecentCrawlId: body.id,
                                  inlinks: [],
                                  pages: []
                                })
                                .catch(e => console.log('Error update site: ', e))

                                URLsfound.forEach(eleC => {
                                  var objCache = {}
                                  objCache.id = null
                                  objCache.title = null
                                  objCache.url = eleC.url
                                  objCache.inlinks = null
                                  objCache.inlinksNumber = null
                                  objCache.pro = false
                                  objCache.status = null
                                  objCache.redirectTo = null
                                  objCache.blockedByRobots = null
                                  objCache.totalErrors = 0
                                  objCache.errorByType = {}
                                  objCache.isSitemap = eleC.url.includes('.xml') ? true : false
                                  db.collection('Sites_cache').doc(eleSite.id).update({
                                    pages: firebase.firestore.FieldValue.arrayUnion(objCache)
                                  })

                                  var objPageCollection = {
                                    id: null,
                                    title: null,
                                    url: eleC.url,
                                    inlinks: [],
                                    inlinksNumber: 0,
                                    pro: false,
                                    // status: itemPage.status,
                                    status: null,
                                    redirectTo: null,
                                    blockedByRobots: null,
                                    totalErrors: 0,
                                    errorByType: {},
                                    active: false,
                                    crawleable: true,
                                    size: null,
                                    isSitemap: eleC.url.includes('.xml') ? true : false
                                  }

                                  db.collection('Sites').doc(eleSite.id).collection('pages').where('url', '==', eleC.url).limit(1).get()
                                  .then(docSite => {
                                    if(docSite.size) {
                                      var counterLoop = 0
                                      docSite.forEach(ele => {
                                        if(counterLoop === 0) {
                                          db.collection('Sites').doc(eleSite.id).collection('pages').doc(ele.id).update(objPageCollection)
                                        }
                                      })
                                    } else {
                                      db.collection('Sites').doc(eleSite.id).collection('pages').add(objPageCollection)
                                      .then((docRefSite) => {
                                          db.collection('Sites').doc(eleSite.id).collection('pages').doc(docRefSite.id).update({
                                            id: docRefSite.id
                                          })
                                      })
                                    }
                                  })
                                })
                              })
                              .catch(e => console.log('Error add crawl site: ', e))
                            } else if(queryCrawl.size) {
                              console.log('Crawl running already!')
                              // this.$toast({
                              //   component: ToastificationContent,
                              //   props: {
                              //     title: 'Crawl running already!',
                              //     icon: 'BellIcon',
                              //     variant: 'warning',
                              //   },
                              // })
                            }
                          })
                          .catch(e => console.log(e.message))
                      // })
                    })
                    .catch(e => {
                      // this.execCrawl = false
                      console.log('Error query sites host: ', e)
                    })
                  }

                  // var objPage = {}
                  // objPage.id = null
                  // objPage.title = null
                  // objPage.url = urlBody
                  // objPage.inlinks = []
                  // objPage.inlinksNumber = 0
                  // objPage.size = null
                  // objPage.pro = false
                  // objPage.status = null
                  // objPage.redirectTo = null
                  // objPage.blockedByRobots = null
                  // objPage.totalErrors = 0
                  // objPage.errorByType = {}
                  // objPage.active = null
                  // objPage.crawleable = true
                  // objPage.isSitemap = false
                  // objPage.lastRequest = null
                  // db.collection('Sites').doc(docRefSite.id).update({
                  //   pages: firebase.firestore.FieldValue.arrayUnion(objPage),
                  //   proPages: firebase.firestore.FieldValue.arrayUnion(urlBody)
                  // })

                  // var objCache = {}
                  // objCache.id = null
                  // objCache.title = null
                  // objCache.url = urlBody
                  // objCache.inlinks = null
                  // objCache.inlinksNumber = null
                  // objCache.pro = false
                  // objCache.status = null
                  // objCache.redirectTo = null
                  // objCache.blockedByRobots = null
                  // objCache.totalErrors = 0
                  // objCache.errorByType = {}
                  // objCache.isSitemap = false
                  // db.collection('Sites_cache').doc(docRefSite.id).update({
                  //   pages: firebase.firestore.FieldValue.arrayUnion(objCache),
                  //   proPages: firebase.firestore.FieldValue.arrayUnion(urlBody)
                  // })

                  db.collection('Sites_cache').doc(docRefSite.id).set({
                    'SiteID': docRefSite.id,
                    'name': this.siteName,
                    'n': this.siteName ? this.siteName.toLowerCase() : '',
                    'WebsiteURL': URLdomain,
                    'homepage': urlBodyx,
                    thumbnail: existThumbnail,
                    'Blockedbyrobot': 'no',
                    // 'pagesFound': '',
                    'proPages': [],
                    inlinks: [],
                    'asignatedProPages': false,
                    'mostRecentCrawl': '',
                    'mostRecentCrawlStatus': '',
                    'totalErrors': 0,
                    'errorsCategory': {},
                    'claimed': false,
                    'owner': '',
                    'plan': '',
                    'paidRecurrence': '',
                    'totalPages': 0,
                    'createdBy': this.userData.uid,
                    'projects': [this.siteProject.uid],
                    'plus1k': false
                  })
                  .catch(e => console.log('Error add site cache: ', e))
                  
                  // add site to project
                  db.collection('Projects').doc(this.siteProject.uid).collection('sites').doc(docRefSite.id).set({
                    'SiteID': docRefSite.id,
                    'WebsiteURL': URLdomain,
                    'homepage': urlBodyx,
                    'name': this.siteName,
                    'n': this.siteName ? this.siteName.toLowerCase() : '',
                    thumbnail: existThumbnail,
                    'totalErrors': 0,
                    'plan': '',
                    'paidRecurrence': '',
                    'claimed': false,
                    'owner': '',
                    'status': 'active',
                    // projectId: this.siteProject.uid,
                  })
                  .catch(error => {
                    console.error(error)
                  })

                  setTimeout(() => {
                    localStorage.setItem('reloadSearchSites', true)

                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Site created successfully`,
                        icon: 'CoffeeIcon',
                        variant: 'success'
                      },
                    })
                    // this.$router.push({ name: 'project-sites', params: {id: this.siteProject.uid, name: this.siteProject.title, own: 'me'} })
                    this.$router.push({
                      name: 'view-errors',
                      params: {
                        id: docRefSite.id, imgSite: existThumbnail, name: this.siteName ? this.siteName.toLowerCase() : '', url: URLdomain, project: this.siteProject.uid, own: 'me'
                      },
                    })
                  }, 1500)

                  db.collection('Projects').doc(this.siteProject.uid).update({
                    numberSites: firebase.firestore.FieldValue.increment(1),
                  })

                  db.collection('Users').doc(this.userData.uid).collection('projects').doc(this.siteProject.uid).update({
                    numberSites: firebase.firestore.FieldValue.increment(1),
                  })

                  // update data teamMembers
                })
              }
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `You have already created this site`,
                  icon: 'CoffeeIcon',
                  variant: 'warning'
                },
              })
              // setTimeout(() => {
              this.processingData = false
              // }, 1500)
            }
          })
        })
      } else {
        var urlBody = httpsString + URLdomain + URLpathname
        var urlBodyx = httpsString + URLdomain + URLpathname
        var URLdomainWithPath = URLdomain + URLpathname
        var URLwww = ''
        var URLwithoutWWW = ''
        var urlPrefix = 'www'
        if(URLdomain.includes('www.')) {
          urlPrefix = 'www'
          URLwww = URLdomain
          URLwithoutWWW = URLdomain.slice(4)
        } else {
          urlPrefix = 'non-www'
          URLwww = 'www.'+URLdomain
          URLwithoutWWW = URLdomain
        }

        // QUERY EXIST SITE
        db.collection('Sites').where('WebsiteURL', 'in', [URLdomainWithPath]).get()
        .then((querySiteThumb) => {
          var existThumbnail = ''
          var idSite =  ''
          if(querySiteThumb.size) {
            querySiteThumb.forEach(itemSite => {
              idSite = itemSite.id
              if(itemSite.data().thumbnail) {
                existThumbnail = itemSite.data().thumbnail
              }
            })
          }

          db.collection('Sites').where('WebsiteURL', 'in', [URLdomainWithPath]).where('createdBy', '==', this.userData.uid).get()
          .then((querySite) => {
            if(!querySite.size) {
              if(querySiteThumb.size) {
                db.collection('Sites').doc(idSite).update({
                  teamMembers: firebase.firestore.FieldValue.arrayUnion(this.userData.uid),
                  projects: firebase.firestore.FieldValue.arrayUnion(this.siteProject.uid)
                })
                .catch(e => console.log('Error update ref site: ', e))

                db.collection('Projects').doc(this.siteProject.uid).collection('sites').doc(idSite).set({
                  'SiteID': idSite,
                  'WebsiteURL': URLdomainWithPath,
                  'homepage': urlBodyx,
                  'name': this.siteName,
                  'n': this.siteName ? this.siteName.toLowerCase() : '',
                  thumbnail: existThumbnail,
                  'totalErrors': 0,
                  'plan': '',
                  'paidRecurrence': '',
                  'claimed': false,
                  'owner': '',
                  'status': 'active',
                  // projectId: this.siteProject.uid,
                })
                .catch(error => {
                  console.error(error)
                })

                this.crawlSite(idSite)

                if(idSite) {
                  var body = {
                    url: '',
                    type: 'full',
                    forceNotFound: 'yes',
                    pageSize: 999999999,
                    id: 'seo-shops-full-' + Date.now(),
                    takeScreenshots: 'no',
                    source: 'seo-cloud-full-' + Date.now(),
                    triggeredBy: 'test triggered',
                    screenshotType: 'test',
                    screenshotComment: '',
                    stringSearch: ["PHP ERROR",'Lorem','Ipsum'],
                    ignoreIndexNoFollow: "yes"
                  }
                  var getUrl = new URL(this.siteURL);
                  var hostUrl = getUrl.host;
                  var httpsString = getUrl.protocol + '//';
                  var urlConcat = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                  var urlBody = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                  var ultimoCaracterUrl = urlConcat.charAt(urlConcat.length - 1);
                  if(ultimoCaracterUrl != "/") {
                    urlConcat += "/";
                  }

                  body.id = 'seo-shops-full-' + Date.now()
                  body.source = 'seo-shops-full-' + Date.now()
                  body.url = getUrl.protocol + "//" + hostUrl

                  if(getUrl.pathname === "/") {
                    body.takeScreenshots = 'no'
                    body.screenshotComment = ""
                  }

                  db.collection('Sites').doc(idSite).get()
                  .then(eleSite => {
                    // exist site
                      var countSites = 0
                      var pageSizes = eleSite.data().pageSizes && eleSite.data().pageSizes.length ? eleSite.data().pageSizes : []
                      var pagesSite = eleSite.data().pages && eleSite.data().pages.length ? eleSite.data().pages : []
                      var filterInternals = pagesSite.filter(ele => ele.isSitemap === true)
                      var filterNoInternals = pagesSite.filter(ele => ele.isSitemap === false)
                      var filterProPages = filterNoInternals.filter(ele => ele.pro === true)
                      var filterNoProPages = filterNoInternals.filter(ele => ele.pro === false)
                      var filterInlinks = filterNoProPages

                      var condicionParaOrdenar = (pageA, pageB) => {
                        return pageB.inlinks.length - pageA.inlinks.length;
                      }
                      filterInlinks.sort(condicionParaOrdenar)

                      var arrayFoundPages = []
                      var arrayProPages = []
                      filterProPages.forEach(ele => {
                        var getUrl = new URL(ele.url)
                        if(getUrl.pathname !== '/') {
                          arrayProPages.push(ele.url)
                        }

                        arrayFoundPages.unshift(ele.url)
                      })
                      
                        db.collection('Crawls').where('WebsiteURL', '==', hostUrl).where('status', '==', 'running').where('crawltype', '==', 'full').get()
                        .then(queryCrawl => {
                          // only 1 loop
                          if(countSites === 0 && !queryCrawl.size) {
                            countSites += 1
                            var stringsArray = eleSite.data().stringSearch ? eleSite.data().stringSearch : []
                            var suspiciousTextArray = eleSite.data().suspiciousText ? eleSite.data().suspiciousText : []
                            var arrayStringsSuspicious = []
                            stringsArray.forEach(ele => {
                              arrayStringsSuspicious.push(ele)
                            })

                            suspiciousTextArray.forEach(ele => {
                              arrayStringsSuspicious.push(ele)
                            })

                            var getConfigCrawl = localStorage.getItem('configCrawl')
                            var limitCrawl = 100
                            var configCrawlType = 'JS FREE'
                            var configCrawlPages = 'FREE'
                            var setCrawl = false
                            if(getConfigCrawl) {
                              setCrawl = true
                              var parseConfig = JSON.parse(getConfigCrawl)
                              var logicTime = moment(parseConfig.date)
                              var actualTime = moment(new Date())
                              var diffSeconds = actualTime.diff(logicTime, 'seconds');
                              if(diffSeconds < 600) {
                                limitCrawl = parseConfig.pages
                                configCrawlType = parseConfig.crawl
                                configCrawlPages = parseConfig.pages === 25 ? 'GUESS/FREE' : parseConfig.pages === 100 ? 'FREE' : parseConfig.pages === 600 ? 'LICENSE' : 'FREE'
                              }
                              var getConfigCrawl = localStorage.removeItem('configCrawl')
                            }
                            
                            var limitCrawlSetSite = eleSite.data().limitCrawl ? eleSite.data().limitCrawl : 0
                            if(limitCrawlSetSite) {
                              if(configCrawlPages === 'GUESS/FREE' && limitCrawlSetSite < 25) {
                                limitCrawl = limitCrawlSetSite
                              } else if(configCrawlPages === 'FREE' && limitCrawlSetSite < 100) {
                                limitCrawl = limitCrawlSetSite
                              } else if(configCrawlPages === 'LICENSE') {
                                limitCrawl = limitCrawlSetSite
                              }
                            }

                            // add Crawl
                            db.collection('Crawls').add({
                              'IDcrawl': body.id,
                              'dateStart': Date.now(),
                              'dateStartFormat': new Date(),
                              'dateEnd': null,
                              'crawlDuration': null,
                              'status': 'running',
                              'crawltype': 'full',
                              'ignoreindexnofollow': 'yes',
                              'Requests': 1,
                              'SiteID': eleSite.id,
                              'WebsiteURL': hostUrl,
                              'blockedByRobots': false,
                              'logicRequests': 1,
                              'proPages': eleSite.data().proPages.length ? eleSite.data().proPages : [urlBody],
                              'totalErrors': 0,
                              'limitCrawl': limitCrawl,
                              'stringSearch': arrayStringsSuspicious,
                              'founds': arrayFoundPages,
                              'requests': [],
                              'pages': [],
                              'inlinks': [],
                              'external': [],
                              'pageSizes': pageSizes,
                              'updated': Date.now(),
                              'plus1k': false,
                              'errorsPages': [],
                              'source': 'seo',
                              'configCrawlType': 'JS FREE',
                              'configCrawlPages': 'FREE',
                              'errorsTypePage': [],
                              'timeoutPages': 0,
                              'errorsCategory': {}
                            })
                            .then((docRefCrawl) => {
                              var URLsfound = [
                                {
                                  'id': null,
                                  'title': null,
                                  'runPosition': 1,
                                  'lastRequest': null,
                                  'pro': true,
                                  'inlinks': [],
                                  'status': null,
                                  'redirectTo': null,
                                  'blockedByRobots': false,
                                  // 'totalErrors': 0,

                                  'crawleable': true,
                                  'crawled': false,
                                  'crawlerInvoke': true,
                                  'url': urlBody
                                  
                                },
                                {
                                  'id': null,
                                  'title': null,
                                  'runPosition': 2,
                                  'lastRequest': null,
                                  'pro': false,
                                  'inlinks': [],
                                  'status': null,
                                  'redirectTo': null,
                                  'blockedByRobots': false,

                                  'crawleable': true,
                                  'crawled': false,
                                  'crawlerInvoke': true,
                                  'url': urlConcat + "sitemap.xml"
                                }
                              ]

                              db.collection('Sites').doc(eleSite.id).update({
                                mostRecentCrawl: docRefCrawl.id,
                                mostRecentCrawlDate: Date.now(),
                                n_log_crawls: eleSite.data().n_log_crawls ? firebase.firestore.FieldValue.increment(1) : 1,
                                errorsTypePage: [],
                                totalErrors: 0,
                                errorsPages: [],
                                externalLinks: [],
                                errorsCategory: {
                                  title: 0,
                                  alts: 0,
                                  desc: 0,
                                  h1: 0,
                                  h2: 0,
                                  wCount: 0,
                                  missImgs: 0,
                                  dLink: 0,
                                  gPadlock: 0,
                                  urlCan: 0,
                                  strings: 0,
                                  Imgs: 0,
                                  externalLinks: 0,
                                  titleCache: 0,
                                  altsCache: 0,
                                  descCache: 0,
                                  h1Cache: 0,
                                  h2Cache: 0,
                                  wCountCache: 0,
                                  missImgsCache: 0,
                                  dLinkCache: 0,
                                  gPadlockCache: 0,
                                  urlCanCache: 0,
                                  stringsCache: 0,
                                  ImgsCache: 0,
                                  suspCache: 0
                                }
                              })

                              // add pages crawl
                              URLsfound.forEach(eleC => {
                                db.collection('Crawls').doc(docRefCrawl.id).update({
                                  requests: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                                })

                                db.collection('Crawls').doc(docRefCrawl.id).update({
                                  founds: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                                })

                                db.collection('Crawls').doc(docRefCrawl.id).update({
                                  pages: firebase.firestore.FieldValue.arrayUnion({
                                    id: '',
                                    title: '',
                                    runPosition: null,
                                    lastRequest: null,
                                    pro: false,
                                    inlinks: [],
                                    status: null,
                                    redirectTo: '',
                                    blockedByRobots: false,
                                    crawled: true,
                                    crawleable: true,
                                    url: eleC.url,
                                    isSitemap: false,
                                    hasRun: false,
                                    active: false,
                                    size: 999999999,
                                    tries: 1,
                                    tryTimestamp: Date.now()
                                  })
                                })
                                .catch(e => console.log('Error update master page: ', e.message))
                              })

                              // LOGIC RUN INVOKE CRAWL
                              var protocolSite = ''
                              var homepageSite = ''
                              if(eleSite.data().stringSearch.length > 0) {
                                body.stringSearch = eleSite.data().stringSearch
                              }
                              protocolSite = eleSite.data().prot ? eleSite.data().prot : getUrl.protocol
                              body.url = protocolSite + "//" + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                              homepageSite = eleSite.data().homepage ? eleSite.data().homepage : ''
                              body.takeScreenshots = 'no'
                              body.triggeredBy = eleSite.id
                              body.source = docRefCrawl.id
                              body.type = 'only-urls'
                              body.useFastCrawler = 'no'
                              body.typePage = 'homeXML'
                              body.parseAsTextContent = 'yes'
                              body.screenshotComment = ''

                              var urlOrigin = body.url
                              
                              // var config = {
                              //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                              //   "method": "POST",
                              //   "timeout": 0,
                              //   "headers": {
                              //     "Content-Type": "application/json",
                              //     "Accept": "application/json"
                              //   },
                              //   "data": JSON.stringify(body),
                              // };

                              var config = {
                                method: 'POST',
                                url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl',
                                maxBodyLength: Infinity,
                                timeout: 0,
                                headers: {
                                  'Content-Type': 'application/json',
                                  'Accept': 'application/json'
                                },
                                data: JSON.stringify(body)
                              };
            
                              this.axios(config)
                              .then((response) => {
                                body.url = urlOrigin
                                body.takeScreenshots = 'no'
                                body.type = 'full'
                                body.typePage = 'normal'
                                // console.log(response.data)
                                console.log('invoke home')
                              })
                              .catch(e => {
                                console.log(e)
                              })
                              
                              // run crawl three initials xml
                              // body.typePage = 'normal'
                              var atomicBody = body
                              var countInvocked = 0
                              // let arraySites = [atomicBody.url + "/site-map.xml", atomicBody.url + "/sitemap.xml", atomicBody.url + "/page-sitemap.xml"]
                              let arraySites = [atomicBody.url + (atomicBody.url.endsWith('/') ? 'sitemap.xml' : '/sitemap.xml')]
                              // let arraySites = []
                              arraySites.forEach(res => {
                                var objXMLCrawl = {
                                  forceNotFound: 'yes',
                                  id: body.id,
                                  ignoreIndexNoFollow: 'yes',
                                  pageSize: 999999999,
                                  screenshotComment: '',
                                  screenshotType: 'test',
                                  source: body.source,
                                  stringSearch: body.stringSearch,
                                  takeScreenshots: 'no',
                                  triggeredBy: body.triggeredBy,
                                  type: 'only-urls',
                                  url: res,
                                  useFastCrawler: 'no',
                                  typePage: 'normal',
                                  parseAsTextContent: 'no'
                                }
                                atomicBody.url = res
                                atomicBody.type = 'only-urls'
                                atomicBody.takeScreenshots = 'no'
                                atomicBody.typePage = 'normal'
                                
                                // var config = {
                                //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                                //   "method": "POST",
                                //   "timeout": 0,
                                //   "headers": {
                                //     "Content-Type": "application/json",
                                //     "Accept": "application/json"
                                //   },
                                //   "data": JSON.stringify(atomicBody),
                                // };
                                body.url = urlOrigin
                                body.type = 'full'

                                var config = {
                                  method: 'POST',
                                  url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl',
                                  maxBodyLength: Infinity,
                                  timeout: 0,
                                  headers: {
                                    'Content-Type': 'application/json',
                                    'Accept': 'application/json'
                                  },
                                  data: JSON.stringify(objXMLCrawl)
                                };
              
                                this.axios(config)
                                .then((response) => {
                                  countInvocked += 1
                                  // body.url = urlOrigin
                                  // if(countInvocked === 3) {
                                    // console.log(response.data)
                                    console.log('invoke init xml')
                                    body.stringSearch = ["PHP ERROR",'Lorem','Ipsum']
                                    body.takeScreenshots = 'no'
                                    body.screenshotComment = ""
                                    body.url = urlOrigin
                                  // }
                                })
                                .catch(e => {
                                  console.log(e)
                                })
                              })

                              // this.itemsPagesOverview = []
                              db.collection('Sites').doc(eleSite.id).update({
                                mostRecentCrawlStatus: 'running',
                                mostRecentCrawl: docRefCrawl.id,
                                mostRecentCrawlId: body.id,
                                pages: [],
                                // pageSizes: [],
                                externalLinks: []
                              })
                              .catch(e => console.log('Error update site: ', e))

                              db.collection('Sites_cache').doc(eleSite.id).update({
                                mostRecentCrawlStatus: 'running',
                                mostRecentCrawl: docRefCrawl.id,
                                mostRecentCrawlId: body.id,
                                inlinks: [],
                                pages: []
                              })
                              .catch(e => console.log('Error update site: ', e))

                              URLsfound.forEach(eleC => {
                                var objCache = {}
                                objCache.id = null
                                objCache.title = null
                                objCache.url = eleC.url
                                objCache.inlinks = null
                                objCache.inlinksNumber = null
                                objCache.pro = false
                                objCache.status = null
                                objCache.redirectTo = null
                                objCache.blockedByRobots = null
                                objCache.totalErrors = 0
                                objCache.errorByType = {}
                                objCache.isSitemap = eleC.url.includes('.xml') ? true : false
                                db.collection('Sites_cache').doc(eleSite.id).update({
                                  pages: firebase.firestore.FieldValue.arrayUnion(objCache)
                                })

                                var objPageCollection = {
                                  id: null,
                                  title: null,
                                  url: eleC.url,
                                  inlinks: [],
                                  inlinksNumber: 0,
                                  pro: false,
                                  // status: itemPage.status,
                                  status: null,
                                  redirectTo: null,
                                  blockedByRobots: null,
                                  totalErrors: 0,
                                  errorByType: {},
                                  active: false,
                                  crawleable: true,
                                  size: null,
                                  isSitemap: eleC.url.includes('.xml') ? true : false
                                }

                                db.collection('Sites').doc(eleSite.id).collection('pages').where('url', '==', eleC.url).limit(1).get()
                                .then(docSite => {
                                  if(docSite.size) {
                                    var counterLoop = 0
                                    docSite.forEach(ele => {
                                      if(counterLoop === 0) {
                                        db.collection('Sites').doc(eleSite.id).collection('pages').doc(ele.id).update(objPageCollection)
                                      }
                                    })
                                  } else {
                                    db.collection('Sites').doc(eleSite.id).collection('pages').add(objPageCollection)
                                    .then((docRefSite) => {
                                        db.collection('Sites').doc(eleSite.id).collection('pages').doc(docRefSite.id).update({
                                          id: docRefSite.id
                                        })
                                    })
                                  }
                                })
                              })
                            })
                            .catch(e => console.log('Error add crawl site: ', e))
                          } else if(queryCrawl.size) {
                            console.log('Crawl running already!')
                          }
                        })
                        .catch(e => console.log(e.message))
                    // })
                  })
                  .catch(e => {
                    // this.execCrawl = false
                    console.log('Error query sites host: ', e)
                  })
                }

                db.collection('Projects').doc(this.siteProject.uid).update({
                  numberSites: firebase.firestore.FieldValue.increment(1),
                })

                db.collection('Users').doc(this.userData.uid).collection('projects').doc(this.siteProject.uid).update({
                  numberSites: firebase.firestore.FieldValue.increment(1),
                })

                setTimeout(() => {
                  localStorage.setItem('reloadSearchSites', true)

                  db.collection('Users').doc(this.userData.uid).get()
                  .then(docUser => {
                    var objnewSiteMail = {
                      nameUser: docUser.data().nombre ? docUser.data().nombre : docUser.data().email,
                      email: docUser.data().email,
                      existUser: true,
                      urlSite: URLdomainWithPath,
                      idsite: idSite,
                      idproject: this.siteProject.uid
                    }

                    // update seocloud-dcdfb
                    var confignewSiteMail = {
                      method: 'POST',
                      url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/newSiteMail',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      data: JSON.stringify(objnewSiteMail),
                    }

                    this.axios(confignewSiteMail)
                    .then(() => {
                      console.log('email sended')
                    })
                    .catch(error => {
                      console.log(error)
                    })
                  })
                  
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Site created successfully`,
                      icon: 'CoffeeIcon',
                      variant: 'success'
                    },
                  })
                  this.$router.push({
                    name: 'view-errors',
                    params: {
                      id: idSite, imgSite: existThumbnail, name: this.siteName ? this.siteName.toLowerCase() : '', url: URLdomainWithPath, project: this.siteProject.uid, own: 'me'
                    },
                  })
                }, 1500)
              } else {
                db.collection('Sites').add({
                  'SiteID': '',
                  'name': this.siteName,
                  'n': this.siteName ? this.siteName.toLowerCase() : '',
                  'WebsiteURL': URLdomainWithPath,
                  'homepage': urlBodyx,
                  thumbnail: existThumbnail,
                  'Blockedbyrobot': 'no',
                  // 'pagesFound': '',
                  'proPages': [],
                  'asignatedProPages': false,
                  'mostRecentCrawl': '',
                  'mostRecentCrawlStatus': '',
                  'totalErrors': 0,
                  'errorsCategory': {},
                  'claimed': false,
                  'owner': '',
                  'plan': '',
                  'paidRecurrence': '',
                  'totalPages': 0,
                  'createdBy': this.userData.uid,
                  'created': Date.now(),
                  'registrationDay': moment().format('dddd'),

                  'owners': [],
                  'limitCrawl': 600,
                  'prot': URLprotocol,
                  'domainPrefix': urlPrefix,
                  'stringSearch': ['PHP ERROR','Lorem','Ipsum','Consectetur'],
                  'totalSuccessCrawl': 0,
                  'externalLinks': [],
                  'pageSizes': [],
                  'projects': [this.siteProject.uid],
                  'plus1k': false,
                  'teamMembers': [],
                  'errorsPages': [],
                  'suspiciousText': ['viagra'],
                  'n_guess_crawls': 0,
                  'n_log_crawls': 0
                  
                  // 'FirstFound': Date.now(),
                  // 'sizeValidate': true,
                  // 'Mostrecentfullcrawl': body.id,
                  // 'Mostrecentfullcrawldatestart': Date.now(),
                  // 'Mostrecentfullcrawldateend': null,
                  // 'Mostrecentfullcrawlsuccessid': null,
                  // 'Mostrecentfullcrawlsuccess': false,
                  // 'Mostrecentcrawltype': bodySite.type ? bodySite.type : 'full',
                  // 'Mostrecentsinglecrawl': null,
                  // 'MostrecentsinglecrawlURL': null,
                  // 'Mostrecentsinglecrawldate': null
                })
                .then(docRefSite => {
                  this.crawlSite(docRefSite.id)
                  
                  // update Sites id
                  db.collection('Sites').doc(docRefSite.id).update({
                    SiteID: docRefSite.id,
                    teamMembers: firebase.firestore.FieldValue.arrayUnion(this.userData.uid)
                  })
                  .catch(e => console.log('Error update ref site: ', e))

                  db.collection('Users').doc(this.userData.uid).get()
                  .then(docUser => {
                    var objnewSiteMail = {
                      nameUser: docUser.data().nombre ? docUser.data().nombre : docUser.data().email,
                      email: docUser.data().email,
                      existUser: true,
                      urlSite: URLdomainWithPath,
                      idsite: docRefSite.id,
                      idproject: this.siteProject.uid
                    }

                    // update seocloud-dcdfb
                    var confignewSiteMail = {
                      method: 'POST',
                      url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/newSiteMail',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      data: JSON.stringify(objnewSiteMail),
                    }

                    this.axios(confignewSiteMail)
                    .then(() => {
                      console.log('email sended')
                    })
                    .catch(error => {
                      console.log(error)
                    })
                  })
                  
                  if(docRefSite.id) {
                    var body = {
                      url: '',
                      type: 'full',
                      forceNotFound: 'yes',
                      pageSize: 999999999,
                      id: 'seo-shops-full-' + Date.now(),
                      takeScreenshots: 'no',
                      source: 'seo-cloud-full-' + Date.now(),
                      triggeredBy: 'test triggered',
                      screenshotType: 'test',
                      screenshotComment: '',
                      stringSearch: ["PHP ERROR",'Lorem','Ipsum'],
                      ignoreIndexNoFollow: "yes"
                    }
                    var getUrl = new URL(this.siteURL);
                    var hostUrl = getUrl.host;
                    var httpsString = getUrl.protocol + '//';
                    var urlConcat = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                    var urlBody = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                    var ultimoCaracterUrl = urlConcat.charAt(urlConcat.length - 1);
                    if(ultimoCaracterUrl != "/") {
                      urlConcat += "/";
                    }

                    body.id = 'seo-shops-full-' + Date.now()
                    body.source = 'seo-shops-full-' + Date.now()
                    body.url = getUrl.protocol + "//" + hostUrl

                    if(getUrl.pathname === "/") {
                      body.takeScreenshots = 'no'
                      body.screenshotComment = ""
                    }

                    db.collection('Sites').doc(docRefSite.id).get()
                    .then(eleSite => {
                      // exist site
                      // db.collection('Crawls').where('status', '==', 'running').get()
                      // .then(queryCrawlRunning => {
                        // if(querySites.) {
                        var countSites = 0
                        var pageSizes = eleSite.data().pageSizes && eleSite.data().pageSizes.length ? eleSite.data().pageSizes : []
                        var pagesSite = eleSite.data().pages && eleSite.data().pages.length ? eleSite.data().pages : []
                        var filterInternals = pagesSite.filter(ele => ele.isSitemap === true)
                        var filterNoInternals = pagesSite.filter(ele => ele.isSitemap === false)
                        var filterProPages = filterNoInternals.filter(ele => ele.pro === true)
                        var filterNoProPages = filterNoInternals.filter(ele => ele.pro === false)
                        var filterInlinks = filterNoProPages

                        var condicionParaOrdenar = (pageA, pageB) => {
                          return pageB.inlinks.length - pageA.inlinks.length;
                        }
                        filterInlinks.sort(condicionParaOrdenar)

                        var arrayFoundPages = []
                        var arrayProPages = []
                        filterProPages.forEach(ele => {
                          var getUrl = new URL(ele.url)
                          if(getUrl.pathname !== '/') {
                            arrayProPages.push(ele.url)
                          }

                          arrayFoundPages.unshift(ele.url)
                        })

                        // filterInternals.forEach(ele => {
                        //   var filterPage = arrayFoundPages.filter(item => item === ele.url)
                        //   if(!filterPage.length) {
                        //     arrayFoundPages.push(ele.url)
                        //   }
                        // })
                        
                          db.collection('Crawls').where('WebsiteURL', '==', hostUrl).where('status', '==', 'running').where('crawltype', '==', 'full').get()
                          .then(queryCrawl => {
                            // only 1 loop
                            if(countSites === 0 && !queryCrawl.size) {
                              countSites += 1
                              var stringsArray = eleSite.data().stringSearch ? eleSite.data().stringSearch : []
                              var suspiciousTextArray = eleSite.data().suspiciousText ? eleSite.data().suspiciousText : []
                              var arrayStringsSuspicious = []
                              stringsArray.forEach(ele => {
                                arrayStringsSuspicious.push(ele)
                              })

                              suspiciousTextArray.forEach(ele => {
                                arrayStringsSuspicious.push(ele)
                              })

                              var getConfigCrawl = localStorage.getItem('configCrawl')
                              var limitCrawl = 100
                              var configCrawlType = 'JS FREE'
                              var configCrawlPages = 'FREE'
                              var setCrawl = false
                              if(getConfigCrawl) {
                                setCrawl = true
                                var parseConfig = JSON.parse(getConfigCrawl)
                                var logicTime = moment(parseConfig.date)
                                var actualTime = moment(new Date())
                                var diffSeconds = actualTime.diff(logicTime, 'seconds');
                                if(diffSeconds < 600) {
                                  limitCrawl = parseConfig.pages
                                  configCrawlType = parseConfig.crawl
                                  configCrawlPages = parseConfig.pages === 25 ? 'GUESS/FREE' : parseConfig.pages === 100 ? 'FREE' : parseConfig.pages === 600 ? 'LICENSE' : 'FREE'
                                }
                                var getConfigCrawl = localStorage.removeItem('configCrawl')
                              }
                              
                              var limitCrawlSetSite = eleSite.data().limitCrawl ? eleSite.data().limitCrawl : 0
                              if(limitCrawlSetSite) {
                                if(configCrawlPages === 'GUESS/FREE' && limitCrawlSetSite < 25) {
                                  limitCrawl = limitCrawlSetSite
                                } else if(configCrawlPages === 'FREE' && limitCrawlSetSite < 100) {
                                  limitCrawl = limitCrawlSetSite
                                } else if(configCrawlPages === 'LICENSE') {
                                  limitCrawl = limitCrawlSetSite
                                }
                              }

                              // add Crawl
                              db.collection('Crawls').add({
                                'IDcrawl': body.id,
                                'dateStart': Date.now(),
                                'dateStartFormat': new Date(),
                                'dateEnd': null,
                                'crawlDuration': null,
                                'status': 'running',
                                'crawltype': 'full',
                                'ignoreindexnofollow': 'yes',
                                'Requests': 1,
                                'SiteID': eleSite.id,
                                'WebsiteURL': hostUrl,
                                'blockedByRobots': false,
                                'logicRequests': 1,
                                'proPages': eleSite.data().proPages.length ? eleSite.data().proPages : [urlBody],
                                'totalErrors': 0,
                                'limitCrawl': limitCrawl,
                                'stringSearch': arrayStringsSuspicious,
                                'founds': arrayFoundPages,
                                'requests': [],
                                'pages': [],
                                'inlinks': [],
                                'external': [],
                                'pageSizes': pageSizes,
                                'updated': Date.now(),
                                'plus1k': false,
                                'errorsPages': [],
                                'source': 'seo',
                                'configCrawlType': 'JS FREE',
                                'configCrawlPages': 'FREE',
                                'errorsTypePage': [],
                                'timeoutPages': 0,
                                'errorsCategory': {}
                              })
                              .then((docRefCrawl) => {
                                var URLsfound = [
                                  {
                                    'id': null,
                                    'title': null,
                                    'runPosition': 1,
                                    'lastRequest': null,
                                    'pro': true,
                                    'inlinks': [],
                                    'status': null,
                                    'redirectTo': null,
                                    'blockedByRobots': false,
                                    // 'totalErrors': 0,

                                    'crawleable': true,
                                    'crawled': false,
                                    'crawlerInvoke': true,
                                    'url': urlBody
                                    
                                  },
                                  // {
                                  //   'id': null,
                                  //   'title': null,
                                  //   'runPosition': 2,
                                  //   'lastRequest': null,
                                  //   'pro': false,
                                  //   'inlinks': [],
                                  //   'status': null,
                                  //   'redirectTo': null,
                                  //   'blockedByRobots': false,

                                  //   'crawleable': true,
                                  //   'crawled': false,
                                  //   'crawlerInvoke': true,
                                  //   'url': urlConcat + "site-map.xml"
                                  // },
                                  {
                                    'id': null,
                                    'title': null,
                                    'runPosition': 2,
                                    'lastRequest': null,
                                    'pro': false,
                                    'inlinks': [],
                                    'status': null,
                                    'redirectTo': null,
                                    'blockedByRobots': false,

                                    'crawleable': true,
                                    'crawled': false,
                                    'crawlerInvoke': true,
                                    'url': urlConcat + "sitemap.xml"
                                  },
                                  // {
                                  //   'id': null,
                                  //   'title': null,
                                  //   'runPosition': 4,
                                  //   'lastRequest': null,
                                  //   'pro': false,
                                  //   'inlinks': [],
                                  //   'status': null,
                                  //   'redirectTo': null,
                                  //   'blockedByRobots': false,

                                  //   'crawleable': true,
                                  //   'crawled': false,
                                  //   'crawlerInvoke': true,
                                  //   'url': urlConcat + "page-sitemap.xml"
                                  // }
                                ]

                                db.collection('Sites').doc(eleSite.id).update({
                                  mostRecentCrawl: docRefCrawl.id,
                                  mostRecentCrawlDate: Date.now(),
                                  n_log_crawls: eleSite.data().n_log_crawls ? firebase.firestore.FieldValue.increment(1) : 1,
                                  errorsTypePage: [],
                                  totalErrors: 0,
                                  errorsPages: [],
                                  externalLinks: [],
                                  errorsCategory: {
                                    title: 0,
                                    alts: 0,
                                    desc: 0,
                                    h1: 0,
                                    h2: 0,
                                    wCount: 0,
                                    missImgs: 0,
                                    dLink: 0,
                                    gPadlock: 0,
                                    urlCan: 0,
                                    strings: 0,
                                    Imgs: 0,
                                    externalLinks: 0,
                                    titleCache: 0,
                                    altsCache: 0,
                                    descCache: 0,
                                    h1Cache: 0,
                                    h2Cache: 0,
                                    wCountCache: 0,
                                    missImgsCache: 0,
                                    dLinkCache: 0,
                                    gPadlockCache: 0,
                                    urlCanCache: 0,
                                    stringsCache: 0,
                                    ImgsCache: 0,
                                    suspCache: 0
                                  }
                                })

                                // add pages crawl
                                URLsfound.forEach(eleC => {
                                  db.collection('Crawls').doc(docRefCrawl.id).update({
                                    requests: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                                  })

                                  db.collection('Crawls').doc(docRefCrawl.id).update({
                                    founds: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                                  })

                                  db.collection('Crawls').doc(docRefCrawl.id).update({
                                    pages: firebase.firestore.FieldValue.arrayUnion({
                                      id: '',
                                      title: '',
                                      runPosition: null,
                                      lastRequest: null,
                                      pro: false,
                                      inlinks: [],
                                      status: null,
                                      redirectTo: '',
                                      blockedByRobots: false,
                                      crawled: true,
                                      crawleable: true,
                                      url: eleC.url,
                                      isSitemap: false,
                                      hasRun: false,
                                      active: false,
                                      size: 999999999,
                                      tries: 1,
                                      tryTimestamp: Date.now()
                                    })
                                  })
                                  .catch(e => console.log('Error update master page: ', e.message))
                                })

                                // LOGIC RUN INVOKE CRAWL
                                var protocolSite = ''
                                var homepageSite = ''
                                if(eleSite.data().stringSearch.length > 0) {
                                  body.stringSearch = eleSite.data().stringSearch
                                }
                                protocolSite = eleSite.data().prot ? eleSite.data().prot : getUrl.protocol
                                body.url = protocolSite + "//" + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                                homepageSite = eleSite.data().homepage ? eleSite.data().homepage : ''
                                body.takeScreenshots = 'no'
                                body.triggeredBy = eleSite.id
                                body.source = docRefCrawl.id
                                body.type = 'only-urls'
                                body.useFastCrawler = 'no'
                                body.typePage = 'homeXML'
                                body.parseAsTextContent = 'yes'
                                body.screenshotComment = ''

                                var urlOrigin = body.url
                                
                                // var config = {
                                //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                                //   "method": "POST",
                                //   "timeout": 0,
                                //   "headers": {
                                //     "Content-Type": "application/json",
                                //     "Accept": "application/json"
                                //   },
                                //   "data": JSON.stringify(body),
                                // };

                                var config = {
                                  method: 'POST',
                                  url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl',
                                  maxBodyLength: Infinity,
                                  timeout: 0,
                                  headers: {
                                    'Content-Type': 'application/json',
                                    'Accept': 'application/json'
                                  },
                                  data: JSON.stringify(body)
                                };
              
                                this.axios(config)
                                .then((response) => {
                                  body.url = urlOrigin
                                  body.takeScreenshots = 'no'
                                  body.type = 'full'
                                  body.typePage = 'normal'
                                  // console.log(response.data)
                                  console.log('invoke home')
                                })
                                .catch(e => {
                                  console.log(e)
                                })
                                
                                // run crawl three initials xml
                                var atomicBody = body
                                var countInvocked = 0
                                // let arraySites = [atomicBody.url + "/site-map.xml", atomicBody.url + "/sitemap.xml", atomicBody.url + "/page-sitemap.xml"]
                                let arraySites = [atomicBody.url + (atomicBody.url.endsWith('/') ? 'sitemap.xml' : '/sitemap.xml')]
                                // let arraySites = []
                                arraySites.forEach(res => {
                                  var objXMLCrawl = {
                                    forceNotFound: 'yes',
                                    id: body.id,
                                    ignoreIndexNoFollow: 'yes',
                                    pageSize: 999999999,
                                    screenshotComment: '',
                                    screenshotType: 'test',
                                    source: body.source,
                                    stringSearch: body.stringSearch,
                                    takeScreenshots: 'no',
                                    triggeredBy: body.triggeredBy,
                                    type: 'only-urls',
                                    url: res,
                                    useFastCrawler: 'no',
                                    typePage: 'normal',
                                    parseAsTextContent: 'no'
                                  }
                                  atomicBody.url = res
                                  atomicBody.type = 'only-urls'
                                  atomicBody.takeScreenshots = 'no'
                                  atomicBody.typePage = 'normal'
                                  
                                  // var config = {
                                  //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                                  //   "method": "POST",
                                  //   "timeout": 0,
                                  //   "headers": {
                                  //     "Content-Type": "application/json",
                                  //     "Accept": "application/json"
                                  //   },
                                  //   "data": JSON.stringify(atomicBody),
                                  // };
                                  body.url = urlOrigin
                                  body.type = 'full'

                                  var config = {
                                    method: 'POST',
                                    url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl',
                                    maxBodyLength: Infinity,
                                    timeout: 0,
                                    headers: {
                                      'Content-Type': 'application/json',
                                      'Accept': 'application/json'
                                    },
                                    data: JSON.stringify(objXMLCrawl)
                                  };
                
                                  this.axios(config)
                                  .then((response) => {
                                    countInvocked += 1
                                    // body.url = urlOrigin
                                    // if(countInvocked === 3) {
                                      // console.log(response.data)
                                      console.log('invoke init xml')
                                      body.stringSearch = ["PHP ERROR",'Lorem','Ipsum']
                                      body.takeScreenshots = 'no'
                                      body.screenshotComment = ""
                                      body.url = urlOrigin
                                    // }
                                  })
                                  .catch(e => {
                                    console.log(e)
                                  })
                                })

                                // this.itemsPagesOverview = []
                                db.collection('Sites').doc(eleSite.id).update({
                                  mostRecentCrawlStatus: 'running',
                                  mostRecentCrawl: docRefCrawl.id,
                                  mostRecentCrawlId: body.id,
                                  pages: [],
                                  // pageSizes: [],
                                  externalLinks: []
                                })
                                .catch(e => console.log('Error update site: ', e))

                                db.collection('Sites_cache').doc(eleSite.id).update({
                                  mostRecentCrawlStatus: 'running',
                                  mostRecentCrawl: docRefCrawl.id,
                                  mostRecentCrawlId: body.id,
                                  inlinks: [],
                                  pages: []
                                })
                                .catch(e => console.log('Error update site: ', e))

                                URLsfound.forEach(eleC => {
                                  var objCache = {}
                                  objCache.id = null
                                  objCache.title = null
                                  objCache.url = eleC.url
                                  objCache.inlinks = null
                                  objCache.inlinksNumber = null
                                  objCache.pro = false
                                  objCache.status = null
                                  objCache.redirectTo = null
                                  objCache.blockedByRobots = null
                                  objCache.totalErrors = 0
                                  objCache.errorByType = {}
                                  objCache.isSitemap = eleC.url.includes('.xml') ? true : false
                                  db.collection('Sites_cache').doc(eleSite.id).update({
                                    pages: firebase.firestore.FieldValue.arrayUnion(objCache)
                                  })

                                  var objPageCollection = {
                                    id: null,
                                    title: null,
                                    url: eleC.url,
                                    inlinks: [],
                                    inlinksNumber: 0,
                                    pro: false,
                                    // status: itemPage.status,
                                    status: null,
                                    redirectTo: null,
                                    blockedByRobots: null,
                                    totalErrors: 0,
                                    errorByType: {},
                                    active: false,
                                    crawleable: true,
                                    size: null,
                                    isSitemap: eleC.url.includes('.xml') ? true : false
                                  }

                                  db.collection('Sites').doc(eleSite.id).collection('pages').where('url', '==', eleC.url).limit(1).get()
                                  .then(docSite => {
                                    if(docSite.size) {
                                      var counterLoop = 0
                                      docSite.forEach(ele => {
                                        if(counterLoop === 0) {
                                          db.collection('Sites').doc(eleSite.id).collection('pages').doc(ele.id).update(objPageCollection)
                                        }
                                      })
                                    } else {
                                      db.collection('Sites').doc(eleSite.id).collection('pages').add(objPageCollection)
                                      .then((docRefSite) => {
                                          db.collection('Sites').doc(eleSite.id).collection('pages').doc(docRefSite.id).update({
                                            id: docRefSite.id
                                          })
                                      })
                                    }
                                  })
                                })
                              })
                              .catch(e => console.log('Error add crawl site: ', e))
                            } else if(queryCrawl.size) {
                              console.log('Crawl running already!')
                              // this.$toast({
                              //   component: ToastificationContent,
                              //   props: {
                              //     title: 'Crawl running already!',
                              //     icon: 'BellIcon',
                              //     variant: 'warning',
                              //   },
                              // })
                            }
                          })
                          .catch(e => console.log(e.message))
                      // })
                    })
                    .catch(e => {
                      // this.execCrawl = false
                      console.log('Error query sites host: ', e)
                    })
                  }

                  // var objPage = {}
                  // objPage.id = null
                  // objPage.title = null
                  // objPage.url = urlBody
                  // objPage.inlinks = []
                  // objPage.inlinksNumber = 0
                  // objPage.size = null
                  // objPage.pro = false
                  // objPage.status = null
                  // objPage.redirectTo = null
                  // objPage.blockedByRobots = null
                  // objPage.totalErrors = 0
                  // objPage.errorByType = {}
                  // objPage.active = null
                  // objPage.crawleable = true
                  // objPage.isSitemap = false
                  // objPage.lastRequest = null
                  // db.collection('Sites').doc(docRefSite.id).update({
                  //   pages: firebase.firestore.FieldValue.arrayUnion(objPage),
                  //   proPages: firebase.firestore.FieldValue.arrayUnion(urlBody)
                  // })

                  // var objCache = {}
                  // objCache.id = null
                  // objCache.title = null
                  // objCache.url = urlBody
                  // objCache.inlinks = null
                  // objCache.inlinksNumber = null
                  // objCache.pro = false
                  // objCache.status = null
                  // objCache.redirectTo = null
                  // objCache.blockedByRobots = null
                  // objCache.totalErrors = 0
                  // objCache.errorByType = {}
                  // objCache.isSitemap = false
                  // db.collection('Sites_cache').doc(docRefSite.id).update({
                  //   pages: firebase.firestore.FieldValue.arrayUnion(objCache),
                  //   proPages: firebase.firestore.FieldValue.arrayUnion(urlBody)
                  // })

                  db.collection('Sites_cache').doc(docRefSite.id).set({
                    'SiteID': docRefSite.id,
                    'name': this.siteName,
                    'n': this.siteName ? this.siteName.toLowerCase() : '',
                    'WebsiteURL': URLdomainWithPath,
                    'homepage': urlBodyx,
                    thumbnail: existThumbnail,
                    'Blockedbyrobot': 'no',
                    // 'pagesFound': '',
                    'proPages': [],
                    inlinks: [],
                    'asignatedProPages': false,
                    'mostRecentCrawl': '',
                    'mostRecentCrawlStatus': '',
                    'totalErrors': 0,
                    'errorsCategory': {},
                    'claimed': false,
                    'owner': '',
                    'plan': '',
                    'paidRecurrence': '',
                    'totalPages': 0,
                    'createdBy': this.userData.uid,
                    'projects': [this.siteProject.uid],
                    'plus1k': false
                  })
                  .catch(e => console.log('Error add site cache: ', e))
                  
                  // add site to project
                  db.collection('Projects').doc(this.siteProject.uid).collection('sites').doc(docRefSite.id).set({
                    'SiteID': docRefSite.id,
                    'WebsiteURL': URLdomainWithPath,
                    'homepage': urlBodyx,
                    'name': this.siteName,
                    'n': this.siteName ? this.siteName.toLowerCase() : '',
                    thumbnail: existThumbnail,
                    'totalErrors': 0,
                    'plan': '',
                    'paidRecurrence': '',
                    'claimed': false,
                    'owner': '',
                    'status': 'active',
                    // projectId: this.siteProject.uid,
                  })
                  .catch(error => {
                    console.error(error)
                  })

                  setTimeout(() => {
                    localStorage.setItem('reloadSearchSites', true)

                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Site created successfully`,
                        icon: 'CoffeeIcon',
                        variant: 'success'
                      },
                    })
                    // this.$router.push({ name: 'project-sites', params: {id: this.siteProject.uid, name: this.siteProject.title, own: 'me'} })
                    this.$router.push({
                      name: 'view-errors',
                      params: {
                        id: docRefSite.id, imgSite: existThumbnail, name: this.siteName ? this.siteName.toLowerCase() : '', url: URLdomainWithPath, project: this.siteProject.uid, own: 'me'
                      },
                    })
                  }, 1500)

                  db.collection('Projects').doc(this.siteProject.uid).update({
                    numberSites: firebase.firestore.FieldValue.increment(1),
                  })

                  db.collection('Users').doc(this.userData.uid).collection('projects').doc(this.siteProject.uid).update({
                    numberSites: firebase.firestore.FieldValue.increment(1),
                  })

                  // update data teamMembers
                })
              }
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `You have already created this site`,
                  icon: 'CoffeeIcon',
                  variant: 'warning'
                },
              })
              // setTimeout(() => {
              this.processingData = false
              // }, 1500)
            }
          })
        })
      }
    },
    crawlSite(idSite) {
      var user = auth.currentUser
      if(user && user.uid) {
          db.collection('Sites').doc(idSite).get()
          .then(eleSite => {
              // exist site
              var countSites = 0
              var pageSizes = eleSite.data().pageSizes && eleSite.data().pageSizes.length ? eleSite.data().pageSizes : []
              var pagesSite = eleSite.data().pages && eleSite.data().pages.length ? eleSite.data().pages : []
              var filterInternals = pagesSite.filter(ele => ele.isSitemap === true)
              var filterNoInternals = pagesSite.filter(ele => ele.isSitemap === false)
              var filterProPages = filterNoInternals.filter(ele => ele.pro === true)
              var filterNoProPages = filterNoInternals.filter(ele => ele.pro === false)
              var filterInlinks = filterNoProPages
  
              var condicionParaOrdenar = (pageA, pageB) => {
                return pageB.inlinks.length - pageA.inlinks.length;
              }
              filterInlinks.sort(condicionParaOrdenar)
  
              var arrayFoundPages = []
              var arrayProPages = []
              filterProPages.forEach(ele => {
                var getUrl = new URL(ele.url)
                if(getUrl.pathname !== '/') {
                  arrayProPages.push(ele.url)
                }

                arrayFoundPages.unshift(ele.url)
              })
  
              var body = {
                url: '',
                type: 'full',
                forceNotFound: 'yes',
                pageSize: 999999999,
                id: 'index-check-single-' + Date.now(),
                takeScreenshots: 'no',
                source: 'seo-cloud-full-' + Date.now(),
                triggeredBy: 'test triggered',
                screenshotType: 'test',
                screenshotComment: '',
                stringSearch: ["PHP ERROR",'Lorem','Ipsum'],
                ignoreIndexNoFollow: "yes"
              }
              var getUrl = new URL(eleSite.data().homepage);
              var hostUrl = getUrl.host;
              var httpsString = getUrl.protocol + '//';
              var urlConcat = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
              var urlBody = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
              var ultimoCaracterUrl = urlConcat.charAt(urlConcat.length - 1);
              if(ultimoCaracterUrl != "/") {
                urlConcat += "/";
              }
  
              body.id = 'index-check-single-' + Date.now()
              body.source = 'index-check-single-' + Date.now()
              body.url = getUrl.protocol + "//" + hostUrl
  
              if(getUrl.pathname === "/") {
                  body.takeScreenshots = 'no'
                  body.screenshotComment = ""
              }
              
              db.collection('Crawls').where('WebsiteURL', '==', hostUrl).where('status', '==', 'running-index').get()
              .then(queryCrawl => {
                  // only 1 loop
                  if(countSites === 0 && !queryCrawl.size) {
                      countSites += 1
                      var stringsArray = eleSite.data().stringSearch ? eleSite.data().stringSearch : []
                      var suspiciousTextArray = eleSite.data().suspiciousText ? eleSite.data().suspiciousText : []
                      var arrayStringsSuspicious = []
                      stringsArray.forEach(ele => {
                          arrayStringsSuspicious.push(ele)
                      })
  
                      suspiciousTextArray.forEach(ele => {
                          arrayStringsSuspicious.push(ele)
                      })
  
                      var getConfigCrawl = localStorage.getItem('configCrawl')
                      var limitCrawl = 100
                      var configCrawlType = 'JS FREE'
                      var configCrawlPages = 'FREE'
                      var setCrawl = false
                      if(getConfigCrawl) {
                        setCrawl = true
                        var parseConfig = JSON.parse(getConfigCrawl)
                        var logicTime = moment(parseConfig.date)
                        var actualTime = moment(new Date())
                        var diffSeconds = actualTime.diff(logicTime, 'seconds');
                        if(diffSeconds < 600) {
                          limitCrawl = parseConfig.pages
                          configCrawlType = parseConfig.crawl
                          configCrawlPages = parseConfig.pages === 25 ? 'GUESS/FREE' : parseConfig.pages === 100 ? 'FREE' : parseConfig.pages === 600 ? 'LICENSE' : 'FREE'
                        }
                        var getConfigCrawl = localStorage.removeItem('configCrawl')
                      }
                      
                      var limitCrawlSetSite = eleSite.data().limitCrawl ? eleSite.data().limitCrawl : 0
                      if(limitCrawlSetSite) {
                        if(configCrawlPages === 'GUESS/FREE' && limitCrawlSetSite < 25) {
                          limitCrawl = limitCrawlSetSite
                        } else if(configCrawlPages === 'FREE' && limitCrawlSetSite < 100) {
                          limitCrawl = limitCrawlSetSite
                        } else if(configCrawlPages === 'LICENSE') {
                          limitCrawl = limitCrawlSetSite
                        }
                      }
  
                      // add Crawl
                      db.collection('Crawls').add({
                        'IDcrawl': body.id,
                        'dateStart': Date.now(),
                        'dateStartFormat': new Date(),
                        'dateEnd': null,
                        'crawlDuration': null,
                        'status': 'running',
                        'crawltype': 'only-robots',
                        'ignoreindexnofollow': 'yes',
                        'Requests': 1,
                        'SiteID': eleSite.id,
                        'WebsiteURL': hostUrl,
                        'blockedByRobots': false,
                        'logicRequests': 1,
                        'proPages': eleSite.data().proPages.length ? eleSite.data().proPages : [urlBody],
                        'totalErrors': 0,
                        'limitCrawl': limitCrawl,
                        'stringSearch': arrayStringsSuspicious,
                        'founds': arrayFoundPages,
                        'requests': [],
                        'pages': [],
                        'inlinks': [],
                        'external': [],
                        'pageSizes': pageSizes,
                        'updated': Date.now(),
                        'plus1k': false,
                        'errorsPages': [],
                        'source': 'index-check-site',
                        'configCrawlType': 'index',
                        'configCrawlPages': 'FREE',
                        'errorsTypePage': [],
                        'timeoutPages': 0,
                        'errorsCategory': {}
                      })
                      .then((docRefCrawl) => {
                          var URLsfound = [
                              {
                                'id': null,
                                'title': null,
                                'runPosition': 1,
                                'lastRequest': null,
                                'pro': true,
                                'inlinks': [],
                                'status': null,
                                'redirectTo': null,
                                'blockedByRobots': false,
                                // 'totalErrors': 0,

                                'crawleable': true,
                                'crawled': false,
                                'crawlerInvoke': true,
                                'url': urlBody
                              },
                              // {
                              //   'id': null,
                              //   'title': null,
                              //   'runPosition': 2,
                              //   'lastRequest': null,
                              //   'pro': false,
                              //   'inlinks': [],
                              //   'status': null,
                              //   'redirectTo': null,
                              //   'blockedByRobots': false,
  
                              //   'crawleable': true,
                              //   'crawled': false,
                              //   'crawlerInvoke': true,
                              //   'url': urlConcat + "sitemap.xml"
                              // }
                          ]
  
                          db.collection('Sites').doc(eleSite.id).update({
                              mostRecentCrawlIndex: docRefCrawl.id,
                              n_log_crawls_ind: eleSite.data().n_log_crawls_ind ? firebase.firestore.FieldValue.increment(1) : 1,
                              mostRecentCrawlDateIndex: Date.now()
                              // totalErrors: 0
                          })
  
                          // add pages crawl
                          URLsfound.forEach(eleC => {
                              db.collection('Crawls').doc(docRefCrawl.id).update({
                                  requests: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                              })
  
                              db.collection('Crawls').doc(docRefCrawl.id).update({
                                  founds: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                              })
  
                              db.collection('Crawls').doc(docRefCrawl.id).update({
                                  pages: firebase.firestore.FieldValue.arrayUnion({
                                      id: '',
                                      title: '',
                                      runPosition: null,
                                      lastRequest: null,
                                      pro: false,
                                      inlinks: [],
                                      status: null,
                                      redirectTo: '',
                                      blockedByRobots: false,
                                      crawled: true,
                                      crawleable: true,
                                      url: eleC.url,
                                      isSitemap: false,
                                      hasRun: false,
                                      active: false,
                                      size: 999999999,
                                      tries: 1,
                                      tryTimestamp: Date.now()
                                  })
                              })
                              .catch(e => console.log('Error update master page: ', e.message))
                          })
  
                          // LOGIC RUN INVOKE CRAWL
                          var protocolSite = ''
                          var homepageSite = ''
                          if(eleSite.data().stringSearch.length > 0) {
                              body.stringSearch = eleSite.data().stringSearch
                          }
                          protocolSite = eleSite.data().prot ? eleSite.data().prot : getUrl.protocol
                          body.url = protocolSite + "//" + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                          homepageSite = eleSite.data().homepage ? eleSite.data().homepage : ''
                          body.takeScreenshots = 'no'
                          body.triggeredBy = eleSite.id
                          body.source = docRefCrawl.id
                          body.type = 'only-urls'
                          body.useFastCrawler = 'no'
                          body.typePage = 'homeXML'
                          body.screenshotComment = ''
  
                          var urlOrigin = body.url
                          
                          // var config = {
                          //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                          //   "method": "POST",
                          //   "timeout": 0,
                          //   "headers": {
                          //     "Content-Type": "application/json",
                          //     "Accept": "application/json"
                          //   },
                          //   "data": JSON.stringify(body),
                          // };
  
                          var config = {
                              method: 'POST',
                              url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl-index',
                              maxBodyLength: Infinity,
                              timeout: 0,
                              headers: {
                                  'Content-Type': 'application/json',
                                  'Accept': 'application/json'
                              },
                              data: JSON.stringify(body)
                          };
      
                          this.axios(config)
                          .then((response) => {
                            body.url = urlOrigin
                            body.takeScreenshots = 'no'
                            body.type = 'full'
                            body.typePage = 'normal'
                            // console.log(response.data)
                            console.log('invoke home')

                            db.collection('Crawls').doc(docRefCrawl.id).update({
                              updated: Date.now(),
                              dateEnd: Date.now(),
                              status: 'success'
                            })
                            .catch(e => console.log('Error update crawl: ', e.message))
                          })
                          .catch(e => {
                            console.log(e)
                          })
                          
                          // run crawl three initials xml
                          // body.typePage = 'normal'
                          var atomicBody = body
                          var countInvocked = 0
                          // let arraySites = [atomicBody.url + "/site-map.xml", atomicBody.url + "/sitemap.xml", atomicBody.url + "/page-sitemap.xml"]
                          // let arraySites = [atomicBody.url + (atomicBody.url.endsWith('/') ? 'sitemap.xml' : '/sitemap.xml')]
                          let arraySites = []
                          arraySites.forEach(res => {
                            var objXMLCrawl = {
                              forceNotFound: 'yes',
                              id: body.id,
                              ignoreIndexNoFollow: 'yes',
                              pageSize: 999999999,
                              screenshotComment: '',
                              screenshotType: 'test',
                              source: body.source,
                              stringSearch: body.stringSearch,
                              takeScreenshots: 'no',
                              triggeredBy: body.triggeredBy,
                              type: 'only-urls',
                              url: res,
                              useFastCrawler: 'no',
                              typePage: 'normal',
                              parseAsTextContent: 'no'
                            }
                            atomicBody.url = res
                            atomicBody.type = 'only-urls'
                            atomicBody.takeScreenshots = 'no'
                            atomicBody.typePage = 'normal'
                            
                            // var config = {
                            //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                            //   "method": "POST",
                            //   "timeout": 0,
                            //   "headers": {
                            //     "Content-Type": "application/json",
                            //     "Accept": "application/json"
                            //   },
                            //   "data": JSON.stringify(atomicBody),
                            // };
                            body.url = urlOrigin
                            body.type = 'full'

                            var config = {
                              method: 'POST',
                              url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl-index',
                              maxBodyLength: Infinity,
                              timeout: 0,
                              headers: {
                              'Content-Type': 'application/json',
                              'Accept': 'application/json'
                              },
                              data: JSON.stringify(objXMLCrawl)
                            };
            
                            this.axios(config)
                            .then((response) => {
                              countInvocked += 1
                              // body.url = urlOrigin
                              // if(countInvocked === 3) {
                              // console.log(response.data)
                              console.log('invoke init xml')
                              body.stringSearch = ["PHP ERROR",'Lorem','Ipsum']
                              body.takeScreenshots = 'no'
                              body.screenshotComment = ""
                              body.url = urlOrigin
                              // }
                            })
                            .catch(e => {
                                console.log(e)
                            })
                          })
                      })
                      .catch(e => {
                        console.log('Error add crawl site: ', e)
                      })
                  } else if(queryCrawl.size) {
                    console.log('Crawl running already!')
                  }
              })
              .catch(e => {
                console.log(e.message)
              })
          })
          .catch(e => {
            console.log('Error query sites host: ', e)
          })
      } else {
      }
    },
    takeScreenshotNow(website) {
      let data = JSON.stringify({
        "website": website
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://sslcheckerapi-h4klmyt43a-uc.a.run.app/screenshots/register',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

      this.axios(config)
      .then((response) => {
        console.log('register screenshots: ', response)
      })
      .catch((error) => {
        console.error('Error register screenshot: ', error)
      });
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style>
.vs--searchable {
    background: #ffffff;
}
.btn-continue-addsite {
    border-radius: 23px;
}
.card-sites-process-crawling {
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #CFD3E599 !important;
    border-radius: 10px;
}
.plan-active {
    background: #7838FF14;
    border: 1px solid #7838FF;
    border-radius: 10px;
}
.plan-inactive {
    background: #F0F1F7;
    border: 1px solid #DCDCF2;
    border-radius: 10px;
}
.select-project-add-site .vs__search {
  background: #ffffff !important;
}
/* .cards {
  display: flex;
}
.cards label {
  cursor: pointer;
}
.cards label input {
  display: none;
}
.cards label .text {
  color: #ae2b26;
  font-family: "roboto", arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100px;
  height: 150px;
  margin: 0 16px;
  border: 1px solid #f2f2f2;
  background: #f8f8f8;
  padding: 16px;
  text-align: center;
}
.cards label.isActive {
  font-size: 30px;
}
.cards label:hover .text{
  border-color: #ae2b26;
  box-shadow: 0 0 8px #ccc;
}
.cards label input:checked + .text {
  color: green;
  border-color: green;
} */
</style>
