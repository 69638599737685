<template>
  <div>
    <create-alert
      :idsite="siteObj.length >= 1 ? siteObj[0] : ''"
      :show-open-create-alert.sync="showOpenCreateAlert"
      :pages200.sync="pages200"
    />
    <!-- <div class="d-flex mt-50">
      <b-button
        :variant="Mobile ? 'primary' : 'light'"
        :class="Mobile ? 'px-50 py-50 mr-50 mt-50' : 'px-50 py-50 mr-50 mt-50 button-tab-unselect'"
        pill
        :style="Mobile ? 'border: 0px !important; height: 35px !important; min-height: 35px !important;' : 'color: rgb(167, 170, 185) !important; border: 0px !important; height: 35px !important; min-height: 35px !important;'"
        @click="Mobile = true, Desktop = false, History = false"
      >
        Alerts
      </b-button>
    </div> -->
    <b-row class="mt-2" style="background-color: #f8f8f8;">
      <b-col md="12">
        <b-button
          variant="info"
          class=""
          style="border-radius: 50px; vertical-align: middle; display: flex; position: absolute; right: 10px; top: 5px; z-index: 1;"
          @click="$route.name === 'shared-site' ? sharedAlert() : showOpenCreateAlert = true"
        >
          <feather-icon
            icon="BellIcon"
            class="mr-50"
          />
          <span class="align-middle">Alerts</span>
        </b-button>
        <!-- <h4 style="text-align: center;">Coming soon...</h4> -->
      </b-col>
      <b-col md="12" >
        <PerformanceComponent :urlid="urlConvert" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BTab, BCard, BCardHeader, BRow, BCol, BCardText, BCardBody, BAvatar, BTabs,
} from 'bootstrap-vue'
import CreateAlert from '@/views/extensions/acl/table/sidebar/CreateAlert.vue'
import PerformanceComponent from '@/views/apps/sites/sites-edit/site-errors/Performance/views/Performance.vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  name: 'Performance',
  components: {
    BButton,
    BTabs,
    BTab,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BCardText,
    BCardBody,
    BAvatar,
    CreateAlert,
    PerformanceComponent,
    VueApexCharts,
  },
  // props: ['active'],
  props: ['active', 'pros', 'siteObj', 'pages'],
  data() {
    return {
      Mobile: true,
      Desktop: false,
      History: false,
      goal_overview: {},
      goalOverviewRadialBar: {
        series: [85],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ['#51e5a8'],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '77%',
              },
              track: {
                background: '#ebe9f1',
                strokeWidth: '50%',
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: '#232c51',
                  fontSize: '2.86rem',
                  fontWeight: '600',
                },
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: 'round',
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
        },
      },

      showOpenCreateAlert: false,
      pages200: [],
      urlConvert: ''
    }
  },
  watch: {
    pages(val) {
      if(val && val.length) {
        this.pages200 = val.filter(ele => ele.status === 200 && !ele.cUrl.includes('.xml'))

        // console.log(this.$route.query.chg);
        if(this.$route.query.chg) {
          this.showOpenCreateAlert = true
          history.pushState(null, "", `?section=performance`)
        }
      }
    },
    siteObj(val) {
      // console.log(val)
      if(val && val.length) {
        // val.forEach(element => {
          if(val[0].domainFix) {
            if(this.esURLValida(val[0].domainFix)) {
              var urlDomain = val[0].domainFix
              var urlConvertDomain = new URL(urlDomain)
              var urlModificada = urlConvertDomain.host.replace(/\./g, "_");
              this.urlConvert = urlModificada
            }
          } else if(val[0].domain) {
            if(this.esURLValida(val[0].domain)) {
              var urlDomain = val[0].domain
              var urlConvertDomain = new URL(urlDomain)
              var urlModificada = urlConvertDomain.host.replace(/\./g, "_");
              this.urlConvert = urlModificada
            }
          }
        // });
      }
    }
  },
  methods: {
    esURLValida(url) {
      try {
        new URL(url); // Intenta crear un objeto URL
        return true;  // Si no lanza un error, es válida
      } catch (e) {
        return false; // Si lanza un error, no es válida
      }
    },
    sharedAlert() {
      localStorage.setItem('configCrawlViewSite', true)
    }
  }
}
</script>

<style>

</style>
