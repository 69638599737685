<script>
export default {
  components: {},
  props: {
    details: {
      type: Object,
      required: true,
    },
  }
}
</script>

<template>
  <div>
    <div v-for="(thumbnail, i) of details.itmes" :key="'filmstrip_'+i">
      <img :src="thumbnail.data" alt="Screenshot" />
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
