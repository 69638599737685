<!-- eslint-disable no-irregular-whitespace -->
<template>
  <b-card
      no-body
      :class="{'open': visible}"
      class="shadow-none"
      @mouseenter="collapseOpen"
      @mouseleave="collapseClose"
  >
    <b-card-header

        :class="{'collapsed': !visible}"
        :aria-expanded="visible ? 'true' : 'false'"
        :aria-controls="collapseItemID"
        role="tab"

        class="collapse_head--"
        @click="updateVisible(!visible)"
    >
      <slot name="header">
        test eheader
      </slot>
    </b-card-header>

    <b-collapse
        v-if="better || opportunities"
        :id="collapseItemID"
        v-model="visible"
        :accordion="accordion"
        role="tabpanel"
    >
      <b-card-body class="collapse_body--">
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCollapse,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    unique: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    updateVisible(val = true) {
      if (this.better || this.opportunities) {
        this.visible = val
      } else {
        this.visible = false
      }
      this.autoScroll()
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
    autoScroll() {
      if (!this.unique) {
        return
      }
      const top = document.getElementById(this.unique).offsetTop
      window.scroll({
        top,
        left: 0,
        behavior: 'smooth',
      })
    },
  },
}
</script>
