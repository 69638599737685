<template>
  <div
    class="content_history_resume w-100 resume_chart"
  >
    <div
      class="mb-1 cursor-pointer"
      @click="(val) => $emit('update:tooltipClick', null)"
    >
      <span><feather-icon
        icon="ArrowLeftIcon"
        class="inline-block text-primary"
        style="margin-top:-2px"
      /></span> <span style="font-size:14px">Back to more recent test</span>
    </div>
    <div class="mb-2 text-center">
      <h4 class="mb-0">
        {{ tooltipClick.date }}
      </h4>
      <p class="text-gray-400 mb-0">
        {{ tooltipClick.time }}
      </p>
    </div>
    <h4 class="text-left">
      Desktop:
    </h4>
    <div
      id="resumeDesktop"
      class="d-flex align-items-center"
    >
      <div style="width:66.66%">
        <div>
          <apex
            class="m-0"
            type="radialBar"
            height="200px"
            width="100%"
            :options="desktop.chartOptions"
            :series="desktop.series"
          />
        </div>
      </div>
      <div
        style="font-size: 18px;width:33.33%"
      >
        <p>
          in <br>
          {{ tooltipClick.loadtimeDesktop.y }} seconds
        </p>
        <a
          target="_blank"
          @click="$emit('update', { id: siteKey, d: tooltipClick.dKey, m: tooltipClick.mKey, selected: 'Desktop' } )"
        >view more</a>
      </div>
    </div>
    <h4 class="text-left">
      Mobile:
    </h4>
    <div
      id="resumeMobile"
      class="d-flex align-items-center"
    >
      <div style="width:66.66%">
        <div>
          <apex
            type="radialBar"
            height="200px"
            width="100%"
            :options="mobile.chartOptions"
            :series="mobile.series"
          />
        </div>
      </div>
      <div
        style="font-size: 18px;width:33.33%"
      >
        <p>
          in <br>
          {{ tooltipClick.loadtimeMobile.y }} seconds
        </p>
        <a
          target="_blank"
          @click="$emit('update', { id: siteKey, d: tooltipClick.dKey, m: tooltipClick.mKey, selected: 'Mobile' } )"
        >view more</a>
      </div>
    </div>
    <b-button v-show="false" class="fix-this mt-2">
      Fix this
    </b-button>
  </div>
</template>

<script>
import apex from 'vue-apexcharts'
import { BButton } from 'bootstrap-vue'
import functions from '../performanceFunctions'

export default {
  components: {
    BButton, apex,
  },
  props: {
    tooltipClick: {
      type: Object,
      required: true,
      default: () => {},
    },
    siteKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      desktop: {
        series: [0],
        chartOptions: {
          chart: {
            height: 250,
            type: 'radialBar',
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: '75%',
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  color: '#232C51',
                  fontSize: '36px',
                  show: true,
                },
                value: {
                  color: '#232C51',
                  fontSize: '16px',
                  show: true,
                  formatter(val) {
                    if (val <= 49) return 'bad'
                    if (val >= 50 && val <= 89) return 'average'
                    if (val >= 90) return 'excellent'
                    return ''
                  },
                },
              },
            },

          },
          fill: {
            colors: ['#53D991'],
            opacity: 1,
          },
          stroke: {
            lineCap: 'round',
          },
          labels: [0],
        },
      },
      mobile: {
        series: [0],
        chartOptions: {
          chart: {
            height: 250,
            type: 'radialBar',
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: '75%',
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  color: '#232C51',
                  fontSize: '35px',
                  show: true,
                },
                value: {
                  color: '#232C51',
                  fontSize: '16px',
                  show: true,
                  formatter(val) {
                    if (val <= 49) return 'bad'
                    if (val >= 50 && val <= 89) return 'average'
                    if (val >= 90) return 'excellent'
                    return ''
                  },
                },
              },
            },

          },
          fill: {
            colors: ['#53D991'],
            opacity: 1,
          },
          stroke: {
            lineCap: 'round',
          },
          labels: [0],
        },
      },
    }
  },
  watch: {
    tooltipClick() {
      this.updateCharts()
    },
  },
  mounted() {
    this.updateCharts()
  },
  methods: {
    updateCharts() {
      if (this.tooltipClick) {
      // Desktop
        this.desktop.series = [this.tooltipClick.performanceDesktop.y]
        this.desktop.chartOptions.labels[0] = `${this.tooltipClick.performanceDesktop.y}%`
        this.desktop.chartOptions.fill.colors[0] = functions.getColorChart(this.tooltipClick.performanceDesktop.y)
        // Mobile
        this.mobile.series = [this.tooltipClick.performanceMobile.y]
        this.mobile.chartOptions.labels[0] = `${this.tooltipClick.performanceMobile.y}%`
        this.mobile.chartOptions.fill.colors[0] = functions.getColorChart(this.tooltipClick.performanceMobile.y)
      }
    },
  },
}
</script>
