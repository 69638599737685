<script>
import {_getEntityGroupItems} from "@/views/apps/sites/sites-edit/site-errors/Performance/views/Performance/Components/performanceFunctions";
import {getCell, getItemValue} from "@/views/apps/sites/sites-edit/site-errors/Performance/@core/utils/psi-render/value-render";

export default {
  data() {
    return {
      URL_PREFIXES: ['http://', 'https://', 'data:'],
    }
  },
  methods: {
    _getEntityGroupItems,
    getCell,
    getItemValue,
    isNumericCell(heading) {
      return heading.valueType === 'numeric' || heading.valueType === 'bytes' || heading.valueType === 'ms' || heading.valueType === 'timespanMs'
    }
  },
  components: {},
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  computed: {
    groupItems() {
      return this._getEntityGroupItems(this.details)
    },
    allRows() {
      let groups = this._getEntityGroupItems(this.details)
      const results = []

      if(!groups.length) {
        return this.details.items
      }


      groups = groups.map(group => {
        return {
          ...group,
          rowType: 'group'
        }
      })

      for(const group of groups) {
        const entityName = typeof group.entity === 'string' ? group.entity : undefined;
        const items = this.details.items.filter((item) => item.entity === entityName).map((item) => {
          return {
            ...item,
            rowType: 'item'
          }
        })

        // push to results
        results.push(group)
        results.push(...items)
      }

      // plane the array of arrays and merge with groups
      return results
    }
  },
}
</script>


<template>
  <div class="module-table">


    <!-- <table class="w-full text-sm text-left mt-4 border table-fixed md:table-auto"> -->
    <table class="w-100 fs-6 text-start mt-4 w-full text-sm text-left mt-4 border table-fixed md:table-auto" style="table-layout: auto; border: 1px solid #ebe9f1 !important;">
      <thead class="text-xs text-gray-700 uppercase bg-white ">
      <tr>
        <th
            class="tw-row line-height-1 h-16 font-weight-normal text-capitalize align-middle text-base text-gray-400"
            v-for="(header, index) in details.headings"
            :data-col="JSON.stringify(header)"
            :class="[
                'audit-col-' + (String(header.valueType).toLowerCase() || 'text'),
                header.label && header.key !== 'node' ? '' : 'hidden',
                index === 0 ? 'text-left' :  header.label === '% of LCP' ? 'text-left' : ''
            ]"
        >

          {{ header.label ? header.label : '' }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(row, index) in allRows" :key="'row_' + index"
        class="hover:bg-gray-50"
      >
        <td
            class="tw-row table-cell"
            v-for="(heading) in details.headings"
            :class="{
              'bg-gray-100' : row.rowType === 'group',
              'pl-2': row.rowType === 'item',
              'text-right font-semibold': isNumericCell(heading),
              'hidden': !heading.label && heading.key !== 'node'
            }"
        >
          <div
              :data-value="JSON.stringify(row)"
              :data-heading="JSON.stringify(heading)"
              v-if="getItemValue(row, heading) && !getItemValue(row, heading).hasPlain"
              v-html="getItemValue(row, heading)"

          ></div>

          <div
              v-else
              :data-value="JSON.stringify(row)"
              :data-heading="JSON.stringify(heading)"
              :class="{
                'node-cell': heading.valueType === 'node'
              }"
              >

            <span class="inline" v-html="getItemValue(row, heading) && getItemValue(row, heading).htmlElement">

            </span>

            <span class="text-blue-500 font-mono ml-2" style="line-height: 20px">
              {{ getItemValue(row, heading) && getItemValue(row, heading).snippet}}
            </span>

          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped lang="scss">
.tw-row {
  padding: 10px 10px;
  line-height: 10px;
}

.audit-col-bytes {
  text-align: right;
}

.audit-col-ms, .audit-col-timespanms {
  @apply text-right
}

.node-cell {
  @apply flex items-center gap-2 flex-wrap
}

.table-cell {
  line-height: 1.4;
}

</style>

<style>
.node-cell img {
  max-width: 100px;
}
</style>
