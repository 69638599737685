<script>
import {getCell, getItemValue} from "@/views/apps/sites/sites-edit/site-errors/Performance/@core/utils/psi-render/value-render";
import FilmsStrip from "@/views/apps/sites/sites-edit/site-errors/Performance/views/Performance/Components/Collapse/TWCollapseParts/FilmsStrip.vue";
import Table from "@/views/apps/sites/sites-edit/site-errors/Performance/views/Performance/Components/Collapse/TWCollapseParts/Table.vue";

export default {
  components: {Table, FilmsStrip},
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      htmlList: ''
    }
  },
  methods: {
    // methods here
    getCell,
    getItemValue,
  },

}
</script>

<template>
  <div >
   <div v-for="item of details.items" >
     <FilmsStrip
         :details="item"
         v-if="item.type === 'filmstrip'" />

     <Table
         :details="item"
         v-if="item.type === 'table' || item.type === 'opportunity'" />
   </div>


  </div>
</template>

<style scoped lang="scss">

</style>
