<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    ratingLabel: {
      type: String,
      required: true,
    },
    score: {
      type: String,
      required: false,
      default: '',
    },
    displayValue: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    getAuditBadgeColor() {
      switch (this.ratingLabel) {
        case 'fail':
          return 'bg-fail';
        case 'pass':
          return 'bg-pass';
        case 'average':
          return 'bg-average';
        default:
          return 'bg-white';
      }
    }
  }
}
</script>

<template>
  <div
    @click.stop="$emit('click')"
    class="header-audit w-100 d-flex align-items-center transition-all position-relative"
    :class="{
        'border-b border-gray-200': isOpen,
    }"
  >
    <div class="position-absolute start-0 top-0 h-100 d-flex align-items-center pl-2" style="left: 0;">
      <!-- class="rounded-full w-4 h-4 ml-1 inline-block" -->
      <span
          class="rounded-circle"
          :class="[
            getAuditBadgeColor(),
            this.ratingLabel === 'informative' ? 'border-1 border-gray-600' : ''
          ]"
          style="width: 1rem; height: 1rem; margin-left: 0.25rem; display: inline-block;"
      ></span>
    </div>

    <div class="p-1 px-2 ml-3 text-lg">
      {{ title }}
      <span class="display-val" v-if="displayValue">
        — {{ displayValue }}
      </span>
    </div>

    <div class="position-absolute end-0 top-0 h-100 d-flex align-items-center pr-5" style="right: 0;">
      <span
        v-if="score"
        :class="getAuditBadgeColor()"
        class="rounded-circle text-white"
        style="padding: 5px 10px; border-radius: 9999px !important;"
      >{{score}}</span>
    </div>


    <div class="position-absolute start-0 top-0 h-100 d-flex align-items-center pr-2" style="right: 0;">
      <svg
          class="transition-all"
          :class="{
              'transform rotate-180': isOpen,
          }"
          width="1em" height="1em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m6 9l6 6l6-6"/></svg>
    </div>

  </div>
</template>

<style lang="scss" scoped>
.bg-orange-400 {
  background-color: #f59e0b;
}

.border-1 {
  border-width: 1px;
}

.header-audit {
  &:hover {
    background-color: rgba(0,0,0,0.05);
  }
  border-bottom: 1px solid rgb(229, 231, 235) !important;
}

.bg-fail {
  background-color: #FC7D7D;
}

.bg-average {
  background-color: #FFA54E;
}

.bg-pass {
  background-color: #53D991;
}

.display-val {
  color: #ca34ff;
}
</style>
