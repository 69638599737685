<template>
  <div
    class="potential_savings"
  >
    <b-table
      striped
      hover
      :items="items"
      :fields="fields"
      :small="true"
      :outlined="true"
      :fixed="true"
    >
      <template #cell(url)="data">
        <a
          v-if="(data.value).length > 51"
          target="_blank"
          :href="data.value"
          :title="data.value"
        >{{ (data.value).substr(0,25) }}...{{ (data.value).substr(data.value.length - 25) }}</a>
        <a
          v-else
          target="_blank"
          :href="data.value"
        >{{ data.value }}</a>
        <!-- <b class="text-info">{{ data.value }}</b> -->
      </template>

      <template #cell()="data">
        <i v-if="typeof (data.value) === 'number'">{{ (data.value).toLocaleString() }}</i>
        <a
          v-else-if="data.value.type === 'link'"
          target="_blank"
          :title="data.value.url"
          :href="data.value.url"
        >{{ data.value.text }}</a>
        <div
          v-else-if="data.value.type === 'node'"
          class="snippet_code"
        >
          {{ data.value.snippet }}
        </div>
        <div v-else-if="data.value.type === 'source-location'">
          <a
            v-if="(data.value.url).length > 51"
            target="_blank"
            :href="data.value.url"
            :title="data.value.url"
          >...{{ renderLink(data.value.url) }}</a>
        </div>
        <i v-else>{{ data.value }}</i>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'

export default {
  components: {
    BTable,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    fields: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  methods: {
    renderLink(link) {
      const url = new URL(link)
      const urlStr = url.pathname.toString()
      return `${urlStr.substr(urlStr.length - 25)} (${url.hostname})`
    },
  },
}
</script>
