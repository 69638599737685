export default {
    namespaced: true,
    state: {
        results: {},
        selectedType: 'desktop',
    },
    getters: {
        getPsiResults: state => {
            return state.results
        },
        getActualType: state => {
            return state.selectedType
        }
    },
    mutations: {
        SET_PDI_RESULTS(state, val) {
            //console.log('SET_PDI_RESULTS', val)
            state.results = val
        },
        SET_ACTUAL_TYPE(state, val) {
            //console.log('SET_ACTUAL_TYPE', val)
            state.selectedType = val
        }
    },
    actions: {},
}
